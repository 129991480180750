/* eslint-disable */
import {
  ItemType,
  ItemSubType,
  itemTypeFromJSON,
  itemSubTypeFromJSON,
  itemTypeToJSON,
  itemSubTypeToJSON,
} from './item';
import { Timestamp } from './google/protobuf/timestamp';
import { EffectivePermission, ItemPermissionsEntry } from './permission';
import { Labels } from './label';
import {
  SettingsEntryAsString,
  SettingsEntryAsBool,
  SettingsEntryAsNumber,
  SettingsEntryAsDate,
} from './settings';

export const protobufPackage = 'de.netfiles.pb';

export enum DocumentStatus {
  DOCUMENTSTATUS_UNSPECIFIED = 0,
  DOCUMENTSTATUS_CHECKEDIN = 1,
  DOCUMENTSTATUS_LOCKED = 2,
  DOCUMENTSTATUS_PROTECTED = 3,
  UNRECOGNIZED = -1,
}

export function documentStatusFromJSON(object: any): DocumentStatus {
  switch (object) {
    case 0:
    case 'DOCUMENTSTATUS_UNSPECIFIED':
      return DocumentStatus.DOCUMENTSTATUS_UNSPECIFIED;
    case 1:
    case 'DOCUMENTSTATUS_CHECKEDIN':
      return DocumentStatus.DOCUMENTSTATUS_CHECKEDIN;
    case 2:
    case 'DOCUMENTSTATUS_LOCKED':
      return DocumentStatus.DOCUMENTSTATUS_LOCKED;
    case 3:
    case 'DOCUMENTSTATUS_PROTECTED':
      return DocumentStatus.DOCUMENTSTATUS_PROTECTED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DocumentStatus.UNRECOGNIZED;
  }
}

export function documentStatusToJSON(object: DocumentStatus): string {
  switch (object) {
    case DocumentStatus.DOCUMENTSTATUS_UNSPECIFIED:
      return 'DOCUMENTSTATUS_UNSPECIFIED';
    case DocumentStatus.DOCUMENTSTATUS_CHECKEDIN:
      return 'DOCUMENTSTATUS_CHECKEDIN';
    case DocumentStatus.DOCUMENTSTATUS_LOCKED:
      return 'DOCUMENTSTATUS_LOCKED';
    case DocumentStatus.DOCUMENTSTATUS_PROTECTED:
      return 'DOCUMENTSTATUS_PROTECTED';
    default:
      return 'UNKNOWN';
  }
}

/** Document Object */
export interface Document {
  id: string;
  dataroomId: string;
  parentId: string;
  name: string;
  type: string;
  author: string;
  title: string;
  path?: string | undefined;
  version: string;
  documentSize: number;
  creationDate: Date | undefined;
  modificationDate: Date | undefined;
  /** documetns only */
  md5Checksum: string;
  /** any document that was converted */
  pdfVariantExists: boolean;
  effectivePermission: EffectivePermission | undefined;
  mimeType: string;
  pdfVariantCreatable: boolean;
  thumbnailId: string;
  description: string;
  documentClass: string;
  documentStatistics?: Statistics | undefined;
  permissions: ItemPermissionsEntry[];
  restorePath?: string | undefined;
  parents?: Parents | undefined;
  parentTitle?: string | undefined;
  sequence?: string | undefined;
  index?: string | undefined;
  noIndex?: boolean | undefined;
  isExpired?: boolean | undefined;
  isRead?: boolean | undefined;
  itemType: ItemType;
  itemSubtype?: ItemSubType | undefined;
  isApproved?: boolean | undefined;
  labels?: Labels | undefined;
  hasDifferentParentRights?: boolean | undefined;
  status?: DocumentStatus | undefined;
  statusSetBy?: string | undefined;
  numberOfComments?: number | undefined;
  versionOwner?: string | undefined;
  parent?: Parent | undefined;
  canApprove?: boolean | undefined;
  approvedOn?: Date | undefined;
  approvedBy?: string | undefined;
  numberOfSharedAnnotations?: number | undefined;
}

/** Defines one parent */
export interface Parent {
  id: string;
  name: string;
  title: string;
  itemType: ItemType;
  index?: string | undefined;
}

/** Defines array of parents */
export interface Parents {
  items: Parent[];
}

/** Statistics */
export interface Statistics {
  accessCount?: number | undefined;
  lastAccess?: Date | undefined;
}

/** Property for document status */
export interface SettingsEntryAsDocumentStatus {
  value: DocumentStatus;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
}

/** Property for document status */
export interface SettingsEntryAsStatistics {
  value: Statistics | undefined;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
}

/** Document Properties */
export interface DocumentProperties {
  id: string;
  name?: SettingsEntryAsString | undefined;
  author?: SettingsEntryAsString | undefined;
  title?: SettingsEntryAsString | undefined;
  sequence?: SettingsEntryAsString | undefined;
  index?: SettingsEntryAsString | undefined;
  noIndex?: SettingsEntryAsBool | undefined;
  path?: SettingsEntryAsString | undefined;
  version?: SettingsEntryAsString | undefined;
  versionOwner?: SettingsEntryAsString | undefined;
  documentSize?: SettingsEntryAsNumber | undefined;
  creationDate?: SettingsEntryAsDate | undefined;
  modificationDate?: SettingsEntryAsDate | undefined;
  description?: SettingsEntryAsString | undefined;
  documentStatistics?: SettingsEntryAsStatistics | undefined;
  tags?: SettingsEntryAsString | undefined;
  url?: SettingsEntryAsString | undefined;
  isRead?: SettingsEntryAsBool | undefined;
  status?: SettingsEntryAsDocumentStatus | undefined;
  statusSetBy?: SettingsEntryAsString | undefined;
  approvalGroup?: SettingsEntryAsString | undefined;
  approvedOn?: SettingsEntryAsDate | undefined;
  approvedBy?: SettingsEntryAsString | undefined;
  isApproved?: SettingsEntryAsBool | undefined;
  expirationDate?: SettingsEntryAsDate | undefined;
  deletedOn?: SettingsEntryAsDate | undefined;
  hasDifferentParentRights?: SettingsEntryAsBool | undefined;
  itemType: ItemType;
  itemSubtype?: ItemSubType | undefined;
}

function createBaseDocument(): Document {
  return {
    id: '',
    dataroomId: '',
    parentId: '',
    name: '',
    type: '',
    author: '',
    title: '',
    path: undefined,
    version: '',
    documentSize: 0,
    creationDate: undefined,
    modificationDate: undefined,
    md5Checksum: '',
    pdfVariantExists: false,
    effectivePermission: undefined,
    mimeType: '',
    pdfVariantCreatable: false,
    thumbnailId: '',
    description: '',
    documentClass: '',
    documentStatistics: undefined,
    permissions: [],
    restorePath: undefined,
    parents: undefined,
    parentTitle: undefined,
    sequence: undefined,
    index: undefined,
    noIndex: undefined,
    isExpired: undefined,
    isRead: undefined,
    itemType: 0,
    itemSubtype: undefined,
    isApproved: undefined,
    labels: undefined,
    hasDifferentParentRights: undefined,
    status: undefined,
    statusSetBy: undefined,
    numberOfComments: undefined,
    versionOwner: undefined,
    parent: undefined,
    canApprove: undefined,
    approvedOn: undefined,
    approvedBy: undefined,
    numberOfSharedAnnotations: undefined,
  };
}

export const Document = {
  fromJSON(object: any): Document {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      parentId: isSet(object.parentId) ? String(object.parentId) : '',
      name: isSet(object.name) ? String(object.name) : '',
      type: isSet(object.type) ? String(object.type) : '',
      author: isSet(object.author) ? String(object.author) : '',
      title: isSet(object.title) ? String(object.title) : '',
      path: isSet(object.path) ? String(object.path) : undefined,
      version: isSet(object.version) ? String(object.version) : '',
      documentSize: isSet(object.documentSize)
        ? Number(object.documentSize)
        : 0,
      creationDate: isSet(object.creationDate)
        ? fromJsonTimestamp(object.creationDate)
        : undefined,
      modificationDate: isSet(object.modificationDate)
        ? fromJsonTimestamp(object.modificationDate)
        : undefined,
      md5Checksum: isSet(object.md5Checksum) ? String(object.md5Checksum) : '',
      pdfVariantExists: isSet(object.pdfVariantExists)
        ? Boolean(object.pdfVariantExists)
        : false,
      effectivePermission: isSet(object.effectivePermission)
        ? EffectivePermission.fromJSON(object.effectivePermission)
        : undefined,
      mimeType: isSet(object.mimeType) ? String(object.mimeType) : '',
      pdfVariantCreatable: isSet(object.pdfVariantCreatable)
        ? Boolean(object.pdfVariantCreatable)
        : false,
      thumbnailId: isSet(object.thumbnailId) ? String(object.thumbnailId) : '',
      description: isSet(object.description) ? String(object.description) : '',
      documentClass: isSet(object.documentClass)
        ? String(object.documentClass)
        : '',
      documentStatistics: isSet(object.documentStatistics)
        ? Statistics.fromJSON(object.documentStatistics)
        : undefined,
      permissions: Array.isArray(object?.permissions)
        ? object.permissions.map((e: any) => ItemPermissionsEntry.fromJSON(e))
        : [],
      restorePath: isSet(object.restorePath)
        ? String(object.restorePath)
        : undefined,
      parents: isSet(object.parents)
        ? Parents.fromJSON(object.parents)
        : undefined,
      parentTitle: isSet(object.parentTitle)
        ? String(object.parentTitle)
        : undefined,
      sequence: isSet(object.sequence) ? String(object.sequence) : undefined,
      index: isSet(object.index) ? String(object.index) : undefined,
      noIndex: isSet(object.noIndex) ? Boolean(object.noIndex) : undefined,
      isExpired: isSet(object.isExpired)
        ? Boolean(object.isExpired)
        : undefined,
      isRead: isSet(object.isRead) ? Boolean(object.isRead) : undefined,
      itemType: isSet(object.itemType) ? itemTypeFromJSON(object.itemType) : 0,
      itemSubtype: isSet(object.itemSubtype)
        ? itemSubTypeFromJSON(object.itemSubtype)
        : undefined,
      isApproved: isSet(object.isApproved)
        ? Boolean(object.isApproved)
        : undefined,
      labels: isSet(object.labels) ? Labels.fromJSON(object.labels) : undefined,
      hasDifferentParentRights: isSet(object.hasDifferentParentRights)
        ? Boolean(object.hasDifferentParentRights)
        : undefined,
      status: isSet(object.status)
        ? documentStatusFromJSON(object.status)
        : undefined,
      statusSetBy: isSet(object.statusSetBy)
        ? String(object.statusSetBy)
        : undefined,
      numberOfComments: isSet(object.numberOfComments)
        ? Number(object.numberOfComments)
        : undefined,
      versionOwner: isSet(object.versionOwner)
        ? String(object.versionOwner)
        : undefined,
      parent: isSet(object.parent) ? Parent.fromJSON(object.parent) : undefined,
      canApprove: isSet(object.canApprove)
        ? Boolean(object.canApprove)
        : undefined,
      approvedOn: isSet(object.approvedOn)
        ? fromJsonTimestamp(object.approvedOn)
        : undefined,
      approvedBy: isSet(object.approvedBy)
        ? String(object.approvedBy)
        : undefined,
      numberOfSharedAnnotations: isSet(object.numberOfSharedAnnotations)
        ? Number(object.numberOfSharedAnnotations)
        : undefined,
    };
  },

  toJSON(message: Document): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.parentId !== undefined && (obj.parentId = message.parentId);
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined && (obj.type = message.type);
    message.author !== undefined && (obj.author = message.author);
    message.title !== undefined && (obj.title = message.title);
    message.path !== undefined && (obj.path = message.path);
    message.version !== undefined && (obj.version = message.version);
    message.documentSize !== undefined &&
      (obj.documentSize = Math.round(message.documentSize));
    message.creationDate !== undefined &&
      (obj.creationDate = message.creationDate.toISOString());
    message.modificationDate !== undefined &&
      (obj.modificationDate = message.modificationDate.toISOString());
    message.md5Checksum !== undefined &&
      (obj.md5Checksum = message.md5Checksum);
    message.pdfVariantExists !== undefined &&
      (obj.pdfVariantExists = message.pdfVariantExists);
    message.effectivePermission !== undefined &&
      (obj.effectivePermission = message.effectivePermission
        ? EffectivePermission.toJSON(message.effectivePermission)
        : undefined);
    message.mimeType !== undefined && (obj.mimeType = message.mimeType);
    message.pdfVariantCreatable !== undefined &&
      (obj.pdfVariantCreatable = message.pdfVariantCreatable);
    message.thumbnailId !== undefined &&
      (obj.thumbnailId = message.thumbnailId);
    message.description !== undefined &&
      (obj.description = message.description);
    message.documentClass !== undefined &&
      (obj.documentClass = message.documentClass);
    message.documentStatistics !== undefined &&
      (obj.documentStatistics = message.documentStatistics
        ? Statistics.toJSON(message.documentStatistics)
        : undefined);
    if (message.permissions) {
      obj.permissions = message.permissions.map(e =>
        e ? ItemPermissionsEntry.toJSON(e) : undefined,
      );
    } else {
      obj.permissions = [];
    }
    message.restorePath !== undefined &&
      (obj.restorePath = message.restorePath);
    message.parents !== undefined &&
      (obj.parents = message.parents
        ? Parents.toJSON(message.parents)
        : undefined);
    message.parentTitle !== undefined &&
      (obj.parentTitle = message.parentTitle);
    message.sequence !== undefined && (obj.sequence = message.sequence);
    message.index !== undefined && (obj.index = message.index);
    message.noIndex !== undefined && (obj.noIndex = message.noIndex);
    message.isExpired !== undefined && (obj.isExpired = message.isExpired);
    message.isRead !== undefined && (obj.isRead = message.isRead);
    message.itemType !== undefined &&
      (obj.itemType = itemTypeToJSON(message.itemType));
    message.itemSubtype !== undefined &&
      (obj.itemSubtype =
        message.itemSubtype !== undefined
          ? itemSubTypeToJSON(message.itemSubtype)
          : undefined);
    message.isApproved !== undefined && (obj.isApproved = message.isApproved);
    message.labels !== undefined &&
      (obj.labels = message.labels ? Labels.toJSON(message.labels) : undefined);
    message.hasDifferentParentRights !== undefined &&
      (obj.hasDifferentParentRights = message.hasDifferentParentRights);
    message.status !== undefined &&
      (obj.status =
        message.status !== undefined
          ? documentStatusToJSON(message.status)
          : undefined);
    message.statusSetBy !== undefined &&
      (obj.statusSetBy = message.statusSetBy);
    message.numberOfComments !== undefined &&
      (obj.numberOfComments = Math.round(message.numberOfComments));
    message.versionOwner !== undefined &&
      (obj.versionOwner = message.versionOwner);
    message.parent !== undefined &&
      (obj.parent = message.parent ? Parent.toJSON(message.parent) : undefined);
    message.canApprove !== undefined && (obj.canApprove = message.canApprove);
    message.approvedOn !== undefined &&
      (obj.approvedOn = message.approvedOn.toISOString());
    message.approvedBy !== undefined && (obj.approvedBy = message.approvedBy);
    message.numberOfSharedAnnotations !== undefined &&
      (obj.numberOfSharedAnnotations = Math.round(
        message.numberOfSharedAnnotations,
      ));
    return obj;
  },
};

function createBaseParent(): Parent {
  return { id: '', name: '', title: '', itemType: 0, index: undefined };
}

export const Parent = {
  fromJSON(object: any): Parent {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name) ? String(object.name) : '',
      title: isSet(object.title) ? String(object.title) : '',
      itemType: isSet(object.itemType) ? itemTypeFromJSON(object.itemType) : 0,
      index: isSet(object.index) ? String(object.index) : undefined,
    };
  },

  toJSON(message: Parent): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.title !== undefined && (obj.title = message.title);
    message.itemType !== undefined &&
      (obj.itemType = itemTypeToJSON(message.itemType));
    message.index !== undefined && (obj.index = message.index);
    return obj;
  },
};

function createBaseParents(): Parents {
  return { items: [] };
}

export const Parents = {
  fromJSON(object: any): Parents {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => Parent.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Parents): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map(e => (e ? Parent.toJSON(e) : undefined));
    } else {
      obj.items = [];
    }
    return obj;
  },
};

function createBaseStatistics(): Statistics {
  return { accessCount: undefined, lastAccess: undefined };
}

export const Statistics = {
  fromJSON(object: any): Statistics {
    return {
      accessCount: isSet(object.accessCount)
        ? Number(object.accessCount)
        : undefined,
      lastAccess: isSet(object.lastAccess)
        ? fromJsonTimestamp(object.lastAccess)
        : undefined,
    };
  },

  toJSON(message: Statistics): unknown {
    const obj: any = {};
    message.accessCount !== undefined &&
      (obj.accessCount = Math.round(message.accessCount));
    message.lastAccess !== undefined &&
      (obj.lastAccess = message.lastAccess.toISOString());
    return obj;
  },
};

function createBaseSettingsEntryAsDocumentStatus(): SettingsEntryAsDocumentStatus {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
  };
}

export const SettingsEntryAsDocumentStatus = {
  fromJSON(object: any): SettingsEntryAsDocumentStatus {
    return {
      value: isSet(object.value) ? documentStatusFromJSON(object.value) : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsDocumentStatus): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = documentStatusToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    return obj;
  },
};

function createBaseSettingsEntryAsStatistics(): SettingsEntryAsStatistics {
  return {
    value: undefined,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
  };
}

export const SettingsEntryAsStatistics = {
  fromJSON(object: any): SettingsEntryAsStatistics {
    return {
      value: isSet(object.value)
        ? Statistics.fromJSON(object.value)
        : undefined,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsStatistics): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = message.value
        ? Statistics.toJSON(message.value)
        : undefined);
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    return obj;
  },
};

function createBaseDocumentProperties(): DocumentProperties {
  return {
    id: '',
    name: undefined,
    author: undefined,
    title: undefined,
    sequence: undefined,
    index: undefined,
    noIndex: undefined,
    path: undefined,
    version: undefined,
    versionOwner: undefined,
    documentSize: undefined,
    creationDate: undefined,
    modificationDate: undefined,
    description: undefined,
    documentStatistics: undefined,
    tags: undefined,
    url: undefined,
    isRead: undefined,
    status: undefined,
    statusSetBy: undefined,
    approvalGroup: undefined,
    approvedOn: undefined,
    approvedBy: undefined,
    isApproved: undefined,
    expirationDate: undefined,
    deletedOn: undefined,
    hasDifferentParentRights: undefined,
    itemType: 0,
    itemSubtype: undefined,
  };
}

export const DocumentProperties = {
  fromJSON(object: any): DocumentProperties {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name)
        ? SettingsEntryAsString.fromJSON(object.name)
        : undefined,
      author: isSet(object.author)
        ? SettingsEntryAsString.fromJSON(object.author)
        : undefined,
      title: isSet(object.title)
        ? SettingsEntryAsString.fromJSON(object.title)
        : undefined,
      sequence: isSet(object.sequence)
        ? SettingsEntryAsString.fromJSON(object.sequence)
        : undefined,
      index: isSet(object.index)
        ? SettingsEntryAsString.fromJSON(object.index)
        : undefined,
      noIndex: isSet(object.noIndex)
        ? SettingsEntryAsBool.fromJSON(object.noIndex)
        : undefined,
      path: isSet(object.path)
        ? SettingsEntryAsString.fromJSON(object.path)
        : undefined,
      version: isSet(object.version)
        ? SettingsEntryAsString.fromJSON(object.version)
        : undefined,
      versionOwner: isSet(object.versionOwner)
        ? SettingsEntryAsString.fromJSON(object.versionOwner)
        : undefined,
      documentSize: isSet(object.documentSize)
        ? SettingsEntryAsNumber.fromJSON(object.documentSize)
        : undefined,
      creationDate: isSet(object.creationDate)
        ? SettingsEntryAsDate.fromJSON(object.creationDate)
        : undefined,
      modificationDate: isSet(object.modificationDate)
        ? SettingsEntryAsDate.fromJSON(object.modificationDate)
        : undefined,
      description: isSet(object.description)
        ? SettingsEntryAsString.fromJSON(object.description)
        : undefined,
      documentStatistics: isSet(object.documentStatistics)
        ? SettingsEntryAsStatistics.fromJSON(object.documentStatistics)
        : undefined,
      tags: isSet(object.tags)
        ? SettingsEntryAsString.fromJSON(object.tags)
        : undefined,
      url: isSet(object.url)
        ? SettingsEntryAsString.fromJSON(object.url)
        : undefined,
      isRead: isSet(object.isRead)
        ? SettingsEntryAsBool.fromJSON(object.isRead)
        : undefined,
      status: isSet(object.status)
        ? SettingsEntryAsDocumentStatus.fromJSON(object.status)
        : undefined,
      statusSetBy: isSet(object.statusSetBy)
        ? SettingsEntryAsString.fromJSON(object.statusSetBy)
        : undefined,
      approvalGroup: isSet(object.approvalGroup)
        ? SettingsEntryAsString.fromJSON(object.approvalGroup)
        : undefined,
      approvedOn: isSet(object.approvedOn)
        ? SettingsEntryAsDate.fromJSON(object.approvedOn)
        : undefined,
      approvedBy: isSet(object.approvedBy)
        ? SettingsEntryAsString.fromJSON(object.approvedBy)
        : undefined,
      isApproved: isSet(object.isApproved)
        ? SettingsEntryAsBool.fromJSON(object.isApproved)
        : undefined,
      expirationDate: isSet(object.expirationDate)
        ? SettingsEntryAsDate.fromJSON(object.expirationDate)
        : undefined,
      deletedOn: isSet(object.deletedOn)
        ? SettingsEntryAsDate.fromJSON(object.deletedOn)
        : undefined,
      hasDifferentParentRights: isSet(object.hasDifferentParentRights)
        ? SettingsEntryAsBool.fromJSON(object.hasDifferentParentRights)
        : undefined,
      itemType: isSet(object.itemType) ? itemTypeFromJSON(object.itemType) : 0,
      itemSubtype: isSet(object.itemSubtype)
        ? itemSubTypeFromJSON(object.itemSubtype)
        : undefined,
    };
  },

  toJSON(message: DocumentProperties): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined &&
      (obj.name = message.name
        ? SettingsEntryAsString.toJSON(message.name)
        : undefined);
    message.author !== undefined &&
      (obj.author = message.author
        ? SettingsEntryAsString.toJSON(message.author)
        : undefined);
    message.title !== undefined &&
      (obj.title = message.title
        ? SettingsEntryAsString.toJSON(message.title)
        : undefined);
    message.sequence !== undefined &&
      (obj.sequence = message.sequence
        ? SettingsEntryAsString.toJSON(message.sequence)
        : undefined);
    message.index !== undefined &&
      (obj.index = message.index
        ? SettingsEntryAsString.toJSON(message.index)
        : undefined);
    message.noIndex !== undefined &&
      (obj.noIndex = message.noIndex
        ? SettingsEntryAsBool.toJSON(message.noIndex)
        : undefined);
    message.path !== undefined &&
      (obj.path = message.path
        ? SettingsEntryAsString.toJSON(message.path)
        : undefined);
    message.version !== undefined &&
      (obj.version = message.version
        ? SettingsEntryAsString.toJSON(message.version)
        : undefined);
    message.versionOwner !== undefined &&
      (obj.versionOwner = message.versionOwner
        ? SettingsEntryAsString.toJSON(message.versionOwner)
        : undefined);
    message.documentSize !== undefined &&
      (obj.documentSize = message.documentSize
        ? SettingsEntryAsNumber.toJSON(message.documentSize)
        : undefined);
    message.creationDate !== undefined &&
      (obj.creationDate = message.creationDate
        ? SettingsEntryAsDate.toJSON(message.creationDate)
        : undefined);
    message.modificationDate !== undefined &&
      (obj.modificationDate = message.modificationDate
        ? SettingsEntryAsDate.toJSON(message.modificationDate)
        : undefined);
    message.description !== undefined &&
      (obj.description = message.description
        ? SettingsEntryAsString.toJSON(message.description)
        : undefined);
    message.documentStatistics !== undefined &&
      (obj.documentStatistics = message.documentStatistics
        ? SettingsEntryAsStatistics.toJSON(message.documentStatistics)
        : undefined);
    message.tags !== undefined &&
      (obj.tags = message.tags
        ? SettingsEntryAsString.toJSON(message.tags)
        : undefined);
    message.url !== undefined &&
      (obj.url = message.url
        ? SettingsEntryAsString.toJSON(message.url)
        : undefined);
    message.isRead !== undefined &&
      (obj.isRead = message.isRead
        ? SettingsEntryAsBool.toJSON(message.isRead)
        : undefined);
    message.status !== undefined &&
      (obj.status = message.status
        ? SettingsEntryAsDocumentStatus.toJSON(message.status)
        : undefined);
    message.statusSetBy !== undefined &&
      (obj.statusSetBy = message.statusSetBy
        ? SettingsEntryAsString.toJSON(message.statusSetBy)
        : undefined);
    message.approvalGroup !== undefined &&
      (obj.approvalGroup = message.approvalGroup
        ? SettingsEntryAsString.toJSON(message.approvalGroup)
        : undefined);
    message.approvedOn !== undefined &&
      (obj.approvedOn = message.approvedOn
        ? SettingsEntryAsDate.toJSON(message.approvedOn)
        : undefined);
    message.approvedBy !== undefined &&
      (obj.approvedBy = message.approvedBy
        ? SettingsEntryAsString.toJSON(message.approvedBy)
        : undefined);
    message.isApproved !== undefined &&
      (obj.isApproved = message.isApproved
        ? SettingsEntryAsBool.toJSON(message.isApproved)
        : undefined);
    message.expirationDate !== undefined &&
      (obj.expirationDate = message.expirationDate
        ? SettingsEntryAsDate.toJSON(message.expirationDate)
        : undefined);
    message.deletedOn !== undefined &&
      (obj.deletedOn = message.deletedOn
        ? SettingsEntryAsDate.toJSON(message.deletedOn)
        : undefined);
    message.hasDifferentParentRights !== undefined &&
      (obj.hasDifferentParentRights = message.hasDifferentParentRights
        ? SettingsEntryAsBool.toJSON(message.hasDifferentParentRights)
        : undefined);
    message.itemType !== undefined &&
      (obj.itemType = itemTypeToJSON(message.itemType));
    message.itemSubtype !== undefined &&
      (obj.itemSubtype =
        message.itemSubtype !== undefined
          ? itemSubTypeToJSON(message.itemSubtype)
          : undefined);
    return obj;
  },
};

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
