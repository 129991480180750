export default {
  general: {
    appName: 'netfiles',
    cancel: 'Cancel',
    logout: 'Logout',
    continue: 'Continue',
  },
  login: {
    accept: 'Accept invitation',
    backToLogin: 'Back to login',
    forgotPassword: 'Forgot password?',
    forgotPasswordConfirmText:
      'If the e-mail address/user name is associated with a netfiles account, we will send you an e-mail with a link to reset your password. If you do not receive this e-mail within the next few minutes, please check your spam folder.',
    forgotPasswordConfirmTitle: 'Please check your e‑mail inbox',
    login: 'Login',
    loginError: 'Wrong username or password. Please try again.',
    verifyPasswordError: 'Wrong password. Please try again.',
    verifyPasswordMessage:
      'The password requirements have changed. Please enter your password to verify it.',
    verifyEmailMessage:
      'The security provisions for the netfiles data room, which you use, require regular verification of your e‑mail address.',
    verifyEmailMessageSecond:
      'To verify the validity of you e‑mail address, please enter the code that we sent you in an e-mail.',
    verifyEmailError: 'The e-mail could not be verified',
    emailVerificationCode: 'Security Code',
    verifyEmail: 'Verify E-mail',
    loginWithPasskey: 'Login with Passkey',
    loginWithPassword: 'Login with password',
    passkey: 'Passkey',
    password: 'Password',
    redirecting: 'Logging you in...',
    resetPassword: 'Reset password',
    usernameEmail: 'Username / E-Mail',
    autoLogoutMessage: 'You have been logged out due to security reasons.',
    autoLogoutPasswordChangeMessage:
      'You successfully changed your password. Please log in again.',
    autoLogoutPasswordCheckTimeoutMessage:
      'You have been logged out due to security reasons.',
    accountLocked:
      'This username/e-mail is now locked. If there is an account linked to the username/e-mail then an e-mail has been sent to the stored e-mail address.',
  },
  authDialog: {
    enablePasskeyTitle: 'Enable Passkey',
    enablePasskeyMessage:
      'You have successfully logged in. To make logging in easier and more secure in future, you can now create a passkey for the netfiles login.',
    enablePasskeyExplanation:
      'Passkeys allow you to confirm your identity with your fingerprint, your face or your screen lock. You should only create a passkey if this is your device.',
    enablePasskeyButton: 'Continue',
    doNotEnablePasskeyButton: 'Not now',
  },
  passkeyDialog: {
    title: 'Creating Passkey',
    messageWorking:
      'Creating your passkey. This may take a few seconds. Please wait.',
    messageSucceeded:
      'Passkey created successfully. You can now log in to netfiles without password. Click "Continue" to enter the data room.',
    messageFailed:
      'Passkey could not be created. Try again next time you log in.',
    dismiss: 'Continue',
  },
  twoFaDialog: {
    securityCode: 'Security code',
    title: 'Enter security code',
    text_1:
      'To authorize the login, please enter the security code that we sent by text message to your mobile phone ({tanSendAddress}) (Message-ID: {messageId}).',
    text_2:
      'To authorize the login, please enter the security code that we sent by e-mail ({tanSendAddress}) (Message-ID: {messageId}).',
    text_3:
      'To authorize the login, please enter the security code from your Authenticator/OTP-App.',
    errorMessage: 'Cannot verify security code',
    confirmTitle: 'No security code received?',
    confirmText:
      'If you have not received a security code, please wait two minutes. Then click “Resend code”. If you still don’t receive a code, contact the administrator of the data room.<br><br>netfiles recommends 2-factor authentication with a one-time password via an authenticator/OTP app. This way you no longer depend on delivery of the security code by text message or e-mail. For further information visit the netfiles helpcenter.',
    confirmProceed: 'Resend Code',
    confirmTitleSms: 'Problems with Authenticator App?',
    confirmTextSms:
      'If you have problems using the Authenticator App, try the alternative login verification via text message to your mobile phone.',
    confirmProceedSms: 'Send Code',
    mobilePhoneNr: 'Mobile phone',
    mobileText: 'A mobile phone number is needed to receive text messages',
    saveMobile: 'Save number and resend code',
    setMobile: {
      title: 'Enter/Reset mobile number',
      text_1:
        'For the 2-factor authentication via text message, a valid mobile number must be stored for your user account. Please make sure that the entered number can be reached via the cellular network.',
      text_2:
        'The necessary security code has just been sent to you by e-mail.',
    },
  },
  forms: {
    validation: {
      required: 'This field is required.',
      invalidEmail: 'Invalid e-mail address.',
      invalidUsername: 'Invalid username.',
      invalidPassword: 'Invalid password.',
      invalidIntPhoneNumber: 'Please use international format +49 123 12…',
    },
  },
  securityQuestion: {
    securityQuestion: 'Security Question and Answer',
    setSecurityQuestion: 'Set Security question',
    securityQuestionText:
      'Select a security question and define an answer. You must answer the security question correctly if you want to reset your login password.',
    question: 'Question',
    answer: 'Answer',
    otherQuestion: 'Other questions',
  },
  checkPassword: {
    checkPassword: 'Check password',
  },
  footer: {
    url: {
      title: 'www.netfiles.com',
      url: 'https://www.netfiles.com/en',
    },
    tac: {
      title: 'Terms of Use',
      url: 'https://www.netfiles.com/en/about-us/terms-and-conditions-of-use',
    },
    privacy: {
      title: 'Privacy Policy',
      url: 'https://www.netfiles.com/en/about-us/data-privacy-policy',
    },
    imprint: {
      title: 'Imprint',
      url: 'https://www.netfiles.com/en/imprint',
    },
    help: {
      title: 'Help Center',
      url: 'https://help.netfiles.de/en',
    },
  },
};
