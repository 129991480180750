import { ApiCodes } from './ApiCodes';

/**
 * This class holds errors from the json-api. Herein a possible transformation of errors to client-side errors could be made
 */
export class ApiError {
  errors: {
    field?: string;
    code: string;
    args?: Record<string, string>;
  }[];

  constructor(
    data: {
      // text = Text to use for logging only
      text?: string;
      // apicode = Error code-string that MIGHT be forwarded to client
      apicode?: string;
      // additionalData = Data to make available to client for building error message
      additionalData?: Record<string, string>;
    }[],
  ) {
    const allErrors = [];

    for (const error of data) {
      if (error.text !== undefined && error.apicode === undefined) {
        // Pure text errors should not be forwarded to client. Use generic error code instead
        error.apicode = ApiCodes.internalError;
      }

      // Can have a check here that transforms apicode to client-side error code
      // For now, we always forward the apicode to client

      const err: {
        code: string;
        args: Record<string, string>;
      } = {
        code: error.apicode ?? ApiCodes.internalError,
        args: error.additionalData ?? {},
      };

      allErrors.push(err);
    }

    this.errors = allErrors;
  }

  toString() {
    return JSON.stringify(this.errors);
  }
}
