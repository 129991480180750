export enum DataroomAttributes {
  DataroomAttributes_dataroomName = 'dataroomName',
  // General
  DataroomAttributes_showFolderOnTop = 'showFolderOnTop',
  DataroomAttributes_showTimeCreated = 'showTimeCreated',
  DataroomAttributes_showDateWithTime = 'showDateWithTime',
  DataroomAttributes_showVersionAuthor = 'showVersionAuthor',
  DataroomAttributes_showNumberOfDocuments = 'showNumberOfDocuments',
  DataroomAttributes_logActivityRecursive = 'logActivityRecursive',
  DataroomAttributes_downloadListFormat = 'downloadListFormat',
  DataroomAttributes_emailSenderName = 'emailSenderName',
  // Document
  DataroomAttributes_requireDocumentApproval = 'requireDocumentApproval',
  DataroomAttributes_notifyAboutChanges = 'notifyAboutChanges',
  DataroomAttributes_notifyAboutChangesForUnconfirmedUsers = 'notifyAboutChangesForUnconfirmedUsers',
  DataroomAttributes_useVersionControl = 'useVersionControl',
  DataroomAttributes_useGroupLabels = 'useGroupLabels',
  DataroomAttributes_usePrivateLabels = 'usePrivateLabels',
  DataroomAttributes_createOfficeDocuments = 'createOfficeDocuments',
  DataroomAttributes_showOfficeDocumentsNavigable = 'showOfficeDocumentsNavigable',
  DataroomAttributes_createTextDocuments = 'createTextDocuments',
  DataroomAttributes_downloadInsteadOpen = 'downloadInsteadOpen',
  DataroomAttributes_downloadZip = 'downloadZip',
  DataroomAttributes_allowFolderDownload = 'allowFolderDownload',
  DataroomAttributes_useFulltextSearch = 'useFulltextSearch',
  DataroomAttributes_createDocumentAnnotations = 'createDocumentAnnotations',
  DataroomAttributes_createDocumentRedactions = 'createDocumentRedactions',
  DataroomAttributes_createSharedDocumentAnnotations = 'createSharedDocumentAnnotations',
  DataroomAttributes_showPermissionsIcon = 'showPermissionsIcon',
  DataroomAttributes_daysExpiredDocumentsHidden = 'daysExpiredDocumentsHidden',
  DataroomAttributes_daysEmptyTrash = 'daysEmptyTrash',
  // security
  DataroomAttributes_showLoginActivity = 'showLoginActivity',
  DataroomAttributes_sendEmailForNewDevices = 'sendEmailForNewDevices',
  DataroomAttributes_autoLogoff = 'autoLogoff',
  DataroomAttributes_loginVerification = 'loginVerification',
  DataroomAttributes_excludeIpRange = 'excludeIpRange',
  DataroomAttributes_scanForVirus = 'scanForVirus',
  DataroomAttributes_encryptFiles = 'encryptFiles',
  DataroomAttributes_usePermissionReadOnly = 'usePermissionReadOnly',
  DataroomAttributes_usePermissionDownloadPdf = 'usePermissionDownloadPdf',
  DataroomAttributes_useWatermarksPdf = 'useWatermarksPdf',
  DataroomAttributes_downloadPdfsEncrypted = 'downloadPdfsEncrypted',
  // Password
  DataroomAttributes_passwordMinLength = 'passwordMinLength',
  DataroomAttributes_passwordRequiresSpecialChars = 'passwordRequiresSpecialChars',
  DataroomAttributes_passwordRequiresNumbers = 'passwordRequiresNumbers',
  DataroomAttributes_passwordRequiresMixedLetters = 'passwordRequiresMixedLetters',
  DataroomAttributes_passwordChangeInterval = 'passwordChangeInterval',
  DataroomAttributes_passwordReset = 'passwordReset',
  DataroomAttributes_passwordResetByAdmin = 'passwordResetByAdmin',
  // Index
  DataroomAttributes_useFileIndex = 'useFileIndex',
  DataroomAttributes_spreadUploadByIndex = 'spreadUploadByIndex',
  DataroomAttributes_freezeIndex = 'freezeIndex',
  DataroomAttributes_sortIndex = 'sortIndex',

  // User
  DataroomAttributes_markUnseenDocuments = 'markUnseenDocuments',
  DataroomAttributes_userMayChangeRights = 'userMayChangeRights',
  DataroomAttributes_userMayTrashFiles = 'userMayTrashFiles',
  DataroomAttributes_showAdminGroup = 'showAdminGroup',
  DataroomAttributes_defaultVisibility = 'defaultVisibility',
  DataroomAttributes_allowEmailsToUsers = 'allowEmailsToUsers',
  DataroomAttributes_allowEmailsAsPlaintextOnly = 'allowEmailsAsPlaintextOnly',
  DataroomAttributes_requireEmailsCopyToSender = 'requireEmailsCopyToSender',
  DataroomAttributes_sendObserverEmailOnInvite = 'sendObserverEmailOnInvite',
  DataroomAttributes_sendInformationEmails = 'sendInformationEmails',
  DataroomAttributes_showIntroductionDialog = 'showIntroductionDialog',
  DataroomAttributes_defineUsernamesEnabled = 'defineUsernamesEnabled',
  DataroomAttributes_inviteValidDays = 'inviteValidDays',

  // Functions
  DataroomAttributes_useManagedFiletransfer = 'useManagedFiletransfer',
  DataroomAttributes_useManagedFiletransferLocalUpload = 'useManagedFiletransferLocalUpload',
  DataroomAttributes_useManagedFiletransferPassword = 'useManagedFiletransferPassword',
  DataroomAttributes_managedFiletransferVisibility = 'managedFiletransferVisibility',
  DataroomAttributes_useTabletApp = 'useTabletApp',
  DataroomAttributes_useWebdav = 'useWebdav',
  DataroomAttributes_useSftp = 'useSftp',
  DataroomAttributes_useTasks = 'useTasks',
  DataroomAttributes_useAppointments = 'useAppointments',
  DataroomAttributes_useVideoConferences = 'useVideoConferences',
  DataroomAttributes_useDiscussions = 'useDiscussions',
  DataroomAttributes_useMeetings = 'useMeetings',
  DataroomAttributes_useVotings = 'useVotings',
  DataroomAttributes_showFavorites = 'showFavorites',
  DataroomAttributes_showNewFiles = 'showNewFiles',
  DataroomAttributes_showLockedFiles = 'showLockedFiles',
  DataroomAttributes_showRecentComments = 'showRecentComments',
  DataroomAttributes_showPreferredFiles = 'showPreferredFiles',
  DataroomAttributes_useComments = 'useComments',
  DataroomAttributes_commentVisibility = 'commentVisibility',

  // Disclaimer

  DataroomAttributes_disclaimerText = 'disclaimerText',
  DataroomAttributes_useDisclaimer = 'useDisclaimer',
  DataroomAttributes_reacceptDisclaimer = 'reacceptDisclaimer',

  // UI

  DataroomAttributes_loginPageUrl = 'loginPageUrl',
  DataroomAttributes_colorScheme = 'colorScheme',
  DataroomAttributes_emailLogo = 'emailLogo',
  DataroomAttributes_dataroomLogo = 'dataroomLogo',
  DataroomAttributes_onUploadScaleOnly = 'onUploadScaleOnly',
}

export enum UserAttributes {
  UserAttributes_username = 'username',
  UserAttributes_firstName = 'firstName',
  UserAttributes_lastName = 'lastName',
  UserAttributes_email = 'email',
  UserAttributes_salutation = 'salutation',
  UserAttributes_title = 'userTitle',
  UserAttributes_mobilePhoneNr = 'mobilePhoneNr',
  UserAttributes_phoneNr = 'phoneNr',
  UserAttributes_faxNr = 'faxNr',
  UserAttributes_department = 'department',
  UserAttributes_position = 'position',
  UserAttributes_address = 'address',
  UserAttributes_website = 'website',
  UserAttributes_company = 'company',
  UserAttributes_realname = 'realname',
  UserAttributes_language = 'language',
  UserAttributes_notification = 'notification',
  UserAttributes_showWelcomeDialog = 'showWelcomeDialog',
  UserAttributes_twoFactorEnabled = 'twoFactorEnabled',
  UserAttributes_twoFactorType = 'twoFactorType',
  UserAttributes_themeMode = 'themeMode',
  UserAttributes_receiveInfoMails = 'receiveInfoMails',
  UserAttributes_unpackArchivesOnUpload = 'unpackArchivesOnUpload',
  UserAttributes_hasPhoto = 'hasPhoto',
  UserAttributes_useExternalFiletransfer = 'useManagedFiletransfer',
  UserAttributes_managedFiletransferVisibility = 'managedFiletransferVisibility',
  UserAttributes_allowAccess = 'allowAccess',
  UserAttributes_useMsOffice = 'useMsOffice',
  UserAttributes_restrictAccessFrom = 'restrictAccessFrom',
  UserAttributes_restrictAccessTo = 'restrictAccessTo',
  UserAttributes_useSftp = 'useSftp',
  UserAttributes_useWebdav = 'useWebdav',
  UserAttributes_useTabletApp = 'useTabletApp',
  UserAttributes_downloadProtectedPdf = 'downloadProtectedPdf',
  UserAttributes_printProtectedPdf = 'printProtectedPdf',
  UserAttributes_mountpoint = 'mountpoint',
  UserAttributes_createVideoConferences = 'createVideoConferences',
  UserAttributes_visibility = 'visibility',
  UserAttributes_created = 'created',
  UserAttributes_logins = 'logins',
  UserAttributes_groups = 'groups',
  UserAttributes_lastLogin = 'lastLogin',
  UserAttributes_invitationSent = 'invitationSent',
  UserAttributes_accessContractData = 'accessContractData',
  UserAttributes_favorites = 'favorites',
  UserAttributes_newFiles = 'newFiles',
  UserAttributes_preferredFiles = 'preferredFiles',
  UserAttributes_lockedFiles = 'lockedFiles',
  UserAttributes_recentComments = 'recentComments',
  UserAttributes_expiredFiles = 'expiredFiles',
  UserAttributes_unreadFiles = 'unreadFiles',
  UserAttributes_labels = 'labels',
  UserAttributes_signature = 'signature',
  UserAttributes_userFeatures = 'userFeatures',
  UserAttributes_tobeapprovedFiles = 'tobeapprovedFiles',
  UserAttributes_inbox = 'inbox',
  UserAttributes_outbox = 'outbox',
  UserAttributes_tasks = 'tasks',
  UserAttributes_appointments = 'appointments',
  UserAttributes_videoconferences = 'videoconferences',
  UserAttributes_discussions = 'discussions',
  UserAttributes_singleSignOnId = 'singleSignOnId',
  UserAttributes_singleSignOnEnabled = 'singleSignOnEnabled',
  UserAttributes_newsletter = 'newsletter',
  UserAttributes_sendInvitationLater = 'sendInvitationLater',
  UserAttributes_sendInvitationToMeOnly = 'sendInvitationToMeOnly',
  UserAttributes_userActivationObserverEmail = 'userActivationObserverEmail',
  UserAttributes_costCenter = 'costCenter',
  UserAttributes_messageToNewUser = 'messageToNewUser',
  UserAttributes_locale = 'locale',
  UserAttributes_timezone = 'timezone',
}

export enum FilesItemAttributes {
  FilesItemAttributes_title = 'title',
  FilesItemAttributes_status = 'status',
  FilesItemAttributes_statusSetBy = 'statusSetBy',
  FilesItemAttributes_description = 'description',
  FilesItemAttributes_documentSize = 'documentSize',
  FilesItemAttributes_author = 'author',
  FilesItemAttributes_version = 'version',
  FilesItemAttributes_versionOwner = 'versionOwner',
  FilesItemAttributes_url = 'url',
  FilesItemAttributes_expirationDate = 'expirationDate',
  FilesItemAttributes_approvedBy = 'approvedBy',
  FilesItemAttributes_approvedOn = 'approvedOn',
  FilesItemAttributes_approvalGroup = 'approvalGroup',
  FilesItemAttributes_sequence = 'sequence',
  FilesItemAttributes_index = 'index',
  FilesItemAttributes_noIndex = 'noIndex',
  FilesItemAttributes_tags = 'tags',
  FilesItemAttributes_creationDate = 'creationDate',
  FilesItemAttributes_modificationDate = 'modificationDate',
  FilesItemAttributes_path = 'path',
  FilesItemAttributes_isRead = 'isRead',
  FilesItemAttributes_isApproved = 'isApproved',
  FilesItemAttributes_canApprove = 'canApprove',
  FilesItemAttributes_documentStatistics = 'documentStatistics',
  FilesItemAttributes_recursiveApprovalType = 'recursiveApprovalType',
  FilesItemAttributes_recursiveApproval = 'recursiveApproval',
  FilesItemAttributes_pdfPassword = 'pdfPassword',
  FilesItemAttributes_recursivePdfPasswordEnabled = 'recursivePdfPasswordEnabled',
  FilesItemAttributes_collectionSize = 'collectionSize',
  FilesItemAttributes_subDocs = 'subDocs',
  FilesItemAttributes_setExpirationDate = 'setExpirationDate',
  FilesItemAttributes_recursiveSetExpirationDate = 'recursiveSetExpirationDate',
  FilesItemAttributes_shortUrl = 'shortUrl',
  FilesItemAttributes_userNotifications = 'userNotifications',
  FilesItemAttributes_notification = 'notification',
  FilesItemAttributes_recursiveNotifications = 'recursiveNotifications',
  FilesItemAttributes_numberOfComments = 'numberOfComments',
  FilesItemAttributes_deletedOn = 'deletedOn',
}

export enum GroupAttributes {
  GroupAttributes_groupAdmins = 'groupAdmins',
  GroupAttributes_labels = 'labels',
  GroupAttributes_title = 'title',
  GroupAttributes_description = 'description',
}

export enum GeneralAttributes {
  GeneralAttributes_itemType = 'itemType',
  GeneralAttributes_id = 'id',
}
