import type { App } from 'vue';
import { createI18n } from 'vue-i18n';
// User defined lang
import enLocale from './en';
import deLocale from './de';

export type DateTimeFormatsType = {
  de: {
    long: object;
    short: object;
    currency: object;
  };
  'en-GB': {
    long: object;
    short: object;
    currency: object;
  };
  en: {
    long: object;
    short: object;
    currency: object;
  };
  'en-US': {
    long: object;
    short: object;
    currency: object;
  };
  'de-DE': {
    long: object;
    short: object;
    currency: object;
  };
};

export const messages = {
  en: {
    ...enLocale,
  },
  'en-GB': {
    ...enLocale,
  },
  'en-US': {
    ...enLocale,
  },
  de: {
    ...deLocale,
  },
  'de-DE': {
    ...deLocale,
  },
};

export const datetimeFormats = {
  'en-US': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    currency: {
      style: 'currency',
      currency: 'USD',
      separator: ',',
      delimiter: '.',
      minimumFractionDigits: 2, // set fraction digits to 0 to remove cents
      maximumFractionDigits: 2,
    },
  },
  en: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    currency: {
      style: 'currency',
      currency: 'USD',
      separator: ',',
      delimiter: '.',
      minimumFractionDigits: 2, // set fraction digits to 0 to remove cents
      maximumFractionDigits: 2,
    },
  },
  'en-GB': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    currency: {
      style: 'currency',
      currency: 'GBP',
      separator: ',',
      delimiter: '.',
      minimumFractionDigits: 2, // set fraction digits to 0 to remove cents
      maximumFractionDigits: 2,
    },
  },
  de: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      separator: '.',
      delimiter: ',',
      minimumFractionDigits: 2, // set fraction digits to 0 to remove cents
      maximumFractionDigits: 2,
    },
  },
  'de-DE': {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      separator: '.',
      delimiter: ',',
      minimumFractionDigits: 2, // set fraction digits to 0 to remove cents
      maximumFractionDigits: 2,
    },
  },
};

export const getLocale = () => {
  const cookieLanguage = sessionStorage.getItem('language');
  if (cookieLanguage) {
    return cookieLanguage;
  }
  const language = navigator.language.toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return 'de';
};

export const i18n = createI18n({
  legacy: false,
  locale: getLocale(),
  globalInjection: true,
  fallbackLocale: 'de',
  messages,
  datetimeFormats,
  silentFallbackWarn: true,
  warnHtmlMessage: false,
});

// export default i18n
export default (app: App<Element>): void => {
  app.use(i18n);
};
