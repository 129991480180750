/* eslint-disable */
import {
  DataroomAccessType,
  User,
  dataroomAccessTypeFromJSON,
  dataroomAccessTypeToJSON,
} from './user';
import { ItemType, itemTypeFromJSON, itemTypeToJSON } from './item';
import {
  SortOrder,
  SortDirection,
  sortOrderFromJSON,
  sortDirectionFromJSON,
  sortOrderToJSON,
  sortDirectionToJSON,
} from './util';
import { UserSettings } from './settings';
import {
  SecurityQuestionRequirements,
  PasswordRequirements,
} from './me_service';
import { ProfileImage } from './image';
import { FilesItem } from './files';
import { Group } from './groups';

export const protobufPackage = 'de.netfiles.pb';

/** Get new user fields */
export interface NewUserRequest {
  dataroomId: string;
  settings?: UserSettings | undefined;
  groupId?: string | undefined;
}

export interface AvailableUsers {
  curUsers: number;
  maxUsers: number;
  availableUsers: number;
  hasFlatrate: boolean;
}

/** Get new user fields */
export interface NewUserFieldsResponse {
  settings: UserSettings | undefined;
  availableUsers: AvailableUsers | undefined;
}

/** GetUserRequest - returns data about the user with the given ID */
export interface UserRequest {
  userId: string;
  dataroomId: string;
}

/** Used for getting multiple users */
export interface MultipleUsersRequest {
  userIds: string[];
  dataroomId: string;
}

export interface MultipleSettingsResponse {
  settings: UserSettings[];
}

/** ResetUserPassword request */
export interface ResetUserPasswordRequest {
  userId: string;
  dataroomId: string;
}

/** Forgot password request */
export interface ForgotPasswordRequest {
  username: string;
  tid?: string | undefined;
}

/** ResetUserPasswordLink request */
export interface ResetUserPasswordLinkRequest {
  link: string;
}

/** ResetUserPasswordLink response */
export interface ResetUserPasswordLinkResponse {
  settings: SecurityQuestionRequirements | undefined;
  passwordSettings: PasswordRequirements | undefined;
  language: string;
  realname: string;
  username: string;
}

/** Reset password */
export interface ResetPasswordRequest {
  link: string;
  password?: string | undefined;
  securityQuestionAnswer?: string | undefined;
  smsLock?: string | undefined;
  smsKey?: string | undefined;
}

/** Response */
export interface UserResponse {
  user?: User | undefined;
}

/** Update User Request */
export interface UpdateUserRequest {
  userId: string;
  dataroomId: string;
  user: User | undefined;
}

/** Update User Response */
export interface UpdateUserResponse {
  user?: User | undefined;
}

/** User Profile Image Response */
export interface UserProfileImageResponse {
  profileImage?: ProfileImage | undefined;
}

export interface UpdateMultipleUsersSettingsRequest {
  dataroomId: string;
  settings: UserSettings[];
}

export interface MultipleUserSettingsResponse {
  settings: UserSettings[];
}

/** Get User Settings Response */
export interface UserSettingsResponse {
  settings?: UserSettings | undefined;
}

/** Update User Settings Request */
export interface UpdateUserSettingsRequest {
  userId: string;
  dataroomId: string;
  settings: UserSettings | undefined;
}

/** Remove several users request */
export interface RemoveUsersRequest {
  dataroomId: string;
  sendEmail?: boolean | undefined;
  userIds: string[];
}

/** Import users request */
export interface ImportUsersRequest {
  dataroomId: string;
  groupId?: string | undefined;
  jobId?: string | undefined;
  body: Uint8Array;
}

/** Resends invitation to users */
export interface ResendInvitationRequest {
  dataroomId: string;
  messageToNewUser?: string | undefined;
  userIds: string[];
}

/** Generate 2FA request */
export interface Generate2faResponse {
  lock: string;
  messageId: number;
}

export interface Validate2faRequest {
  dataroomId: string;
  lock: string;
  key: string;
  fingerprint: string;
}

export interface Validate2faResponse {
  token: string;
}

export interface RegisterOtpResponse {
  secret: string;
}

export interface Validate2faOtpRequest {
  dataroomId: string;
  otp: string;
  fingerprint: string;
}

/** Generate 2FA SMS request */
export interface Generate2faSmsRequest {
  dataroomId: string;
  mobile?: string | undefined;
  emailCode?: string | undefined;
  lock?: string | undefined;
}

/** Generate 2FA SMS response */
export interface Generate2faSmsResponse {
  required: boolean;
  twoFaType: number;
  tanSendAddress: string;
  tanId: string;
}

/** Handle data room access by user */
export interface DataroomAccessRequest {
  dataroomIds: string[];
  accessType: DataroomAccessType;
  userId: string;
}

/** Entry of response to get the groups a user may see */
export interface GetVisibleUsersEntry {
  title?: string | undefined;
  id: string;
  itemType: ItemType;
  users: User[];
}

/** Request to get the groups a user may see, optional including users */
export interface GetVisibleUsersForUserRequest {
  userId: string;
  dataroomId: string;
  groupId?: string | undefined;
}

/** Response to get the groups a user may see, optional including users */
export interface GetVisibleUsersForUserResponse {
  withUsers: boolean;
  groups: GetVisibleUsersEntry[];
}

/** Request to get documents a user may see */
export interface GetAccessibleFilesForUserRequest {
  userId: string;
  dataroomId: string;
  itemId: string;
  limit: number;
  iterator: string;
  startIndex: number;
  sortOrder: SortOrder;
  sortDirection: SortDirection;
}

/** Response to get documents a user may see */
export interface GetAccessibleFilesForUserResponse {
  items: FilesItem[];
  totalNumChildren: number;
  iterator: string;
  startIndex: number;
  sortOrder: SortOrder;
  sortDirection: SortDirection;
}

export interface GetUserGroupsResponse {
  groups: Group[];
}

export interface CheckPasswordRequest {
  password: string;
  link?: string | undefined;
  token?: string | undefined;
}

export interface CheckPasswordResponse {
  secure: boolean;
}

function createBaseNewUserRequest(): NewUserRequest {
  return { dataroomId: '', settings: undefined, groupId: undefined };
}

export const NewUserRequest = {
  fromJSON(object: any): NewUserRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      settings: isSet(object.settings)
        ? UserSettings.fromJSON(object.settings)
        : undefined,
      groupId: isSet(object.groupId) ? String(object.groupId) : undefined,
    };
  },

  toJSON(message: NewUserRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.settings !== undefined &&
      (obj.settings = message.settings
        ? UserSettings.toJSON(message.settings)
        : undefined);
    message.groupId !== undefined && (obj.groupId = message.groupId);
    return obj;
  },
};

function createBaseAvailableUsers(): AvailableUsers {
  return { curUsers: 0, maxUsers: 0, availableUsers: 0, hasFlatrate: false };
}

export const AvailableUsers = {
  fromJSON(object: any): AvailableUsers {
    return {
      curUsers: isSet(object.curUsers) ? Number(object.curUsers) : 0,
      maxUsers: isSet(object.maxUsers) ? Number(object.maxUsers) : 0,
      availableUsers: isSet(object.availableUsers)
        ? Number(object.availableUsers)
        : 0,
      hasFlatrate: isSet(object.hasFlatrate)
        ? Boolean(object.hasFlatrate)
        : false,
    };
  },

  toJSON(message: AvailableUsers): unknown {
    const obj: any = {};
    message.curUsers !== undefined &&
      (obj.curUsers = Math.round(message.curUsers));
    message.maxUsers !== undefined &&
      (obj.maxUsers = Math.round(message.maxUsers));
    message.availableUsers !== undefined &&
      (obj.availableUsers = Math.round(message.availableUsers));
    message.hasFlatrate !== undefined &&
      (obj.hasFlatrate = message.hasFlatrate);
    return obj;
  },
};

function createBaseNewUserFieldsResponse(): NewUserFieldsResponse {
  return { settings: undefined, availableUsers: undefined };
}

export const NewUserFieldsResponse = {
  fromJSON(object: any): NewUserFieldsResponse {
    return {
      settings: isSet(object.settings)
        ? UserSettings.fromJSON(object.settings)
        : undefined,
      availableUsers: isSet(object.availableUsers)
        ? AvailableUsers.fromJSON(object.availableUsers)
        : undefined,
    };
  },

  toJSON(message: NewUserFieldsResponse): unknown {
    const obj: any = {};
    message.settings !== undefined &&
      (obj.settings = message.settings
        ? UserSettings.toJSON(message.settings)
        : undefined);
    message.availableUsers !== undefined &&
      (obj.availableUsers = message.availableUsers
        ? AvailableUsers.toJSON(message.availableUsers)
        : undefined);
    return obj;
  },
};

function createBaseUserRequest(): UserRequest {
  return { userId: '', dataroomId: '' };
}

export const UserRequest = {
  fromJSON(object: any): UserRequest {
    return {
      userId: isSet(object.userId) ? String(object.userId) : '',
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
    };
  },

  toJSON(message: UserRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    return obj;
  },
};

function createBaseMultipleUsersRequest(): MultipleUsersRequest {
  return { userIds: [], dataroomId: '' };
}

export const MultipleUsersRequest = {
  fromJSON(object: any): MultipleUsersRequest {
    return {
      userIds: Array.isArray(object?.userIds)
        ? object.userIds.map((e: any) => String(e))
        : [],
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
    };
  },

  toJSON(message: MultipleUsersRequest): unknown {
    const obj: any = {};
    if (message.userIds) {
      obj.userIds = message.userIds.map(e => e);
    } else {
      obj.userIds = [];
    }
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    return obj;
  },
};

function createBaseMultipleSettingsResponse(): MultipleSettingsResponse {
  return { settings: [] };
}

export const MultipleSettingsResponse = {
  fromJSON(object: any): MultipleSettingsResponse {
    return {
      settings: Array.isArray(object?.settings)
        ? object.settings.map((e: any) => UserSettings.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MultipleSettingsResponse): unknown {
    const obj: any = {};
    if (message.settings) {
      obj.settings = message.settings.map(e =>
        e ? UserSettings.toJSON(e) : undefined,
      );
    } else {
      obj.settings = [];
    }
    return obj;
  },
};

function createBaseResetUserPasswordRequest(): ResetUserPasswordRequest {
  return { userId: '', dataroomId: '' };
}

export const ResetUserPasswordRequest = {
  fromJSON(object: any): ResetUserPasswordRequest {
    return {
      userId: isSet(object.userId) ? String(object.userId) : '',
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
    };
  },

  toJSON(message: ResetUserPasswordRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    return obj;
  },
};

function createBaseForgotPasswordRequest(): ForgotPasswordRequest {
  return { username: '', tid: undefined };
}

export const ForgotPasswordRequest = {
  fromJSON(object: any): ForgotPasswordRequest {
    return {
      username: isSet(object.username) ? String(object.username) : '',
      tid: isSet(object.tid) ? String(object.tid) : undefined,
    };
  },

  toJSON(message: ForgotPasswordRequest): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    message.tid !== undefined && (obj.tid = message.tid);
    return obj;
  },
};

function createBaseResetUserPasswordLinkRequest(): ResetUserPasswordLinkRequest {
  return { link: '' };
}

export const ResetUserPasswordLinkRequest = {
  fromJSON(object: any): ResetUserPasswordLinkRequest {
    return {
      link: isSet(object.link) ? String(object.link) : '',
    };
  },

  toJSON(message: ResetUserPasswordLinkRequest): unknown {
    const obj: any = {};
    message.link !== undefined && (obj.link = message.link);
    return obj;
  },
};

function createBaseResetUserPasswordLinkResponse(): ResetUserPasswordLinkResponse {
  return {
    settings: undefined,
    passwordSettings: undefined,
    language: '',
    realname: '',
    username: '',
  };
}

export const ResetUserPasswordLinkResponse = {
  fromJSON(object: any): ResetUserPasswordLinkResponse {
    return {
      settings: isSet(object.settings)
        ? SecurityQuestionRequirements.fromJSON(object.settings)
        : undefined,
      passwordSettings: isSet(object.passwordSettings)
        ? PasswordRequirements.fromJSON(object.passwordSettings)
        : undefined,
      language: isSet(object.language) ? String(object.language) : '',
      realname: isSet(object.realname) ? String(object.realname) : '',
      username: isSet(object.username) ? String(object.username) : '',
    };
  },

  toJSON(message: ResetUserPasswordLinkResponse): unknown {
    const obj: any = {};
    message.settings !== undefined &&
      (obj.settings = message.settings
        ? SecurityQuestionRequirements.toJSON(message.settings)
        : undefined);
    message.passwordSettings !== undefined &&
      (obj.passwordSettings = message.passwordSettings
        ? PasswordRequirements.toJSON(message.passwordSettings)
        : undefined);
    message.language !== undefined && (obj.language = message.language);
    message.realname !== undefined && (obj.realname = message.realname);
    message.username !== undefined && (obj.username = message.username);
    return obj;
  },
};

function createBaseResetPasswordRequest(): ResetPasswordRequest {
  return {
    link: '',
    password: undefined,
    securityQuestionAnswer: undefined,
    smsLock: undefined,
    smsKey: undefined,
  };
}

export const ResetPasswordRequest = {
  fromJSON(object: any): ResetPasswordRequest {
    return {
      link: isSet(object.link) ? String(object.link) : '',
      password: isSet(object.password) ? String(object.password) : undefined,
      securityQuestionAnswer: isSet(object.securityQuestionAnswer)
        ? String(object.securityQuestionAnswer)
        : undefined,
      smsLock: isSet(object.smsLock) ? String(object.smsLock) : undefined,
      smsKey: isSet(object.smsKey) ? String(object.smsKey) : undefined,
    };
  },

  toJSON(message: ResetPasswordRequest): unknown {
    const obj: any = {};
    message.link !== undefined && (obj.link = message.link);
    message.password !== undefined && (obj.password = message.password);
    message.securityQuestionAnswer !== undefined &&
      (obj.securityQuestionAnswer = message.securityQuestionAnswer);
    message.smsLock !== undefined && (obj.smsLock = message.smsLock);
    message.smsKey !== undefined && (obj.smsKey = message.smsKey);
    return obj;
  },
};

function createBaseUserResponse(): UserResponse {
  return { user: undefined };
}

export const UserResponse = {
  fromJSON(object: any): UserResponse {
    return {
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: UserResponse): unknown {
    const obj: any = {};
    message.user !== undefined &&
      (obj.user = message.user ? User.toJSON(message.user) : undefined);
    return obj;
  },
};

function createBaseUpdateUserRequest(): UpdateUserRequest {
  return { userId: '', dataroomId: '', user: undefined };
}

export const UpdateUserRequest = {
  fromJSON(object: any): UpdateUserRequest {
    return {
      userId: isSet(object.userId) ? String(object.userId) : '',
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: UpdateUserRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.user !== undefined &&
      (obj.user = message.user ? User.toJSON(message.user) : undefined);
    return obj;
  },
};

function createBaseUpdateUserResponse(): UpdateUserResponse {
  return { user: undefined };
}

export const UpdateUserResponse = {
  fromJSON(object: any): UpdateUserResponse {
    return {
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
    };
  },

  toJSON(message: UpdateUserResponse): unknown {
    const obj: any = {};
    message.user !== undefined &&
      (obj.user = message.user ? User.toJSON(message.user) : undefined);
    return obj;
  },
};

function createBaseUserProfileImageResponse(): UserProfileImageResponse {
  return { profileImage: undefined };
}

export const UserProfileImageResponse = {
  fromJSON(object: any): UserProfileImageResponse {
    return {
      profileImage: isSet(object.profileImage)
        ? ProfileImage.fromJSON(object.profileImage)
        : undefined,
    };
  },

  toJSON(message: UserProfileImageResponse): unknown {
    const obj: any = {};
    message.profileImage !== undefined &&
      (obj.profileImage = message.profileImage
        ? ProfileImage.toJSON(message.profileImage)
        : undefined);
    return obj;
  },
};

function createBaseUpdateMultipleUsersSettingsRequest(): UpdateMultipleUsersSettingsRequest {
  return { dataroomId: '', settings: [] };
}

export const UpdateMultipleUsersSettingsRequest = {
  fromJSON(object: any): UpdateMultipleUsersSettingsRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      settings: Array.isArray(object?.settings)
        ? object.settings.map((e: any) => UserSettings.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UpdateMultipleUsersSettingsRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    if (message.settings) {
      obj.settings = message.settings.map(e =>
        e ? UserSettings.toJSON(e) : undefined,
      );
    } else {
      obj.settings = [];
    }
    return obj;
  },
};

function createBaseMultipleUserSettingsResponse(): MultipleUserSettingsResponse {
  return { settings: [] };
}

export const MultipleUserSettingsResponse = {
  fromJSON(object: any): MultipleUserSettingsResponse {
    return {
      settings: Array.isArray(object?.settings)
        ? object.settings.map((e: any) => UserSettings.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MultipleUserSettingsResponse): unknown {
    const obj: any = {};
    if (message.settings) {
      obj.settings = message.settings.map(e =>
        e ? UserSettings.toJSON(e) : undefined,
      );
    } else {
      obj.settings = [];
    }
    return obj;
  },
};

function createBaseUserSettingsResponse(): UserSettingsResponse {
  return { settings: undefined };
}

export const UserSettingsResponse = {
  fromJSON(object: any): UserSettingsResponse {
    return {
      settings: isSet(object.settings)
        ? UserSettings.fromJSON(object.settings)
        : undefined,
    };
  },

  toJSON(message: UserSettingsResponse): unknown {
    const obj: any = {};
    message.settings !== undefined &&
      (obj.settings = message.settings
        ? UserSettings.toJSON(message.settings)
        : undefined);
    return obj;
  },
};

function createBaseUpdateUserSettingsRequest(): UpdateUserSettingsRequest {
  return { userId: '', dataroomId: '', settings: undefined };
}

export const UpdateUserSettingsRequest = {
  fromJSON(object: any): UpdateUserSettingsRequest {
    return {
      userId: isSet(object.userId) ? String(object.userId) : '',
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      settings: isSet(object.settings)
        ? UserSettings.fromJSON(object.settings)
        : undefined,
    };
  },

  toJSON(message: UpdateUserSettingsRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.settings !== undefined &&
      (obj.settings = message.settings
        ? UserSettings.toJSON(message.settings)
        : undefined);
    return obj;
  },
};

function createBaseRemoveUsersRequest(): RemoveUsersRequest {
  return { dataroomId: '', sendEmail: undefined, userIds: [] };
}

export const RemoveUsersRequest = {
  fromJSON(object: any): RemoveUsersRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      sendEmail: isSet(object.sendEmail)
        ? Boolean(object.sendEmail)
        : undefined,
      userIds: Array.isArray(object?.userIds)
        ? object.userIds.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: RemoveUsersRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.sendEmail !== undefined && (obj.sendEmail = message.sendEmail);
    if (message.userIds) {
      obj.userIds = message.userIds.map(e => e);
    } else {
      obj.userIds = [];
    }
    return obj;
  },
};

function createBaseImportUsersRequest(): ImportUsersRequest {
  return {
    dataroomId: '',
    groupId: undefined,
    jobId: undefined,
    body: new Uint8Array(),
  };
}

export const ImportUsersRequest = {
  fromJSON(object: any): ImportUsersRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      groupId: isSet(object.groupId) ? String(object.groupId) : undefined,
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
      body: isSet(object.body)
        ? bytesFromBase64(object.body)
        : new Uint8Array(),
    };
  },

  toJSON(message: ImportUsersRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.groupId !== undefined && (obj.groupId = message.groupId);
    message.jobId !== undefined && (obj.jobId = message.jobId);
    message.body !== undefined &&
      (obj.body = base64FromBytes(
        message.body !== undefined ? message.body : new Uint8Array(),
      ));
    return obj;
  },
};

function createBaseResendInvitationRequest(): ResendInvitationRequest {
  return { dataroomId: '', messageToNewUser: undefined, userIds: [] };
}

export const ResendInvitationRequest = {
  fromJSON(object: any): ResendInvitationRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      messageToNewUser: isSet(object.messageToNewUser)
        ? String(object.messageToNewUser)
        : undefined,
      userIds: Array.isArray(object?.userIds)
        ? object.userIds.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: ResendInvitationRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.messageToNewUser !== undefined &&
      (obj.messageToNewUser = message.messageToNewUser);
    if (message.userIds) {
      obj.userIds = message.userIds.map(e => e);
    } else {
      obj.userIds = [];
    }
    return obj;
  },
};

function createBaseGenerate2faResponse(): Generate2faResponse {
  return { lock: '', messageId: 0 };
}

export const Generate2faResponse = {
  fromJSON(object: any): Generate2faResponse {
    return {
      lock: isSet(object.lock) ? String(object.lock) : '',
      messageId: isSet(object.messageId) ? Number(object.messageId) : 0,
    };
  },

  toJSON(message: Generate2faResponse): unknown {
    const obj: any = {};
    message.lock !== undefined && (obj.lock = message.lock);
    message.messageId !== undefined &&
      (obj.messageId = Math.round(message.messageId));
    return obj;
  },
};

function createBaseValidate2faRequest(): Validate2faRequest {
  return { dataroomId: '', lock: '', key: '', fingerprint: '' };
}

export const Validate2faRequest = {
  fromJSON(object: any): Validate2faRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      lock: isSet(object.lock) ? String(object.lock) : '',
      key: isSet(object.key) ? String(object.key) : '',
      fingerprint: isSet(object.fingerprint) ? String(object.fingerprint) : '',
    };
  },

  toJSON(message: Validate2faRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.lock !== undefined && (obj.lock = message.lock);
    message.key !== undefined && (obj.key = message.key);
    message.fingerprint !== undefined &&
      (obj.fingerprint = message.fingerprint);
    return obj;
  },
};

function createBaseValidate2faResponse(): Validate2faResponse {
  return { token: '' };
}

export const Validate2faResponse = {
  fromJSON(object: any): Validate2faResponse {
    return {
      token: isSet(object.token) ? String(object.token) : '',
    };
  },

  toJSON(message: Validate2faResponse): unknown {
    const obj: any = {};
    message.token !== undefined && (obj.token = message.token);
    return obj;
  },
};

function createBaseRegisterOtpResponse(): RegisterOtpResponse {
  return { secret: '' };
}

export const RegisterOtpResponse = {
  fromJSON(object: any): RegisterOtpResponse {
    return {
      secret: isSet(object.secret) ? String(object.secret) : '',
    };
  },

  toJSON(message: RegisterOtpResponse): unknown {
    const obj: any = {};
    message.secret !== undefined && (obj.secret = message.secret);
    return obj;
  },
};

function createBaseValidate2faOtpRequest(): Validate2faOtpRequest {
  return { dataroomId: '', otp: '', fingerprint: '' };
}

export const Validate2faOtpRequest = {
  fromJSON(object: any): Validate2faOtpRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      otp: isSet(object.otp) ? String(object.otp) : '',
      fingerprint: isSet(object.fingerprint) ? String(object.fingerprint) : '',
    };
  },

  toJSON(message: Validate2faOtpRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.otp !== undefined && (obj.otp = message.otp);
    message.fingerprint !== undefined &&
      (obj.fingerprint = message.fingerprint);
    return obj;
  },
};

function createBaseGenerate2faSmsRequest(): Generate2faSmsRequest {
  return {
    dataroomId: '',
    mobile: undefined,
    emailCode: undefined,
    lock: undefined,
  };
}

export const Generate2faSmsRequest = {
  fromJSON(object: any): Generate2faSmsRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      mobile: isSet(object.mobile) ? String(object.mobile) : undefined,
      emailCode: isSet(object.emailCode) ? String(object.emailCode) : undefined,
      lock: isSet(object.lock) ? String(object.lock) : undefined,
    };
  },

  toJSON(message: Generate2faSmsRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.mobile !== undefined && (obj.mobile = message.mobile);
    message.emailCode !== undefined && (obj.emailCode = message.emailCode);
    message.lock !== undefined && (obj.lock = message.lock);
    return obj;
  },
};

function createBaseGenerate2faSmsResponse(): Generate2faSmsResponse {
  return { required: false, twoFaType: 0, tanSendAddress: '', tanId: '' };
}

export const Generate2faSmsResponse = {
  fromJSON(object: any): Generate2faSmsResponse {
    return {
      required: isSet(object.required) ? Boolean(object.required) : false,
      twoFaType: isSet(object.twoFaType) ? Number(object.twoFaType) : 0,
      tanSendAddress: isSet(object.tanSendAddress)
        ? String(object.tanSendAddress)
        : '',
      tanId: isSet(object.tanId) ? String(object.tanId) : '',
    };
  },

  toJSON(message: Generate2faSmsResponse): unknown {
    const obj: any = {};
    message.required !== undefined && (obj.required = message.required);
    message.twoFaType !== undefined &&
      (obj.twoFaType = Math.round(message.twoFaType));
    message.tanSendAddress !== undefined &&
      (obj.tanSendAddress = message.tanSendAddress);
    message.tanId !== undefined && (obj.tanId = message.tanId);
    return obj;
  },
};

function createBaseDataroomAccessRequest(): DataroomAccessRequest {
  return { dataroomIds: [], accessType: 0, userId: '' };
}

export const DataroomAccessRequest = {
  fromJSON(object: any): DataroomAccessRequest {
    return {
      dataroomIds: Array.isArray(object?.dataroomIds)
        ? object.dataroomIds.map((e: any) => String(e))
        : [],
      accessType: isSet(object.accessType)
        ? dataroomAccessTypeFromJSON(object.accessType)
        : 0,
      userId: isSet(object.userId) ? String(object.userId) : '',
    };
  },

  toJSON(message: DataroomAccessRequest): unknown {
    const obj: any = {};
    if (message.dataroomIds) {
      obj.dataroomIds = message.dataroomIds.map(e => e);
    } else {
      obj.dataroomIds = [];
    }
    message.accessType !== undefined &&
      (obj.accessType = dataroomAccessTypeToJSON(message.accessType));
    message.userId !== undefined && (obj.userId = message.userId);
    return obj;
  },
};

function createBaseGetVisibleUsersEntry(): GetVisibleUsersEntry {
  return { title: undefined, id: '', itemType: 0, users: [] };
}

export const GetVisibleUsersEntry = {
  fromJSON(object: any): GetVisibleUsersEntry {
    return {
      title: isSet(object.title) ? String(object.title) : undefined,
      id: isSet(object.id) ? String(object.id) : '',
      itemType: isSet(object.itemType) ? itemTypeFromJSON(object.itemType) : 0,
      users: Array.isArray(object?.users)
        ? object.users.map((e: any) => User.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetVisibleUsersEntry): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.id !== undefined && (obj.id = message.id);
    message.itemType !== undefined &&
      (obj.itemType = itemTypeToJSON(message.itemType));
    if (message.users) {
      obj.users = message.users.map(e => (e ? User.toJSON(e) : undefined));
    } else {
      obj.users = [];
    }
    return obj;
  },
};

function createBaseGetVisibleUsersForUserRequest(): GetVisibleUsersForUserRequest {
  return { userId: '', dataroomId: '', groupId: undefined };
}

export const GetVisibleUsersForUserRequest = {
  fromJSON(object: any): GetVisibleUsersForUserRequest {
    return {
      userId: isSet(object.userId) ? String(object.userId) : '',
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      groupId: isSet(object.groupId) ? String(object.groupId) : undefined,
    };
  },

  toJSON(message: GetVisibleUsersForUserRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.groupId !== undefined && (obj.groupId = message.groupId);
    return obj;
  },
};

function createBaseGetVisibleUsersForUserResponse(): GetVisibleUsersForUserResponse {
  return { withUsers: false, groups: [] };
}

export const GetVisibleUsersForUserResponse = {
  fromJSON(object: any): GetVisibleUsersForUserResponse {
    return {
      withUsers: isSet(object.withUsers) ? Boolean(object.withUsers) : false,
      groups: Array.isArray(object?.groups)
        ? object.groups.map((e: any) => GetVisibleUsersEntry.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetVisibleUsersForUserResponse): unknown {
    const obj: any = {};
    message.withUsers !== undefined && (obj.withUsers = message.withUsers);
    if (message.groups) {
      obj.groups = message.groups.map(e =>
        e ? GetVisibleUsersEntry.toJSON(e) : undefined,
      );
    } else {
      obj.groups = [];
    }
    return obj;
  },
};

function createBaseGetAccessibleFilesForUserRequest(): GetAccessibleFilesForUserRequest {
  return {
    userId: '',
    dataroomId: '',
    itemId: '',
    limit: 0,
    iterator: '',
    startIndex: 0,
    sortOrder: 0,
    sortDirection: 0,
  };
}

export const GetAccessibleFilesForUserRequest = {
  fromJSON(object: any): GetAccessibleFilesForUserRequest {
    return {
      userId: isSet(object.userId) ? String(object.userId) : '',
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      itemId: isSet(object.itemId) ? String(object.itemId) : '',
      limit: isSet(object.limit) ? Number(object.limit) : 0,
      iterator: isSet(object.iterator) ? String(object.iterator) : '',
      startIndex: isSet(object.startIndex) ? Number(object.startIndex) : 0,
      sortOrder: isSet(object.sortOrder)
        ? sortOrderFromJSON(object.sortOrder)
        : 0,
      sortDirection: isSet(object.sortDirection)
        ? sortDirectionFromJSON(object.sortDirection)
        : 0,
    };
  },

  toJSON(message: GetAccessibleFilesForUserRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.itemId !== undefined && (obj.itemId = message.itemId);
    message.limit !== undefined && (obj.limit = Math.round(message.limit));
    message.iterator !== undefined && (obj.iterator = message.iterator);
    message.startIndex !== undefined &&
      (obj.startIndex = Math.round(message.startIndex));
    message.sortOrder !== undefined &&
      (obj.sortOrder = sortOrderToJSON(message.sortOrder));
    message.sortDirection !== undefined &&
      (obj.sortDirection = sortDirectionToJSON(message.sortDirection));
    return obj;
  },
};

function createBaseGetAccessibleFilesForUserResponse(): GetAccessibleFilesForUserResponse {
  return {
    items: [],
    totalNumChildren: 0,
    iterator: '',
    startIndex: 0,
    sortOrder: 0,
    sortDirection: 0,
  };
}

export const GetAccessibleFilesForUserResponse = {
  fromJSON(object: any): GetAccessibleFilesForUserResponse {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => FilesItem.fromJSON(e))
        : [],
      totalNumChildren: isSet(object.totalNumChildren)
        ? Number(object.totalNumChildren)
        : 0,
      iterator: isSet(object.iterator) ? String(object.iterator) : '',
      startIndex: isSet(object.startIndex) ? Number(object.startIndex) : 0,
      sortOrder: isSet(object.sortOrder)
        ? sortOrderFromJSON(object.sortOrder)
        : 0,
      sortDirection: isSet(object.sortDirection)
        ? sortDirectionFromJSON(object.sortDirection)
        : 0,
    };
  },

  toJSON(message: GetAccessibleFilesForUserResponse): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map(e => (e ? FilesItem.toJSON(e) : undefined));
    } else {
      obj.items = [];
    }
    message.totalNumChildren !== undefined &&
      (obj.totalNumChildren = Math.round(message.totalNumChildren));
    message.iterator !== undefined && (obj.iterator = message.iterator);
    message.startIndex !== undefined &&
      (obj.startIndex = Math.round(message.startIndex));
    message.sortOrder !== undefined &&
      (obj.sortOrder = sortOrderToJSON(message.sortOrder));
    message.sortDirection !== undefined &&
      (obj.sortDirection = sortDirectionToJSON(message.sortDirection));
    return obj;
  },
};

function createBaseGetUserGroupsResponse(): GetUserGroupsResponse {
  return { groups: [] };
}

export const GetUserGroupsResponse = {
  fromJSON(object: any): GetUserGroupsResponse {
    return {
      groups: Array.isArray(object?.groups)
        ? object.groups.map((e: any) => Group.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetUserGroupsResponse): unknown {
    const obj: any = {};
    if (message.groups) {
      obj.groups = message.groups.map(e => (e ? Group.toJSON(e) : undefined));
    } else {
      obj.groups = [];
    }
    return obj;
  },
};

function createBaseCheckPasswordRequest(): CheckPasswordRequest {
  return { password: '', link: undefined, token: undefined };
}

export const CheckPasswordRequest = {
  fromJSON(object: any): CheckPasswordRequest {
    return {
      password: isSet(object.password) ? String(object.password) : '',
      link: isSet(object.link) ? String(object.link) : undefined,
      token: isSet(object.token) ? String(object.token) : undefined,
    };
  },

  toJSON(message: CheckPasswordRequest): unknown {
    const obj: any = {};
    message.password !== undefined && (obj.password = message.password);
    message.link !== undefined && (obj.link = message.link);
    message.token !== undefined && (obj.token = message.token);
    return obj;
  },
};

function createBaseCheckPasswordResponse(): CheckPasswordResponse {
  return { secure: false };
}

export const CheckPasswordResponse = {
  fromJSON(object: any): CheckPasswordResponse {
    return {
      secure: isSet(object.secure) ? Boolean(object.secure) : false,
    };
  },

  toJSON(message: CheckPasswordResponse): unknown {
    const obj: any = {};
    message.secure !== undefined && (obj.secure = message.secure);
    return obj;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

const atob: (b64: string) => string =
  globalThis.atob ||
  (b64 => globalThis.Buffer.from(b64, 'base64').toString('binary'));
function bytesFromBase64(b64: string): Uint8Array {
  const bin = atob(b64);
  const arr = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i);
  }
  return arr;
}

const btoa: (bin: string) => string =
  globalThis.btoa ||
  (bin => globalThis.Buffer.from(bin, 'binary').toString('base64'));
function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = [];
  arr.forEach(byte => {
    bin.push(String.fromCharCode(byte));
  });
  return btoa(bin.join(''));
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
