/* eslint-disable */
export const protobufPackage = 'de.netfiles.pb';

/**
 * Sort Order
 * Unsorted == UNSPECIFIED
 */
export enum SortOrder {
  SORT_ORDER_UNSPECIFIED = 0,
  SORT_ORDER_NAME = 1,
  SORT_ORDER_SIZE = 2,
  SORT_ORDER_CREATION_DATE = 3,
  SORT_ORDER_MODIFICATION_DATE = 4,
  SORT_ORDER_LASTLOGIN_DATE = 5,
  SORT_ORDER_LOGINCOUNT = 6,
  SORT_ORDER_EMAIL = 7,
  SORT_ORDER_SCORE = 8,
  /** SORT_ORDER_DATE - ActivityLog */
  SORT_ORDER_DATE = 9,
  SORT_ORDER_USER = 10,
  SORT_ORDER_TYPE = 11,
  SORT_ORDER_OBJECT = 12,
  SORT_ORDER_ACTIVITY = 13,
  SORT_ORDER_LOGINS = 14,
  SORT_ORDER_DOCUMENT_VIEWS = 15,
  SORT_ORDER_DOCUMENT_DOWNLOADS = 16,
  SORT_ORDER_GROUP = 17,
  SORT_ORDER_INVOICE_FROM = 18,
  SORT_ORDER_INVOICE_UNTIL = 19,
  SORT_ORDER_INVOICE_DATE = 20,
  SORT_ORDER_INVOICE_AMOUNT = 21,
  SORT_ORDER_INVOICE_AMOUNT_OPEN = 22,
  SORT_ORDER_INVOICE_AMOUNT_PAID = 23,
  /** SORT_ORDER_IP_ADDRESS - Login Activity */
  SORT_ORDER_IP_ADDRESS = 24,
  SORT_ORDER_LOCATION = 25,
  UNRECOGNIZED = -1,
}

export function sortOrderFromJSON(object: any): SortOrder {
  switch (object) {
    case 0:
    case 'SORT_ORDER_UNSPECIFIED':
      return SortOrder.SORT_ORDER_UNSPECIFIED;
    case 1:
    case 'SORT_ORDER_NAME':
      return SortOrder.SORT_ORDER_NAME;
    case 2:
    case 'SORT_ORDER_SIZE':
      return SortOrder.SORT_ORDER_SIZE;
    case 3:
    case 'SORT_ORDER_CREATION_DATE':
      return SortOrder.SORT_ORDER_CREATION_DATE;
    case 4:
    case 'SORT_ORDER_MODIFICATION_DATE':
      return SortOrder.SORT_ORDER_MODIFICATION_DATE;
    case 5:
    case 'SORT_ORDER_LASTLOGIN_DATE':
      return SortOrder.SORT_ORDER_LASTLOGIN_DATE;
    case 6:
    case 'SORT_ORDER_LOGINCOUNT':
      return SortOrder.SORT_ORDER_LOGINCOUNT;
    case 7:
    case 'SORT_ORDER_EMAIL':
      return SortOrder.SORT_ORDER_EMAIL;
    case 8:
    case 'SORT_ORDER_SCORE':
      return SortOrder.SORT_ORDER_SCORE;
    case 9:
    case 'SORT_ORDER_DATE':
      return SortOrder.SORT_ORDER_DATE;
    case 10:
    case 'SORT_ORDER_USER':
      return SortOrder.SORT_ORDER_USER;
    case 11:
    case 'SORT_ORDER_TYPE':
      return SortOrder.SORT_ORDER_TYPE;
    case 12:
    case 'SORT_ORDER_OBJECT':
      return SortOrder.SORT_ORDER_OBJECT;
    case 13:
    case 'SORT_ORDER_ACTIVITY':
      return SortOrder.SORT_ORDER_ACTIVITY;
    case 14:
    case 'SORT_ORDER_LOGINS':
      return SortOrder.SORT_ORDER_LOGINS;
    case 15:
    case 'SORT_ORDER_DOCUMENT_VIEWS':
      return SortOrder.SORT_ORDER_DOCUMENT_VIEWS;
    case 16:
    case 'SORT_ORDER_DOCUMENT_DOWNLOADS':
      return SortOrder.SORT_ORDER_DOCUMENT_DOWNLOADS;
    case 17:
    case 'SORT_ORDER_GROUP':
      return SortOrder.SORT_ORDER_GROUP;
    case 18:
    case 'SORT_ORDER_INVOICE_FROM':
      return SortOrder.SORT_ORDER_INVOICE_FROM;
    case 19:
    case 'SORT_ORDER_INVOICE_UNTIL':
      return SortOrder.SORT_ORDER_INVOICE_UNTIL;
    case 20:
    case 'SORT_ORDER_INVOICE_DATE':
      return SortOrder.SORT_ORDER_INVOICE_DATE;
    case 21:
    case 'SORT_ORDER_INVOICE_AMOUNT':
      return SortOrder.SORT_ORDER_INVOICE_AMOUNT;
    case 22:
    case 'SORT_ORDER_INVOICE_AMOUNT_OPEN':
      return SortOrder.SORT_ORDER_INVOICE_AMOUNT_OPEN;
    case 23:
    case 'SORT_ORDER_INVOICE_AMOUNT_PAID':
      return SortOrder.SORT_ORDER_INVOICE_AMOUNT_PAID;
    case 24:
    case 'SORT_ORDER_IP_ADDRESS':
      return SortOrder.SORT_ORDER_IP_ADDRESS;
    case 25:
    case 'SORT_ORDER_LOCATION':
      return SortOrder.SORT_ORDER_LOCATION;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SortOrder.UNRECOGNIZED;
  }
}

export function sortOrderToJSON(object: SortOrder): string {
  switch (object) {
    case SortOrder.SORT_ORDER_UNSPECIFIED:
      return 'SORT_ORDER_UNSPECIFIED';
    case SortOrder.SORT_ORDER_NAME:
      return 'SORT_ORDER_NAME';
    case SortOrder.SORT_ORDER_SIZE:
      return 'SORT_ORDER_SIZE';
    case SortOrder.SORT_ORDER_CREATION_DATE:
      return 'SORT_ORDER_CREATION_DATE';
    case SortOrder.SORT_ORDER_MODIFICATION_DATE:
      return 'SORT_ORDER_MODIFICATION_DATE';
    case SortOrder.SORT_ORDER_LASTLOGIN_DATE:
      return 'SORT_ORDER_LASTLOGIN_DATE';
    case SortOrder.SORT_ORDER_LOGINCOUNT:
      return 'SORT_ORDER_LOGINCOUNT';
    case SortOrder.SORT_ORDER_EMAIL:
      return 'SORT_ORDER_EMAIL';
    case SortOrder.SORT_ORDER_SCORE:
      return 'SORT_ORDER_SCORE';
    case SortOrder.SORT_ORDER_DATE:
      return 'SORT_ORDER_DATE';
    case SortOrder.SORT_ORDER_USER:
      return 'SORT_ORDER_USER';
    case SortOrder.SORT_ORDER_TYPE:
      return 'SORT_ORDER_TYPE';
    case SortOrder.SORT_ORDER_OBJECT:
      return 'SORT_ORDER_OBJECT';
    case SortOrder.SORT_ORDER_ACTIVITY:
      return 'SORT_ORDER_ACTIVITY';
    case SortOrder.SORT_ORDER_LOGINS:
      return 'SORT_ORDER_LOGINS';
    case SortOrder.SORT_ORDER_DOCUMENT_VIEWS:
      return 'SORT_ORDER_DOCUMENT_VIEWS';
    case SortOrder.SORT_ORDER_DOCUMENT_DOWNLOADS:
      return 'SORT_ORDER_DOCUMENT_DOWNLOADS';
    case SortOrder.SORT_ORDER_GROUP:
      return 'SORT_ORDER_GROUP';
    case SortOrder.SORT_ORDER_INVOICE_FROM:
      return 'SORT_ORDER_INVOICE_FROM';
    case SortOrder.SORT_ORDER_INVOICE_UNTIL:
      return 'SORT_ORDER_INVOICE_UNTIL';
    case SortOrder.SORT_ORDER_INVOICE_DATE:
      return 'SORT_ORDER_INVOICE_DATE';
    case SortOrder.SORT_ORDER_INVOICE_AMOUNT:
      return 'SORT_ORDER_INVOICE_AMOUNT';
    case SortOrder.SORT_ORDER_INVOICE_AMOUNT_OPEN:
      return 'SORT_ORDER_INVOICE_AMOUNT_OPEN';
    case SortOrder.SORT_ORDER_INVOICE_AMOUNT_PAID:
      return 'SORT_ORDER_INVOICE_AMOUNT_PAID';
    case SortOrder.SORT_ORDER_IP_ADDRESS:
      return 'SORT_ORDER_IP_ADDRESS';
    case SortOrder.SORT_ORDER_LOCATION:
      return 'SORT_ORDER_LOCATION';
    default:
      return 'UNKNOWN';
  }
}

/** Sort Direction */
export enum SortDirection {
  SORT_DIRECTION_UNSPECIFIED = 0,
  SORT_DIRECTION_ASCENDING = 1,
  SORT_DIRECTION_DESCENDING = 2,
  UNRECOGNIZED = -1,
}

export function sortDirectionFromJSON(object: any): SortDirection {
  switch (object) {
    case 0:
    case 'SORT_DIRECTION_UNSPECIFIED':
      return SortDirection.SORT_DIRECTION_UNSPECIFIED;
    case 1:
    case 'SORT_DIRECTION_ASCENDING':
      return SortDirection.SORT_DIRECTION_ASCENDING;
    case 2:
    case 'SORT_DIRECTION_DESCENDING':
      return SortDirection.SORT_DIRECTION_DESCENDING;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SortDirection.UNRECOGNIZED;
  }
}

export function sortDirectionToJSON(object: SortDirection): string {
  switch (object) {
    case SortDirection.SORT_DIRECTION_UNSPECIFIED:
      return 'SORT_DIRECTION_UNSPECIFIED';
    case SortDirection.SORT_DIRECTION_ASCENDING:
      return 'SORT_DIRECTION_ASCENDING';
    case SortDirection.SORT_DIRECTION_DESCENDING:
      return 'SORT_DIRECTION_DESCENDING';
    default:
      return 'UNKNOWN';
  }
}

/** Search period for new/modified */
export enum SearchPeriod {
  SEARCH_PERIOD_UNSPECIFIED = 0,
  SEARCH_PERIOD_SINCE_LASTLOGIN = 1,
  SEARCH_PERIOD_SINCE_1DAY = 2,
  SEARCH_PERIOD_SINCE_2DAYS = 3,
  SEARCH_PERIOD_SINCE_3DAYS = 4,
  SEARCH_PERIOD_SINCE_7DAYS = 5,
  SEARCH_PERIOD_SINCE_14DAYS = 6,
  SEARCH_PERIOD_SINCE_21DAYS = 7,
  SEARCH_PERIOD_SINCE_31DAYS = 8,
  SEARCH_PERIOD_SINCE_92DAYS = 9,
  SEARCH_PERIOD_SINCE_183DAYS = 10,
  SEARCH_PERIOD_SINCE_366DAYS = 11,
  SEARCH_PERIOD_MORETHAN_366DAYS = 12,
  UNRECOGNIZED = -1,
}

export function searchPeriodFromJSON(object: any): SearchPeriod {
  switch (object) {
    case 0:
    case 'SEARCH_PERIOD_UNSPECIFIED':
      return SearchPeriod.SEARCH_PERIOD_UNSPECIFIED;
    case 1:
    case 'SEARCH_PERIOD_SINCE_LASTLOGIN':
      return SearchPeriod.SEARCH_PERIOD_SINCE_LASTLOGIN;
    case 2:
    case 'SEARCH_PERIOD_SINCE_1DAY':
      return SearchPeriod.SEARCH_PERIOD_SINCE_1DAY;
    case 3:
    case 'SEARCH_PERIOD_SINCE_2DAYS':
      return SearchPeriod.SEARCH_PERIOD_SINCE_2DAYS;
    case 4:
    case 'SEARCH_PERIOD_SINCE_3DAYS':
      return SearchPeriod.SEARCH_PERIOD_SINCE_3DAYS;
    case 5:
    case 'SEARCH_PERIOD_SINCE_7DAYS':
      return SearchPeriod.SEARCH_PERIOD_SINCE_7DAYS;
    case 6:
    case 'SEARCH_PERIOD_SINCE_14DAYS':
      return SearchPeriod.SEARCH_PERIOD_SINCE_14DAYS;
    case 7:
    case 'SEARCH_PERIOD_SINCE_21DAYS':
      return SearchPeriod.SEARCH_PERIOD_SINCE_21DAYS;
    case 8:
    case 'SEARCH_PERIOD_SINCE_31DAYS':
      return SearchPeriod.SEARCH_PERIOD_SINCE_31DAYS;
    case 9:
    case 'SEARCH_PERIOD_SINCE_92DAYS':
      return SearchPeriod.SEARCH_PERIOD_SINCE_92DAYS;
    case 10:
    case 'SEARCH_PERIOD_SINCE_183DAYS':
      return SearchPeriod.SEARCH_PERIOD_SINCE_183DAYS;
    case 11:
    case 'SEARCH_PERIOD_SINCE_366DAYS':
      return SearchPeriod.SEARCH_PERIOD_SINCE_366DAYS;
    case 12:
    case 'SEARCH_PERIOD_MORETHAN_366DAYS':
      return SearchPeriod.SEARCH_PERIOD_MORETHAN_366DAYS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SearchPeriod.UNRECOGNIZED;
  }
}

export function searchPeriodToJSON(object: SearchPeriod): string {
  switch (object) {
    case SearchPeriod.SEARCH_PERIOD_UNSPECIFIED:
      return 'SEARCH_PERIOD_UNSPECIFIED';
    case SearchPeriod.SEARCH_PERIOD_SINCE_LASTLOGIN:
      return 'SEARCH_PERIOD_SINCE_LASTLOGIN';
    case SearchPeriod.SEARCH_PERIOD_SINCE_1DAY:
      return 'SEARCH_PERIOD_SINCE_1DAY';
    case SearchPeriod.SEARCH_PERIOD_SINCE_2DAYS:
      return 'SEARCH_PERIOD_SINCE_2DAYS';
    case SearchPeriod.SEARCH_PERIOD_SINCE_3DAYS:
      return 'SEARCH_PERIOD_SINCE_3DAYS';
    case SearchPeriod.SEARCH_PERIOD_SINCE_7DAYS:
      return 'SEARCH_PERIOD_SINCE_7DAYS';
    case SearchPeriod.SEARCH_PERIOD_SINCE_14DAYS:
      return 'SEARCH_PERIOD_SINCE_14DAYS';
    case SearchPeriod.SEARCH_PERIOD_SINCE_21DAYS:
      return 'SEARCH_PERIOD_SINCE_21DAYS';
    case SearchPeriod.SEARCH_PERIOD_SINCE_31DAYS:
      return 'SEARCH_PERIOD_SINCE_31DAYS';
    case SearchPeriod.SEARCH_PERIOD_SINCE_92DAYS:
      return 'SEARCH_PERIOD_SINCE_92DAYS';
    case SearchPeriod.SEARCH_PERIOD_SINCE_183DAYS:
      return 'SEARCH_PERIOD_SINCE_183DAYS';
    case SearchPeriod.SEARCH_PERIOD_SINCE_366DAYS:
      return 'SEARCH_PERIOD_SINCE_366DAYS';
    case SearchPeriod.SEARCH_PERIOD_MORETHAN_366DAYS:
      return 'SEARCH_PERIOD_MORETHAN_366DAYS';
    default:
      return 'UNKNOWN';
  }
}

/** The Search Scope */
export enum SearchScope {
  SEARCH_SCOPE_UNSPECIFIED = 0,
  SEARCH_SCOPE_DOCUMENTS_ONLY = 1,
  SEARCH_SCOPE_COLLECTIONS_ONLY = 2,
  SEARCH_SCOPE_DOCUMENTS_COLLECTIONS = 3,
  UNRECOGNIZED = -1,
}

export function searchScopeFromJSON(object: any): SearchScope {
  switch (object) {
    case 0:
    case 'SEARCH_SCOPE_UNSPECIFIED':
      return SearchScope.SEARCH_SCOPE_UNSPECIFIED;
    case 1:
    case 'SEARCH_SCOPE_DOCUMENTS_ONLY':
      return SearchScope.SEARCH_SCOPE_DOCUMENTS_ONLY;
    case 2:
    case 'SEARCH_SCOPE_COLLECTIONS_ONLY':
      return SearchScope.SEARCH_SCOPE_COLLECTIONS_ONLY;
    case 3:
    case 'SEARCH_SCOPE_DOCUMENTS_COLLECTIONS':
      return SearchScope.SEARCH_SCOPE_DOCUMENTS_COLLECTIONS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SearchScope.UNRECOGNIZED;
  }
}

export function searchScopeToJSON(object: SearchScope): string {
  switch (object) {
    case SearchScope.SEARCH_SCOPE_UNSPECIFIED:
      return 'SEARCH_SCOPE_UNSPECIFIED';
    case SearchScope.SEARCH_SCOPE_DOCUMENTS_ONLY:
      return 'SEARCH_SCOPE_DOCUMENTS_ONLY';
    case SearchScope.SEARCH_SCOPE_COLLECTIONS_ONLY:
      return 'SEARCH_SCOPE_COLLECTIONS_ONLY';
    case SearchScope.SEARCH_SCOPE_DOCUMENTS_COLLECTIONS:
      return 'SEARCH_SCOPE_DOCUMENTS_COLLECTIONS';
    default:
      return 'UNKNOWN';
  }
}

/** The Search Type */
export enum SearchType {
  SEARCH_TYPE_UNSPECIFIED = 0,
  SEARCH_TYPE_ATTRIBUTES_ONLY = 1,
  SEARCH_TYPE_FULLTEXT = 2,
  SEARCH_TYPE_TITLE = 3,
  UNRECOGNIZED = -1,
}

export function searchTypeFromJSON(object: any): SearchType {
  switch (object) {
    case 0:
    case 'SEARCH_TYPE_UNSPECIFIED':
      return SearchType.SEARCH_TYPE_UNSPECIFIED;
    case 1:
    case 'SEARCH_TYPE_ATTRIBUTES_ONLY':
      return SearchType.SEARCH_TYPE_ATTRIBUTES_ONLY;
    case 2:
    case 'SEARCH_TYPE_FULLTEXT':
      return SearchType.SEARCH_TYPE_FULLTEXT;
    case 3:
    case 'SEARCH_TYPE_TITLE':
      return SearchType.SEARCH_TYPE_TITLE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SearchType.UNRECOGNIZED;
  }
}

export function searchTypeToJSON(object: SearchType): string {
  switch (object) {
    case SearchType.SEARCH_TYPE_UNSPECIFIED:
      return 'SEARCH_TYPE_UNSPECIFIED';
    case SearchType.SEARCH_TYPE_ATTRIBUTES_ONLY:
      return 'SEARCH_TYPE_ATTRIBUTES_ONLY';
    case SearchType.SEARCH_TYPE_FULLTEXT:
      return 'SEARCH_TYPE_FULLTEXT';
    case SearchType.SEARCH_TYPE_TITLE:
      return 'SEARCH_TYPE_TITLE';
    default:
      return 'UNKNOWN';
  }
}

/** Generic empty message to avoid re-declaring empty messaages again and again */
export interface Empty {}

function createBaseEmpty(): Empty {
  return {};
}

export const Empty = {
  fromJSON(_: any): Empty {
    return {};
  },

  toJSON(_: Empty): unknown {
    const obj: any = {};
    return obj;
  },
};
