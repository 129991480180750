/* eslint-disable */
import { ItemType, itemTypeFromJSON, itemTypeToJSON } from './item';
import { Timestamp } from './google/protobuf/timestamp';
import {
  Language,
  Locale,
  Salutation,
  DefaultVisibilityOptions,
  languageFromJSON,
  localeFromJSON,
  salutationFromJSON,
  defaultVisibilityOptionsFromJSON,
  languageToJSON,
  localeToJSON,
  salutationToJSON,
  defaultVisibilityOptionsToJSON,
} from './settings';
import { EffectivePermission } from './permission';

export const protobufPackage = 'de.netfiles.pb';

export enum DataroomAccessType {
  DATAROOM_ACCESS_TYPE_UNSPECIFIED = 0,
  DATAROOM_ACCESS_TYPE_ACCEPT = 1,
  DATAROOM_ACCESS_TYPE_DECLINE = 2,
  DATAROOM_ACCESS_TYPE_TERMINATE = 3,
  UNRECOGNIZED = -1,
}

export function dataroomAccessTypeFromJSON(object: any): DataroomAccessType {
  switch (object) {
    case 0:
    case 'DATAROOM_ACCESS_TYPE_UNSPECIFIED':
      return DataroomAccessType.DATAROOM_ACCESS_TYPE_UNSPECIFIED;
    case 1:
    case 'DATAROOM_ACCESS_TYPE_ACCEPT':
      return DataroomAccessType.DATAROOM_ACCESS_TYPE_ACCEPT;
    case 2:
    case 'DATAROOM_ACCESS_TYPE_DECLINE':
      return DataroomAccessType.DATAROOM_ACCESS_TYPE_DECLINE;
    case 3:
    case 'DATAROOM_ACCESS_TYPE_TERMINATE':
      return DataroomAccessType.DATAROOM_ACCESS_TYPE_TERMINATE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DataroomAccessType.UNRECOGNIZED;
  }
}

export function dataroomAccessTypeToJSON(object: DataroomAccessType): string {
  switch (object) {
    case DataroomAccessType.DATAROOM_ACCESS_TYPE_UNSPECIFIED:
      return 'DATAROOM_ACCESS_TYPE_UNSPECIFIED';
    case DataroomAccessType.DATAROOM_ACCESS_TYPE_ACCEPT:
      return 'DATAROOM_ACCESS_TYPE_ACCEPT';
    case DataroomAccessType.DATAROOM_ACCESS_TYPE_DECLINE:
      return 'DATAROOM_ACCESS_TYPE_DECLINE';
    case DataroomAccessType.DATAROOM_ACCESS_TYPE_TERMINATE:
      return 'DATAROOM_ACCESS_TYPE_TERMINATE';
    default:
      return 'UNKNOWN';
  }
}

/** User Socket Event */
export enum UserSocketEvent {
  USER_SOCKET_EVENT_SETTINGS_UPDATED = 0,
  USER_SOCKET_EVENT_DATAROOM_ADDED = 1,
  USER_SOCKET_EVENT_DATAROOM_REMOVED = 2,
  USER_SOCKET_EVENT_LOGOUT = 3,
  USER_SOCKET_EVENT_GROUP_ADDED = 4,
  USER_SOCKET_EVENT_GROUP_REMOVED = 5,
  USER_SOCKET_EVENT_DATAROOM_ADMIN_CHANGED = 6,
  UNRECOGNIZED = -1,
}

export function userSocketEventFromJSON(object: any): UserSocketEvent {
  switch (object) {
    case 0:
    case 'USER_SOCKET_EVENT_SETTINGS_UPDATED':
      return UserSocketEvent.USER_SOCKET_EVENT_SETTINGS_UPDATED;
    case 1:
    case 'USER_SOCKET_EVENT_DATAROOM_ADDED':
      return UserSocketEvent.USER_SOCKET_EVENT_DATAROOM_ADDED;
    case 2:
    case 'USER_SOCKET_EVENT_DATAROOM_REMOVED':
      return UserSocketEvent.USER_SOCKET_EVENT_DATAROOM_REMOVED;
    case 3:
    case 'USER_SOCKET_EVENT_LOGOUT':
      return UserSocketEvent.USER_SOCKET_EVENT_LOGOUT;
    case 4:
    case 'USER_SOCKET_EVENT_GROUP_ADDED':
      return UserSocketEvent.USER_SOCKET_EVENT_GROUP_ADDED;
    case 5:
    case 'USER_SOCKET_EVENT_GROUP_REMOVED':
      return UserSocketEvent.USER_SOCKET_EVENT_GROUP_REMOVED;
    case 6:
    case 'USER_SOCKET_EVENT_DATAROOM_ADMIN_CHANGED':
      return UserSocketEvent.USER_SOCKET_EVENT_DATAROOM_ADMIN_CHANGED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return UserSocketEvent.UNRECOGNIZED;
  }
}

export function userSocketEventToJSON(object: UserSocketEvent): string {
  switch (object) {
    case UserSocketEvent.USER_SOCKET_EVENT_SETTINGS_UPDATED:
      return 'USER_SOCKET_EVENT_SETTINGS_UPDATED';
    case UserSocketEvent.USER_SOCKET_EVENT_DATAROOM_ADDED:
      return 'USER_SOCKET_EVENT_DATAROOM_ADDED';
    case UserSocketEvent.USER_SOCKET_EVENT_DATAROOM_REMOVED:
      return 'USER_SOCKET_EVENT_DATAROOM_REMOVED';
    case UserSocketEvent.USER_SOCKET_EVENT_LOGOUT:
      return 'USER_SOCKET_EVENT_LOGOUT';
    case UserSocketEvent.USER_SOCKET_EVENT_GROUP_ADDED:
      return 'USER_SOCKET_EVENT_GROUP_ADDED';
    case UserSocketEvent.USER_SOCKET_EVENT_GROUP_REMOVED:
      return 'USER_SOCKET_EVENT_GROUP_REMOVED';
    case UserSocketEvent.USER_SOCKET_EVENT_DATAROOM_ADMIN_CHANGED:
      return 'USER_SOCKET_EVENT_DATAROOM_ADMIN_CHANGED';
    default:
      return 'UNKNOWN';
  }
}

/** User Message */
export interface User {
  id: string;
  name: string;
  type?: string | undefined;
  path?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  realName?: string | undefined;
  title: string;
  email?: string | undefined;
  href?: string | undefined;
  logins?: number | undefined;
  lastLogin?: Date | undefined;
  language?: Language | undefined;
  locale?: Locale | undefined;
  timezone?: string | undefined;
  salutation?: Salutation | undefined;
  isUserInactive?: boolean | undefined;
  isUserUnconfirmed?: boolean | undefined;
  isUserInvited?: boolean | undefined;
  phoneNr?: string | undefined;
  mobilePhoneNr?: string | undefined;
  itemType: ItemType;
  effectivePermission?: EffectivePermission | undefined;
  restrictAccessFrom?: Date | undefined;
  restrictAccessTo?: Date | undefined;
  visibility?: DefaultVisibilityOptions | undefined;
  mountpoint?: string | undefined;
  company?: string | undefined;
  userTitle?: string | undefined;
}

/** User Login Activity */
export interface UserLoginActivity {
  id: string;
  name: string;
  title: string;
  itemType: ItemType;
  creationDate: Date | undefined;
  modificationDate: Date | undefined;
  logins: number;
  ipAddress: string;
  location: string;
  effectivePermission?: EffectivePermission | undefined;
}

export interface UserGroupMessage {
  groupId: string;
  isAdmin: boolean;
  isResposibleAdmin?: boolean | undefined;
}

export interface UserAdminChangeMessage {
  dataroomId: string;
  isResposibleAdmin: boolean;
}

function createBaseUser(): User {
  return {
    id: '',
    name: '',
    type: undefined,
    path: undefined,
    firstName: undefined,
    lastName: undefined,
    realName: undefined,
    title: '',
    email: undefined,
    href: undefined,
    logins: undefined,
    lastLogin: undefined,
    language: undefined,
    locale: undefined,
    timezone: undefined,
    salutation: undefined,
    isUserInactive: undefined,
    isUserUnconfirmed: undefined,
    isUserInvited: undefined,
    phoneNr: undefined,
    mobilePhoneNr: undefined,
    itemType: 0,
    effectivePermission: undefined,
    restrictAccessFrom: undefined,
    restrictAccessTo: undefined,
    visibility: undefined,
    mountpoint: undefined,
    company: undefined,
    userTitle: undefined,
  };
}

export const User = {
  fromJSON(object: any): User {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name) ? String(object.name) : '',
      type: isSet(object.type) ? String(object.type) : undefined,
      path: isSet(object.path) ? String(object.path) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      realName: isSet(object.realName) ? String(object.realName) : undefined,
      title: isSet(object.title) ? String(object.title) : '',
      email: isSet(object.email) ? String(object.email) : undefined,
      href: isSet(object.href) ? String(object.href) : undefined,
      logins: isSet(object.logins) ? Number(object.logins) : undefined,
      lastLogin: isSet(object.lastLogin)
        ? fromJsonTimestamp(object.lastLogin)
        : undefined,
      language: isSet(object.language)
        ? languageFromJSON(object.language)
        : undefined,
      locale: isSet(object.locale) ? localeFromJSON(object.locale) : undefined,
      timezone: isSet(object.timezone) ? String(object.timezone) : undefined,
      salutation: isSet(object.salutation)
        ? salutationFromJSON(object.salutation)
        : undefined,
      isUserInactive: isSet(object.isUserInactive)
        ? Boolean(object.isUserInactive)
        : undefined,
      isUserUnconfirmed: isSet(object.isUserUnconfirmed)
        ? Boolean(object.isUserUnconfirmed)
        : undefined,
      isUserInvited: isSet(object.isUserInvited)
        ? Boolean(object.isUserInvited)
        : undefined,
      phoneNr: isSet(object.phoneNr) ? String(object.phoneNr) : undefined,
      mobilePhoneNr: isSet(object.mobilePhoneNr)
        ? String(object.mobilePhoneNr)
        : undefined,
      itemType: isSet(object.itemType) ? itemTypeFromJSON(object.itemType) : 0,
      effectivePermission: isSet(object.effectivePermission)
        ? EffectivePermission.fromJSON(object.effectivePermission)
        : undefined,
      restrictAccessFrom: isSet(object.restrictAccessFrom)
        ? fromJsonTimestamp(object.restrictAccessFrom)
        : undefined,
      restrictAccessTo: isSet(object.restrictAccessTo)
        ? fromJsonTimestamp(object.restrictAccessTo)
        : undefined,
      visibility: isSet(object.visibility)
        ? defaultVisibilityOptionsFromJSON(object.visibility)
        : undefined,
      mountpoint: isSet(object.mountpoint)
        ? String(object.mountpoint)
        : undefined,
      company: isSet(object.company) ? String(object.company) : undefined,
      userTitle: isSet(object.userTitle) ? String(object.userTitle) : undefined,
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined && (obj.type = message.type);
    message.path !== undefined && (obj.path = message.path);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.realName !== undefined && (obj.realName = message.realName);
    message.title !== undefined && (obj.title = message.title);
    message.email !== undefined && (obj.email = message.email);
    message.href !== undefined && (obj.href = message.href);
    message.logins !== undefined && (obj.logins = Math.round(message.logins));
    message.lastLogin !== undefined &&
      (obj.lastLogin = message.lastLogin.toISOString());
    message.language !== undefined &&
      (obj.language =
        message.language !== undefined
          ? languageToJSON(message.language)
          : undefined);
    message.locale !== undefined &&
      (obj.locale =
        message.locale !== undefined
          ? localeToJSON(message.locale)
          : undefined);
    message.timezone !== undefined && (obj.timezone = message.timezone);
    message.salutation !== undefined &&
      (obj.salutation =
        message.salutation !== undefined
          ? salutationToJSON(message.salutation)
          : undefined);
    message.isUserInactive !== undefined &&
      (obj.isUserInactive = message.isUserInactive);
    message.isUserUnconfirmed !== undefined &&
      (obj.isUserUnconfirmed = message.isUserUnconfirmed);
    message.isUserInvited !== undefined &&
      (obj.isUserInvited = message.isUserInvited);
    message.phoneNr !== undefined && (obj.phoneNr = message.phoneNr);
    message.mobilePhoneNr !== undefined &&
      (obj.mobilePhoneNr = message.mobilePhoneNr);
    message.itemType !== undefined &&
      (obj.itemType = itemTypeToJSON(message.itemType));
    message.effectivePermission !== undefined &&
      (obj.effectivePermission = message.effectivePermission
        ? EffectivePermission.toJSON(message.effectivePermission)
        : undefined);
    message.restrictAccessFrom !== undefined &&
      (obj.restrictAccessFrom = message.restrictAccessFrom.toISOString());
    message.restrictAccessTo !== undefined &&
      (obj.restrictAccessTo = message.restrictAccessTo.toISOString());
    message.visibility !== undefined &&
      (obj.visibility =
        message.visibility !== undefined
          ? defaultVisibilityOptionsToJSON(message.visibility)
          : undefined);
    message.mountpoint !== undefined && (obj.mountpoint = message.mountpoint);
    message.company !== undefined && (obj.company = message.company);
    message.userTitle !== undefined && (obj.userTitle = message.userTitle);
    return obj;
  },
};

function createBaseUserLoginActivity(): UserLoginActivity {
  return {
    id: '',
    name: '',
    title: '',
    itemType: 0,
    creationDate: undefined,
    modificationDate: undefined,
    logins: 0,
    ipAddress: '',
    location: '',
    effectivePermission: undefined,
  };
}

export const UserLoginActivity = {
  fromJSON(object: any): UserLoginActivity {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name) ? String(object.name) : '',
      title: isSet(object.title) ? String(object.title) : '',
      itemType: isSet(object.itemType) ? itemTypeFromJSON(object.itemType) : 0,
      creationDate: isSet(object.creationDate)
        ? fromJsonTimestamp(object.creationDate)
        : undefined,
      modificationDate: isSet(object.modificationDate)
        ? fromJsonTimestamp(object.modificationDate)
        : undefined,
      logins: isSet(object.logins) ? Number(object.logins) : 0,
      ipAddress: isSet(object.ipAddress) ? String(object.ipAddress) : '',
      location: isSet(object.location) ? String(object.location) : '',
      effectivePermission: isSet(object.effectivePermission)
        ? EffectivePermission.fromJSON(object.effectivePermission)
        : undefined,
    };
  },

  toJSON(message: UserLoginActivity): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.title !== undefined && (obj.title = message.title);
    message.itemType !== undefined &&
      (obj.itemType = itemTypeToJSON(message.itemType));
    message.creationDate !== undefined &&
      (obj.creationDate = message.creationDate.toISOString());
    message.modificationDate !== undefined &&
      (obj.modificationDate = message.modificationDate.toISOString());
    message.logins !== undefined && (obj.logins = Math.round(message.logins));
    message.ipAddress !== undefined && (obj.ipAddress = message.ipAddress);
    message.location !== undefined && (obj.location = message.location);
    message.effectivePermission !== undefined &&
      (obj.effectivePermission = message.effectivePermission
        ? EffectivePermission.toJSON(message.effectivePermission)
        : undefined);
    return obj;
  },
};

function createBaseUserGroupMessage(): UserGroupMessage {
  return { groupId: '', isAdmin: false, isResposibleAdmin: undefined };
}

export const UserGroupMessage = {
  fromJSON(object: any): UserGroupMessage {
    return {
      groupId: isSet(object.groupId) ? String(object.groupId) : '',
      isAdmin: isSet(object.isAdmin) ? Boolean(object.isAdmin) : false,
      isResposibleAdmin: isSet(object.isResposibleAdmin)
        ? Boolean(object.isResposibleAdmin)
        : undefined,
    };
  },

  toJSON(message: UserGroupMessage): unknown {
    const obj: any = {};
    message.groupId !== undefined && (obj.groupId = message.groupId);
    message.isAdmin !== undefined && (obj.isAdmin = message.isAdmin);
    message.isResposibleAdmin !== undefined &&
      (obj.isResposibleAdmin = message.isResposibleAdmin);
    return obj;
  },
};

function createBaseUserAdminChangeMessage(): UserAdminChangeMessage {
  return { dataroomId: '', isResposibleAdmin: false };
}

export const UserAdminChangeMessage = {
  fromJSON(object: any): UserAdminChangeMessage {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      isResposibleAdmin: isSet(object.isResposibleAdmin)
        ? Boolean(object.isResposibleAdmin)
        : false,
    };
  },

  toJSON(message: UserAdminChangeMessage): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.isResposibleAdmin !== undefined &&
      (obj.isResposibleAdmin = message.isResposibleAdmin);
    return obj;
  },
};

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
