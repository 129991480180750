"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isEmpty = isEmpty;
exports.minLength = minLength;
exports.maxLength = maxLength;
exports.isEmail = isEmail;
exports.isValidUsername = isValidUsername;
exports.isValidUsernameOrEmail = isValidUsernameOrEmail;
exports.isIsoDate = isIsoDate;
exports.isZip = isZip;
exports.isIntPhoneNumber = isIntPhoneNumber;
exports.isUrl = isUrl;
exports.isBoolean = isBoolean;
exports.isNumber = isNumber;
exports.isFilename = isFilename;
// Check if value is empty
function isEmpty(value) {
    if (value === '') {
        return 'forms.validation.required';
    }
    return true;
}
// Check if value longer than len
function minLength(value, len) {
    if (value.length < len) {
        return 'forms.validation.tooShort';
    }
    return true;
}
// Check if value shorter than len
function maxLength(value, len) {
    if (value.length > len) {
        return 'forms.validation.tooLong';
    }
    return true;
}
// Check if value is valid email address
function isEmail(value) {
    const pattern = /^[A-z0-9._-]+@[A-z0-9.-]+\.[A-z]+$/;
    if (!pattern.test(value)) {
        return 'forms.validation.invalidEmail';
    }
    return true;
}
// Check if a value is a valid Netfiles username
function isValidUsername(value) {
    const pattern = /^[A-z0-9][A-z0-9._-]*$/;
    if (!pattern.test(value)) {
        return 'forms.validation.invalidUsername';
    }
    return true;
}
// Check if a value is a valid Netfiles username or email
function isValidUsernameOrEmail(value) {
    if (value.includes('@')) {
        return isEmail(value);
    }
    return isValidUsername(value);
}
// Check if value is valid iso date string
function isIsoDate(value) {
    const pattern = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-9]|3[0-1])T([0-2]\d:[0-5]\d:[0-5]\d)\.\d{3}Z$/;
    if (!pattern.test(value)) {
        return 'forms.validation.invalidDate';
    }
    return true;
}
// Check if value is valid zip code
function isZip(value) {
    const pattern = /^[a-z0-9][a-z0-9 -]{0,10}[a-z0-9]$/i;
    if (!pattern.test(value)) {
        return 'forms.validation.invalidZip';
    }
    return true;
}
// Check if value is valid international phone number
function isIntPhoneNumber(value) {
    const pattern = /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/;
    if (!pattern.test(value)) {
        return 'forms.validation.invalidIntPhoneNumber';
    }
    return true;
}
// Check if value is valid full URL
function isUrl(value) {
    const pattern = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;
    if (!pattern.test(value)) {
        return 'forms.validation.invalidUrl';
    }
    return true;
}
// Check if value is a boolean
function isBoolean(value) {
    if (typeof value !== 'boolean') {
        return 'forms.validation.invalidValue';
    }
    return true;
}
// Check if value is a number
function isNumber(value) {
    if (typeof value === 'number') {
        return true;
    }
    if (Number.isNaN(parseInt(value, 10)) ||
        parseInt(value, 10).toString() !== value) {
        return 'forms.validation.invalidNumber';
    }
    return true;
}
// Check if value is a valid filename/folder
function isFilename(value) {
    // Need to check for non-printable chars here
    // eslint-disable-next-line no-control-regex
    const pattern = /^[^*{}:|\\/?"<>[\x00-\x1F\x7F]*$/;
    if (!pattern.test(value) || value.endsWith('.')) {
        return 'forms.validation.invalidFilename';
    }
    return true;
}
