export enum ApiCodes {
  authenticationFailed = 'authentication.failed',
  objectAlreadyExists = 'object.alreadyexists',
  objectNotRestorable = 'object.notrestorable',
  objectNotFound = 'object.notfound',
  objectNotReplaced = 'object.notreplaced',
  objectWrongTarget = 'object.wrongtarget',
  objectTargetSelf = 'object.targetself',
  accessDenied = 'object.accessdenied',
  contentNotFound = 'object.nocontent',
  objectSequenceExists = 'object.sequenceexists',
  methodNotFound = 'method.notfound',
  errorInExecution = 'error.general',
  quantumExceeded = 'dataroom.quotaexceeded',
  featureNotAvailable = 'dataroom.featureNotAvailable',
  groupNotEmpty = 'group.notempty',
  userCannotRemoveSelf = 'user.cannotremoveself',
  sendLinkNoAccess = 'sendlink.noaccess',
  indexUploadWrongIndexFormat = 'upload.wrongIndexFormat',
  indexUploadIndexExists = 'upload.indexExists',
  internalError = 'internal.server.error',
  renameDuplicateError = 'upload.renameDuplicateError',
  corruptArchiveError = 'jobManager.errors.corruptArchiveError',
  passwordUnsecure = 'password.unsecure',
  passwordReused = 'password.reusedold',
  passwordTooShort = 'password.tooShort',
  passwordNotAllowed = 'password.notAllowed',
  passwordOldIsWrong = 'password.oldIsWrong',
  passwordResetDisabled = 'password.resetdisabled',
  userMultiAccountsFound = 'user.multiaccountsfound',
  userDeactivated = 'user.deactivated',
  userIsResponsibleAdmin = 'user.isResponsibleAdmin',
  userIsLastAdmin = 'user.isLastAdmin',
  virusFound = 'jobManager.errors.virusFound',
  linkInvalid = 'link.invalid',
  usersImportWrongFormat = 'importUsers.wrongFormat',
  usersImportNotEnoughAvailableUsers = 'importUsers.notEnoughAvailableUsers',
  noPdfAvailable = 'pdf.notavailable',
  mftCollectionTooLage = 'mft.collectionTooLarge',
  mftNoActionsLeft = 'mft.noActionsLeft',
  phoneMissing = 'phone.missing',
  mail2faNotAllowed = 'auth2fa.mailNotAllowed',
  invalidSmsCode = 'reset.invalidSmsCode',
  invalidJson = 'json.invalid',
  invalidSearchterm = 'search.invalidTerm',
  missingParameters = 'missing.parameters',
  locked = 'locked',
}
