/* eslint-disable */
export const protobufPackage = 'de.netfiles.pb';

/** Netfiles item types */
export enum ItemType {
  ITEM_TYPE_UNSPECIFIED = 0,
  ITEM_TYPE_DATAROOM = 1,
  ITEM_TYPE_COLLECTION = 2,
  ITEM_TYPE_DOCUMENT = 3,
  ITEM_TYPE_WIKI = 4,
  ITEM_TYPE_MEETING = 5,
  ITEM_TYPE_MEETING_TOPIC = 6,
  ITEM_TYPE_MEETING_TOPIC_TASK = 7,
  ITEM_TYPE_MEETING_TOPIC_MINUTE = 8,
  ITEM_TYPE_FILES_ROOT = 9,
  ITEM_TYPE_CONFERENCES_ROOT = 10,
  ITEM_TYPE_TASKS_ROOT = 11,
  ITEM_TYPE_DATES_ROOT = 12,
  ITEM_TYPE_DISCUSSIONS_ROOT = 13,
  ITEM_TYPE_QANDA_ROOT = 14,
  ITEM_TYPE_USER = 15,
  ITEM_TYPE_GROUP = 16,
  ITEM_TYPE_GROUPS_ROOT = 17,
  ITEM_TYPE_TRASH_ROOT = 18,
  ITEM_TYPE_SEARCH_ROOT = 19,
  ITEM_TYPE_SEARCH_NEW_MODIFIED_ROOT = 20,
  ITEM_TYPE_SEARCH_RECENTLY_USED_ROOT = 21,
  ITEM_TYPE_SEARCH_TO_BE_APPROVED_ROOT = 22,
  ITEM_TYPE_SEARCH_EXPIRED_ROOT = 23,
  ITEM_TYPE_SEARCH_LABLED_ROOT = 24,
  ITEM_TYPE_SEARCH_UNREAD_ROOT = 25,
  ITEM_TYPE_SEARCH_LOCKED_FILES_ROOT = 26,
  ITEM_TYPE_SEARCH_RECENT_COMMENTS_ROOT = 27,
  ITEM_TYPE_BOOKMARK = 28,
  ITEM_TYPE_COMMENT = 29,
  ITEM_TYPE_INBOX_ROOT = 30,
  ITEM_TYPE_OUTBOX_ROOT = 31,
  ITEM_TYPE_INBOX_ITEM = 32,
  ITEM_TYPE_OUTBOX_ITEM = 33,
  ITEM_TYPE_EXPORT_ITEM = 34,
  ITEM_TYPE_ACTIVITY_LOG_ITEM = 35,
  ITEM_TYPE_ACTIVITY_LOG_ROOT = 36,
  ITEM_TYPE_ACTIVITY_LOG_USER_ROOT = 37,
  ITEM_TYPE_ACTIVITY_LOG_GROUP_ROOT = 38,
  ITEM_TYPE_ACTIVITY_LOG_ITEM_ENTRY = 39,
  ITEM_TYPE_ACTIVITY_LOG_GROUP = 40,
  ITEM_TYPE_ACTIVITY_LOG_USER = 41,
  ITEM_TYPE_INVOICES_ROOT = 42,
  ITEM_TYPE_INVOICE = 43,
  ITEM_TYPE_CONTRACT_ROOT = 44,
  ITEM_TYPE_CONTRACT_ITEM = 45,
  ITEM_TYPE_USER_LOGIN_ACTIVITY = 46,
  ITEM_TYPE_SHORTCUT = 98,
  ITEM_TYPE_TRASH = 99,
  UNRECOGNIZED = -1,
}

export function itemTypeFromJSON(object: any): ItemType {
  switch (object) {
    case 0:
    case 'ITEM_TYPE_UNSPECIFIED':
      return ItemType.ITEM_TYPE_UNSPECIFIED;
    case 1:
    case 'ITEM_TYPE_DATAROOM':
      return ItemType.ITEM_TYPE_DATAROOM;
    case 2:
    case 'ITEM_TYPE_COLLECTION':
      return ItemType.ITEM_TYPE_COLLECTION;
    case 3:
    case 'ITEM_TYPE_DOCUMENT':
      return ItemType.ITEM_TYPE_DOCUMENT;
    case 4:
    case 'ITEM_TYPE_WIKI':
      return ItemType.ITEM_TYPE_WIKI;
    case 5:
    case 'ITEM_TYPE_MEETING':
      return ItemType.ITEM_TYPE_MEETING;
    case 6:
    case 'ITEM_TYPE_MEETING_TOPIC':
      return ItemType.ITEM_TYPE_MEETING_TOPIC;
    case 7:
    case 'ITEM_TYPE_MEETING_TOPIC_TASK':
      return ItemType.ITEM_TYPE_MEETING_TOPIC_TASK;
    case 8:
    case 'ITEM_TYPE_MEETING_TOPIC_MINUTE':
      return ItemType.ITEM_TYPE_MEETING_TOPIC_MINUTE;
    case 9:
    case 'ITEM_TYPE_FILES_ROOT':
      return ItemType.ITEM_TYPE_FILES_ROOT;
    case 10:
    case 'ITEM_TYPE_CONFERENCES_ROOT':
      return ItemType.ITEM_TYPE_CONFERENCES_ROOT;
    case 11:
    case 'ITEM_TYPE_TASKS_ROOT':
      return ItemType.ITEM_TYPE_TASKS_ROOT;
    case 12:
    case 'ITEM_TYPE_DATES_ROOT':
      return ItemType.ITEM_TYPE_DATES_ROOT;
    case 13:
    case 'ITEM_TYPE_DISCUSSIONS_ROOT':
      return ItemType.ITEM_TYPE_DISCUSSIONS_ROOT;
    case 14:
    case 'ITEM_TYPE_QANDA_ROOT':
      return ItemType.ITEM_TYPE_QANDA_ROOT;
    case 15:
    case 'ITEM_TYPE_USER':
      return ItemType.ITEM_TYPE_USER;
    case 16:
    case 'ITEM_TYPE_GROUP':
      return ItemType.ITEM_TYPE_GROUP;
    case 17:
    case 'ITEM_TYPE_GROUPS_ROOT':
      return ItemType.ITEM_TYPE_GROUPS_ROOT;
    case 18:
    case 'ITEM_TYPE_TRASH_ROOT':
      return ItemType.ITEM_TYPE_TRASH_ROOT;
    case 19:
    case 'ITEM_TYPE_SEARCH_ROOT':
      return ItemType.ITEM_TYPE_SEARCH_ROOT;
    case 20:
    case 'ITEM_TYPE_SEARCH_NEW_MODIFIED_ROOT':
      return ItemType.ITEM_TYPE_SEARCH_NEW_MODIFIED_ROOT;
    case 21:
    case 'ITEM_TYPE_SEARCH_RECENTLY_USED_ROOT':
      return ItemType.ITEM_TYPE_SEARCH_RECENTLY_USED_ROOT;
    case 22:
    case 'ITEM_TYPE_SEARCH_TO_BE_APPROVED_ROOT':
      return ItemType.ITEM_TYPE_SEARCH_TO_BE_APPROVED_ROOT;
    case 23:
    case 'ITEM_TYPE_SEARCH_EXPIRED_ROOT':
      return ItemType.ITEM_TYPE_SEARCH_EXPIRED_ROOT;
    case 24:
    case 'ITEM_TYPE_SEARCH_LABLED_ROOT':
      return ItemType.ITEM_TYPE_SEARCH_LABLED_ROOT;
    case 25:
    case 'ITEM_TYPE_SEARCH_UNREAD_ROOT':
      return ItemType.ITEM_TYPE_SEARCH_UNREAD_ROOT;
    case 26:
    case 'ITEM_TYPE_SEARCH_LOCKED_FILES_ROOT':
      return ItemType.ITEM_TYPE_SEARCH_LOCKED_FILES_ROOT;
    case 27:
    case 'ITEM_TYPE_SEARCH_RECENT_COMMENTS_ROOT':
      return ItemType.ITEM_TYPE_SEARCH_RECENT_COMMENTS_ROOT;
    case 28:
    case 'ITEM_TYPE_BOOKMARK':
      return ItemType.ITEM_TYPE_BOOKMARK;
    case 29:
    case 'ITEM_TYPE_COMMENT':
      return ItemType.ITEM_TYPE_COMMENT;
    case 30:
    case 'ITEM_TYPE_INBOX_ROOT':
      return ItemType.ITEM_TYPE_INBOX_ROOT;
    case 31:
    case 'ITEM_TYPE_OUTBOX_ROOT':
      return ItemType.ITEM_TYPE_OUTBOX_ROOT;
    case 32:
    case 'ITEM_TYPE_INBOX_ITEM':
      return ItemType.ITEM_TYPE_INBOX_ITEM;
    case 33:
    case 'ITEM_TYPE_OUTBOX_ITEM':
      return ItemType.ITEM_TYPE_OUTBOX_ITEM;
    case 34:
    case 'ITEM_TYPE_EXPORT_ITEM':
      return ItemType.ITEM_TYPE_EXPORT_ITEM;
    case 35:
    case 'ITEM_TYPE_ACTIVITY_LOG_ITEM':
      return ItemType.ITEM_TYPE_ACTIVITY_LOG_ITEM;
    case 36:
    case 'ITEM_TYPE_ACTIVITY_LOG_ROOT':
      return ItemType.ITEM_TYPE_ACTIVITY_LOG_ROOT;
    case 37:
    case 'ITEM_TYPE_ACTIVITY_LOG_USER_ROOT':
      return ItemType.ITEM_TYPE_ACTIVITY_LOG_USER_ROOT;
    case 38:
    case 'ITEM_TYPE_ACTIVITY_LOG_GROUP_ROOT':
      return ItemType.ITEM_TYPE_ACTIVITY_LOG_GROUP_ROOT;
    case 39:
    case 'ITEM_TYPE_ACTIVITY_LOG_ITEM_ENTRY':
      return ItemType.ITEM_TYPE_ACTIVITY_LOG_ITEM_ENTRY;
    case 40:
    case 'ITEM_TYPE_ACTIVITY_LOG_GROUP':
      return ItemType.ITEM_TYPE_ACTIVITY_LOG_GROUP;
    case 41:
    case 'ITEM_TYPE_ACTIVITY_LOG_USER':
      return ItemType.ITEM_TYPE_ACTIVITY_LOG_USER;
    case 42:
    case 'ITEM_TYPE_INVOICES_ROOT':
      return ItemType.ITEM_TYPE_INVOICES_ROOT;
    case 43:
    case 'ITEM_TYPE_INVOICE':
      return ItemType.ITEM_TYPE_INVOICE;
    case 44:
    case 'ITEM_TYPE_CONTRACT_ROOT':
      return ItemType.ITEM_TYPE_CONTRACT_ROOT;
    case 45:
    case 'ITEM_TYPE_CONTRACT_ITEM':
      return ItemType.ITEM_TYPE_CONTRACT_ITEM;
    case 46:
    case 'ITEM_TYPE_USER_LOGIN_ACTIVITY':
      return ItemType.ITEM_TYPE_USER_LOGIN_ACTIVITY;
    case 98:
    case 'ITEM_TYPE_SHORTCUT':
      return ItemType.ITEM_TYPE_SHORTCUT;
    case 99:
    case 'ITEM_TYPE_TRASH':
      return ItemType.ITEM_TYPE_TRASH;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ItemType.UNRECOGNIZED;
  }
}

export function itemTypeToJSON(object: ItemType): string {
  switch (object) {
    case ItemType.ITEM_TYPE_UNSPECIFIED:
      return 'ITEM_TYPE_UNSPECIFIED';
    case ItemType.ITEM_TYPE_DATAROOM:
      return 'ITEM_TYPE_DATAROOM';
    case ItemType.ITEM_TYPE_COLLECTION:
      return 'ITEM_TYPE_COLLECTION';
    case ItemType.ITEM_TYPE_DOCUMENT:
      return 'ITEM_TYPE_DOCUMENT';
    case ItemType.ITEM_TYPE_WIKI:
      return 'ITEM_TYPE_WIKI';
    case ItemType.ITEM_TYPE_MEETING:
      return 'ITEM_TYPE_MEETING';
    case ItemType.ITEM_TYPE_MEETING_TOPIC:
      return 'ITEM_TYPE_MEETING_TOPIC';
    case ItemType.ITEM_TYPE_MEETING_TOPIC_TASK:
      return 'ITEM_TYPE_MEETING_TOPIC_TASK';
    case ItemType.ITEM_TYPE_MEETING_TOPIC_MINUTE:
      return 'ITEM_TYPE_MEETING_TOPIC_MINUTE';
    case ItemType.ITEM_TYPE_FILES_ROOT:
      return 'ITEM_TYPE_FILES_ROOT';
    case ItemType.ITEM_TYPE_CONFERENCES_ROOT:
      return 'ITEM_TYPE_CONFERENCES_ROOT';
    case ItemType.ITEM_TYPE_TASKS_ROOT:
      return 'ITEM_TYPE_TASKS_ROOT';
    case ItemType.ITEM_TYPE_DATES_ROOT:
      return 'ITEM_TYPE_DATES_ROOT';
    case ItemType.ITEM_TYPE_DISCUSSIONS_ROOT:
      return 'ITEM_TYPE_DISCUSSIONS_ROOT';
    case ItemType.ITEM_TYPE_QANDA_ROOT:
      return 'ITEM_TYPE_QANDA_ROOT';
    case ItemType.ITEM_TYPE_USER:
      return 'ITEM_TYPE_USER';
    case ItemType.ITEM_TYPE_GROUP:
      return 'ITEM_TYPE_GROUP';
    case ItemType.ITEM_TYPE_GROUPS_ROOT:
      return 'ITEM_TYPE_GROUPS_ROOT';
    case ItemType.ITEM_TYPE_TRASH_ROOT:
      return 'ITEM_TYPE_TRASH_ROOT';
    case ItemType.ITEM_TYPE_SEARCH_ROOT:
      return 'ITEM_TYPE_SEARCH_ROOT';
    case ItemType.ITEM_TYPE_SEARCH_NEW_MODIFIED_ROOT:
      return 'ITEM_TYPE_SEARCH_NEW_MODIFIED_ROOT';
    case ItemType.ITEM_TYPE_SEARCH_RECENTLY_USED_ROOT:
      return 'ITEM_TYPE_SEARCH_RECENTLY_USED_ROOT';
    case ItemType.ITEM_TYPE_SEARCH_TO_BE_APPROVED_ROOT:
      return 'ITEM_TYPE_SEARCH_TO_BE_APPROVED_ROOT';
    case ItemType.ITEM_TYPE_SEARCH_EXPIRED_ROOT:
      return 'ITEM_TYPE_SEARCH_EXPIRED_ROOT';
    case ItemType.ITEM_TYPE_SEARCH_LABLED_ROOT:
      return 'ITEM_TYPE_SEARCH_LABLED_ROOT';
    case ItemType.ITEM_TYPE_SEARCH_UNREAD_ROOT:
      return 'ITEM_TYPE_SEARCH_UNREAD_ROOT';
    case ItemType.ITEM_TYPE_SEARCH_LOCKED_FILES_ROOT:
      return 'ITEM_TYPE_SEARCH_LOCKED_FILES_ROOT';
    case ItemType.ITEM_TYPE_SEARCH_RECENT_COMMENTS_ROOT:
      return 'ITEM_TYPE_SEARCH_RECENT_COMMENTS_ROOT';
    case ItemType.ITEM_TYPE_BOOKMARK:
      return 'ITEM_TYPE_BOOKMARK';
    case ItemType.ITEM_TYPE_COMMENT:
      return 'ITEM_TYPE_COMMENT';
    case ItemType.ITEM_TYPE_INBOX_ROOT:
      return 'ITEM_TYPE_INBOX_ROOT';
    case ItemType.ITEM_TYPE_OUTBOX_ROOT:
      return 'ITEM_TYPE_OUTBOX_ROOT';
    case ItemType.ITEM_TYPE_INBOX_ITEM:
      return 'ITEM_TYPE_INBOX_ITEM';
    case ItemType.ITEM_TYPE_OUTBOX_ITEM:
      return 'ITEM_TYPE_OUTBOX_ITEM';
    case ItemType.ITEM_TYPE_EXPORT_ITEM:
      return 'ITEM_TYPE_EXPORT_ITEM';
    case ItemType.ITEM_TYPE_ACTIVITY_LOG_ITEM:
      return 'ITEM_TYPE_ACTIVITY_LOG_ITEM';
    case ItemType.ITEM_TYPE_ACTIVITY_LOG_ROOT:
      return 'ITEM_TYPE_ACTIVITY_LOG_ROOT';
    case ItemType.ITEM_TYPE_ACTIVITY_LOG_USER_ROOT:
      return 'ITEM_TYPE_ACTIVITY_LOG_USER_ROOT';
    case ItemType.ITEM_TYPE_ACTIVITY_LOG_GROUP_ROOT:
      return 'ITEM_TYPE_ACTIVITY_LOG_GROUP_ROOT';
    case ItemType.ITEM_TYPE_ACTIVITY_LOG_ITEM_ENTRY:
      return 'ITEM_TYPE_ACTIVITY_LOG_ITEM_ENTRY';
    case ItemType.ITEM_TYPE_ACTIVITY_LOG_GROUP:
      return 'ITEM_TYPE_ACTIVITY_LOG_GROUP';
    case ItemType.ITEM_TYPE_ACTIVITY_LOG_USER:
      return 'ITEM_TYPE_ACTIVITY_LOG_USER';
    case ItemType.ITEM_TYPE_INVOICES_ROOT:
      return 'ITEM_TYPE_INVOICES_ROOT';
    case ItemType.ITEM_TYPE_INVOICE:
      return 'ITEM_TYPE_INVOICE';
    case ItemType.ITEM_TYPE_CONTRACT_ROOT:
      return 'ITEM_TYPE_CONTRACT_ROOT';
    case ItemType.ITEM_TYPE_CONTRACT_ITEM:
      return 'ITEM_TYPE_CONTRACT_ITEM';
    case ItemType.ITEM_TYPE_USER_LOGIN_ACTIVITY:
      return 'ITEM_TYPE_USER_LOGIN_ACTIVITY';
    case ItemType.ITEM_TYPE_SHORTCUT:
      return 'ITEM_TYPE_SHORTCUT';
    case ItemType.ITEM_TYPE_TRASH:
      return 'ITEM_TYPE_TRASH';
    default:
      return 'UNKNOWN';
  }
}

export enum ItemSubType {
  ITEM_SUBTYPE_UNSPECIFIED = 0,
  ITEM_SUBTYPE_GROUP_ADMIN = 1,
  ITEM_SUBTYPE_GROUP_ALLUSERS = 2,
  ITEM_SUBTYPE_DOCUMENT_REMOTE = 3,
  ITEM_SUBTYPE_DOCUMENT_TEXT = 4,
  ITEM_SUBTYPE_DOCUMENT_EXCEL = 5,
  ITEM_SUBTYPE_DOCUMENT_WORD = 6,
  ITEM_SUBTYPE_DOCUMENT_POWERPOINT = 7,
  UNRECOGNIZED = -1,
}

export function itemSubTypeFromJSON(object: any): ItemSubType {
  switch (object) {
    case 0:
    case 'ITEM_SUBTYPE_UNSPECIFIED':
      return ItemSubType.ITEM_SUBTYPE_UNSPECIFIED;
    case 1:
    case 'ITEM_SUBTYPE_GROUP_ADMIN':
      return ItemSubType.ITEM_SUBTYPE_GROUP_ADMIN;
    case 2:
    case 'ITEM_SUBTYPE_GROUP_ALLUSERS':
      return ItemSubType.ITEM_SUBTYPE_GROUP_ALLUSERS;
    case 3:
    case 'ITEM_SUBTYPE_DOCUMENT_REMOTE':
      return ItemSubType.ITEM_SUBTYPE_DOCUMENT_REMOTE;
    case 4:
    case 'ITEM_SUBTYPE_DOCUMENT_TEXT':
      return ItemSubType.ITEM_SUBTYPE_DOCUMENT_TEXT;
    case 5:
    case 'ITEM_SUBTYPE_DOCUMENT_EXCEL':
      return ItemSubType.ITEM_SUBTYPE_DOCUMENT_EXCEL;
    case 6:
    case 'ITEM_SUBTYPE_DOCUMENT_WORD':
      return ItemSubType.ITEM_SUBTYPE_DOCUMENT_WORD;
    case 7:
    case 'ITEM_SUBTYPE_DOCUMENT_POWERPOINT':
      return ItemSubType.ITEM_SUBTYPE_DOCUMENT_POWERPOINT;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ItemSubType.UNRECOGNIZED;
  }
}

export function itemSubTypeToJSON(object: ItemSubType): string {
  switch (object) {
    case ItemSubType.ITEM_SUBTYPE_UNSPECIFIED:
      return 'ITEM_SUBTYPE_UNSPECIFIED';
    case ItemSubType.ITEM_SUBTYPE_GROUP_ADMIN:
      return 'ITEM_SUBTYPE_GROUP_ADMIN';
    case ItemSubType.ITEM_SUBTYPE_GROUP_ALLUSERS:
      return 'ITEM_SUBTYPE_GROUP_ALLUSERS';
    case ItemSubType.ITEM_SUBTYPE_DOCUMENT_REMOTE:
      return 'ITEM_SUBTYPE_DOCUMENT_REMOTE';
    case ItemSubType.ITEM_SUBTYPE_DOCUMENT_TEXT:
      return 'ITEM_SUBTYPE_DOCUMENT_TEXT';
    case ItemSubType.ITEM_SUBTYPE_DOCUMENT_EXCEL:
      return 'ITEM_SUBTYPE_DOCUMENT_EXCEL';
    case ItemSubType.ITEM_SUBTYPE_DOCUMENT_WORD:
      return 'ITEM_SUBTYPE_DOCUMENT_WORD';
    case ItemSubType.ITEM_SUBTYPE_DOCUMENT_POWERPOINT:
      return 'ITEM_SUBTYPE_DOCUMENT_POWERPOINT';
    default:
      return 'UNKNOWN';
  }
}
