/* eslint-disable */
import { ItemType, itemTypeFromJSON, itemTypeToJSON } from './item';
import { Timestamp } from './google/protobuf/timestamp';
import { EffectivePermission } from './permission';

export const protobufPackage = 'de.netfiles.pb';

/** Wiki Object: wiki is a collection AND has content */
export interface Wiki {
  id: string;
  dataroomId: string;
  parentId: string;
  name: string;
  author: string;
  title: string;
  path: string;
  version: string;
  creationDate: Date | undefined;
  modificationDate: Date | undefined;
  downloadProhibited: boolean;
  writeAccess: boolean;
  content: string;
  numChildren: number;
  description: string;
  itemType: ItemType;
  effectivePermission: EffectivePermission | undefined;
}

function createBaseWiki(): Wiki {
  return {
    id: '',
    dataroomId: '',
    parentId: '',
    name: '',
    author: '',
    title: '',
    path: '',
    version: '',
    creationDate: undefined,
    modificationDate: undefined,
    downloadProhibited: false,
    writeAccess: false,
    content: '',
    numChildren: 0,
    description: '',
    itemType: 0,
    effectivePermission: undefined,
  };
}

export const Wiki = {
  fromJSON(object: any): Wiki {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      parentId: isSet(object.parentId) ? String(object.parentId) : '',
      name: isSet(object.name) ? String(object.name) : '',
      author: isSet(object.author) ? String(object.author) : '',
      title: isSet(object.title) ? String(object.title) : '',
      path: isSet(object.path) ? String(object.path) : '',
      version: isSet(object.version) ? String(object.version) : '',
      creationDate: isSet(object.creationDate)
        ? fromJsonTimestamp(object.creationDate)
        : undefined,
      modificationDate: isSet(object.modificationDate)
        ? fromJsonTimestamp(object.modificationDate)
        : undefined,
      downloadProhibited: isSet(object.downloadProhibited)
        ? Boolean(object.downloadProhibited)
        : false,
      writeAccess: isSet(object.writeAccess)
        ? Boolean(object.writeAccess)
        : false,
      content: isSet(object.content) ? String(object.content) : '',
      numChildren: isSet(object.numChildren) ? Number(object.numChildren) : 0,
      description: isSet(object.description) ? String(object.description) : '',
      itemType: isSet(object.itemType) ? itemTypeFromJSON(object.itemType) : 0,
      effectivePermission: isSet(object.effectivePermission)
        ? EffectivePermission.fromJSON(object.effectivePermission)
        : undefined,
    };
  },

  toJSON(message: Wiki): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.parentId !== undefined && (obj.parentId = message.parentId);
    message.name !== undefined && (obj.name = message.name);
    message.author !== undefined && (obj.author = message.author);
    message.title !== undefined && (obj.title = message.title);
    message.path !== undefined && (obj.path = message.path);
    message.version !== undefined && (obj.version = message.version);
    message.creationDate !== undefined &&
      (obj.creationDate = message.creationDate.toISOString());
    message.modificationDate !== undefined &&
      (obj.modificationDate = message.modificationDate.toISOString());
    message.downloadProhibited !== undefined &&
      (obj.downloadProhibited = message.downloadProhibited);
    message.writeAccess !== undefined &&
      (obj.writeAccess = message.writeAccess);
    message.content !== undefined && (obj.content = message.content);
    message.numChildren !== undefined &&
      (obj.numChildren = Math.round(message.numChildren));
    message.description !== undefined &&
      (obj.description = message.description);
    message.itemType !== undefined &&
      (obj.itemType = itemTypeToJSON(message.itemType));
    message.effectivePermission !== undefined &&
      (obj.effectivePermission = message.effectivePermission
        ? EffectivePermission.toJSON(message.effectivePermission)
        : undefined);
    return obj;
  },
};

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
