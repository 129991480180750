/* eslint-disable */
import { Timestamp } from './google/protobuf/timestamp';

export const protobufPackage = 'de.netfiles.pb';

export enum DownloadListFormat {
  DOWNLOAD_LIST_FORMAT_UNSPECIFIED = 0,
  DOWNLOAD_LIST_FORMAT_EXCEL = 1,
  DOWNLOAD_LIST_FORMAT_CSV = 2,
  DOWNLOAD_LIST_FORMAT_PDF = 3,
  UNRECOGNIZED = -1,
}

export function downloadListFormatFromJSON(object: any): DownloadListFormat {
  switch (object) {
    case 0:
    case 'DOWNLOAD_LIST_FORMAT_UNSPECIFIED':
      return DownloadListFormat.DOWNLOAD_LIST_FORMAT_UNSPECIFIED;
    case 1:
    case 'DOWNLOAD_LIST_FORMAT_EXCEL':
      return DownloadListFormat.DOWNLOAD_LIST_FORMAT_EXCEL;
    case 2:
    case 'DOWNLOAD_LIST_FORMAT_CSV':
      return DownloadListFormat.DOWNLOAD_LIST_FORMAT_CSV;
    case 3:
    case 'DOWNLOAD_LIST_FORMAT_PDF':
      return DownloadListFormat.DOWNLOAD_LIST_FORMAT_PDF;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DownloadListFormat.UNRECOGNIZED;
  }
}

export function downloadListFormatToJSON(object: DownloadListFormat): string {
  switch (object) {
    case DownloadListFormat.DOWNLOAD_LIST_FORMAT_UNSPECIFIED:
      return 'DOWNLOAD_LIST_FORMAT_UNSPECIFIED';
    case DownloadListFormat.DOWNLOAD_LIST_FORMAT_EXCEL:
      return 'DOWNLOAD_LIST_FORMAT_EXCEL';
    case DownloadListFormat.DOWNLOAD_LIST_FORMAT_CSV:
      return 'DOWNLOAD_LIST_FORMAT_CSV';
    case DownloadListFormat.DOWNLOAD_LIST_FORMAT_PDF:
      return 'DOWNLOAD_LIST_FORMAT_PDF';
    default:
      return 'UNKNOWN';
  }
}

export enum HideExpiredDocuments {
  HIDE_EXPIRED_DOCUMENTS_UNSPECIFIED = 0,
  HIDE_EXPIRED_DOCUMENTS_NEVER = 1,
  HIDE_EXPIRED_DOCUMENTS_7_DAYS = 2,
  HIDE_EXPIRED_DOCUMENTS_14_DAYS = 3,
  HIDE_EXPIRED_DOCUMENTS_30_DAYS = 4,
  HIDE_EXPIRED_DOCUMENTS_60_DAYS = 5,
  HIDE_EXPIRED_DOCUMENTS_180_DAYS = 6,
  HIDE_EXPIRED_DOCUMENTS_360_DAYS = 7,
  UNRECOGNIZED = -1,
}

export function hideExpiredDocumentsFromJSON(
  object: any,
): HideExpiredDocuments {
  switch (object) {
    case 0:
    case 'HIDE_EXPIRED_DOCUMENTS_UNSPECIFIED':
      return HideExpiredDocuments.HIDE_EXPIRED_DOCUMENTS_UNSPECIFIED;
    case 1:
    case 'HIDE_EXPIRED_DOCUMENTS_NEVER':
      return HideExpiredDocuments.HIDE_EXPIRED_DOCUMENTS_NEVER;
    case 2:
    case 'HIDE_EXPIRED_DOCUMENTS_7_DAYS':
      return HideExpiredDocuments.HIDE_EXPIRED_DOCUMENTS_7_DAYS;
    case 3:
    case 'HIDE_EXPIRED_DOCUMENTS_14_DAYS':
      return HideExpiredDocuments.HIDE_EXPIRED_DOCUMENTS_14_DAYS;
    case 4:
    case 'HIDE_EXPIRED_DOCUMENTS_30_DAYS':
      return HideExpiredDocuments.HIDE_EXPIRED_DOCUMENTS_30_DAYS;
    case 5:
    case 'HIDE_EXPIRED_DOCUMENTS_60_DAYS':
      return HideExpiredDocuments.HIDE_EXPIRED_DOCUMENTS_60_DAYS;
    case 6:
    case 'HIDE_EXPIRED_DOCUMENTS_180_DAYS':
      return HideExpiredDocuments.HIDE_EXPIRED_DOCUMENTS_180_DAYS;
    case 7:
    case 'HIDE_EXPIRED_DOCUMENTS_360_DAYS':
      return HideExpiredDocuments.HIDE_EXPIRED_DOCUMENTS_360_DAYS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return HideExpiredDocuments.UNRECOGNIZED;
  }
}

export function hideExpiredDocumentsToJSON(
  object: HideExpiredDocuments,
): string {
  switch (object) {
    case HideExpiredDocuments.HIDE_EXPIRED_DOCUMENTS_UNSPECIFIED:
      return 'HIDE_EXPIRED_DOCUMENTS_UNSPECIFIED';
    case HideExpiredDocuments.HIDE_EXPIRED_DOCUMENTS_NEVER:
      return 'HIDE_EXPIRED_DOCUMENTS_NEVER';
    case HideExpiredDocuments.HIDE_EXPIRED_DOCUMENTS_7_DAYS:
      return 'HIDE_EXPIRED_DOCUMENTS_7_DAYS';
    case HideExpiredDocuments.HIDE_EXPIRED_DOCUMENTS_14_DAYS:
      return 'HIDE_EXPIRED_DOCUMENTS_14_DAYS';
    case HideExpiredDocuments.HIDE_EXPIRED_DOCUMENTS_30_DAYS:
      return 'HIDE_EXPIRED_DOCUMENTS_30_DAYS';
    case HideExpiredDocuments.HIDE_EXPIRED_DOCUMENTS_60_DAYS:
      return 'HIDE_EXPIRED_DOCUMENTS_60_DAYS';
    case HideExpiredDocuments.HIDE_EXPIRED_DOCUMENTS_180_DAYS:
      return 'HIDE_EXPIRED_DOCUMENTS_180_DAYS';
    case HideExpiredDocuments.HIDE_EXPIRED_DOCUMENTS_360_DAYS:
      return 'HIDE_EXPIRED_DOCUMENTS_360_DAYS';
    default:
      return 'UNKNOWN';
  }
}

export enum EmptyTrashBin {
  EMTPY_TRASH_UNSPECIFIED = 0,
  EMPTY_TRASH_NEVER = 1,
  EMPTY_TRASH_7_DAYS = 2,
  EMPTY_TRASH_14_DAYS = 3,
  EMPTY_TRASH_30_DAYS = 4,
  EMPTY_TRASH_60_DAYS = 5,
  EMPTY_TRASH_180_DAYS = 6,
  EMPTY_TRASH_360_DAYS = 7,
  UNRECOGNIZED = -1,
}

export function emptyTrashBinFromJSON(object: any): EmptyTrashBin {
  switch (object) {
    case 0:
    case 'EMTPY_TRASH_UNSPECIFIED':
      return EmptyTrashBin.EMTPY_TRASH_UNSPECIFIED;
    case 1:
    case 'EMPTY_TRASH_NEVER':
      return EmptyTrashBin.EMPTY_TRASH_NEVER;
    case 2:
    case 'EMPTY_TRASH_7_DAYS':
      return EmptyTrashBin.EMPTY_TRASH_7_DAYS;
    case 3:
    case 'EMPTY_TRASH_14_DAYS':
      return EmptyTrashBin.EMPTY_TRASH_14_DAYS;
    case 4:
    case 'EMPTY_TRASH_30_DAYS':
      return EmptyTrashBin.EMPTY_TRASH_30_DAYS;
    case 5:
    case 'EMPTY_TRASH_60_DAYS':
      return EmptyTrashBin.EMPTY_TRASH_60_DAYS;
    case 6:
    case 'EMPTY_TRASH_180_DAYS':
      return EmptyTrashBin.EMPTY_TRASH_180_DAYS;
    case 7:
    case 'EMPTY_TRASH_360_DAYS':
      return EmptyTrashBin.EMPTY_TRASH_360_DAYS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return EmptyTrashBin.UNRECOGNIZED;
  }
}

export function emptyTrashBinToJSON(object: EmptyTrashBin): string {
  switch (object) {
    case EmptyTrashBin.EMTPY_TRASH_UNSPECIFIED:
      return 'EMTPY_TRASH_UNSPECIFIED';
    case EmptyTrashBin.EMPTY_TRASH_NEVER:
      return 'EMPTY_TRASH_NEVER';
    case EmptyTrashBin.EMPTY_TRASH_7_DAYS:
      return 'EMPTY_TRASH_7_DAYS';
    case EmptyTrashBin.EMPTY_TRASH_14_DAYS:
      return 'EMPTY_TRASH_14_DAYS';
    case EmptyTrashBin.EMPTY_TRASH_30_DAYS:
      return 'EMPTY_TRASH_30_DAYS';
    case EmptyTrashBin.EMPTY_TRASH_60_DAYS:
      return 'EMPTY_TRASH_60_DAYS';
    case EmptyTrashBin.EMPTY_TRASH_180_DAYS:
      return 'EMPTY_TRASH_180_DAYS';
    case EmptyTrashBin.EMPTY_TRASH_360_DAYS:
      return 'EMPTY_TRASH_360_DAYS';
    default:
      return 'UNKNOWN';
  }
}

export enum AutoLogOff {
  AUTO_LOGOFF_UNSPECIFIED = 0,
  AUTO_LOGOFF_10MIN = 1,
  AUTO_LOGOFF_30MIN = 2,
  AUTO_LOGOFF_1HR = 3,
  AUTO_LOGOFF_2HRS = 4,
  AUTO_LOGOFF_4HRS = 5,
  AUTO_LOGOFF_8HRS = 6,
  UNRECOGNIZED = -1,
}

export function autoLogOffFromJSON(object: any): AutoLogOff {
  switch (object) {
    case 0:
    case 'AUTO_LOGOFF_UNSPECIFIED':
      return AutoLogOff.AUTO_LOGOFF_UNSPECIFIED;
    case 1:
    case 'AUTO_LOGOFF_10MIN':
      return AutoLogOff.AUTO_LOGOFF_10MIN;
    case 2:
    case 'AUTO_LOGOFF_30MIN':
      return AutoLogOff.AUTO_LOGOFF_30MIN;
    case 3:
    case 'AUTO_LOGOFF_1HR':
      return AutoLogOff.AUTO_LOGOFF_1HR;
    case 4:
    case 'AUTO_LOGOFF_2HRS':
      return AutoLogOff.AUTO_LOGOFF_2HRS;
    case 5:
    case 'AUTO_LOGOFF_4HRS':
      return AutoLogOff.AUTO_LOGOFF_4HRS;
    case 6:
    case 'AUTO_LOGOFF_8HRS':
      return AutoLogOff.AUTO_LOGOFF_8HRS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AutoLogOff.UNRECOGNIZED;
  }
}

export function autoLogOffToJSON(object: AutoLogOff): string {
  switch (object) {
    case AutoLogOff.AUTO_LOGOFF_UNSPECIFIED:
      return 'AUTO_LOGOFF_UNSPECIFIED';
    case AutoLogOff.AUTO_LOGOFF_10MIN:
      return 'AUTO_LOGOFF_10MIN';
    case AutoLogOff.AUTO_LOGOFF_30MIN:
      return 'AUTO_LOGOFF_30MIN';
    case AutoLogOff.AUTO_LOGOFF_1HR:
      return 'AUTO_LOGOFF_1HR';
    case AutoLogOff.AUTO_LOGOFF_2HRS:
      return 'AUTO_LOGOFF_2HRS';
    case AutoLogOff.AUTO_LOGOFF_4HRS:
      return 'AUTO_LOGOFF_4HRS';
    case AutoLogOff.AUTO_LOGOFF_8HRS:
      return 'AUTO_LOGOFF_8HRS';
    default:
      return 'UNKNOWN';
  }
}

export enum LoginVerification {
  LOGIN_VERIFICATION_UNSPECIFIED = 0,
  LOGIN_VERIFICATION_DISABLED = 1,
  LOGIN_VERIFICATION_FIRST_LOGIN_EMAIL = 2,
  LOGIN_VERIFICATION_FIRST_LOGIN_2FA = 3,
  LOGIN_VERIFICATION_EVERY_LOGIN_2FA = 4,
  UNRECOGNIZED = -1,
}

export function loginVerificationFromJSON(object: any): LoginVerification {
  switch (object) {
    case 0:
    case 'LOGIN_VERIFICATION_UNSPECIFIED':
      return LoginVerification.LOGIN_VERIFICATION_UNSPECIFIED;
    case 1:
    case 'LOGIN_VERIFICATION_DISABLED':
      return LoginVerification.LOGIN_VERIFICATION_DISABLED;
    case 2:
    case 'LOGIN_VERIFICATION_FIRST_LOGIN_EMAIL':
      return LoginVerification.LOGIN_VERIFICATION_FIRST_LOGIN_EMAIL;
    case 3:
    case 'LOGIN_VERIFICATION_FIRST_LOGIN_2FA':
      return LoginVerification.LOGIN_VERIFICATION_FIRST_LOGIN_2FA;
    case 4:
    case 'LOGIN_VERIFICATION_EVERY_LOGIN_2FA':
      return LoginVerification.LOGIN_VERIFICATION_EVERY_LOGIN_2FA;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return LoginVerification.UNRECOGNIZED;
  }
}

export function loginVerificationToJSON(object: LoginVerification): string {
  switch (object) {
    case LoginVerification.LOGIN_VERIFICATION_UNSPECIFIED:
      return 'LOGIN_VERIFICATION_UNSPECIFIED';
    case LoginVerification.LOGIN_VERIFICATION_DISABLED:
      return 'LOGIN_VERIFICATION_DISABLED';
    case LoginVerification.LOGIN_VERIFICATION_FIRST_LOGIN_EMAIL:
      return 'LOGIN_VERIFICATION_FIRST_LOGIN_EMAIL';
    case LoginVerification.LOGIN_VERIFICATION_FIRST_LOGIN_2FA:
      return 'LOGIN_VERIFICATION_FIRST_LOGIN_2FA';
    case LoginVerification.LOGIN_VERIFICATION_EVERY_LOGIN_2FA:
      return 'LOGIN_VERIFICATION_EVERY_LOGIN_2FA';
    default:
      return 'UNKNOWN';
  }
}

export enum PasswordMinLength {
  PASSWORD_MIN_LENGTH_UNSPECIFIED = 0,
  PASSWORD_MIN_LENGTH_8 = 8,
  PASSWORD_MIN_LENGTH_10 = 10,
  PASSWORD_MIN_LENGTH_12 = 12,
  PASSWORD_MIN_LENGTH_14 = 14,
  PASSWORD_MIN_LENGTH_16 = 16,
  UNRECOGNIZED = -1,
}

export function passwordMinLengthFromJSON(object: any): PasswordMinLength {
  switch (object) {
    case 0:
    case 'PASSWORD_MIN_LENGTH_UNSPECIFIED':
      return PasswordMinLength.PASSWORD_MIN_LENGTH_UNSPECIFIED;
    case 8:
    case 'PASSWORD_MIN_LENGTH_8':
      return PasswordMinLength.PASSWORD_MIN_LENGTH_8;
    case 10:
    case 'PASSWORD_MIN_LENGTH_10':
      return PasswordMinLength.PASSWORD_MIN_LENGTH_10;
    case 12:
    case 'PASSWORD_MIN_LENGTH_12':
      return PasswordMinLength.PASSWORD_MIN_LENGTH_12;
    case 14:
    case 'PASSWORD_MIN_LENGTH_14':
      return PasswordMinLength.PASSWORD_MIN_LENGTH_14;
    case 16:
    case 'PASSWORD_MIN_LENGTH_16':
      return PasswordMinLength.PASSWORD_MIN_LENGTH_16;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PasswordMinLength.UNRECOGNIZED;
  }
}

export function passwordMinLengthToJSON(object: PasswordMinLength): string {
  switch (object) {
    case PasswordMinLength.PASSWORD_MIN_LENGTH_UNSPECIFIED:
      return 'PASSWORD_MIN_LENGTH_UNSPECIFIED';
    case PasswordMinLength.PASSWORD_MIN_LENGTH_8:
      return 'PASSWORD_MIN_LENGTH_8';
    case PasswordMinLength.PASSWORD_MIN_LENGTH_10:
      return 'PASSWORD_MIN_LENGTH_10';
    case PasswordMinLength.PASSWORD_MIN_LENGTH_12:
      return 'PASSWORD_MIN_LENGTH_12';
    case PasswordMinLength.PASSWORD_MIN_LENGTH_14:
      return 'PASSWORD_MIN_LENGTH_14';
    case PasswordMinLength.PASSWORD_MIN_LENGTH_16:
      return 'PASSWORD_MIN_LENGTH_16';
    default:
      return 'UNKNOWN';
  }
}

export enum PasswordChangeInterval {
  PASSWORD_CHANGE_UNSPECIFIED = 0,
  PASSWORD_CHANGE_NEVER = 1,
  PASSWORD_CHANGE_30 = 2,
  PASSWORD_CHANGE_60 = 3,
  PASSWORD_CHANGE_90 = 4,
  PASSWORD_CHANGE_180 = 5,
  PASSWORD_CHANGE_360 = 6,
  UNRECOGNIZED = -1,
}

export function passwordChangeIntervalFromJSON(
  object: any,
): PasswordChangeInterval {
  switch (object) {
    case 0:
    case 'PASSWORD_CHANGE_UNSPECIFIED':
      return PasswordChangeInterval.PASSWORD_CHANGE_UNSPECIFIED;
    case 1:
    case 'PASSWORD_CHANGE_NEVER':
      return PasswordChangeInterval.PASSWORD_CHANGE_NEVER;
    case 2:
    case 'PASSWORD_CHANGE_30':
      return PasswordChangeInterval.PASSWORD_CHANGE_30;
    case 3:
    case 'PASSWORD_CHANGE_60':
      return PasswordChangeInterval.PASSWORD_CHANGE_60;
    case 4:
    case 'PASSWORD_CHANGE_90':
      return PasswordChangeInterval.PASSWORD_CHANGE_90;
    case 5:
    case 'PASSWORD_CHANGE_180':
      return PasswordChangeInterval.PASSWORD_CHANGE_180;
    case 6:
    case 'PASSWORD_CHANGE_360':
      return PasswordChangeInterval.PASSWORD_CHANGE_360;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PasswordChangeInterval.UNRECOGNIZED;
  }
}

export function passwordChangeIntervalToJSON(
  object: PasswordChangeInterval,
): string {
  switch (object) {
    case PasswordChangeInterval.PASSWORD_CHANGE_UNSPECIFIED:
      return 'PASSWORD_CHANGE_UNSPECIFIED';
    case PasswordChangeInterval.PASSWORD_CHANGE_NEVER:
      return 'PASSWORD_CHANGE_NEVER';
    case PasswordChangeInterval.PASSWORD_CHANGE_30:
      return 'PASSWORD_CHANGE_30';
    case PasswordChangeInterval.PASSWORD_CHANGE_60:
      return 'PASSWORD_CHANGE_60';
    case PasswordChangeInterval.PASSWORD_CHANGE_90:
      return 'PASSWORD_CHANGE_90';
    case PasswordChangeInterval.PASSWORD_CHANGE_180:
      return 'PASSWORD_CHANGE_180';
    case PasswordChangeInterval.PASSWORD_CHANGE_360:
      return 'PASSWORD_CHANGE_360';
    default:
      return 'UNKNOWN';
  }
}

export enum PasswordResetOptions {
  PASSWORD_RESET_UNSPECIFIED = 0,
  PASSWORD_RESET_NEVER = 1,
  PASSWORD_RESET_ALWAYS = 2,
  PASSWORD_RESET_OWN_QUESTION = 3,
  PASSWORD_RESET_DEFAULT_QUESTION = 4,
  PASSWORD_RESET_2FA = 5,
  UNRECOGNIZED = -1,
}

export function passwordResetOptionsFromJSON(
  object: any,
): PasswordResetOptions {
  switch (object) {
    case 0:
    case 'PASSWORD_RESET_UNSPECIFIED':
      return PasswordResetOptions.PASSWORD_RESET_UNSPECIFIED;
    case 1:
    case 'PASSWORD_RESET_NEVER':
      return PasswordResetOptions.PASSWORD_RESET_NEVER;
    case 2:
    case 'PASSWORD_RESET_ALWAYS':
      return PasswordResetOptions.PASSWORD_RESET_ALWAYS;
    case 3:
    case 'PASSWORD_RESET_OWN_QUESTION':
      return PasswordResetOptions.PASSWORD_RESET_OWN_QUESTION;
    case 4:
    case 'PASSWORD_RESET_DEFAULT_QUESTION':
      return PasswordResetOptions.PASSWORD_RESET_DEFAULT_QUESTION;
    case 5:
    case 'PASSWORD_RESET_2FA':
      return PasswordResetOptions.PASSWORD_RESET_2FA;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PasswordResetOptions.UNRECOGNIZED;
  }
}

export function passwordResetOptionsToJSON(
  object: PasswordResetOptions,
): string {
  switch (object) {
    case PasswordResetOptions.PASSWORD_RESET_UNSPECIFIED:
      return 'PASSWORD_RESET_UNSPECIFIED';
    case PasswordResetOptions.PASSWORD_RESET_NEVER:
      return 'PASSWORD_RESET_NEVER';
    case PasswordResetOptions.PASSWORD_RESET_ALWAYS:
      return 'PASSWORD_RESET_ALWAYS';
    case PasswordResetOptions.PASSWORD_RESET_OWN_QUESTION:
      return 'PASSWORD_RESET_OWN_QUESTION';
    case PasswordResetOptions.PASSWORD_RESET_DEFAULT_QUESTION:
      return 'PASSWORD_RESET_DEFAULT_QUESTION';
    case PasswordResetOptions.PASSWORD_RESET_2FA:
      return 'PASSWORD_RESET_2FA';
    default:
      return 'UNKNOWN';
  }
}

export enum EmailSenderCompanyType {
  EMAIL_SENDER_COMPANY_UNSPECIFIED = 0,
  EMAIL_SENDER_COMPANY_TYPE1 = 1,
  EMAIL_SENDER_COMPANY_TYPE2 = 2,
  EMAIL_SENDER_COMPANY_CUSTOM = 3,
  UNRECOGNIZED = -1,
}

export function emailSenderCompanyTypeFromJSON(
  object: any,
): EmailSenderCompanyType {
  switch (object) {
    case 0:
    case 'EMAIL_SENDER_COMPANY_UNSPECIFIED':
      return EmailSenderCompanyType.EMAIL_SENDER_COMPANY_UNSPECIFIED;
    case 1:
    case 'EMAIL_SENDER_COMPANY_TYPE1':
      return EmailSenderCompanyType.EMAIL_SENDER_COMPANY_TYPE1;
    case 2:
    case 'EMAIL_SENDER_COMPANY_TYPE2':
      return EmailSenderCompanyType.EMAIL_SENDER_COMPANY_TYPE2;
    case 3:
    case 'EMAIL_SENDER_COMPANY_CUSTOM':
      return EmailSenderCompanyType.EMAIL_SENDER_COMPANY_CUSTOM;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return EmailSenderCompanyType.UNRECOGNIZED;
  }
}

export function emailSenderCompanyTypeToJSON(
  object: EmailSenderCompanyType,
): string {
  switch (object) {
    case EmailSenderCompanyType.EMAIL_SENDER_COMPANY_UNSPECIFIED:
      return 'EMAIL_SENDER_COMPANY_UNSPECIFIED';
    case EmailSenderCompanyType.EMAIL_SENDER_COMPANY_TYPE1:
      return 'EMAIL_SENDER_COMPANY_TYPE1';
    case EmailSenderCompanyType.EMAIL_SENDER_COMPANY_TYPE2:
      return 'EMAIL_SENDER_COMPANY_TYPE2';
    case EmailSenderCompanyType.EMAIL_SENDER_COMPANY_CUSTOM:
      return 'EMAIL_SENDER_COMPANY_CUSTOM';
    default:
      return 'UNKNOWN';
  }
}

export enum SortIndexOptions {
  SORT_INDEX_UNSPECIFIED = 0,
  SORT_INDEX_ALPHABET = 1,
  SORT_INDEX_DATE = 2,
  UNRECOGNIZED = -1,
}

export function sortIndexOptionsFromJSON(object: any): SortIndexOptions {
  switch (object) {
    case 0:
    case 'SORT_INDEX_UNSPECIFIED':
      return SortIndexOptions.SORT_INDEX_UNSPECIFIED;
    case 1:
    case 'SORT_INDEX_ALPHABET':
      return SortIndexOptions.SORT_INDEX_ALPHABET;
    case 2:
    case 'SORT_INDEX_DATE':
      return SortIndexOptions.SORT_INDEX_DATE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SortIndexOptions.UNRECOGNIZED;
  }
}

export function sortIndexOptionsToJSON(object: SortIndexOptions): string {
  switch (object) {
    case SortIndexOptions.SORT_INDEX_UNSPECIFIED:
      return 'SORT_INDEX_UNSPECIFIED';
    case SortIndexOptions.SORT_INDEX_ALPHABET:
      return 'SORT_INDEX_ALPHABET';
    case SortIndexOptions.SORT_INDEX_DATE:
      return 'SORT_INDEX_DATE';
    default:
      return 'UNKNOWN';
  }
}

export enum DefaultVisibilityOptions {
  DEFAULT_VISIBILITY_UNSPECIFIED = 0,
  DEFAULT_VISIBILITY_NONE = 1,
  DEFAULT_VISIBILITY_SAME_GROUP = 2,
  DEFAULT_VISIBILITY_ALL = 3,
  UNRECOGNIZED = -1,
}

export function defaultVisibilityOptionsFromJSON(
  object: any,
): DefaultVisibilityOptions {
  switch (object) {
    case 0:
    case 'DEFAULT_VISIBILITY_UNSPECIFIED':
      return DefaultVisibilityOptions.DEFAULT_VISIBILITY_UNSPECIFIED;
    case 1:
    case 'DEFAULT_VISIBILITY_NONE':
      return DefaultVisibilityOptions.DEFAULT_VISIBILITY_NONE;
    case 2:
    case 'DEFAULT_VISIBILITY_SAME_GROUP':
      return DefaultVisibilityOptions.DEFAULT_VISIBILITY_SAME_GROUP;
    case 3:
    case 'DEFAULT_VISIBILITY_ALL':
      return DefaultVisibilityOptions.DEFAULT_VISIBILITY_ALL;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DefaultVisibilityOptions.UNRECOGNIZED;
  }
}

export function defaultVisibilityOptionsToJSON(
  object: DefaultVisibilityOptions,
): string {
  switch (object) {
    case DefaultVisibilityOptions.DEFAULT_VISIBILITY_UNSPECIFIED:
      return 'DEFAULT_VISIBILITY_UNSPECIFIED';
    case DefaultVisibilityOptions.DEFAULT_VISIBILITY_NONE:
      return 'DEFAULT_VISIBILITY_NONE';
    case DefaultVisibilityOptions.DEFAULT_VISIBILITY_SAME_GROUP:
      return 'DEFAULT_VISIBILITY_SAME_GROUP';
    case DefaultVisibilityOptions.DEFAULT_VISIBILITY_ALL:
      return 'DEFAULT_VISIBILITY_ALL';
    default:
      return 'UNKNOWN';
  }
}

export enum SendInformationEmailsOptions {
  SEND_INFORMATION_EMAILS_UNSPECIFIED = 0,
  SEND_INFORMATION_EMAILS_ALL_ADMINS = 1,
  SEND_INFORMATION_EMAILS_RESPONSIBLE_ONLY = 2,
  UNRECOGNIZED = -1,
}

export function sendInformationEmailsOptionsFromJSON(
  object: any,
): SendInformationEmailsOptions {
  switch (object) {
    case 0:
    case 'SEND_INFORMATION_EMAILS_UNSPECIFIED':
      return SendInformationEmailsOptions.SEND_INFORMATION_EMAILS_UNSPECIFIED;
    case 1:
    case 'SEND_INFORMATION_EMAILS_ALL_ADMINS':
      return SendInformationEmailsOptions.SEND_INFORMATION_EMAILS_ALL_ADMINS;
    case 2:
    case 'SEND_INFORMATION_EMAILS_RESPONSIBLE_ONLY':
      return SendInformationEmailsOptions.SEND_INFORMATION_EMAILS_RESPONSIBLE_ONLY;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SendInformationEmailsOptions.UNRECOGNIZED;
  }
}

export function sendInformationEmailsOptionsToJSON(
  object: SendInformationEmailsOptions,
): string {
  switch (object) {
    case SendInformationEmailsOptions.SEND_INFORMATION_EMAILS_UNSPECIFIED:
      return 'SEND_INFORMATION_EMAILS_UNSPECIFIED';
    case SendInformationEmailsOptions.SEND_INFORMATION_EMAILS_ALL_ADMINS:
      return 'SEND_INFORMATION_EMAILS_ALL_ADMINS';
    case SendInformationEmailsOptions.SEND_INFORMATION_EMAILS_RESPONSIBLE_ONLY:
      return 'SEND_INFORMATION_EMAILS_RESPONSIBLE_ONLY';
    default:
      return 'UNKNOWN';
  }
}

export enum CommentVisibilityOptions {
  COMMENT_VISIBILITY_UNSPECIFIED = 0,
  COMMENT_VISIBILITY_RESTRICTED = 1,
  COMMENT_VISIBILITY_ALL = 2,
  UNRECOGNIZED = -1,
}

export function commentVisibilityOptionsFromJSON(
  object: any,
): CommentVisibilityOptions {
  switch (object) {
    case 0:
    case 'COMMENT_VISIBILITY_UNSPECIFIED':
      return CommentVisibilityOptions.COMMENT_VISIBILITY_UNSPECIFIED;
    case 1:
    case 'COMMENT_VISIBILITY_RESTRICTED':
      return CommentVisibilityOptions.COMMENT_VISIBILITY_RESTRICTED;
    case 2:
    case 'COMMENT_VISIBILITY_ALL':
      return CommentVisibilityOptions.COMMENT_VISIBILITY_ALL;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CommentVisibilityOptions.UNRECOGNIZED;
  }
}

export function commentVisibilityOptionsToJSON(
  object: CommentVisibilityOptions,
): string {
  switch (object) {
    case CommentVisibilityOptions.COMMENT_VISIBILITY_UNSPECIFIED:
      return 'COMMENT_VISIBILITY_UNSPECIFIED';
    case CommentVisibilityOptions.COMMENT_VISIBILITY_RESTRICTED:
      return 'COMMENT_VISIBILITY_RESTRICTED';
    case CommentVisibilityOptions.COMMENT_VISIBILITY_ALL:
      return 'COMMENT_VISIBILITY_ALL';
    default:
      return 'UNKNOWN';
  }
}

export enum UIColorScheme {
  UI_COLOR_UNSPECIFIED = 0,
  UI_COLOR_DEFAULT = 1,
  UI_COLOR_BLUE = 2,
  UI_COLOR_GREEN = 3,
  UI_COLOR_ORANGE = 4,
  UI_COLOR_RED = 5,
  UI_COLOR_CUSTOM = 6,
  UNRECOGNIZED = -1,
}

export function uIColorSchemeFromJSON(object: any): UIColorScheme {
  switch (object) {
    case 0:
    case 'UI_COLOR_UNSPECIFIED':
      return UIColorScheme.UI_COLOR_UNSPECIFIED;
    case 1:
    case 'UI_COLOR_DEFAULT':
      return UIColorScheme.UI_COLOR_DEFAULT;
    case 2:
    case 'UI_COLOR_BLUE':
      return UIColorScheme.UI_COLOR_BLUE;
    case 3:
    case 'UI_COLOR_GREEN':
      return UIColorScheme.UI_COLOR_GREEN;
    case 4:
    case 'UI_COLOR_ORANGE':
      return UIColorScheme.UI_COLOR_ORANGE;
    case 5:
    case 'UI_COLOR_RED':
      return UIColorScheme.UI_COLOR_RED;
    case 6:
    case 'UI_COLOR_CUSTOM':
      return UIColorScheme.UI_COLOR_CUSTOM;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return UIColorScheme.UNRECOGNIZED;
  }
}

export function uIColorSchemeToJSON(object: UIColorScheme): string {
  switch (object) {
    case UIColorScheme.UI_COLOR_UNSPECIFIED:
      return 'UI_COLOR_UNSPECIFIED';
    case UIColorScheme.UI_COLOR_DEFAULT:
      return 'UI_COLOR_DEFAULT';
    case UIColorScheme.UI_COLOR_BLUE:
      return 'UI_COLOR_BLUE';
    case UIColorScheme.UI_COLOR_GREEN:
      return 'UI_COLOR_GREEN';
    case UIColorScheme.UI_COLOR_ORANGE:
      return 'UI_COLOR_ORANGE';
    case UIColorScheme.UI_COLOR_RED:
      return 'UI_COLOR_RED';
    case UIColorScheme.UI_COLOR_CUSTOM:
      return 'UI_COLOR_CUSTOM';
    default:
      return 'UNKNOWN';
  }
}

export enum LogoType {
  LOGO_TYPE_UNSPECIFIED = 0,
  LOGO_TYPE_DEFAULT = 1,
  LOGO_TYPE_EMPTY = 2,
  LOGO_TYPE_CUSTOM = 3,
  UNRECOGNIZED = -1,
}

export function logoTypeFromJSON(object: any): LogoType {
  switch (object) {
    case 0:
    case 'LOGO_TYPE_UNSPECIFIED':
      return LogoType.LOGO_TYPE_UNSPECIFIED;
    case 1:
    case 'LOGO_TYPE_DEFAULT':
      return LogoType.LOGO_TYPE_DEFAULT;
    case 2:
    case 'LOGO_TYPE_EMPTY':
      return LogoType.LOGO_TYPE_EMPTY;
    case 3:
    case 'LOGO_TYPE_CUSTOM':
      return LogoType.LOGO_TYPE_CUSTOM;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return LogoType.UNRECOGNIZED;
  }
}

export function logoTypeToJSON(object: LogoType): string {
  switch (object) {
    case LogoType.LOGO_TYPE_UNSPECIFIED:
      return 'LOGO_TYPE_UNSPECIFIED';
    case LogoType.LOGO_TYPE_DEFAULT:
      return 'LOGO_TYPE_DEFAULT';
    case LogoType.LOGO_TYPE_EMPTY:
      return 'LOGO_TYPE_EMPTY';
    case LogoType.LOGO_TYPE_CUSTOM:
      return 'LOGO_TYPE_CUSTOM';
    default:
      return 'UNKNOWN';
  }
}

export enum UserNotification {
  USERNOTIFICATION_UNSPECIFIED = 0,
  USERNOTIFICATION_NEVER = 1,
  USERNOTIFICATION_IMMEDIATELY = 2,
  USERNOTIFICATION_HOURLY = 3,
  USERNOTIFICATION_DAILY = 4,
  USERNOTIFICATION_WEEKLY = 5,
  UNRECOGNIZED = -1,
}

export function userNotificationFromJSON(object: any): UserNotification {
  switch (object) {
    case 0:
    case 'USERNOTIFICATION_UNSPECIFIED':
      return UserNotification.USERNOTIFICATION_UNSPECIFIED;
    case 1:
    case 'USERNOTIFICATION_NEVER':
      return UserNotification.USERNOTIFICATION_NEVER;
    case 2:
    case 'USERNOTIFICATION_IMMEDIATELY':
      return UserNotification.USERNOTIFICATION_IMMEDIATELY;
    case 3:
    case 'USERNOTIFICATION_HOURLY':
      return UserNotification.USERNOTIFICATION_HOURLY;
    case 4:
    case 'USERNOTIFICATION_DAILY':
      return UserNotification.USERNOTIFICATION_DAILY;
    case 5:
    case 'USERNOTIFICATION_WEEKLY':
      return UserNotification.USERNOTIFICATION_WEEKLY;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return UserNotification.UNRECOGNIZED;
  }
}

export function userNotificationToJSON(object: UserNotification): string {
  switch (object) {
    case UserNotification.USERNOTIFICATION_UNSPECIFIED:
      return 'USERNOTIFICATION_UNSPECIFIED';
    case UserNotification.USERNOTIFICATION_NEVER:
      return 'USERNOTIFICATION_NEVER';
    case UserNotification.USERNOTIFICATION_IMMEDIATELY:
      return 'USERNOTIFICATION_IMMEDIATELY';
    case UserNotification.USERNOTIFICATION_HOURLY:
      return 'USERNOTIFICATION_HOURLY';
    case UserNotification.USERNOTIFICATION_DAILY:
      return 'USERNOTIFICATION_DAILY';
    case UserNotification.USERNOTIFICATION_WEEKLY:
      return 'USERNOTIFICATION_WEEKLY';
    default:
      return 'UNKNOWN';
  }
}

export enum Salutation {
  SALUTATION_UNSPECIFIED = 0,
  SALUTATION_NONE = 1,
  SALUTATION_MR = 2,
  SALUTATION_MS = 3,
  UNRECOGNIZED = -1,
}

export function salutationFromJSON(object: any): Salutation {
  switch (object) {
    case 0:
    case 'SALUTATION_UNSPECIFIED':
      return Salutation.SALUTATION_UNSPECIFIED;
    case 1:
    case 'SALUTATION_NONE':
      return Salutation.SALUTATION_NONE;
    case 2:
    case 'SALUTATION_MR':
      return Salutation.SALUTATION_MR;
    case 3:
    case 'SALUTATION_MS':
      return Salutation.SALUTATION_MS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Salutation.UNRECOGNIZED;
  }
}

export function salutationToJSON(object: Salutation): string {
  switch (object) {
    case Salutation.SALUTATION_UNSPECIFIED:
      return 'SALUTATION_UNSPECIFIED';
    case Salutation.SALUTATION_NONE:
      return 'SALUTATION_NONE';
    case Salutation.SALUTATION_MR:
      return 'SALUTATION_MR';
    case Salutation.SALUTATION_MS:
      return 'SALUTATION_MS';
    default:
      return 'UNKNOWN';
  }
}

export enum TwoFactorType {
  TWOFA_UNSEPCIFIED = 0,
  TWOFA_SMS = 1,
  TWOFA_OTP = 2,
  UNRECOGNIZED = -1,
}

export function twoFactorTypeFromJSON(object: any): TwoFactorType {
  switch (object) {
    case 0:
    case 'TWOFA_UNSEPCIFIED':
      return TwoFactorType.TWOFA_UNSEPCIFIED;
    case 1:
    case 'TWOFA_SMS':
      return TwoFactorType.TWOFA_SMS;
    case 2:
    case 'TWOFA_OTP':
      return TwoFactorType.TWOFA_OTP;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return TwoFactorType.UNRECOGNIZED;
  }
}

export function twoFactorTypeToJSON(object: TwoFactorType): string {
  switch (object) {
    case TwoFactorType.TWOFA_UNSEPCIFIED:
      return 'TWOFA_UNSEPCIFIED';
    case TwoFactorType.TWOFA_SMS:
      return 'TWOFA_SMS';
    case TwoFactorType.TWOFA_OTP:
      return 'TWOFA_OTP';
    default:
      return 'UNKNOWN';
  }
}

export enum ThemeMode {
  THEME_UNSPECIFIED = 0,
  THEME_LIGHT = 1,
  THEME_DARK = 2,
  THEME_SYSTEM = 3,
  THEME_DARKLEFTBAR = 4,
  UNRECOGNIZED = -1,
}

export function themeModeFromJSON(object: any): ThemeMode {
  switch (object) {
    case 0:
    case 'THEME_UNSPECIFIED':
      return ThemeMode.THEME_UNSPECIFIED;
    case 1:
    case 'THEME_LIGHT':
      return ThemeMode.THEME_LIGHT;
    case 2:
    case 'THEME_DARK':
      return ThemeMode.THEME_DARK;
    case 3:
    case 'THEME_SYSTEM':
      return ThemeMode.THEME_SYSTEM;
    case 4:
    case 'THEME_DARKLEFTBAR':
      return ThemeMode.THEME_DARKLEFTBAR;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ThemeMode.UNRECOGNIZED;
  }
}

export function themeModeToJSON(object: ThemeMode): string {
  switch (object) {
    case ThemeMode.THEME_UNSPECIFIED:
      return 'THEME_UNSPECIFIED';
    case ThemeMode.THEME_LIGHT:
      return 'THEME_LIGHT';
    case ThemeMode.THEME_DARK:
      return 'THEME_DARK';
    case ThemeMode.THEME_SYSTEM:
      return 'THEME_SYSTEM';
    case ThemeMode.THEME_DARKLEFTBAR:
      return 'THEME_DARKLEFTBAR';
    default:
      return 'UNKNOWN';
  }
}

export enum Language {
  LANGUAGE_UNSPECIFIED = 0,
  LANGUAGE_EN = 1,
  LANGUAGE_DE = 2,
  UNRECOGNIZED = -1,
}

export function languageFromJSON(object: any): Language {
  switch (object) {
    case 0:
    case 'LANGUAGE_UNSPECIFIED':
      return Language.LANGUAGE_UNSPECIFIED;
    case 1:
    case 'LANGUAGE_EN':
      return Language.LANGUAGE_EN;
    case 2:
    case 'LANGUAGE_DE':
      return Language.LANGUAGE_DE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Language.UNRECOGNIZED;
  }
}

export function languageToJSON(object: Language): string {
  switch (object) {
    case Language.LANGUAGE_UNSPECIFIED:
      return 'LANGUAGE_UNSPECIFIED';
    case Language.LANGUAGE_EN:
      return 'LANGUAGE_EN';
    case Language.LANGUAGE_DE:
      return 'LANGUAGE_DE';
    default:
      return 'UNKNOWN';
  }
}

export enum Locale {
  LOCALE_UNSPECIFIED = 0,
  LOCALE_EN_GB = 1,
  LOCALE_EN_US = 2,
  UNRECOGNIZED = -1,
}

export function localeFromJSON(object: any): Locale {
  switch (object) {
    case 0:
    case 'LOCALE_UNSPECIFIED':
      return Locale.LOCALE_UNSPECIFIED;
    case 1:
    case 'LOCALE_EN_GB':
      return Locale.LOCALE_EN_GB;
    case 2:
    case 'LOCALE_EN_US':
      return Locale.LOCALE_EN_US;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Locale.UNRECOGNIZED;
  }
}

export function localeToJSON(object: Locale): string {
  switch (object) {
    case Locale.LOCALE_UNSPECIFIED:
      return 'LOCALE_UNSPECIFIED';
    case Locale.LOCALE_EN_GB:
      return 'LOCALE_EN_GB';
    case Locale.LOCALE_EN_US:
      return 'LOCALE_EN_US';
    default:
      return 'UNKNOWN';
  }
}

export enum EncryptionType {
  Encryption_UNSPECIFIED = 0,
  Encryption_Files = 1,
  Encryption_End2End = 2,
  UNRECOGNIZED = -1,
}

export function encryptionTypeFromJSON(object: any): EncryptionType {
  switch (object) {
    case 0:
    case 'Encryption_UNSPECIFIED':
      return EncryptionType.Encryption_UNSPECIFIED;
    case 1:
    case 'Encryption_Files':
      return EncryptionType.Encryption_Files;
    case 2:
    case 'Encryption_End2End':
      return EncryptionType.Encryption_End2End;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return EncryptionType.UNRECOGNIZED;
  }
}

export function encryptionTypeToJSON(object: EncryptionType): string {
  switch (object) {
    case EncryptionType.Encryption_UNSPECIFIED:
      return 'Encryption_UNSPECIFIED';
    case EncryptionType.Encryption_Files:
      return 'Encryption_Files';
    case EncryptionType.Encryption_End2End:
      return 'Encryption_End2End';
    default:
      return 'UNKNOWN';
  }
}

export enum ManagedFiletransferVisibility {
  MANAGED_FILETRANSFER_VISIBILITY_UNSPECIFIED = 0,
  MANAGED_FILETRANSFER_VISIBILITY_PRIVATE = 1,
  MANAGED_FILETRANSFER_VISIBILITY_GROUPS = 2,
  UNRECOGNIZED = -1,
}

export function managedFiletransferVisibilityFromJSON(
  object: any,
): ManagedFiletransferVisibility {
  switch (object) {
    case 0:
    case 'MANAGED_FILETRANSFER_VISIBILITY_UNSPECIFIED':
      return ManagedFiletransferVisibility.MANAGED_FILETRANSFER_VISIBILITY_UNSPECIFIED;
    case 1:
    case 'MANAGED_FILETRANSFER_VISIBILITY_PRIVATE':
      return ManagedFiletransferVisibility.MANAGED_FILETRANSFER_VISIBILITY_PRIVATE;
    case 2:
    case 'MANAGED_FILETRANSFER_VISIBILITY_GROUPS':
      return ManagedFiletransferVisibility.MANAGED_FILETRANSFER_VISIBILITY_GROUPS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ManagedFiletransferVisibility.UNRECOGNIZED;
  }
}

export function managedFiletransferVisibilityToJSON(
  object: ManagedFiletransferVisibility,
): string {
  switch (object) {
    case ManagedFiletransferVisibility.MANAGED_FILETRANSFER_VISIBILITY_UNSPECIFIED:
      return 'MANAGED_FILETRANSFER_VISIBILITY_UNSPECIFIED';
    case ManagedFiletransferVisibility.MANAGED_FILETRANSFER_VISIBILITY_PRIVATE:
      return 'MANAGED_FILETRANSFER_VISIBILITY_PRIVATE';
    case ManagedFiletransferVisibility.MANAGED_FILETRANSFER_VISIBILITY_GROUPS:
      return 'MANAGED_FILETRANSFER_VISIBILITY_GROUPS';
    default:
      return 'UNKNOWN';
  }
}

export enum InviteValidDays {
  INVITE_VALID_DAYS_UNSPECIFIED = 0,
  INVITE_VALID_DAYS_1 = 1,
  INVITE_VALID_DAYS_7 = 2,
  INVITE_VALID_DAYS_14 = 3,
  INVITE_VALID_DAYS_30 = 4,
  INVITE_VALID_DAYS_90 = 5,
  UNRECOGNIZED = -1,
}

export function inviteValidDaysFromJSON(object: any): InviteValidDays {
  switch (object) {
    case 0:
    case 'INVITE_VALID_DAYS_UNSPECIFIED':
      return InviteValidDays.INVITE_VALID_DAYS_UNSPECIFIED;
    case 1:
    case 'INVITE_VALID_DAYS_1':
      return InviteValidDays.INVITE_VALID_DAYS_1;
    case 2:
    case 'INVITE_VALID_DAYS_7':
      return InviteValidDays.INVITE_VALID_DAYS_7;
    case 3:
    case 'INVITE_VALID_DAYS_14':
      return InviteValidDays.INVITE_VALID_DAYS_14;
    case 4:
    case 'INVITE_VALID_DAYS_30':
      return InviteValidDays.INVITE_VALID_DAYS_30;
    case 5:
    case 'INVITE_VALID_DAYS_90':
      return InviteValidDays.INVITE_VALID_DAYS_90;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return InviteValidDays.UNRECOGNIZED;
  }
}

export function inviteValidDaysToJSON(object: InviteValidDays): string {
  switch (object) {
    case InviteValidDays.INVITE_VALID_DAYS_UNSPECIFIED:
      return 'INVITE_VALID_DAYS_UNSPECIFIED';
    case InviteValidDays.INVITE_VALID_DAYS_1:
      return 'INVITE_VALID_DAYS_1';
    case InviteValidDays.INVITE_VALID_DAYS_7:
      return 'INVITE_VALID_DAYS_7';
    case InviteValidDays.INVITE_VALID_DAYS_14:
      return 'INVITE_VALID_DAYS_14';
    case InviteValidDays.INVITE_VALID_DAYS_30:
      return 'INVITE_VALID_DAYS_30';
    case InviteValidDays.INVITE_VALID_DAYS_90:
      return 'INVITE_VALID_DAYS_90';
    default:
      return 'UNKNOWN';
  }
}

/** Settings entry for email sender */
export interface EmailSenderCompany {
  defaultName?: string | undefined;
  companyName?: string | undefined;
  name?: string | undefined;
  type: EmailSenderCompanyType;
}

/** Settings entry for email sender name */
export interface SettingsEntryAsEmailSenderCompany {
  value: EmailSenderCompany | undefined;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Settings entry for trash bin */
export interface SettingsEntryAsTrashBin {
  value: EmptyTrashBin;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Setiings entry for hide expired documents */
export interface SettingsEntryAsHideExpired {
  value: HideExpiredDocuments;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Setiings entry for download format */
export interface SettingsEntryAsDownloadListFormat {
  value: DownloadListFormat;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Settings entry for automatic logoff */
export interface SettingsEntryAsAutoLogOff {
  value: AutoLogOff;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Settings entry for the login verification */
export interface SettingsEntryAsLoginVerification {
  value: LoginVerification;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Settings entry for password min length */
export interface SettingsEntryAsPasswordMinLength {
  value: PasswordMinLength;
  editable: boolean;
  fieldType?: string | undefined;
  available?: boolean | undefined;
}

/** Settings entry for password change interval */
export interface SettingsEntryAsPasswordChange {
  value: PasswordChangeInterval;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Settings entry for password reset options */
export interface SettingsEntryAsPasswordReset {
  value: PasswordResetOptions;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Settings entry for password reset options */
export interface SettingsEntryAsSortIndex {
  value: SortIndexOptions;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Settings entry for visibility */
export interface SettingsEntryAsDefaultVisibility {
  value: DefaultVisibilityOptions;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Settings entry for information emails */
export interface SettingsEntryAsSendInformationEmails {
  value: SendInformationEmailsOptions;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Settings entry for comments visibility */
export interface SettingsEntryAsCommentVisibility {
  value: CommentVisibilityOptions;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Settings entry for theme mode */
export interface SettingsEntryAsThemeMode {
  value: ThemeMode;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Settings entry for theme mode */
export interface SettingsEntryAsUserNotification {
  value: UserNotification;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Settings entry for 2FA type */
export interface SettingsEntryAsTwoFactorType {
  value: TwoFactorType;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Settings entry for salutation */
export interface SettingsEntryAsSalutation {
  value: Salutation;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Settings entry for language */
export interface SettingsEntryAsLanguage {
  value: Language;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Settings entry for language */
export interface SettingsEntryAsLocale {
  value: Locale;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Settings entry for language */
export interface SettingsEntryAsEncryptionType {
  value: EncryptionType;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Settings entry mft visibility */
export interface SettingsEntryAsManagedFiletransferVisibility {
  value: ManagedFiletransferVisibility;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Settings entry for invite valid days */
export interface SettingsEntryAsInviteValidDays {
  value: InviteValidDays;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** Generic Dataroom Settings */
export interface DataroomSettings {
  dataroomId: string;
  lastChanged: Date | undefined;
  /** General */
  dataroomName: SettingsEntryAsString | undefined;
  /** folderFirst */
  showFolderOnTop: SettingsEntryAsBool | undefined;
  /** showTimeCreated */
  showTimeCreated: SettingsEntryAsBool | undefined;
  /** showDateWithTime */
  showDateWithTime: SettingsEntryAsBool | undefined;
  /** showAuthor */
  showVersionAuthor: SettingsEntryAsBool | undefined;
  /** showCOllectionSize */
  showNumberOfDocuments: SettingsEntryAsBool | undefined;
  /** downloadListFormat */
  downloadListFormat: SettingsEntryAsDownloadListFormat | undefined;
  /** activityLogRecursive */
  logActivityRecursive: SettingsEntryAsBool | undefined;
  /** NF_NotifyEmailSenderCompany */
  emailSenderName: SettingsEntryAsEmailSenderCompany | undefined;
  /** Documents */
  requireDocumentApproval?: SettingsEntryAsBool | undefined;
  /** allowAgents */
  notifyAboutChanges: SettingsEntryAsBool | undefined;
  /** allowAgentsUnConfirmed */
  notifyAboutChangesForUnconfirmedUsers: SettingsEntryAsBool | undefined;
  /** versionControl */
  useVersionControl: SettingsEntryAsBool | undefined;
  /** useGroupLabels */
  useGroupLabels: SettingsEntryAsBool | undefined;
  /** openOfficeDocuments */
  createOfficeDocuments: SettingsEntryAsBool | undefined;
  /** showOfficeInCollabora */
  showOfficeDocumentsNavigable: SettingsEntryAsBool | undefined;
  /** showDocuments */
  createTextDocuments: SettingsEntryAsBool | undefined;
  /** defaultDownload */
  downloadInsteadOpen?: SettingsEntryAsBool | undefined;
  /** defaultZipDownloads */
  downloadZip: SettingsEntryAsBool | undefined;
  /** canDownloadFolderOption */
  allowFolderDownload: SettingsEntryAsBool | undefined;
  /** createFulltextIndex */
  useFulltextSearch: SettingsEntryAsBool | undefined;
  /** createViewerAnnotations */
  createDocumentAnnotations?: SettingsEntryAsBool | undefined;
  /** createViewerSharedAnnotations */
  createSharedDocumentAnnotations?: SettingsEntryAsBool | undefined;
  /** createViewerRedactions */
  createDocumentRedactions?: SettingsEntryAsBool | undefined;
  /** showRightsIcon */
  showPermissionsIcon: SettingsEntryAsBool | undefined;
  /** docLivetime */
  daysExpiredDocumentsHidden?: SettingsEntryAsHideExpired | undefined;
  /** emptyTrashDays */
  daysEmptyTrash: SettingsEntryAsTrashBin | undefined;
  /** useLabels */
  usePrivateLabels: SettingsEntryAsBool | undefined;
  /** Security */
  showLoginActivity: SettingsEntryAsBool | undefined;
  /** showDeviceAccess */
  sendEmailForNewDevices: SettingsEntryAsBool | undefined;
  /** newlogoutSecs */
  autoLogoff: SettingsEntryAsAutoLogOff | undefined;
  /** device_access_type */
  loginVerification: SettingsEntryAsLoginVerification | undefined;
  /** ipaddress */
  excludeIpRange: SettingsEntryAsString | undefined;
  /** virusScan */
  scanForVirus: SettingsEntryAsBool | undefined;
  /** encryptFiles */
  encryptFiles: SettingsEntryAsBool | undefined;
  encryptionType?: SettingsEntryAsEncryptionType | undefined;
  /** prohibitDownload */
  usePermissionReadOnly: SettingsEntryAsBool | undefined;
  /** allowPdfDownload */
  usePermissionDownloadPdf: SettingsEntryAsBool | undefined;
  /** useWatermarks */
  useWatermarksPdf: SettingsEntryAsBool | undefined;
  /** downloadentryptedpdf */
  downloadPdfsEncrypted: SettingsEntryAsBool | undefined;
  /** Password */
  passwordMinLength: SettingsEntryAsPasswordMinLength | undefined;
  /** passwordUseSpecial */
  passwordRequiresSpecialChars: SettingsEntryAsBool | undefined;
  /** passwordUseNumbers */
  passwordRequiresNumbers: SettingsEntryAsBool | undefined;
  /** passwordUseMixed */
  passwordRequiresMixedLetters: SettingsEntryAsBool | undefined;
  /** passwordChangeInt */
  passwordChangeInterval: SettingsEntryAsPasswordChange | undefined;
  /** passwordQuestion */
  passwordReset: SettingsEntryAsPasswordReset | undefined;
  /** passwordNoResetByAdmin */
  passwordResetByAdmin: SettingsEntryAsBool | undefined;
  /** Index */
  useFileIndex?: SettingsEntryAsBool | undefined;
  /** indexSpread */
  spreadUploadByIndex?: SettingsEntryAsBool | undefined;
  /** indexFreeze */
  freezeIndex?: SettingsEntryAsBool | undefined;
  /** indexSortby */
  sortIndex?: SettingsEntryAsSortIndex | undefined;
  /** User */
  markUnseenDocuments: SettingsEntryAsBool | undefined;
  /** editRights */
  userMayChangeRights: SettingsEntryAsBool | undefined;
  /** showBin */
  userMayTrashFiles: SettingsEntryAsBool | undefined;
  /** showAdmins */
  showAdminGroup: SettingsEntryAsBool | undefined;
  /** doesNotSeeOthersGlobal */
  defaultVisibility: SettingsEntryAsDefaultVisibility | undefined;
  /** userToUserMailDelivery */
  allowEmailsToUsers: SettingsEntryAsBool | undefined;
  /** plainTextEmails */
  allowEmailsAsPlaintextOnly: SettingsEntryAsBool | undefined;
  /** emailSenderCopy */
  requireEmailsCopyToSender: SettingsEntryAsBool | undefined;
  /** informObserverAboutInvite */
  sendObserverEmailOnInvite: SettingsEntryAsBool | undefined;
  /** NF_InformResponsibleOnly */
  sendInformationEmails: SettingsEntryAsSendInformationEmails | undefined;
  /** showWelcome */
  showIntroductionDialog: SettingsEntryAsBool | undefined;
  /** defineUsernames */
  defineUsernamesEnabled?: SettingsEntryAsBool | undefined;
  /** inviteValidDuration */
  inviteValidDays?: SettingsEntryAsInviteValidDays | undefined;
  /** Functions */
  useManagedFiletransfer?: SettingsEntryAsBool | undefined;
  /** managedFileTransferUpload */
  useManagedFiletransferLocalUpload?: SettingsEntryAsBool | undefined;
  /** managedFileTransferPassword */
  useManagedFiletransferPassword?: SettingsEntryAsBool | undefined;
  /** ipad */
  useTabletApp?: SettingsEntryAsBool | undefined;
  /** webdav */
  useWebdav?: SettingsEntryAsBool | undefined;
  /** sftp */
  useSftp?: SettingsEntryAsBool | undefined;
  /** showTasks */
  useTasks?: SettingsEntryAsBool | undefined;
  /** showDates */
  useAppointments?: SettingsEntryAsBool | undefined;
  /** showVideoConference */
  useVideoConferences?: SettingsEntryAsBool | undefined;
  /** showDiscussions */
  useDiscussions?: SettingsEntryAsBool | undefined;
  /** showMeetings */
  useMeetings?: SettingsEntryAsBool | undefined;
  /** showVotings */
  useVotings?: SettingsEntryAsBool | undefined;
  /** showFavourites */
  showFavorites?: SettingsEntryAsBool | undefined;
  /** showNewFiles */
  showNewFiles?: SettingsEntryAsBool | undefined;
  /** showLocked */
  showLockedFiles?: SettingsEntryAsBool | undefined;
  /** showRecentComments */
  showRecentComments?: SettingsEntryAsBool | undefined;
  /** showPrefered */
  showPreferredFiles?: SettingsEntryAsBool | undefined;
  /** showComments */
  useComments?: SettingsEntryAsBool | undefined;
  /** showCommentsScope */
  commentVisibility?: SettingsEntryAsCommentVisibility | undefined;
  /** Disclaimer */
  useDisclaimer?: SettingsEntryAsBool | undefined;
  /** disclaimerReAccept */
  reacceptDisclaimer?: SettingsEntryAsBool | undefined;
  /** disclaimerReAccept */
  disclaimerText?: SettingsEntryAsString | undefined;
  /** UI */
  dataroomLogo: SettingsEntryAsUILogo | undefined;
  onUploadScaleOnly?: SettingsEntryAsBool | undefined;
  emailLogo: SettingsEntryAsUILogo | undefined;
  colorScheme: SettingsEntryAsUIColorScheme | undefined;
  loginPageUrl: SettingsEntryAsString | undefined;
  /** Additional */
  showPasswordDirective?: SettingsEntryAsBool | undefined;
  allowExportDataroom?: SettingsEntryAsBool | undefined;
  allowImportUsers?: SettingsEntryAsBool | undefined;
  allowTimeRestrictedAccess?: SettingsEntryAsBool | undefined;
  showDataroomIndex?: SettingsEntryAsBool | undefined;
  showUserActivities?: SettingsEntryAsBool | undefined;
  allowInvitationSendLater?: SettingsEntryAsBool | undefined;
  allowInvitationSendAsLink?: SettingsEntryAsBool | undefined;
  showDataroomSettings?: SettingsEntryAsBool | undefined;
}

/** Settings entry for ui logo */
export interface SettingsEntryAsUILogo {
  value: LogoType;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
}

export interface UIColorSchemeEntry {
  value: UIColorScheme;
  color: string;
}

/** Settings entry for ui color scheme */
export interface SettingsEntryAsUIColorScheme {
  value: UIColorSchemeEntry | undefined;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
}

/** User Settings */
export interface UserSettings {
  id: string;
  username: SettingsEntryAsString | undefined;
  realName: SettingsEntryAsString | undefined;
  language: SettingsEntryAsLanguage | undefined;
  notification: SettingsEntryAsUserNotification | undefined;
  showWelcomeDialog: SettingsEntryAsBool | undefined;
  twoFactorType: SettingsEntryAsTwoFactorType | undefined;
  themeMode: SettingsEntryAsThemeMode | undefined;
  useMsOffice?: SettingsEntryAsBool | undefined;
  receiveInfoMails?: SettingsEntryAsBool | undefined;
  useManagedFiletransfer?: SettingsEntryAsBool | undefined;
  createVideoConferences?: SettingsEntryAsBool | undefined;
  restrictAccessFrom?: SettingsEntryAsDate | undefined;
  restrictAccessTo?: SettingsEntryAsDate | undefined;
  useSftp?: SettingsEntryAsBool | undefined;
  unpackArchivesOnUpload?: SettingsEntryAsBool | undefined;
  firstName: SettingsEntryAsString | undefined;
  lastName: SettingsEntryAsString | undefined;
  email: SettingsEntryAsString | undefined;
  salutation: SettingsEntryAsSalutation | undefined;
  title: SettingsEntryAsString | undefined;
  mobilePhoneNr: SettingsEntryAsString | undefined;
  website: SettingsEntryAsString | undefined;
  company: SettingsEntryAsString | undefined;
  address: UserSettingsEntryAsAddress | undefined;
  department: SettingsEntryAsString | undefined;
  position: SettingsEntryAsString | undefined;
  phoneNr: SettingsEntryAsString | undefined;
  faxNr: SettingsEntryAsString | undefined;
  useWebdav?: SettingsEntryAsBool | undefined;
  useTabletApp?: SettingsEntryAsBool | undefined;
  mountpoint?: SettingsEntryAsString | undefined;
  usePrivateFolder?: SettingsEntryAsBool | undefined;
  labels?: UserSettingsEntryAsPrivateLabel | undefined;
  downloadProtectedPdf?: SettingsEntryAsBool | undefined;
  printProtectedPdf?: SettingsEntryAsBool | undefined;
  signature?: UserSettingsEntryAsSignature | undefined;
  userFeatures?: UserSettingsEntryAsUserFeatures | undefined;
  twoFactorEnabled?: SettingsEntryAsBool | undefined;
  hasPhoto?: SettingsEntryAsBool | undefined;
  visibility?: SettingsEntryAsDefaultVisibility | undefined;
  logins?: SettingsEntryAsNumber | undefined;
  lastLogin?: SettingsEntryAsDate | undefined;
  created?: SettingsEntryAsDate | undefined;
  groups?: UserSettingsEntryAsGroupMembership | undefined;
  userGroups?: UserSettingsEntryAsUserGroupMembership | undefined;
  invitationSent?: SettingsEntryAsDate | undefined;
  accessContractData?: SettingsEntryAsBool | undefined;
  singleSignOnId?: SettingsEntryAsString | undefined;
  singleSignOnEnabled?: SettingsEntryAsBool | undefined;
  newsletter?: SettingsEntryAsBool | undefined;
  endCustomerId?: SettingsEntryAsString | undefined;
  sendInvitationLater?: SettingsEntryAsBool | undefined;
  sendInvitationToMeOnly?: SettingsEntryAsBool | undefined;
  userActivationObserverEmail?: SettingsEntryAsString | undefined;
  costCenter?: SettingsEntryAsString | undefined;
  messageToNewUser?: SettingsEntryAsString | undefined;
  timezone?: SettingsEntryAsString | undefined;
  locale?: SettingsEntryAsLocale | undefined;
  userTitle?: SettingsEntryAsString | undefined;
  managedFiletransferVisibility?:
    | SettingsEntryAsManagedFiletransferVisibility
    | undefined;
  hasSharedSecret?: boolean | undefined;
}

/** A list of features a user can select for the left frame */
export interface UserFeatures {
  favorites?: boolean | undefined;
  newFiles?: boolean | undefined;
  preferredFiles?: boolean | undefined;
  lockedFiles?: boolean | undefined;
  recentComments?: boolean | undefined;
  expiredFiles?: boolean | undefined;
  unreadFiles?: boolean | undefined;
  labels?: boolean | undefined;
  tobeapprovedFiles?: boolean | undefined;
  inbox?: boolean | undefined;
  outbox?: boolean | undefined;
  tasks?: boolean | undefined;
  appointments?: boolean | undefined;
  videoconferences?: boolean | undefined;
  discussions?: boolean | undefined;
}

/** The users signature for emails */
export interface UserSignature {
  signatureEnabled: boolean;
  signatureFromPersonalData: boolean;
  signature: string;
  personalSignature: string;
}

/** The users address information */
export interface UserAddress {
  country: string;
  city: string;
  zipCode: string;
  street: string;
}

/** The users private labels */
export interface PrivateLabel {
  label1: PrivateLabelEntry | undefined;
  label2: PrivateLabelEntry | undefined;
  label3: PrivateLabelEntry | undefined;
  label4: PrivateLabelEntry | undefined;
  label5: PrivateLabelEntry | undefined;
  label6: PrivateLabelEntry | undefined;
  label7: PrivateLabelEntry | undefined;
}

/** One entry of private lobel */
export interface PrivateLabelEntry {
  text: string;
  enabled: boolean;
}

/** The label with extended information */
export interface UserSettingsEntryAsPrivateLabel {
  value: PrivateLabel | undefined;
  editable: boolean;
}

/** The useraddress with extended information */
export interface UserSettingsEntryAsAddress {
  value: UserAddress | undefined;
  editable: boolean;
}

/** The user signature with extended information */
export interface UserSettingsEntryAsSignature {
  value: UserSignature | undefined;
  editable: boolean;
}

/** One user setting with value as string */
export interface SettingsEntryAsString {
  value: string;
  editable?: boolean | undefined;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** One user setting with value as bool */
export interface SettingsEntryAsBool {
  value?: boolean | undefined;
  editable?: boolean | undefined;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** One user setting with value as bool */
export interface SettingsEntryAsNumber {
  value: number;
  editable?: boolean | undefined;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** One user setting with value as date */
export interface SettingsEntryAsDate {
  value: Date | undefined;
  editable?: boolean | undefined;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
  available?: boolean | undefined;
}

/** One user setting with value as date */
export interface UserSettingsEntryAsUserFeatures {
  value: UserFeatures | undefined;
  editable: boolean;
}

/** Group membership of user (deprecated) */
export interface UserSettingsEntryAsGroupMembership {
  value: string[];
  editable: boolean;
}

/** One entry for group membership of user */
export interface UserMembershipGroup {
  id: string;
  title: string;
  name: string;
}

/** Group membership of user */
export interface UserSettingsEntryAsUserGroupMembership {
  value: UserMembershipGroup[];
  editable: boolean;
}

function createBaseEmailSenderCompany(): EmailSenderCompany {
  return {
    defaultName: undefined,
    companyName: undefined,
    name: undefined,
    type: 0,
  };
}

export const EmailSenderCompany = {
  fromJSON(object: any): EmailSenderCompany {
    return {
      defaultName: isSet(object.defaultName)
        ? String(object.defaultName)
        : undefined,
      companyName: isSet(object.companyName)
        ? String(object.companyName)
        : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      type: isSet(object.type)
        ? emailSenderCompanyTypeFromJSON(object.type)
        : 0,
    };
  },

  toJSON(message: EmailSenderCompany): unknown {
    const obj: any = {};
    message.defaultName !== undefined &&
      (obj.defaultName = message.defaultName);
    message.companyName !== undefined &&
      (obj.companyName = message.companyName);
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined &&
      (obj.type = emailSenderCompanyTypeToJSON(message.type));
    return obj;
  },
};

function createBaseSettingsEntryAsEmailSenderCompany(): SettingsEntryAsEmailSenderCompany {
  return {
    value: undefined,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsEmailSenderCompany = {
  fromJSON(object: any): SettingsEntryAsEmailSenderCompany {
    return {
      value: isSet(object.value)
        ? EmailSenderCompany.fromJSON(object.value)
        : undefined,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsEmailSenderCompany): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = message.value
        ? EmailSenderCompany.toJSON(message.value)
        : undefined);
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsTrashBin(): SettingsEntryAsTrashBin {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsTrashBin = {
  fromJSON(object: any): SettingsEntryAsTrashBin {
    return {
      value: isSet(object.value) ? emptyTrashBinFromJSON(object.value) : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsTrashBin): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = emptyTrashBinToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsHideExpired(): SettingsEntryAsHideExpired {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsHideExpired = {
  fromJSON(object: any): SettingsEntryAsHideExpired {
    return {
      value: isSet(object.value)
        ? hideExpiredDocumentsFromJSON(object.value)
        : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsHideExpired): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = hideExpiredDocumentsToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsDownloadListFormat(): SettingsEntryAsDownloadListFormat {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsDownloadListFormat = {
  fromJSON(object: any): SettingsEntryAsDownloadListFormat {
    return {
      value: isSet(object.value) ? downloadListFormatFromJSON(object.value) : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsDownloadListFormat): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = downloadListFormatToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsAutoLogOff(): SettingsEntryAsAutoLogOff {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsAutoLogOff = {
  fromJSON(object: any): SettingsEntryAsAutoLogOff {
    return {
      value: isSet(object.value) ? autoLogOffFromJSON(object.value) : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsAutoLogOff): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = autoLogOffToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsLoginVerification(): SettingsEntryAsLoginVerification {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsLoginVerification = {
  fromJSON(object: any): SettingsEntryAsLoginVerification {
    return {
      value: isSet(object.value) ? loginVerificationFromJSON(object.value) : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsLoginVerification): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = loginVerificationToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsPasswordMinLength(): SettingsEntryAsPasswordMinLength {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsPasswordMinLength = {
  fromJSON(object: any): SettingsEntryAsPasswordMinLength {
    return {
      value: isSet(object.value) ? passwordMinLengthFromJSON(object.value) : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsPasswordMinLength): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = passwordMinLengthToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsPasswordChange(): SettingsEntryAsPasswordChange {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsPasswordChange = {
  fromJSON(object: any): SettingsEntryAsPasswordChange {
    return {
      value: isSet(object.value)
        ? passwordChangeIntervalFromJSON(object.value)
        : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsPasswordChange): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = passwordChangeIntervalToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsPasswordReset(): SettingsEntryAsPasswordReset {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsPasswordReset = {
  fromJSON(object: any): SettingsEntryAsPasswordReset {
    return {
      value: isSet(object.value)
        ? passwordResetOptionsFromJSON(object.value)
        : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsPasswordReset): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = passwordResetOptionsToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsSortIndex(): SettingsEntryAsSortIndex {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsSortIndex = {
  fromJSON(object: any): SettingsEntryAsSortIndex {
    return {
      value: isSet(object.value) ? sortIndexOptionsFromJSON(object.value) : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsSortIndex): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = sortIndexOptionsToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsDefaultVisibility(): SettingsEntryAsDefaultVisibility {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsDefaultVisibility = {
  fromJSON(object: any): SettingsEntryAsDefaultVisibility {
    return {
      value: isSet(object.value)
        ? defaultVisibilityOptionsFromJSON(object.value)
        : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsDefaultVisibility): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = defaultVisibilityOptionsToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsSendInformationEmails(): SettingsEntryAsSendInformationEmails {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsSendInformationEmails = {
  fromJSON(object: any): SettingsEntryAsSendInformationEmails {
    return {
      value: isSet(object.value)
        ? sendInformationEmailsOptionsFromJSON(object.value)
        : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsSendInformationEmails): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = sendInformationEmailsOptionsToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsCommentVisibility(): SettingsEntryAsCommentVisibility {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsCommentVisibility = {
  fromJSON(object: any): SettingsEntryAsCommentVisibility {
    return {
      value: isSet(object.value)
        ? commentVisibilityOptionsFromJSON(object.value)
        : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsCommentVisibility): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = commentVisibilityOptionsToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsThemeMode(): SettingsEntryAsThemeMode {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsThemeMode = {
  fromJSON(object: any): SettingsEntryAsThemeMode {
    return {
      value: isSet(object.value) ? themeModeFromJSON(object.value) : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsThemeMode): unknown {
    const obj: any = {};
    message.value !== undefined && (obj.value = themeModeToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsUserNotification(): SettingsEntryAsUserNotification {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsUserNotification = {
  fromJSON(object: any): SettingsEntryAsUserNotification {
    return {
      value: isSet(object.value) ? userNotificationFromJSON(object.value) : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsUserNotification): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = userNotificationToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsTwoFactorType(): SettingsEntryAsTwoFactorType {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsTwoFactorType = {
  fromJSON(object: any): SettingsEntryAsTwoFactorType {
    return {
      value: isSet(object.value) ? twoFactorTypeFromJSON(object.value) : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsTwoFactorType): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = twoFactorTypeToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsSalutation(): SettingsEntryAsSalutation {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsSalutation = {
  fromJSON(object: any): SettingsEntryAsSalutation {
    return {
      value: isSet(object.value) ? salutationFromJSON(object.value) : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsSalutation): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = salutationToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsLanguage(): SettingsEntryAsLanguage {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsLanguage = {
  fromJSON(object: any): SettingsEntryAsLanguage {
    return {
      value: isSet(object.value) ? languageFromJSON(object.value) : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsLanguage): unknown {
    const obj: any = {};
    message.value !== undefined && (obj.value = languageToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsLocale(): SettingsEntryAsLocale {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsLocale = {
  fromJSON(object: any): SettingsEntryAsLocale {
    return {
      value: isSet(object.value) ? localeFromJSON(object.value) : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsLocale): unknown {
    const obj: any = {};
    message.value !== undefined && (obj.value = localeToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsEncryptionType(): SettingsEntryAsEncryptionType {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsEncryptionType = {
  fromJSON(object: any): SettingsEntryAsEncryptionType {
    return {
      value: isSet(object.value) ? encryptionTypeFromJSON(object.value) : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsEncryptionType): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = encryptionTypeToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsManagedFiletransferVisibility(): SettingsEntryAsManagedFiletransferVisibility {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsManagedFiletransferVisibility = {
  fromJSON(object: any): SettingsEntryAsManagedFiletransferVisibility {
    return {
      value: isSet(object.value)
        ? managedFiletransferVisibilityFromJSON(object.value)
        : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsManagedFiletransferVisibility): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = managedFiletransferVisibilityToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsInviteValidDays(): SettingsEntryAsInviteValidDays {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsInviteValidDays = {
  fromJSON(object: any): SettingsEntryAsInviteValidDays {
    return {
      value: isSet(object.value) ? inviteValidDaysFromJSON(object.value) : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsInviteValidDays): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = inviteValidDaysToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseDataroomSettings(): DataroomSettings {
  return {
    dataroomId: '',
    lastChanged: undefined,
    dataroomName: undefined,
    showFolderOnTop: undefined,
    showTimeCreated: undefined,
    showDateWithTime: undefined,
    showVersionAuthor: undefined,
    showNumberOfDocuments: undefined,
    downloadListFormat: undefined,
    logActivityRecursive: undefined,
    emailSenderName: undefined,
    requireDocumentApproval: undefined,
    notifyAboutChanges: undefined,
    notifyAboutChangesForUnconfirmedUsers: undefined,
    useVersionControl: undefined,
    useGroupLabels: undefined,
    createOfficeDocuments: undefined,
    showOfficeDocumentsNavigable: undefined,
    createTextDocuments: undefined,
    downloadInsteadOpen: undefined,
    downloadZip: undefined,
    allowFolderDownload: undefined,
    useFulltextSearch: undefined,
    createDocumentAnnotations: undefined,
    createSharedDocumentAnnotations: undefined,
    createDocumentRedactions: undefined,
    showPermissionsIcon: undefined,
    daysExpiredDocumentsHidden: undefined,
    daysEmptyTrash: undefined,
    usePrivateLabels: undefined,
    showLoginActivity: undefined,
    sendEmailForNewDevices: undefined,
    autoLogoff: undefined,
    loginVerification: undefined,
    excludeIpRange: undefined,
    scanForVirus: undefined,
    encryptFiles: undefined,
    encryptionType: undefined,
    usePermissionReadOnly: undefined,
    usePermissionDownloadPdf: undefined,
    useWatermarksPdf: undefined,
    downloadPdfsEncrypted: undefined,
    passwordMinLength: undefined,
    passwordRequiresSpecialChars: undefined,
    passwordRequiresNumbers: undefined,
    passwordRequiresMixedLetters: undefined,
    passwordChangeInterval: undefined,
    passwordReset: undefined,
    passwordResetByAdmin: undefined,
    useFileIndex: undefined,
    spreadUploadByIndex: undefined,
    freezeIndex: undefined,
    sortIndex: undefined,
    markUnseenDocuments: undefined,
    userMayChangeRights: undefined,
    userMayTrashFiles: undefined,
    showAdminGroup: undefined,
    defaultVisibility: undefined,
    allowEmailsToUsers: undefined,
    allowEmailsAsPlaintextOnly: undefined,
    requireEmailsCopyToSender: undefined,
    sendObserverEmailOnInvite: undefined,
    sendInformationEmails: undefined,
    showIntroductionDialog: undefined,
    defineUsernamesEnabled: undefined,
    inviteValidDays: undefined,
    useManagedFiletransfer: undefined,
    useManagedFiletransferLocalUpload: undefined,
    useManagedFiletransferPassword: undefined,
    useTabletApp: undefined,
    useWebdav: undefined,
    useSftp: undefined,
    useTasks: undefined,
    useAppointments: undefined,
    useVideoConferences: undefined,
    useDiscussions: undefined,
    useMeetings: undefined,
    useVotings: undefined,
    showFavorites: undefined,
    showNewFiles: undefined,
    showLockedFiles: undefined,
    showRecentComments: undefined,
    showPreferredFiles: undefined,
    useComments: undefined,
    commentVisibility: undefined,
    useDisclaimer: undefined,
    reacceptDisclaimer: undefined,
    disclaimerText: undefined,
    dataroomLogo: undefined,
    onUploadScaleOnly: undefined,
    emailLogo: undefined,
    colorScheme: undefined,
    loginPageUrl: undefined,
    showPasswordDirective: undefined,
    allowExportDataroom: undefined,
    allowImportUsers: undefined,
    allowTimeRestrictedAccess: undefined,
    showDataroomIndex: undefined,
    showUserActivities: undefined,
    allowInvitationSendLater: undefined,
    allowInvitationSendAsLink: undefined,
    showDataroomSettings: undefined,
  };
}

export const DataroomSettings = {
  fromJSON(object: any): DataroomSettings {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      lastChanged: isSet(object.lastChanged)
        ? fromJsonTimestamp(object.lastChanged)
        : undefined,
      dataroomName: isSet(object.dataroomName)
        ? SettingsEntryAsString.fromJSON(object.dataroomName)
        : undefined,
      showFolderOnTop: isSet(object.showFolderOnTop)
        ? SettingsEntryAsBool.fromJSON(object.showFolderOnTop)
        : undefined,
      showTimeCreated: isSet(object.showTimeCreated)
        ? SettingsEntryAsBool.fromJSON(object.showTimeCreated)
        : undefined,
      showDateWithTime: isSet(object.showDateWithTime)
        ? SettingsEntryAsBool.fromJSON(object.showDateWithTime)
        : undefined,
      showVersionAuthor: isSet(object.showVersionAuthor)
        ? SettingsEntryAsBool.fromJSON(object.showVersionAuthor)
        : undefined,
      showNumberOfDocuments: isSet(object.showNumberOfDocuments)
        ? SettingsEntryAsBool.fromJSON(object.showNumberOfDocuments)
        : undefined,
      downloadListFormat: isSet(object.downloadListFormat)
        ? SettingsEntryAsDownloadListFormat.fromJSON(object.downloadListFormat)
        : undefined,
      logActivityRecursive: isSet(object.logActivityRecursive)
        ? SettingsEntryAsBool.fromJSON(object.logActivityRecursive)
        : undefined,
      emailSenderName: isSet(object.emailSenderName)
        ? SettingsEntryAsEmailSenderCompany.fromJSON(object.emailSenderName)
        : undefined,
      requireDocumentApproval: isSet(object.requireDocumentApproval)
        ? SettingsEntryAsBool.fromJSON(object.requireDocumentApproval)
        : undefined,
      notifyAboutChanges: isSet(object.notifyAboutChanges)
        ? SettingsEntryAsBool.fromJSON(object.notifyAboutChanges)
        : undefined,
      notifyAboutChangesForUnconfirmedUsers: isSet(
        object.notifyAboutChangesForUnconfirmedUsers,
      )
        ? SettingsEntryAsBool.fromJSON(
            object.notifyAboutChangesForUnconfirmedUsers,
          )
        : undefined,
      useVersionControl: isSet(object.useVersionControl)
        ? SettingsEntryAsBool.fromJSON(object.useVersionControl)
        : undefined,
      useGroupLabels: isSet(object.useGroupLabels)
        ? SettingsEntryAsBool.fromJSON(object.useGroupLabels)
        : undefined,
      createOfficeDocuments: isSet(object.createOfficeDocuments)
        ? SettingsEntryAsBool.fromJSON(object.createOfficeDocuments)
        : undefined,
      showOfficeDocumentsNavigable: isSet(object.showOfficeDocumentsNavigable)
        ? SettingsEntryAsBool.fromJSON(object.showOfficeDocumentsNavigable)
        : undefined,
      createTextDocuments: isSet(object.createTextDocuments)
        ? SettingsEntryAsBool.fromJSON(object.createTextDocuments)
        : undefined,
      downloadInsteadOpen: isSet(object.downloadInsteadOpen)
        ? SettingsEntryAsBool.fromJSON(object.downloadInsteadOpen)
        : undefined,
      downloadZip: isSet(object.downloadZip)
        ? SettingsEntryAsBool.fromJSON(object.downloadZip)
        : undefined,
      allowFolderDownload: isSet(object.allowFolderDownload)
        ? SettingsEntryAsBool.fromJSON(object.allowFolderDownload)
        : undefined,
      useFulltextSearch: isSet(object.useFulltextSearch)
        ? SettingsEntryAsBool.fromJSON(object.useFulltextSearch)
        : undefined,
      createDocumentAnnotations: isSet(object.createDocumentAnnotations)
        ? SettingsEntryAsBool.fromJSON(object.createDocumentAnnotations)
        : undefined,
      createSharedDocumentAnnotations: isSet(
        object.createSharedDocumentAnnotations,
      )
        ? SettingsEntryAsBool.fromJSON(object.createSharedDocumentAnnotations)
        : undefined,
      createDocumentRedactions: isSet(object.createDocumentRedactions)
        ? SettingsEntryAsBool.fromJSON(object.createDocumentRedactions)
        : undefined,
      showPermissionsIcon: isSet(object.showPermissionsIcon)
        ? SettingsEntryAsBool.fromJSON(object.showPermissionsIcon)
        : undefined,
      daysExpiredDocumentsHidden: isSet(object.daysExpiredDocumentsHidden)
        ? SettingsEntryAsHideExpired.fromJSON(object.daysExpiredDocumentsHidden)
        : undefined,
      daysEmptyTrash: isSet(object.daysEmptyTrash)
        ? SettingsEntryAsTrashBin.fromJSON(object.daysEmptyTrash)
        : undefined,
      usePrivateLabels: isSet(object.usePrivateLabels)
        ? SettingsEntryAsBool.fromJSON(object.usePrivateLabels)
        : undefined,
      showLoginActivity: isSet(object.showLoginActivity)
        ? SettingsEntryAsBool.fromJSON(object.showLoginActivity)
        : undefined,
      sendEmailForNewDevices: isSet(object.sendEmailForNewDevices)
        ? SettingsEntryAsBool.fromJSON(object.sendEmailForNewDevices)
        : undefined,
      autoLogoff: isSet(object.autoLogoff)
        ? SettingsEntryAsAutoLogOff.fromJSON(object.autoLogoff)
        : undefined,
      loginVerification: isSet(object.loginVerification)
        ? SettingsEntryAsLoginVerification.fromJSON(object.loginVerification)
        : undefined,
      excludeIpRange: isSet(object.excludeIpRange)
        ? SettingsEntryAsString.fromJSON(object.excludeIpRange)
        : undefined,
      scanForVirus: isSet(object.scanForVirus)
        ? SettingsEntryAsBool.fromJSON(object.scanForVirus)
        : undefined,
      encryptFiles: isSet(object.encryptFiles)
        ? SettingsEntryAsBool.fromJSON(object.encryptFiles)
        : undefined,
      encryptionType: isSet(object.encryptionType)
        ? SettingsEntryAsEncryptionType.fromJSON(object.encryptionType)
        : undefined,
      usePermissionReadOnly: isSet(object.usePermissionReadOnly)
        ? SettingsEntryAsBool.fromJSON(object.usePermissionReadOnly)
        : undefined,
      usePermissionDownloadPdf: isSet(object.usePermissionDownloadPdf)
        ? SettingsEntryAsBool.fromJSON(object.usePermissionDownloadPdf)
        : undefined,
      useWatermarksPdf: isSet(object.useWatermarksPdf)
        ? SettingsEntryAsBool.fromJSON(object.useWatermarksPdf)
        : undefined,
      downloadPdfsEncrypted: isSet(object.downloadPdfsEncrypted)
        ? SettingsEntryAsBool.fromJSON(object.downloadPdfsEncrypted)
        : undefined,
      passwordMinLength: isSet(object.passwordMinLength)
        ? SettingsEntryAsPasswordMinLength.fromJSON(object.passwordMinLength)
        : undefined,
      passwordRequiresSpecialChars: isSet(object.passwordRequiresSpecialChars)
        ? SettingsEntryAsBool.fromJSON(object.passwordRequiresSpecialChars)
        : undefined,
      passwordRequiresNumbers: isSet(object.passwordRequiresNumbers)
        ? SettingsEntryAsBool.fromJSON(object.passwordRequiresNumbers)
        : undefined,
      passwordRequiresMixedLetters: isSet(object.passwordRequiresMixedLetters)
        ? SettingsEntryAsBool.fromJSON(object.passwordRequiresMixedLetters)
        : undefined,
      passwordChangeInterval: isSet(object.passwordChangeInterval)
        ? SettingsEntryAsPasswordChange.fromJSON(object.passwordChangeInterval)
        : undefined,
      passwordReset: isSet(object.passwordReset)
        ? SettingsEntryAsPasswordReset.fromJSON(object.passwordReset)
        : undefined,
      passwordResetByAdmin: isSet(object.passwordResetByAdmin)
        ? SettingsEntryAsBool.fromJSON(object.passwordResetByAdmin)
        : undefined,
      useFileIndex: isSet(object.useFileIndex)
        ? SettingsEntryAsBool.fromJSON(object.useFileIndex)
        : undefined,
      spreadUploadByIndex: isSet(object.spreadUploadByIndex)
        ? SettingsEntryAsBool.fromJSON(object.spreadUploadByIndex)
        : undefined,
      freezeIndex: isSet(object.freezeIndex)
        ? SettingsEntryAsBool.fromJSON(object.freezeIndex)
        : undefined,
      sortIndex: isSet(object.sortIndex)
        ? SettingsEntryAsSortIndex.fromJSON(object.sortIndex)
        : undefined,
      markUnseenDocuments: isSet(object.markUnseenDocuments)
        ? SettingsEntryAsBool.fromJSON(object.markUnseenDocuments)
        : undefined,
      userMayChangeRights: isSet(object.userMayChangeRights)
        ? SettingsEntryAsBool.fromJSON(object.userMayChangeRights)
        : undefined,
      userMayTrashFiles: isSet(object.userMayTrashFiles)
        ? SettingsEntryAsBool.fromJSON(object.userMayTrashFiles)
        : undefined,
      showAdminGroup: isSet(object.showAdminGroup)
        ? SettingsEntryAsBool.fromJSON(object.showAdminGroup)
        : undefined,
      defaultVisibility: isSet(object.defaultVisibility)
        ? SettingsEntryAsDefaultVisibility.fromJSON(object.defaultVisibility)
        : undefined,
      allowEmailsToUsers: isSet(object.allowEmailsToUsers)
        ? SettingsEntryAsBool.fromJSON(object.allowEmailsToUsers)
        : undefined,
      allowEmailsAsPlaintextOnly: isSet(object.allowEmailsAsPlaintextOnly)
        ? SettingsEntryAsBool.fromJSON(object.allowEmailsAsPlaintextOnly)
        : undefined,
      requireEmailsCopyToSender: isSet(object.requireEmailsCopyToSender)
        ? SettingsEntryAsBool.fromJSON(object.requireEmailsCopyToSender)
        : undefined,
      sendObserverEmailOnInvite: isSet(object.sendObserverEmailOnInvite)
        ? SettingsEntryAsBool.fromJSON(object.sendObserverEmailOnInvite)
        : undefined,
      sendInformationEmails: isSet(object.sendInformationEmails)
        ? SettingsEntryAsSendInformationEmails.fromJSON(
            object.sendInformationEmails,
          )
        : undefined,
      showIntroductionDialog: isSet(object.showIntroductionDialog)
        ? SettingsEntryAsBool.fromJSON(object.showIntroductionDialog)
        : undefined,
      defineUsernamesEnabled: isSet(object.defineUsernamesEnabled)
        ? SettingsEntryAsBool.fromJSON(object.defineUsernamesEnabled)
        : undefined,
      inviteValidDays: isSet(object.inviteValidDays)
        ? SettingsEntryAsInviteValidDays.fromJSON(object.inviteValidDays)
        : undefined,
      useManagedFiletransfer: isSet(object.useManagedFiletransfer)
        ? SettingsEntryAsBool.fromJSON(object.useManagedFiletransfer)
        : undefined,
      useManagedFiletransferLocalUpload: isSet(
        object.useManagedFiletransferLocalUpload,
      )
        ? SettingsEntryAsBool.fromJSON(object.useManagedFiletransferLocalUpload)
        : undefined,
      useManagedFiletransferPassword: isSet(
        object.useManagedFiletransferPassword,
      )
        ? SettingsEntryAsBool.fromJSON(object.useManagedFiletransferPassword)
        : undefined,
      useTabletApp: isSet(object.useTabletApp)
        ? SettingsEntryAsBool.fromJSON(object.useTabletApp)
        : undefined,
      useWebdav: isSet(object.useWebdav)
        ? SettingsEntryAsBool.fromJSON(object.useWebdav)
        : undefined,
      useSftp: isSet(object.useSftp)
        ? SettingsEntryAsBool.fromJSON(object.useSftp)
        : undefined,
      useTasks: isSet(object.useTasks)
        ? SettingsEntryAsBool.fromJSON(object.useTasks)
        : undefined,
      useAppointments: isSet(object.useAppointments)
        ? SettingsEntryAsBool.fromJSON(object.useAppointments)
        : undefined,
      useVideoConferences: isSet(object.useVideoConferences)
        ? SettingsEntryAsBool.fromJSON(object.useVideoConferences)
        : undefined,
      useDiscussions: isSet(object.useDiscussions)
        ? SettingsEntryAsBool.fromJSON(object.useDiscussions)
        : undefined,
      useMeetings: isSet(object.useMeetings)
        ? SettingsEntryAsBool.fromJSON(object.useMeetings)
        : undefined,
      useVotings: isSet(object.useVotings)
        ? SettingsEntryAsBool.fromJSON(object.useVotings)
        : undefined,
      showFavorites: isSet(object.showFavorites)
        ? SettingsEntryAsBool.fromJSON(object.showFavorites)
        : undefined,
      showNewFiles: isSet(object.showNewFiles)
        ? SettingsEntryAsBool.fromJSON(object.showNewFiles)
        : undefined,
      showLockedFiles: isSet(object.showLockedFiles)
        ? SettingsEntryAsBool.fromJSON(object.showLockedFiles)
        : undefined,
      showRecentComments: isSet(object.showRecentComments)
        ? SettingsEntryAsBool.fromJSON(object.showRecentComments)
        : undefined,
      showPreferredFiles: isSet(object.showPreferredFiles)
        ? SettingsEntryAsBool.fromJSON(object.showPreferredFiles)
        : undefined,
      useComments: isSet(object.useComments)
        ? SettingsEntryAsBool.fromJSON(object.useComments)
        : undefined,
      commentVisibility: isSet(object.commentVisibility)
        ? SettingsEntryAsCommentVisibility.fromJSON(object.commentVisibility)
        : undefined,
      useDisclaimer: isSet(object.useDisclaimer)
        ? SettingsEntryAsBool.fromJSON(object.useDisclaimer)
        : undefined,
      reacceptDisclaimer: isSet(object.reacceptDisclaimer)
        ? SettingsEntryAsBool.fromJSON(object.reacceptDisclaimer)
        : undefined,
      disclaimerText: isSet(object.disclaimerText)
        ? SettingsEntryAsString.fromJSON(object.disclaimerText)
        : undefined,
      dataroomLogo: isSet(object.dataroomLogo)
        ? SettingsEntryAsUILogo.fromJSON(object.dataroomLogo)
        : undefined,
      onUploadScaleOnly: isSet(object.onUploadScaleOnly)
        ? SettingsEntryAsBool.fromJSON(object.onUploadScaleOnly)
        : undefined,
      emailLogo: isSet(object.emailLogo)
        ? SettingsEntryAsUILogo.fromJSON(object.emailLogo)
        : undefined,
      colorScheme: isSet(object.colorScheme)
        ? SettingsEntryAsUIColorScheme.fromJSON(object.colorScheme)
        : undefined,
      loginPageUrl: isSet(object.loginPageUrl)
        ? SettingsEntryAsString.fromJSON(object.loginPageUrl)
        : undefined,
      showPasswordDirective: isSet(object.showPasswordDirective)
        ? SettingsEntryAsBool.fromJSON(object.showPasswordDirective)
        : undefined,
      allowExportDataroom: isSet(object.allowExportDataroom)
        ? SettingsEntryAsBool.fromJSON(object.allowExportDataroom)
        : undefined,
      allowImportUsers: isSet(object.allowImportUsers)
        ? SettingsEntryAsBool.fromJSON(object.allowImportUsers)
        : undefined,
      allowTimeRestrictedAccess: isSet(object.allowTimeRestrictedAccess)
        ? SettingsEntryAsBool.fromJSON(object.allowTimeRestrictedAccess)
        : undefined,
      showDataroomIndex: isSet(object.showDataroomIndex)
        ? SettingsEntryAsBool.fromJSON(object.showDataroomIndex)
        : undefined,
      showUserActivities: isSet(object.showUserActivities)
        ? SettingsEntryAsBool.fromJSON(object.showUserActivities)
        : undefined,
      allowInvitationSendLater: isSet(object.allowInvitationSendLater)
        ? SettingsEntryAsBool.fromJSON(object.allowInvitationSendLater)
        : undefined,
      allowInvitationSendAsLink: isSet(object.allowInvitationSendAsLink)
        ? SettingsEntryAsBool.fromJSON(object.allowInvitationSendAsLink)
        : undefined,
      showDataroomSettings: isSet(object.showDataroomSettings)
        ? SettingsEntryAsBool.fromJSON(object.showDataroomSettings)
        : undefined,
    };
  },

  toJSON(message: DataroomSettings): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.lastChanged !== undefined &&
      (obj.lastChanged = message.lastChanged.toISOString());
    message.dataroomName !== undefined &&
      (obj.dataroomName = message.dataroomName
        ? SettingsEntryAsString.toJSON(message.dataroomName)
        : undefined);
    message.showFolderOnTop !== undefined &&
      (obj.showFolderOnTop = message.showFolderOnTop
        ? SettingsEntryAsBool.toJSON(message.showFolderOnTop)
        : undefined);
    message.showTimeCreated !== undefined &&
      (obj.showTimeCreated = message.showTimeCreated
        ? SettingsEntryAsBool.toJSON(message.showTimeCreated)
        : undefined);
    message.showDateWithTime !== undefined &&
      (obj.showDateWithTime = message.showDateWithTime
        ? SettingsEntryAsBool.toJSON(message.showDateWithTime)
        : undefined);
    message.showVersionAuthor !== undefined &&
      (obj.showVersionAuthor = message.showVersionAuthor
        ? SettingsEntryAsBool.toJSON(message.showVersionAuthor)
        : undefined);
    message.showNumberOfDocuments !== undefined &&
      (obj.showNumberOfDocuments = message.showNumberOfDocuments
        ? SettingsEntryAsBool.toJSON(message.showNumberOfDocuments)
        : undefined);
    message.downloadListFormat !== undefined &&
      (obj.downloadListFormat = message.downloadListFormat
        ? SettingsEntryAsDownloadListFormat.toJSON(message.downloadListFormat)
        : undefined);
    message.logActivityRecursive !== undefined &&
      (obj.logActivityRecursive = message.logActivityRecursive
        ? SettingsEntryAsBool.toJSON(message.logActivityRecursive)
        : undefined);
    message.emailSenderName !== undefined &&
      (obj.emailSenderName = message.emailSenderName
        ? SettingsEntryAsEmailSenderCompany.toJSON(message.emailSenderName)
        : undefined);
    message.requireDocumentApproval !== undefined &&
      (obj.requireDocumentApproval = message.requireDocumentApproval
        ? SettingsEntryAsBool.toJSON(message.requireDocumentApproval)
        : undefined);
    message.notifyAboutChanges !== undefined &&
      (obj.notifyAboutChanges = message.notifyAboutChanges
        ? SettingsEntryAsBool.toJSON(message.notifyAboutChanges)
        : undefined);
    message.notifyAboutChangesForUnconfirmedUsers !== undefined &&
      (obj.notifyAboutChangesForUnconfirmedUsers =
        message.notifyAboutChangesForUnconfirmedUsers
          ? SettingsEntryAsBool.toJSON(
              message.notifyAboutChangesForUnconfirmedUsers,
            )
          : undefined);
    message.useVersionControl !== undefined &&
      (obj.useVersionControl = message.useVersionControl
        ? SettingsEntryAsBool.toJSON(message.useVersionControl)
        : undefined);
    message.useGroupLabels !== undefined &&
      (obj.useGroupLabels = message.useGroupLabels
        ? SettingsEntryAsBool.toJSON(message.useGroupLabels)
        : undefined);
    message.createOfficeDocuments !== undefined &&
      (obj.createOfficeDocuments = message.createOfficeDocuments
        ? SettingsEntryAsBool.toJSON(message.createOfficeDocuments)
        : undefined);
    message.showOfficeDocumentsNavigable !== undefined &&
      (obj.showOfficeDocumentsNavigable = message.showOfficeDocumentsNavigable
        ? SettingsEntryAsBool.toJSON(message.showOfficeDocumentsNavigable)
        : undefined);
    message.createTextDocuments !== undefined &&
      (obj.createTextDocuments = message.createTextDocuments
        ? SettingsEntryAsBool.toJSON(message.createTextDocuments)
        : undefined);
    message.downloadInsteadOpen !== undefined &&
      (obj.downloadInsteadOpen = message.downloadInsteadOpen
        ? SettingsEntryAsBool.toJSON(message.downloadInsteadOpen)
        : undefined);
    message.downloadZip !== undefined &&
      (obj.downloadZip = message.downloadZip
        ? SettingsEntryAsBool.toJSON(message.downloadZip)
        : undefined);
    message.allowFolderDownload !== undefined &&
      (obj.allowFolderDownload = message.allowFolderDownload
        ? SettingsEntryAsBool.toJSON(message.allowFolderDownload)
        : undefined);
    message.useFulltextSearch !== undefined &&
      (obj.useFulltextSearch = message.useFulltextSearch
        ? SettingsEntryAsBool.toJSON(message.useFulltextSearch)
        : undefined);
    message.createDocumentAnnotations !== undefined &&
      (obj.createDocumentAnnotations = message.createDocumentAnnotations
        ? SettingsEntryAsBool.toJSON(message.createDocumentAnnotations)
        : undefined);
    message.createSharedDocumentAnnotations !== undefined &&
      (obj.createSharedDocumentAnnotations =
        message.createSharedDocumentAnnotations
          ? SettingsEntryAsBool.toJSON(message.createSharedDocumentAnnotations)
          : undefined);
    message.createDocumentRedactions !== undefined &&
      (obj.createDocumentRedactions = message.createDocumentRedactions
        ? SettingsEntryAsBool.toJSON(message.createDocumentRedactions)
        : undefined);
    message.showPermissionsIcon !== undefined &&
      (obj.showPermissionsIcon = message.showPermissionsIcon
        ? SettingsEntryAsBool.toJSON(message.showPermissionsIcon)
        : undefined);
    message.daysExpiredDocumentsHidden !== undefined &&
      (obj.daysExpiredDocumentsHidden = message.daysExpiredDocumentsHidden
        ? SettingsEntryAsHideExpired.toJSON(message.daysExpiredDocumentsHidden)
        : undefined);
    message.daysEmptyTrash !== undefined &&
      (obj.daysEmptyTrash = message.daysEmptyTrash
        ? SettingsEntryAsTrashBin.toJSON(message.daysEmptyTrash)
        : undefined);
    message.usePrivateLabels !== undefined &&
      (obj.usePrivateLabels = message.usePrivateLabels
        ? SettingsEntryAsBool.toJSON(message.usePrivateLabels)
        : undefined);
    message.showLoginActivity !== undefined &&
      (obj.showLoginActivity = message.showLoginActivity
        ? SettingsEntryAsBool.toJSON(message.showLoginActivity)
        : undefined);
    message.sendEmailForNewDevices !== undefined &&
      (obj.sendEmailForNewDevices = message.sendEmailForNewDevices
        ? SettingsEntryAsBool.toJSON(message.sendEmailForNewDevices)
        : undefined);
    message.autoLogoff !== undefined &&
      (obj.autoLogoff = message.autoLogoff
        ? SettingsEntryAsAutoLogOff.toJSON(message.autoLogoff)
        : undefined);
    message.loginVerification !== undefined &&
      (obj.loginVerification = message.loginVerification
        ? SettingsEntryAsLoginVerification.toJSON(message.loginVerification)
        : undefined);
    message.excludeIpRange !== undefined &&
      (obj.excludeIpRange = message.excludeIpRange
        ? SettingsEntryAsString.toJSON(message.excludeIpRange)
        : undefined);
    message.scanForVirus !== undefined &&
      (obj.scanForVirus = message.scanForVirus
        ? SettingsEntryAsBool.toJSON(message.scanForVirus)
        : undefined);
    message.encryptFiles !== undefined &&
      (obj.encryptFiles = message.encryptFiles
        ? SettingsEntryAsBool.toJSON(message.encryptFiles)
        : undefined);
    message.encryptionType !== undefined &&
      (obj.encryptionType = message.encryptionType
        ? SettingsEntryAsEncryptionType.toJSON(message.encryptionType)
        : undefined);
    message.usePermissionReadOnly !== undefined &&
      (obj.usePermissionReadOnly = message.usePermissionReadOnly
        ? SettingsEntryAsBool.toJSON(message.usePermissionReadOnly)
        : undefined);
    message.usePermissionDownloadPdf !== undefined &&
      (obj.usePermissionDownloadPdf = message.usePermissionDownloadPdf
        ? SettingsEntryAsBool.toJSON(message.usePermissionDownloadPdf)
        : undefined);
    message.useWatermarksPdf !== undefined &&
      (obj.useWatermarksPdf = message.useWatermarksPdf
        ? SettingsEntryAsBool.toJSON(message.useWatermarksPdf)
        : undefined);
    message.downloadPdfsEncrypted !== undefined &&
      (obj.downloadPdfsEncrypted = message.downloadPdfsEncrypted
        ? SettingsEntryAsBool.toJSON(message.downloadPdfsEncrypted)
        : undefined);
    message.passwordMinLength !== undefined &&
      (obj.passwordMinLength = message.passwordMinLength
        ? SettingsEntryAsPasswordMinLength.toJSON(message.passwordMinLength)
        : undefined);
    message.passwordRequiresSpecialChars !== undefined &&
      (obj.passwordRequiresSpecialChars = message.passwordRequiresSpecialChars
        ? SettingsEntryAsBool.toJSON(message.passwordRequiresSpecialChars)
        : undefined);
    message.passwordRequiresNumbers !== undefined &&
      (obj.passwordRequiresNumbers = message.passwordRequiresNumbers
        ? SettingsEntryAsBool.toJSON(message.passwordRequiresNumbers)
        : undefined);
    message.passwordRequiresMixedLetters !== undefined &&
      (obj.passwordRequiresMixedLetters = message.passwordRequiresMixedLetters
        ? SettingsEntryAsBool.toJSON(message.passwordRequiresMixedLetters)
        : undefined);
    message.passwordChangeInterval !== undefined &&
      (obj.passwordChangeInterval = message.passwordChangeInterval
        ? SettingsEntryAsPasswordChange.toJSON(message.passwordChangeInterval)
        : undefined);
    message.passwordReset !== undefined &&
      (obj.passwordReset = message.passwordReset
        ? SettingsEntryAsPasswordReset.toJSON(message.passwordReset)
        : undefined);
    message.passwordResetByAdmin !== undefined &&
      (obj.passwordResetByAdmin = message.passwordResetByAdmin
        ? SettingsEntryAsBool.toJSON(message.passwordResetByAdmin)
        : undefined);
    message.useFileIndex !== undefined &&
      (obj.useFileIndex = message.useFileIndex
        ? SettingsEntryAsBool.toJSON(message.useFileIndex)
        : undefined);
    message.spreadUploadByIndex !== undefined &&
      (obj.spreadUploadByIndex = message.spreadUploadByIndex
        ? SettingsEntryAsBool.toJSON(message.spreadUploadByIndex)
        : undefined);
    message.freezeIndex !== undefined &&
      (obj.freezeIndex = message.freezeIndex
        ? SettingsEntryAsBool.toJSON(message.freezeIndex)
        : undefined);
    message.sortIndex !== undefined &&
      (obj.sortIndex = message.sortIndex
        ? SettingsEntryAsSortIndex.toJSON(message.sortIndex)
        : undefined);
    message.markUnseenDocuments !== undefined &&
      (obj.markUnseenDocuments = message.markUnseenDocuments
        ? SettingsEntryAsBool.toJSON(message.markUnseenDocuments)
        : undefined);
    message.userMayChangeRights !== undefined &&
      (obj.userMayChangeRights = message.userMayChangeRights
        ? SettingsEntryAsBool.toJSON(message.userMayChangeRights)
        : undefined);
    message.userMayTrashFiles !== undefined &&
      (obj.userMayTrashFiles = message.userMayTrashFiles
        ? SettingsEntryAsBool.toJSON(message.userMayTrashFiles)
        : undefined);
    message.showAdminGroup !== undefined &&
      (obj.showAdminGroup = message.showAdminGroup
        ? SettingsEntryAsBool.toJSON(message.showAdminGroup)
        : undefined);
    message.defaultVisibility !== undefined &&
      (obj.defaultVisibility = message.defaultVisibility
        ? SettingsEntryAsDefaultVisibility.toJSON(message.defaultVisibility)
        : undefined);
    message.allowEmailsToUsers !== undefined &&
      (obj.allowEmailsToUsers = message.allowEmailsToUsers
        ? SettingsEntryAsBool.toJSON(message.allowEmailsToUsers)
        : undefined);
    message.allowEmailsAsPlaintextOnly !== undefined &&
      (obj.allowEmailsAsPlaintextOnly = message.allowEmailsAsPlaintextOnly
        ? SettingsEntryAsBool.toJSON(message.allowEmailsAsPlaintextOnly)
        : undefined);
    message.requireEmailsCopyToSender !== undefined &&
      (obj.requireEmailsCopyToSender = message.requireEmailsCopyToSender
        ? SettingsEntryAsBool.toJSON(message.requireEmailsCopyToSender)
        : undefined);
    message.sendObserverEmailOnInvite !== undefined &&
      (obj.sendObserverEmailOnInvite = message.sendObserverEmailOnInvite
        ? SettingsEntryAsBool.toJSON(message.sendObserverEmailOnInvite)
        : undefined);
    message.sendInformationEmails !== undefined &&
      (obj.sendInformationEmails = message.sendInformationEmails
        ? SettingsEntryAsSendInformationEmails.toJSON(
            message.sendInformationEmails,
          )
        : undefined);
    message.showIntroductionDialog !== undefined &&
      (obj.showIntroductionDialog = message.showIntroductionDialog
        ? SettingsEntryAsBool.toJSON(message.showIntroductionDialog)
        : undefined);
    message.defineUsernamesEnabled !== undefined &&
      (obj.defineUsernamesEnabled = message.defineUsernamesEnabled
        ? SettingsEntryAsBool.toJSON(message.defineUsernamesEnabled)
        : undefined);
    message.inviteValidDays !== undefined &&
      (obj.inviteValidDays = message.inviteValidDays
        ? SettingsEntryAsInviteValidDays.toJSON(message.inviteValidDays)
        : undefined);
    message.useManagedFiletransfer !== undefined &&
      (obj.useManagedFiletransfer = message.useManagedFiletransfer
        ? SettingsEntryAsBool.toJSON(message.useManagedFiletransfer)
        : undefined);
    message.useManagedFiletransferLocalUpload !== undefined &&
      (obj.useManagedFiletransferLocalUpload =
        message.useManagedFiletransferLocalUpload
          ? SettingsEntryAsBool.toJSON(
              message.useManagedFiletransferLocalUpload,
            )
          : undefined);
    message.useManagedFiletransferPassword !== undefined &&
      (obj.useManagedFiletransferPassword =
        message.useManagedFiletransferPassword
          ? SettingsEntryAsBool.toJSON(message.useManagedFiletransferPassword)
          : undefined);
    message.useTabletApp !== undefined &&
      (obj.useTabletApp = message.useTabletApp
        ? SettingsEntryAsBool.toJSON(message.useTabletApp)
        : undefined);
    message.useWebdav !== undefined &&
      (obj.useWebdav = message.useWebdav
        ? SettingsEntryAsBool.toJSON(message.useWebdav)
        : undefined);
    message.useSftp !== undefined &&
      (obj.useSftp = message.useSftp
        ? SettingsEntryAsBool.toJSON(message.useSftp)
        : undefined);
    message.useTasks !== undefined &&
      (obj.useTasks = message.useTasks
        ? SettingsEntryAsBool.toJSON(message.useTasks)
        : undefined);
    message.useAppointments !== undefined &&
      (obj.useAppointments = message.useAppointments
        ? SettingsEntryAsBool.toJSON(message.useAppointments)
        : undefined);
    message.useVideoConferences !== undefined &&
      (obj.useVideoConferences = message.useVideoConferences
        ? SettingsEntryAsBool.toJSON(message.useVideoConferences)
        : undefined);
    message.useDiscussions !== undefined &&
      (obj.useDiscussions = message.useDiscussions
        ? SettingsEntryAsBool.toJSON(message.useDiscussions)
        : undefined);
    message.useMeetings !== undefined &&
      (obj.useMeetings = message.useMeetings
        ? SettingsEntryAsBool.toJSON(message.useMeetings)
        : undefined);
    message.useVotings !== undefined &&
      (obj.useVotings = message.useVotings
        ? SettingsEntryAsBool.toJSON(message.useVotings)
        : undefined);
    message.showFavorites !== undefined &&
      (obj.showFavorites = message.showFavorites
        ? SettingsEntryAsBool.toJSON(message.showFavorites)
        : undefined);
    message.showNewFiles !== undefined &&
      (obj.showNewFiles = message.showNewFiles
        ? SettingsEntryAsBool.toJSON(message.showNewFiles)
        : undefined);
    message.showLockedFiles !== undefined &&
      (obj.showLockedFiles = message.showLockedFiles
        ? SettingsEntryAsBool.toJSON(message.showLockedFiles)
        : undefined);
    message.showRecentComments !== undefined &&
      (obj.showRecentComments = message.showRecentComments
        ? SettingsEntryAsBool.toJSON(message.showRecentComments)
        : undefined);
    message.showPreferredFiles !== undefined &&
      (obj.showPreferredFiles = message.showPreferredFiles
        ? SettingsEntryAsBool.toJSON(message.showPreferredFiles)
        : undefined);
    message.useComments !== undefined &&
      (obj.useComments = message.useComments
        ? SettingsEntryAsBool.toJSON(message.useComments)
        : undefined);
    message.commentVisibility !== undefined &&
      (obj.commentVisibility = message.commentVisibility
        ? SettingsEntryAsCommentVisibility.toJSON(message.commentVisibility)
        : undefined);
    message.useDisclaimer !== undefined &&
      (obj.useDisclaimer = message.useDisclaimer
        ? SettingsEntryAsBool.toJSON(message.useDisclaimer)
        : undefined);
    message.reacceptDisclaimer !== undefined &&
      (obj.reacceptDisclaimer = message.reacceptDisclaimer
        ? SettingsEntryAsBool.toJSON(message.reacceptDisclaimer)
        : undefined);
    message.disclaimerText !== undefined &&
      (obj.disclaimerText = message.disclaimerText
        ? SettingsEntryAsString.toJSON(message.disclaimerText)
        : undefined);
    message.dataroomLogo !== undefined &&
      (obj.dataroomLogo = message.dataroomLogo
        ? SettingsEntryAsUILogo.toJSON(message.dataroomLogo)
        : undefined);
    message.onUploadScaleOnly !== undefined &&
      (obj.onUploadScaleOnly = message.onUploadScaleOnly
        ? SettingsEntryAsBool.toJSON(message.onUploadScaleOnly)
        : undefined);
    message.emailLogo !== undefined &&
      (obj.emailLogo = message.emailLogo
        ? SettingsEntryAsUILogo.toJSON(message.emailLogo)
        : undefined);
    message.colorScheme !== undefined &&
      (obj.colorScheme = message.colorScheme
        ? SettingsEntryAsUIColorScheme.toJSON(message.colorScheme)
        : undefined);
    message.loginPageUrl !== undefined &&
      (obj.loginPageUrl = message.loginPageUrl
        ? SettingsEntryAsString.toJSON(message.loginPageUrl)
        : undefined);
    message.showPasswordDirective !== undefined &&
      (obj.showPasswordDirective = message.showPasswordDirective
        ? SettingsEntryAsBool.toJSON(message.showPasswordDirective)
        : undefined);
    message.allowExportDataroom !== undefined &&
      (obj.allowExportDataroom = message.allowExportDataroom
        ? SettingsEntryAsBool.toJSON(message.allowExportDataroom)
        : undefined);
    message.allowImportUsers !== undefined &&
      (obj.allowImportUsers = message.allowImportUsers
        ? SettingsEntryAsBool.toJSON(message.allowImportUsers)
        : undefined);
    message.allowTimeRestrictedAccess !== undefined &&
      (obj.allowTimeRestrictedAccess = message.allowTimeRestrictedAccess
        ? SettingsEntryAsBool.toJSON(message.allowTimeRestrictedAccess)
        : undefined);
    message.showDataroomIndex !== undefined &&
      (obj.showDataroomIndex = message.showDataroomIndex
        ? SettingsEntryAsBool.toJSON(message.showDataroomIndex)
        : undefined);
    message.showUserActivities !== undefined &&
      (obj.showUserActivities = message.showUserActivities
        ? SettingsEntryAsBool.toJSON(message.showUserActivities)
        : undefined);
    message.allowInvitationSendLater !== undefined &&
      (obj.allowInvitationSendLater = message.allowInvitationSendLater
        ? SettingsEntryAsBool.toJSON(message.allowInvitationSendLater)
        : undefined);
    message.allowInvitationSendAsLink !== undefined &&
      (obj.allowInvitationSendAsLink = message.allowInvitationSendAsLink
        ? SettingsEntryAsBool.toJSON(message.allowInvitationSendAsLink)
        : undefined);
    message.showDataroomSettings !== undefined &&
      (obj.showDataroomSettings = message.showDataroomSettings
        ? SettingsEntryAsBool.toJSON(message.showDataroomSettings)
        : undefined);
    return obj;
  },
};

function createBaseSettingsEntryAsUILogo(): SettingsEntryAsUILogo {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
  };
}

export const SettingsEntryAsUILogo = {
  fromJSON(object: any): SettingsEntryAsUILogo {
    return {
      value: isSet(object.value) ? logoTypeFromJSON(object.value) : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsUILogo): unknown {
    const obj: any = {};
    message.value !== undefined && (obj.value = logoTypeToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    return obj;
  },
};

function createBaseUIColorSchemeEntry(): UIColorSchemeEntry {
  return { value: 0, color: '' };
}

export const UIColorSchemeEntry = {
  fromJSON(object: any): UIColorSchemeEntry {
    return {
      value: isSet(object.value) ? uIColorSchemeFromJSON(object.value) : 0,
      color: isSet(object.color) ? String(object.color) : '',
    };
  },

  toJSON(message: UIColorSchemeEntry): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = uIColorSchemeToJSON(message.value));
    message.color !== undefined && (obj.color = message.color);
    return obj;
  },
};

function createBaseSettingsEntryAsUIColorScheme(): SettingsEntryAsUIColorScheme {
  return {
    value: undefined,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
  };
}

export const SettingsEntryAsUIColorScheme = {
  fromJSON(object: any): SettingsEntryAsUIColorScheme {
    return {
      value: isSet(object.value)
        ? UIColorSchemeEntry.fromJSON(object.value)
        : undefined,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsUIColorScheme): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = message.value
        ? UIColorSchemeEntry.toJSON(message.value)
        : undefined);
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    return obj;
  },
};

function createBaseUserSettings(): UserSettings {
  return {
    id: '',
    username: undefined,
    realName: undefined,
    language: undefined,
    notification: undefined,
    showWelcomeDialog: undefined,
    twoFactorType: undefined,
    themeMode: undefined,
    useMsOffice: undefined,
    receiveInfoMails: undefined,
    useManagedFiletransfer: undefined,
    createVideoConferences: undefined,
    restrictAccessFrom: undefined,
    restrictAccessTo: undefined,
    useSftp: undefined,
    unpackArchivesOnUpload: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    salutation: undefined,
    title: undefined,
    mobilePhoneNr: undefined,
    website: undefined,
    company: undefined,
    address: undefined,
    department: undefined,
    position: undefined,
    phoneNr: undefined,
    faxNr: undefined,
    useWebdav: undefined,
    useTabletApp: undefined,
    mountpoint: undefined,
    usePrivateFolder: undefined,
    labels: undefined,
    downloadProtectedPdf: undefined,
    printProtectedPdf: undefined,
    signature: undefined,
    userFeatures: undefined,
    twoFactorEnabled: undefined,
    hasPhoto: undefined,
    visibility: undefined,
    logins: undefined,
    lastLogin: undefined,
    created: undefined,
    groups: undefined,
    userGroups: undefined,
    invitationSent: undefined,
    accessContractData: undefined,
    singleSignOnId: undefined,
    singleSignOnEnabled: undefined,
    newsletter: undefined,
    endCustomerId: undefined,
    sendInvitationLater: undefined,
    sendInvitationToMeOnly: undefined,
    userActivationObserverEmail: undefined,
    costCenter: undefined,
    messageToNewUser: undefined,
    timezone: undefined,
    locale: undefined,
    userTitle: undefined,
    managedFiletransferVisibility: undefined,
    hasSharedSecret: undefined,
  };
}

export const UserSettings = {
  fromJSON(object: any): UserSettings {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      username: isSet(object.username)
        ? SettingsEntryAsString.fromJSON(object.username)
        : undefined,
      realName: isSet(object.realName)
        ? SettingsEntryAsString.fromJSON(object.realName)
        : undefined,
      language: isSet(object.language)
        ? SettingsEntryAsLanguage.fromJSON(object.language)
        : undefined,
      notification: isSet(object.notification)
        ? SettingsEntryAsUserNotification.fromJSON(object.notification)
        : undefined,
      showWelcomeDialog: isSet(object.showWelcomeDialog)
        ? SettingsEntryAsBool.fromJSON(object.showWelcomeDialog)
        : undefined,
      twoFactorType: isSet(object.twoFactorType)
        ? SettingsEntryAsTwoFactorType.fromJSON(object.twoFactorType)
        : undefined,
      themeMode: isSet(object.themeMode)
        ? SettingsEntryAsThemeMode.fromJSON(object.themeMode)
        : undefined,
      useMsOffice: isSet(object.useMsOffice)
        ? SettingsEntryAsBool.fromJSON(object.useMsOffice)
        : undefined,
      receiveInfoMails: isSet(object.receiveInfoMails)
        ? SettingsEntryAsBool.fromJSON(object.receiveInfoMails)
        : undefined,
      useManagedFiletransfer: isSet(object.useManagedFiletransfer)
        ? SettingsEntryAsBool.fromJSON(object.useManagedFiletransfer)
        : undefined,
      createVideoConferences: isSet(object.createVideoConferences)
        ? SettingsEntryAsBool.fromJSON(object.createVideoConferences)
        : undefined,
      restrictAccessFrom: isSet(object.restrictAccessFrom)
        ? SettingsEntryAsDate.fromJSON(object.restrictAccessFrom)
        : undefined,
      restrictAccessTo: isSet(object.restrictAccessTo)
        ? SettingsEntryAsDate.fromJSON(object.restrictAccessTo)
        : undefined,
      useSftp: isSet(object.useSftp)
        ? SettingsEntryAsBool.fromJSON(object.useSftp)
        : undefined,
      unpackArchivesOnUpload: isSet(object.unpackArchivesOnUpload)
        ? SettingsEntryAsBool.fromJSON(object.unpackArchivesOnUpload)
        : undefined,
      firstName: isSet(object.firstName)
        ? SettingsEntryAsString.fromJSON(object.firstName)
        : undefined,
      lastName: isSet(object.lastName)
        ? SettingsEntryAsString.fromJSON(object.lastName)
        : undefined,
      email: isSet(object.email)
        ? SettingsEntryAsString.fromJSON(object.email)
        : undefined,
      salutation: isSet(object.salutation)
        ? SettingsEntryAsSalutation.fromJSON(object.salutation)
        : undefined,
      title: isSet(object.title)
        ? SettingsEntryAsString.fromJSON(object.title)
        : undefined,
      mobilePhoneNr: isSet(object.mobilePhoneNr)
        ? SettingsEntryAsString.fromJSON(object.mobilePhoneNr)
        : undefined,
      website: isSet(object.website)
        ? SettingsEntryAsString.fromJSON(object.website)
        : undefined,
      company: isSet(object.company)
        ? SettingsEntryAsString.fromJSON(object.company)
        : undefined,
      address: isSet(object.address)
        ? UserSettingsEntryAsAddress.fromJSON(object.address)
        : undefined,
      department: isSet(object.department)
        ? SettingsEntryAsString.fromJSON(object.department)
        : undefined,
      position: isSet(object.position)
        ? SettingsEntryAsString.fromJSON(object.position)
        : undefined,
      phoneNr: isSet(object.phoneNr)
        ? SettingsEntryAsString.fromJSON(object.phoneNr)
        : undefined,
      faxNr: isSet(object.faxNr)
        ? SettingsEntryAsString.fromJSON(object.faxNr)
        : undefined,
      useWebdav: isSet(object.useWebdav)
        ? SettingsEntryAsBool.fromJSON(object.useWebdav)
        : undefined,
      useTabletApp: isSet(object.useTabletApp)
        ? SettingsEntryAsBool.fromJSON(object.useTabletApp)
        : undefined,
      mountpoint: isSet(object.mountpoint)
        ? SettingsEntryAsString.fromJSON(object.mountpoint)
        : undefined,
      usePrivateFolder: isSet(object.usePrivateFolder)
        ? SettingsEntryAsBool.fromJSON(object.usePrivateFolder)
        : undefined,
      labels: isSet(object.labels)
        ? UserSettingsEntryAsPrivateLabel.fromJSON(object.labels)
        : undefined,
      downloadProtectedPdf: isSet(object.downloadProtectedPdf)
        ? SettingsEntryAsBool.fromJSON(object.downloadProtectedPdf)
        : undefined,
      printProtectedPdf: isSet(object.printProtectedPdf)
        ? SettingsEntryAsBool.fromJSON(object.printProtectedPdf)
        : undefined,
      signature: isSet(object.signature)
        ? UserSettingsEntryAsSignature.fromJSON(object.signature)
        : undefined,
      userFeatures: isSet(object.userFeatures)
        ? UserSettingsEntryAsUserFeatures.fromJSON(object.userFeatures)
        : undefined,
      twoFactorEnabled: isSet(object.twoFactorEnabled)
        ? SettingsEntryAsBool.fromJSON(object.twoFactorEnabled)
        : undefined,
      hasPhoto: isSet(object.hasPhoto)
        ? SettingsEntryAsBool.fromJSON(object.hasPhoto)
        : undefined,
      visibility: isSet(object.visibility)
        ? SettingsEntryAsDefaultVisibility.fromJSON(object.visibility)
        : undefined,
      logins: isSet(object.logins)
        ? SettingsEntryAsNumber.fromJSON(object.logins)
        : undefined,
      lastLogin: isSet(object.lastLogin)
        ? SettingsEntryAsDate.fromJSON(object.lastLogin)
        : undefined,
      created: isSet(object.created)
        ? SettingsEntryAsDate.fromJSON(object.created)
        : undefined,
      groups: isSet(object.groups)
        ? UserSettingsEntryAsGroupMembership.fromJSON(object.groups)
        : undefined,
      userGroups: isSet(object.userGroups)
        ? UserSettingsEntryAsUserGroupMembership.fromJSON(object.userGroups)
        : undefined,
      invitationSent: isSet(object.invitationSent)
        ? SettingsEntryAsDate.fromJSON(object.invitationSent)
        : undefined,
      accessContractData: isSet(object.accessContractData)
        ? SettingsEntryAsBool.fromJSON(object.accessContractData)
        : undefined,
      singleSignOnId: isSet(object.singleSignOnId)
        ? SettingsEntryAsString.fromJSON(object.singleSignOnId)
        : undefined,
      singleSignOnEnabled: isSet(object.singleSignOnEnabled)
        ? SettingsEntryAsBool.fromJSON(object.singleSignOnEnabled)
        : undefined,
      newsletter: isSet(object.newsletter)
        ? SettingsEntryAsBool.fromJSON(object.newsletter)
        : undefined,
      endCustomerId: isSet(object.endCustomerId)
        ? SettingsEntryAsString.fromJSON(object.endCustomerId)
        : undefined,
      sendInvitationLater: isSet(object.sendInvitationLater)
        ? SettingsEntryAsBool.fromJSON(object.sendInvitationLater)
        : undefined,
      sendInvitationToMeOnly: isSet(object.sendInvitationToMeOnly)
        ? SettingsEntryAsBool.fromJSON(object.sendInvitationToMeOnly)
        : undefined,
      userActivationObserverEmail: isSet(object.userActivationObserverEmail)
        ? SettingsEntryAsString.fromJSON(object.userActivationObserverEmail)
        : undefined,
      costCenter: isSet(object.costCenter)
        ? SettingsEntryAsString.fromJSON(object.costCenter)
        : undefined,
      messageToNewUser: isSet(object.messageToNewUser)
        ? SettingsEntryAsString.fromJSON(object.messageToNewUser)
        : undefined,
      timezone: isSet(object.timezone)
        ? SettingsEntryAsString.fromJSON(object.timezone)
        : undefined,
      locale: isSet(object.locale)
        ? SettingsEntryAsLocale.fromJSON(object.locale)
        : undefined,
      userTitle: isSet(object.userTitle)
        ? SettingsEntryAsString.fromJSON(object.userTitle)
        : undefined,
      managedFiletransferVisibility: isSet(object.managedFiletransferVisibility)
        ? SettingsEntryAsManagedFiletransferVisibility.fromJSON(
            object.managedFiletransferVisibility,
          )
        : undefined,
      hasSharedSecret: isSet(object.hasSharedSecret)
        ? Boolean(object.hasSharedSecret)
        : undefined,
    };
  },

  toJSON(message: UserSettings): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.username !== undefined &&
      (obj.username = message.username
        ? SettingsEntryAsString.toJSON(message.username)
        : undefined);
    message.realName !== undefined &&
      (obj.realName = message.realName
        ? SettingsEntryAsString.toJSON(message.realName)
        : undefined);
    message.language !== undefined &&
      (obj.language = message.language
        ? SettingsEntryAsLanguage.toJSON(message.language)
        : undefined);
    message.notification !== undefined &&
      (obj.notification = message.notification
        ? SettingsEntryAsUserNotification.toJSON(message.notification)
        : undefined);
    message.showWelcomeDialog !== undefined &&
      (obj.showWelcomeDialog = message.showWelcomeDialog
        ? SettingsEntryAsBool.toJSON(message.showWelcomeDialog)
        : undefined);
    message.twoFactorType !== undefined &&
      (obj.twoFactorType = message.twoFactorType
        ? SettingsEntryAsTwoFactorType.toJSON(message.twoFactorType)
        : undefined);
    message.themeMode !== undefined &&
      (obj.themeMode = message.themeMode
        ? SettingsEntryAsThemeMode.toJSON(message.themeMode)
        : undefined);
    message.useMsOffice !== undefined &&
      (obj.useMsOffice = message.useMsOffice
        ? SettingsEntryAsBool.toJSON(message.useMsOffice)
        : undefined);
    message.receiveInfoMails !== undefined &&
      (obj.receiveInfoMails = message.receiveInfoMails
        ? SettingsEntryAsBool.toJSON(message.receiveInfoMails)
        : undefined);
    message.useManagedFiletransfer !== undefined &&
      (obj.useManagedFiletransfer = message.useManagedFiletransfer
        ? SettingsEntryAsBool.toJSON(message.useManagedFiletransfer)
        : undefined);
    message.createVideoConferences !== undefined &&
      (obj.createVideoConferences = message.createVideoConferences
        ? SettingsEntryAsBool.toJSON(message.createVideoConferences)
        : undefined);
    message.restrictAccessFrom !== undefined &&
      (obj.restrictAccessFrom = message.restrictAccessFrom
        ? SettingsEntryAsDate.toJSON(message.restrictAccessFrom)
        : undefined);
    message.restrictAccessTo !== undefined &&
      (obj.restrictAccessTo = message.restrictAccessTo
        ? SettingsEntryAsDate.toJSON(message.restrictAccessTo)
        : undefined);
    message.useSftp !== undefined &&
      (obj.useSftp = message.useSftp
        ? SettingsEntryAsBool.toJSON(message.useSftp)
        : undefined);
    message.unpackArchivesOnUpload !== undefined &&
      (obj.unpackArchivesOnUpload = message.unpackArchivesOnUpload
        ? SettingsEntryAsBool.toJSON(message.unpackArchivesOnUpload)
        : undefined);
    message.firstName !== undefined &&
      (obj.firstName = message.firstName
        ? SettingsEntryAsString.toJSON(message.firstName)
        : undefined);
    message.lastName !== undefined &&
      (obj.lastName = message.lastName
        ? SettingsEntryAsString.toJSON(message.lastName)
        : undefined);
    message.email !== undefined &&
      (obj.email = message.email
        ? SettingsEntryAsString.toJSON(message.email)
        : undefined);
    message.salutation !== undefined &&
      (obj.salutation = message.salutation
        ? SettingsEntryAsSalutation.toJSON(message.salutation)
        : undefined);
    message.title !== undefined &&
      (obj.title = message.title
        ? SettingsEntryAsString.toJSON(message.title)
        : undefined);
    message.mobilePhoneNr !== undefined &&
      (obj.mobilePhoneNr = message.mobilePhoneNr
        ? SettingsEntryAsString.toJSON(message.mobilePhoneNr)
        : undefined);
    message.website !== undefined &&
      (obj.website = message.website
        ? SettingsEntryAsString.toJSON(message.website)
        : undefined);
    message.company !== undefined &&
      (obj.company = message.company
        ? SettingsEntryAsString.toJSON(message.company)
        : undefined);
    message.address !== undefined &&
      (obj.address = message.address
        ? UserSettingsEntryAsAddress.toJSON(message.address)
        : undefined);
    message.department !== undefined &&
      (obj.department = message.department
        ? SettingsEntryAsString.toJSON(message.department)
        : undefined);
    message.position !== undefined &&
      (obj.position = message.position
        ? SettingsEntryAsString.toJSON(message.position)
        : undefined);
    message.phoneNr !== undefined &&
      (obj.phoneNr = message.phoneNr
        ? SettingsEntryAsString.toJSON(message.phoneNr)
        : undefined);
    message.faxNr !== undefined &&
      (obj.faxNr = message.faxNr
        ? SettingsEntryAsString.toJSON(message.faxNr)
        : undefined);
    message.useWebdav !== undefined &&
      (obj.useWebdav = message.useWebdav
        ? SettingsEntryAsBool.toJSON(message.useWebdav)
        : undefined);
    message.useTabletApp !== undefined &&
      (obj.useTabletApp = message.useTabletApp
        ? SettingsEntryAsBool.toJSON(message.useTabletApp)
        : undefined);
    message.mountpoint !== undefined &&
      (obj.mountpoint = message.mountpoint
        ? SettingsEntryAsString.toJSON(message.mountpoint)
        : undefined);
    message.usePrivateFolder !== undefined &&
      (obj.usePrivateFolder = message.usePrivateFolder
        ? SettingsEntryAsBool.toJSON(message.usePrivateFolder)
        : undefined);
    message.labels !== undefined &&
      (obj.labels = message.labels
        ? UserSettingsEntryAsPrivateLabel.toJSON(message.labels)
        : undefined);
    message.downloadProtectedPdf !== undefined &&
      (obj.downloadProtectedPdf = message.downloadProtectedPdf
        ? SettingsEntryAsBool.toJSON(message.downloadProtectedPdf)
        : undefined);
    message.printProtectedPdf !== undefined &&
      (obj.printProtectedPdf = message.printProtectedPdf
        ? SettingsEntryAsBool.toJSON(message.printProtectedPdf)
        : undefined);
    message.signature !== undefined &&
      (obj.signature = message.signature
        ? UserSettingsEntryAsSignature.toJSON(message.signature)
        : undefined);
    message.userFeatures !== undefined &&
      (obj.userFeatures = message.userFeatures
        ? UserSettingsEntryAsUserFeatures.toJSON(message.userFeatures)
        : undefined);
    message.twoFactorEnabled !== undefined &&
      (obj.twoFactorEnabled = message.twoFactorEnabled
        ? SettingsEntryAsBool.toJSON(message.twoFactorEnabled)
        : undefined);
    message.hasPhoto !== undefined &&
      (obj.hasPhoto = message.hasPhoto
        ? SettingsEntryAsBool.toJSON(message.hasPhoto)
        : undefined);
    message.visibility !== undefined &&
      (obj.visibility = message.visibility
        ? SettingsEntryAsDefaultVisibility.toJSON(message.visibility)
        : undefined);
    message.logins !== undefined &&
      (obj.logins = message.logins
        ? SettingsEntryAsNumber.toJSON(message.logins)
        : undefined);
    message.lastLogin !== undefined &&
      (obj.lastLogin = message.lastLogin
        ? SettingsEntryAsDate.toJSON(message.lastLogin)
        : undefined);
    message.created !== undefined &&
      (obj.created = message.created
        ? SettingsEntryAsDate.toJSON(message.created)
        : undefined);
    message.groups !== undefined &&
      (obj.groups = message.groups
        ? UserSettingsEntryAsGroupMembership.toJSON(message.groups)
        : undefined);
    message.userGroups !== undefined &&
      (obj.userGroups = message.userGroups
        ? UserSettingsEntryAsUserGroupMembership.toJSON(message.userGroups)
        : undefined);
    message.invitationSent !== undefined &&
      (obj.invitationSent = message.invitationSent
        ? SettingsEntryAsDate.toJSON(message.invitationSent)
        : undefined);
    message.accessContractData !== undefined &&
      (obj.accessContractData = message.accessContractData
        ? SettingsEntryAsBool.toJSON(message.accessContractData)
        : undefined);
    message.singleSignOnId !== undefined &&
      (obj.singleSignOnId = message.singleSignOnId
        ? SettingsEntryAsString.toJSON(message.singleSignOnId)
        : undefined);
    message.singleSignOnEnabled !== undefined &&
      (obj.singleSignOnEnabled = message.singleSignOnEnabled
        ? SettingsEntryAsBool.toJSON(message.singleSignOnEnabled)
        : undefined);
    message.newsletter !== undefined &&
      (obj.newsletter = message.newsletter
        ? SettingsEntryAsBool.toJSON(message.newsletter)
        : undefined);
    message.endCustomerId !== undefined &&
      (obj.endCustomerId = message.endCustomerId
        ? SettingsEntryAsString.toJSON(message.endCustomerId)
        : undefined);
    message.sendInvitationLater !== undefined &&
      (obj.sendInvitationLater = message.sendInvitationLater
        ? SettingsEntryAsBool.toJSON(message.sendInvitationLater)
        : undefined);
    message.sendInvitationToMeOnly !== undefined &&
      (obj.sendInvitationToMeOnly = message.sendInvitationToMeOnly
        ? SettingsEntryAsBool.toJSON(message.sendInvitationToMeOnly)
        : undefined);
    message.userActivationObserverEmail !== undefined &&
      (obj.userActivationObserverEmail = message.userActivationObserverEmail
        ? SettingsEntryAsString.toJSON(message.userActivationObserverEmail)
        : undefined);
    message.costCenter !== undefined &&
      (obj.costCenter = message.costCenter
        ? SettingsEntryAsString.toJSON(message.costCenter)
        : undefined);
    message.messageToNewUser !== undefined &&
      (obj.messageToNewUser = message.messageToNewUser
        ? SettingsEntryAsString.toJSON(message.messageToNewUser)
        : undefined);
    message.timezone !== undefined &&
      (obj.timezone = message.timezone
        ? SettingsEntryAsString.toJSON(message.timezone)
        : undefined);
    message.locale !== undefined &&
      (obj.locale = message.locale
        ? SettingsEntryAsLocale.toJSON(message.locale)
        : undefined);
    message.userTitle !== undefined &&
      (obj.userTitle = message.userTitle
        ? SettingsEntryAsString.toJSON(message.userTitle)
        : undefined);
    message.managedFiletransferVisibility !== undefined &&
      (obj.managedFiletransferVisibility = message.managedFiletransferVisibility
        ? SettingsEntryAsManagedFiletransferVisibility.toJSON(
            message.managedFiletransferVisibility,
          )
        : undefined);
    message.hasSharedSecret !== undefined &&
      (obj.hasSharedSecret = message.hasSharedSecret);
    return obj;
  },
};

function createBaseUserFeatures(): UserFeatures {
  return {
    favorites: undefined,
    newFiles: undefined,
    preferredFiles: undefined,
    lockedFiles: undefined,
    recentComments: undefined,
    expiredFiles: undefined,
    unreadFiles: undefined,
    labels: undefined,
    tobeapprovedFiles: undefined,
    inbox: undefined,
    outbox: undefined,
    tasks: undefined,
    appointments: undefined,
    videoconferences: undefined,
    discussions: undefined,
  };
}

export const UserFeatures = {
  fromJSON(object: any): UserFeatures {
    return {
      favorites: isSet(object.favorites)
        ? Boolean(object.favorites)
        : undefined,
      newFiles: isSet(object.newFiles) ? Boolean(object.newFiles) : undefined,
      preferredFiles: isSet(object.preferredFiles)
        ? Boolean(object.preferredFiles)
        : undefined,
      lockedFiles: isSet(object.lockedFiles)
        ? Boolean(object.lockedFiles)
        : undefined,
      recentComments: isSet(object.recentComments)
        ? Boolean(object.recentComments)
        : undefined,
      expiredFiles: isSet(object.expiredFiles)
        ? Boolean(object.expiredFiles)
        : undefined,
      unreadFiles: isSet(object.unreadFiles)
        ? Boolean(object.unreadFiles)
        : undefined,
      labels: isSet(object.labels) ? Boolean(object.labels) : undefined,
      tobeapprovedFiles: isSet(object.tobeapprovedFiles)
        ? Boolean(object.tobeapprovedFiles)
        : undefined,
      inbox: isSet(object.inbox) ? Boolean(object.inbox) : undefined,
      outbox: isSet(object.outbox) ? Boolean(object.outbox) : undefined,
      tasks: isSet(object.tasks) ? Boolean(object.tasks) : undefined,
      appointments: isSet(object.appointments)
        ? Boolean(object.appointments)
        : undefined,
      videoconferences: isSet(object.videoconferences)
        ? Boolean(object.videoconferences)
        : undefined,
      discussions: isSet(object.discussions)
        ? Boolean(object.discussions)
        : undefined,
    };
  },

  toJSON(message: UserFeatures): unknown {
    const obj: any = {};
    message.favorites !== undefined && (obj.favorites = message.favorites);
    message.newFiles !== undefined && (obj.newFiles = message.newFiles);
    message.preferredFiles !== undefined &&
      (obj.preferredFiles = message.preferredFiles);
    message.lockedFiles !== undefined &&
      (obj.lockedFiles = message.lockedFiles);
    message.recentComments !== undefined &&
      (obj.recentComments = message.recentComments);
    message.expiredFiles !== undefined &&
      (obj.expiredFiles = message.expiredFiles);
    message.unreadFiles !== undefined &&
      (obj.unreadFiles = message.unreadFiles);
    message.labels !== undefined && (obj.labels = message.labels);
    message.tobeapprovedFiles !== undefined &&
      (obj.tobeapprovedFiles = message.tobeapprovedFiles);
    message.inbox !== undefined && (obj.inbox = message.inbox);
    message.outbox !== undefined && (obj.outbox = message.outbox);
    message.tasks !== undefined && (obj.tasks = message.tasks);
    message.appointments !== undefined &&
      (obj.appointments = message.appointments);
    message.videoconferences !== undefined &&
      (obj.videoconferences = message.videoconferences);
    message.discussions !== undefined &&
      (obj.discussions = message.discussions);
    return obj;
  },
};

function createBaseUserSignature(): UserSignature {
  return {
    signatureEnabled: false,
    signatureFromPersonalData: false,
    signature: '',
    personalSignature: '',
  };
}

export const UserSignature = {
  fromJSON(object: any): UserSignature {
    return {
      signatureEnabled: isSet(object.signatureEnabled)
        ? Boolean(object.signatureEnabled)
        : false,
      signatureFromPersonalData: isSet(object.signatureFromPersonalData)
        ? Boolean(object.signatureFromPersonalData)
        : false,
      signature: isSet(object.signature) ? String(object.signature) : '',
      personalSignature: isSet(object.personalSignature)
        ? String(object.personalSignature)
        : '',
    };
  },

  toJSON(message: UserSignature): unknown {
    const obj: any = {};
    message.signatureEnabled !== undefined &&
      (obj.signatureEnabled = message.signatureEnabled);
    message.signatureFromPersonalData !== undefined &&
      (obj.signatureFromPersonalData = message.signatureFromPersonalData);
    message.signature !== undefined && (obj.signature = message.signature);
    message.personalSignature !== undefined &&
      (obj.personalSignature = message.personalSignature);
    return obj;
  },
};

function createBaseUserAddress(): UserAddress {
  return { country: '', city: '', zipCode: '', street: '' };
}

export const UserAddress = {
  fromJSON(object: any): UserAddress {
    return {
      country: isSet(object.country) ? String(object.country) : '',
      city: isSet(object.city) ? String(object.city) : '',
      zipCode: isSet(object.zipCode) ? String(object.zipCode) : '',
      street: isSet(object.street) ? String(object.street) : '',
    };
  },

  toJSON(message: UserAddress): unknown {
    const obj: any = {};
    message.country !== undefined && (obj.country = message.country);
    message.city !== undefined && (obj.city = message.city);
    message.zipCode !== undefined && (obj.zipCode = message.zipCode);
    message.street !== undefined && (obj.street = message.street);
    return obj;
  },
};

function createBasePrivateLabel(): PrivateLabel {
  return {
    label1: undefined,
    label2: undefined,
    label3: undefined,
    label4: undefined,
    label5: undefined,
    label6: undefined,
    label7: undefined,
  };
}

export const PrivateLabel = {
  fromJSON(object: any): PrivateLabel {
    return {
      label1: isSet(object.label1)
        ? PrivateLabelEntry.fromJSON(object.label1)
        : undefined,
      label2: isSet(object.label2)
        ? PrivateLabelEntry.fromJSON(object.label2)
        : undefined,
      label3: isSet(object.label3)
        ? PrivateLabelEntry.fromJSON(object.label3)
        : undefined,
      label4: isSet(object.label4)
        ? PrivateLabelEntry.fromJSON(object.label4)
        : undefined,
      label5: isSet(object.label5)
        ? PrivateLabelEntry.fromJSON(object.label5)
        : undefined,
      label6: isSet(object.label6)
        ? PrivateLabelEntry.fromJSON(object.label6)
        : undefined,
      label7: isSet(object.label7)
        ? PrivateLabelEntry.fromJSON(object.label7)
        : undefined,
    };
  },

  toJSON(message: PrivateLabel): unknown {
    const obj: any = {};
    message.label1 !== undefined &&
      (obj.label1 = message.label1
        ? PrivateLabelEntry.toJSON(message.label1)
        : undefined);
    message.label2 !== undefined &&
      (obj.label2 = message.label2
        ? PrivateLabelEntry.toJSON(message.label2)
        : undefined);
    message.label3 !== undefined &&
      (obj.label3 = message.label3
        ? PrivateLabelEntry.toJSON(message.label3)
        : undefined);
    message.label4 !== undefined &&
      (obj.label4 = message.label4
        ? PrivateLabelEntry.toJSON(message.label4)
        : undefined);
    message.label5 !== undefined &&
      (obj.label5 = message.label5
        ? PrivateLabelEntry.toJSON(message.label5)
        : undefined);
    message.label6 !== undefined &&
      (obj.label6 = message.label6
        ? PrivateLabelEntry.toJSON(message.label6)
        : undefined);
    message.label7 !== undefined &&
      (obj.label7 = message.label7
        ? PrivateLabelEntry.toJSON(message.label7)
        : undefined);
    return obj;
  },
};

function createBasePrivateLabelEntry(): PrivateLabelEntry {
  return { text: '', enabled: false };
}

export const PrivateLabelEntry = {
  fromJSON(object: any): PrivateLabelEntry {
    return {
      text: isSet(object.text) ? String(object.text) : '',
      enabled: isSet(object.enabled) ? Boolean(object.enabled) : false,
    };
  },

  toJSON(message: PrivateLabelEntry): unknown {
    const obj: any = {};
    message.text !== undefined && (obj.text = message.text);
    message.enabled !== undefined && (obj.enabled = message.enabled);
    return obj;
  },
};

function createBaseUserSettingsEntryAsPrivateLabel(): UserSettingsEntryAsPrivateLabel {
  return { value: undefined, editable: false };
}

export const UserSettingsEntryAsPrivateLabel = {
  fromJSON(object: any): UserSettingsEntryAsPrivateLabel {
    return {
      value: isSet(object.value)
        ? PrivateLabel.fromJSON(object.value)
        : undefined,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
    };
  },

  toJSON(message: UserSettingsEntryAsPrivateLabel): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = message.value
        ? PrivateLabel.toJSON(message.value)
        : undefined);
    message.editable !== undefined && (obj.editable = message.editable);
    return obj;
  },
};

function createBaseUserSettingsEntryAsAddress(): UserSettingsEntryAsAddress {
  return { value: undefined, editable: false };
}

export const UserSettingsEntryAsAddress = {
  fromJSON(object: any): UserSettingsEntryAsAddress {
    return {
      value: isSet(object.value)
        ? UserAddress.fromJSON(object.value)
        : undefined,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
    };
  },

  toJSON(message: UserSettingsEntryAsAddress): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = message.value
        ? UserAddress.toJSON(message.value)
        : undefined);
    message.editable !== undefined && (obj.editable = message.editable);
    return obj;
  },
};

function createBaseUserSettingsEntryAsSignature(): UserSettingsEntryAsSignature {
  return { value: undefined, editable: false };
}

export const UserSettingsEntryAsSignature = {
  fromJSON(object: any): UserSettingsEntryAsSignature {
    return {
      value: isSet(object.value)
        ? UserSignature.fromJSON(object.value)
        : undefined,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
    };
  },

  toJSON(message: UserSettingsEntryAsSignature): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = message.value
        ? UserSignature.toJSON(message.value)
        : undefined);
    message.editable !== undefined && (obj.editable = message.editable);
    return obj;
  },
};

function createBaseSettingsEntryAsString(): SettingsEntryAsString {
  return {
    value: '',
    editable: undefined,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsString = {
  fromJSON(object: any): SettingsEntryAsString {
    return {
      value: isSet(object.value) ? String(object.value) : '',
      editable: isSet(object.editable) ? Boolean(object.editable) : undefined,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsString): unknown {
    const obj: any = {};
    message.value !== undefined && (obj.value = message.value);
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsBool(): SettingsEntryAsBool {
  return {
    value: undefined,
    editable: undefined,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsBool = {
  fromJSON(object: any): SettingsEntryAsBool {
    return {
      value: isSet(object.value) ? Boolean(object.value) : undefined,
      editable: isSet(object.editable) ? Boolean(object.editable) : undefined,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsBool): unknown {
    const obj: any = {};
    message.value !== undefined && (obj.value = message.value);
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsNumber(): SettingsEntryAsNumber {
  return {
    value: 0,
    editable: undefined,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsNumber = {
  fromJSON(object: any): SettingsEntryAsNumber {
    return {
      value: isSet(object.value) ? Number(object.value) : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : undefined,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsNumber): unknown {
    const obj: any = {};
    message.value !== undefined && (obj.value = Math.round(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseSettingsEntryAsDate(): SettingsEntryAsDate {
  return {
    value: undefined,
    editable: undefined,
    fieldType: undefined,
    tooltipType: undefined,
    available: undefined,
  };
}

export const SettingsEntryAsDate = {
  fromJSON(object: any): SettingsEntryAsDate {
    return {
      value: isSet(object.value) ? fromJsonTimestamp(object.value) : undefined,
      editable: isSet(object.editable) ? Boolean(object.editable) : undefined,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
      available: isSet(object.available)
        ? Boolean(object.available)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsDate): unknown {
    const obj: any = {};
    message.value !== undefined && (obj.value = message.value.toISOString());
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    message.available !== undefined && (obj.available = message.available);
    return obj;
  },
};

function createBaseUserSettingsEntryAsUserFeatures(): UserSettingsEntryAsUserFeatures {
  return { value: undefined, editable: false };
}

export const UserSettingsEntryAsUserFeatures = {
  fromJSON(object: any): UserSettingsEntryAsUserFeatures {
    return {
      value: isSet(object.value)
        ? UserFeatures.fromJSON(object.value)
        : undefined,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
    };
  },

  toJSON(message: UserSettingsEntryAsUserFeatures): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = message.value
        ? UserFeatures.toJSON(message.value)
        : undefined);
    message.editable !== undefined && (obj.editable = message.editable);
    return obj;
  },
};

function createBaseUserSettingsEntryAsGroupMembership(): UserSettingsEntryAsGroupMembership {
  return { value: [], editable: false };
}

export const UserSettingsEntryAsGroupMembership = {
  fromJSON(object: any): UserSettingsEntryAsGroupMembership {
    return {
      value: Array.isArray(object?.value)
        ? object.value.map((e: any) => String(e))
        : [],
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
    };
  },

  toJSON(message: UserSettingsEntryAsGroupMembership): unknown {
    const obj: any = {};
    if (message.value) {
      obj.value = message.value.map(e => e);
    } else {
      obj.value = [];
    }
    message.editable !== undefined && (obj.editable = message.editable);
    return obj;
  },
};

function createBaseUserMembershipGroup(): UserMembershipGroup {
  return { id: '', title: '', name: '' };
}

export const UserMembershipGroup = {
  fromJSON(object: any): UserMembershipGroup {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      title: isSet(object.title) ? String(object.title) : '',
      name: isSet(object.name) ? String(object.name) : '',
    };
  },

  toJSON(message: UserMembershipGroup): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.title !== undefined && (obj.title = message.title);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },
};

function createBaseUserSettingsEntryAsUserGroupMembership(): UserSettingsEntryAsUserGroupMembership {
  return { value: [], editable: false };
}

export const UserSettingsEntryAsUserGroupMembership = {
  fromJSON(object: any): UserSettingsEntryAsUserGroupMembership {
    return {
      value: Array.isArray(object?.value)
        ? object.value.map((e: any) => UserMembershipGroup.fromJSON(e))
        : [],
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
    };
  },

  toJSON(message: UserSettingsEntryAsUserGroupMembership): unknown {
    const obj: any = {};
    if (message.value) {
      obj.value = message.value.map(e =>
        e ? UserMembershipGroup.toJSON(e) : undefined,
      );
    } else {
      obj.value = [];
    }
    message.editable !== undefined && (obj.editable = message.editable);
    return obj;
  },
};

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
