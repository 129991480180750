<script setup lang="ts">
import Backend, { LoginResponse } from '@/backend/Backend';
import ConfirmDialog from './components/ConfirmDialog.vue';
import getBrowserFingerprint from 'get-browser-fingerprint';
import { appVersion, gitInfo } from '@/gitinfo';
import { browserSupportsWebAuthn } from '@simplewebauthn/browser';
import { ConfirmDialogReturn } from './Types';
import { getMainLogger } from './shared/utils/logging';
import { i18n } from './lang';
import {
  isEmpty,
  isIntPhoneNumber,
  isValidUsernameOrEmail,
} from '@/shared/validation/CommonValidators';
import { LoginResult } from '@/backend/Backend';
import { nextTick, onBeforeMount, onMounted, ref, watch } from 'vue';
import {
  TestIdLoginDialog,
  TestIdLoginPasskeyDialog,
  TestIdLoginSetSecurityQuestion,
  TestIdLoginVerifyPassword,
  TestIdLoginVerifyEmail,
  TestIdLoginTwoFaTypeSms,
  TestIdLoginAcceptLoginMessage,
} from '@/shared/types/CypressIds';
import { TwoFaRequired, TwoFaType } from './shared/models/generated/two_fa';
import { useI18n } from 'vue-i18n';
import { useTheme } from 'vuetify';
import { v4 } from 'uuid';
import { VForm } from 'vuetify/lib/components/index.mjs';
import { PasswordResetOptions } from '@/shared/models/generated/settings';
import { EmailVerificationRequired } from './shared/models/generated/email_verify';
import {
  ClientRedirectResponse,
  VerifyPasskeyResponse,
  ChallangeType,
  LoginMessageRequired,
} from './shared/models/generated/auth_service';
import { VerifyPasswordResponse } from './shared/models/generated/auth_service';

const kRequiredMarker = ' *';

const loggingEndpoint =
  process.env.NODE_ENV === 'development' ? 'console' : '/';
const log = getMainLogger(loggingEndpoint, 'Auth', 3).getSubLogger({
  name: 'App',
});

enum LogoutType {
  AUTO_LOGOUT,
  PASSWORD_CHANGE,
  PASSWORD_CHECK_TIMEOUT,
}

const usernameFieldForm = ref<typeof VForm | null>(null);
const passwordFieldForm = ref<typeof VForm | null>(null);

const theme = useTheme();
const uiTheme = ref('light');
const username = ref('');
const password = ref('');
const emailVerificationCode = ref('');
const error = ref(false);
const showTwoFaError = ref(false);
const loading = ref(false);
const canLoginWithPassword = ref(false);
const hasLoginInfo = ref(false);
const hasWebAuthn = browserSupportsWebAuthn();
const shouldAskUserForPasswordless = ref(false);
const creatingPasskey = ref(false);
const passkeyRegistrationSucceeded = ref(false);
const passkeyRegistrationFailed = ref(false);
const redirecting = ref(false);
const forgotPasswordDialog = ref(false);
const forgotPasswordConfirm = ref(false);
const doEmailVerification = ref(false);
const doTwoFa = ref(false);
const twoFa = ref<null | TwoFaRequired>(null);
const emailVerification = ref<null | EmailVerificationRequired>(null);
const doPasswordCheck = ref(false);
const doSetSecurityQuestion = ref(false);
const predefinedQuestions = ref<string[]>([]);
const predefinedQuestion = ref<string>('');
const allowCustomQuestion = ref(false);
const question = ref('');
const answer = ref('');
const showVerifyPasswordError = ref(false);
const showVerifyEmailError = ref(false);
const tfaInput = ref('');
const tanSendAddress = ref('');
const messageId = ref(0);
const twoFaNewMobileEmailCode = ref('');
const twoFaNewMobileEmailCodeError = ref(false);
const mobileMissing = ref(false);
const tfaType = ref<TwoFaType>(TwoFaType.TWO_FA_TYPE_EMAIL);
const tfaOriginalType = ref<TwoFaType>(TwoFaType.TWO_FA_TYPE_EMAIL);
const confirm = ref<typeof ConfirmDialog | null>(null);
const loggedOut = ref<LogoutType | null>(null);
const usernameLocked = ref(false);
const doLoginMessage = ref(false);
const loginMessage = ref<LoginMessageRequired | null>(null);
const loginMessageCountdown = ref<null | number>(null);
const acceptLoginMessageLoading = ref<boolean>(false);

const { t } = useI18n();

async function loadSecurityQuestionSettings() {
  const settings =
    await Backend.getInstance().getSecurityQuestionRequirements(v4());
  if (settings === null) {
    return;
  }
  predefinedQuestion.value = settings.question || '';
  if (
    settings.passwordReset === PasswordResetOptions.PASSWORD_RESET_OWN_QUESTION
  ) {
    allowCustomQuestion.value = true;
    predefinedQuestions.value.push(t('securityQuestion.otherQuestion'));
  }
  predefinedQuestions.value = predefinedQuestions.value.concat(
    settings.predefinedQuestions,
  );
  if (!predefinedQuestions.value.includes(predefinedQuestion.value)) {
    predefinedQuestion.value = t('securityQuestion.otherQuestion');
  }
  void nextTick(() => {
    // avoid getting cleared by watcher
    question.value = settings.question || '';
  });
}

function obfuscateTanAddress(value: string, type: TwoFaType) {
  let start = 0;
  let end = 0;
  const valueArray = value.split('');
  if (type === TwoFaType.TWO_FA_TYPE_EMAIL) {
    start = 1;
    end = value.indexOf('@');
  }
  if (type === TwoFaType.TWO_FA_TYPE_SMS) {
    end = value.length - 3;
    start = end - 5;
  }
  for (let i = start; i < end; i++) {
    valueArray[i] = 'x';
  }
  return valueArray.join('');
}

async function handleAuthResponse(
  requestId: string,
  response:
  | ClientRedirectResponse
  | VerifyPasskeyResponse
  | VerifyPasswordResponse,
): Promise<void> {
  loading.value = false;
  if (
    response.challangeResponseData === undefined ||
    response.challangeType === undefined
  ) {
    if (
      response.tokenId === undefined ||
      response.tokenId === '' ||
      response.baseUrl === undefined ||
      response.isNetfilesClassic === undefined ||
      response.userId === undefined
    ) {
      return;
    }
    redirecting.value = true;
    await Backend.getInstance().redirectClient(
      requestId,
      response.tokenId,
      {
        baseUrl: response.baseUrl,
        isNetfilesClassic: response.isNetfilesClassic,
      },
      response.userId,
    );
    return;
  }

  switch (response.challangeType) {
    case ChallangeType.CHALLANGE_TYPE_2FA:
      if (response.challangeResponseData.twoFaRequired === undefined) {
        return;
      }
      doTwoFa.value = true;
      twoFa.value = response.challangeResponseData.twoFaRequired;
      tfaType.value = response.challangeResponseData.twoFaRequired.twoFaType;
      tfaOriginalType.value =
        response.challangeResponseData.twoFaRequired.twoFaType;
      mobileMissing.value =
        response.challangeResponseData.twoFaRequired.tanSendAddress === '';
      tanSendAddress.value =
        response.challangeResponseData.twoFaRequired.tanSendAddress || '';
      messageId.value =
        response.challangeResponseData.twoFaRequired.messageId ?? 0;
      break;
    case ChallangeType.CHALLANGE_TYPE_EMAIL_VERIFICATION:
      doEmailVerification.value = true;
      emailVerification.value =
        response.challangeResponseData.emailVerificationRequired ?? null;
      break;
    case ChallangeType.CHALLANGE_TYPE_LOGIN_MESSAGE:
      if (response.challangeResponseData.loginMessageRequired === undefined) {
        return;
      }
      doLoginMessage.value = true;
      loginMessage.value = response.challangeResponseData.loginMessageRequired;
      break;
    case ChallangeType.CHALLANGE_TYPE_PASSWORD_CHANGE:
      if (response.challangeResponseData.passwordChangeRequired === undefined) {
        return;
      }
      window.location.assign(
        response.challangeResponseData.passwordChangeRequired.link,
      );
      break;
    case ChallangeType.CHALLANGE_TYPE_PASSWORD_CHECK:
      if (response.challangeResponseData.passwordCheckRequired !== true) {
        return;
      }
      doPasswordCheck.value = true;
      break;
    case ChallangeType.CHALLANGE_TYPE_SECURITY_QUESTION:
      doSetSecurityQuestion.value = true;
      void loadSecurityQuestionSettings();
      break;
    default:
      break;
  }
}

async function performAcceptLoginMessage() {
  acceptLoginMessageLoading.value = true;
  const response = await Backend.getInstance().acceptLoginMessage(
    v4(),
    getBrowserFingerprint({ enableScreen: false }),
  );
  if (response === null) {
    acceptLoginMessageLoading.value = false;
    username.value = '';
    password.value = '';
    doSetSecurityQuestion.value = false;
    loggedOut.value = LogoutType.AUTO_LOGOUT;
    return;
  }
  await handleAuthResponse(v4(), response);
}

async function decreaseLoginMessageCount() {
  if (loginMessageCountdown.value === null) {
    return;
  }
  loginMessageCountdown.value--;

  if (loginMessageCountdown.value === 0) {
    void performAcceptLoginMessage();
    loginMessageCountdown.value = null;
    return;
  }

  void setTimeout(decreaseLoginMessageCount, 1000);
}

watch(loginMessage, () => {
  if (loginMessage.value === null) {
    loginMessageCountdown.value = null;
    return;
  }
  if (loginMessageCountdown.value === null) {
    loginMessageCountdown.value = 10;
  }
  setTimeout(decreaseLoginMessageCount, 1 * 1000);
});

async function performSetSecurityQuestion() {
  const requestId = v4();
  loading.value = true;
  const response = await Backend.getInstance().changeSecurityQuestion(
    requestId,
    question.value,
    answer.value,
  );
  loading.value = false;
  if (response === null) {
    username.value = '';
    password.value = '';
    doSetSecurityQuestion.value = false;
    loggedOut.value = LogoutType.AUTO_LOGOUT;
    return;
  }
  await handleAuthResponse(requestId, response);
}

async function verifyPassword(): Promise<void> {
  loading.value = true;
  const requestId = v4();
  const response = await Backend.getInstance().verifyPassword(
    requestId,
    password.value,
  );

  if (response === false) {
    loading.value = false;
    showVerifyPasswordError.value = true;
    return;
  }
  if (response === null) {
    loading.value = false;
    username.value = '';
    password.value = '';
    doPasswordCheck.value = false;
    loggedOut.value = LogoutType.PASSWORD_CHECK_TIMEOUT;
    return;
  }
  await handleAuthResponse(requestId, response);
}

async function verifyEmail(): Promise<void> {
  loading.value = true;
  const requestId = v4();
  const fingerprint = getBrowserFingerprint({ enableScreen: false });
  emailVerificationCode.value = emailVerificationCode.value.trim();
  const response = await Backend.getInstance().verifyEmail(
    requestId,
    emailVerificationCode.value,
    emailVerification.value?.tanId ?? '',
    fingerprint,
  );
  loading.value = false;
  if (response === false || response === null) {
    showVerifyEmailError.value = true;
    return;
  }
  doEmailVerification.value = false;
  await handleAuthResponse(requestId, response);
}

async function forgotPassword(): Promise<void> {
  error.value = false;
  loading.value = true;
  const requestId = v4();
  await Backend.getInstance().forgotPassword(requestId, username.value);
  loading.value = false;
  forgotPasswordConfirm.value = true;
}

function disableForgotPasswordButton(): boolean {
  return username.value === '';
}

// ================================================================
// Once the login process is complete (either via passkey, password,
// password + passkey-creation), we then redirect the user to the
// actual product.
//
// If 2FA is required, the server will return it here
//
// If not, our job is done here
// ================================================================
async function finishLogin(): Promise<boolean | void> {
  const requestId: string = v4();
  shouldAskUserForPasswordless.value = false;
  loading.value = true;
  creatingPasskey.value = false;
  error.value = false;

  Backend.getInstance().tokenData = null; // not needed anymore and will conflict with 2FA
  const response = await Backend.getInstance().requestClientRedirectStart(
    requestId,
    username.value,
    password.value,
    getBrowserFingerprint({ enableScreen: false }),
  );
  if (response === null) {
    error.value = true;
    loading.value = false;
    return;
  }
  await handleAuthResponse(requestId, response);
  return true;
}

// ================================================================
// Check if there are any passkeys stored on the server
// - If yes, the server will respond with a challenge that is valid
//   for 60 seconds. The user can then click on 'LOGIN WITH PASSKEY'
//   in those 60 seconds to log in.
// - If NOT, the server will return null
//
// We set a Timeout here for 50 seconds to be sure that the
// challenge is really still there on the server if we want to auth
//
// The challenge and everything else is stored in `Backend.ts`,
// ================================================================
async function getAuthorizationOptions(): Promise<void> {
  if (usernameFieldForm.value !== null) {
    usernameFieldForm.value.validate();
  }
  if (
    username.value.length < 2 ||
    isValidUsernameOrEmail(username.value) !== true
  ) {
    return;
  }
  error.value = false;
  loading.value = true;
  const requestId: string = v4();
  log.debug({ requestId, msg: 'getAuthorizationOptions' });
  const backend: Backend = Backend.getInstance();
  try {
    await backend.getAuthorizationOptions(requestId, username.value);
    log.debug({
      requestId,
      msg: 'getAuthorizationOptions',
      hasAuthOptions: backend.hasAuthorizationOptions(),
    });
  } catch (err) {
    log.debug({
      requestId,
      msg: 'getAuthorizationOptions',
      error: err,
    });
  }

  setTimeout(() => {
    loading.value = false;
    hasLoginInfo.value = backend.hasAuthorizationOptions();
    canLoginWithPassword.value = false;
    if (backend.hasAuthorizationOptions()) {
      const timeoutSeconds = backend.authorizationOptions?.ttl ?? 60;
      log.info({
        requestId,
        msg: 'getAuthorizationOptions',
        ttl: timeoutSeconds,
      });
      setTimeout(() => {
        hasLoginInfo.value = false;
      }, timeoutSeconds * 1000);
    } else {
      canLoginWithPassword.value = true;
    }
  }, 500);
}

// ================================================================
// If we have authOptions (Passkey) **and** the user clicks on
// LOGING WITH PASSKEY, we will then call `Backend.ts` to login
// using the Passkey-options it has received from the server.
//
// If it succeeds, it will have a TokenData which we then can
// use to perform some minor actions ('Start Redirect') and then
// we are done.
// ================================================================
async function loginWithPasskey(): Promise<void> {
  error.value = false;
  loading.value = true;
  const requestId: string = v4();
  log.debug({ requestId, msg: 'loginWithPasskey' });
  const backend = Backend.getInstance();
  const fingerprint = getBrowserFingerprint({ enableScreen: false });
  const response = await backend.loginWithPasskey(requestId, fingerprint);
  if (!response) {
    log.info({
      msg: 'loginWithPasskey',
      error: 'could not login with passkey',
    });
    hasLoginInfo.value = false;
    loading.value = false;
    error.value = true;
    setTimeout(() => {
      error.value = false;
    }, 3000);
    return;
  }
  loading.value = false;
  error.value = false;
  const pkvrf = backend.passkeyVerificationResponse;
  if (pkvrf === null) {
    error.value = true;
    hasLoginInfo.value = false;
    return;
  }
  await handleAuthResponse(requestId, pkvrf);
}

function canLoginWithPasskey(): boolean {
  return (
    hasWebAuthn &&
    username.value !== '' &&
    hasLoginInfo.value === true &&
    password.value === ''
  );
}

function isValidPassword(value: string): boolean | string {
  if (value.trim() === '') {
    return t('forms.validation.invalidPassword');
  }

  return true;
}

function translateRule(
  value: string,
  rule: (value: string) => boolean | string,
) {
  const result = rule(value);

  return function (): string | boolean {
    if (result === true) {
      return true;
    }

    return i18n.global.t(result);
  };
}

async function verify2fa() {
  const requestId = v4();
  loading.value = true;
  if (twoFa.value === null) {
    loading.value = false;
    return;
  }
  showTwoFaError.value = false;
  tfaInput.value = tfaInput.value.trim();
  let response;
  if (tfaType.value === TwoFaType.TWO_FA_TYPE_OTP) {
    response = await Backend.getInstance().validate2faOtp(
      requestId,
      getBrowserFingerprint({ enableScreen: false }),
      tfaInput.value,
    );
  } else {
    response = await Backend.getInstance().validate2fa(
      requestId,
      getBrowserFingerprint({ enableScreen: false }),
      twoFa.value.tanId ?? '',
      tfaInput.value,
    );
  }
  loading.value = false;

  if (response === null) {
    showTwoFaError.value = true;
    return;
  }
  await handleAuthResponse(requestId, response);
}

async function handleResendSms() {
  tfaInput.value = '';
  showTwoFaError.value = false;
  const cont = await confirm.value?.open({
    title: t('twoFaDialog.confirmTitleSms'),
    message: t('twoFaDialog.confirmTextSms'),
    cancelButton: t('general.cancel'),
    proceedButton: t('twoFaDialog.confirmProceedSms'),
  });
  if (cont === ConfirmDialogReturn.CONFIRM_DIALOG_RETURN_Cancel) {
    return;
  }
  loading.value = true;
  const response = await Backend.getInstance().generate2fa(
    v4(),
    TwoFaType.TWO_FA_TYPE_SMS,
  );
  loading.value = false;
  if (response === null) {
    error.value = true;
    return;
  }
  if (response.required) {
    doTwoFa.value = true;
    twoFa.value = response;
    tfaType.value = response.twoFaType;
    mobileMissing.value = response.tanSendAddress === '';
    tanSendAddress.value = response.tanSendAddress || '';
    messageId.value = response.messageId ?? 0;
  }
}

async function handleResend() {
  tfaInput.value = '';
  showTwoFaError.value = false;
  const cont = await confirm.value?.open({
    title: t('twoFaDialog.confirmTitle'),
    message: t('twoFaDialog.confirmText'),
    cancelButton: t('general.cancel'),
    proceedButton: t('twoFaDialog.confirmProceed'),
  });
  if (cont === ConfirmDialogReturn.CONFIRM_DIALOG_RETURN_Cancel) {
    return;
  }
  loading.value = true;
  const response = await Backend.getInstance().generate2fa(v4(), tfaType.value);
  loading.value = false;
  if (response === null) {
    error.value = true;
    return;
  }
  if (response.required) {
    doTwoFa.value = true;
    twoFa.value = response;
    tfaType.value = response.twoFaType;
    mobileMissing.value = response.tanSendAddress === '';
    tanSendAddress.value = response.tanSendAddress || '';
    messageId.value = response.messageId ?? 0;
  }
}

async function handleSaveMobile(): Promise<void> {
  if (
    tanSendAddress.value === '' ||
    isIntPhoneNumber(tanSendAddress.value) !== true ||
    twoFaNewMobileEmailCode.value === ''
  ) {
    return;
  }
  twoFaNewMobileEmailCodeError.value = false;
  showTwoFaError.value = false;
  loading.value = true;
  const twoFaRequired = await Backend.getInstance().generate2fa(
    v4(),
    tfaType.value,
    tanSendAddress.value,
    twoFaNewMobileEmailCode.value.trim(),
    twoFa.value?.tanId ?? undefined,
  );
  loading.value = false;
  if (twoFaRequired === null) {
    twoFaNewMobileEmailCodeError.value = true;
    return;
  }
  if (twoFaRequired.required) {
    doTwoFa.value = true;
    twoFa.value = twoFaRequired;
    tfaType.value = twoFaRequired.twoFaType;
    mobileMissing.value = twoFaRequired.tanSendAddress === '';
    tanSendAddress.value = twoFaRequired.tanSendAddress || '';
  }

  return;
}

// ================================================================
// If there were no passkeys on the server (no options) **OR** the
// user explicitly chose to login using password, we then log in the
// user using that method.
//
// NOTE: For now, we leave it to the user to decide whether to log in
// using Passkey or continue with password **WHEN** a Passkey exists.
//
// In the future, we should *not* allow password if a passkey exists.
//
// If the user logged in using Password because theyt didn't have
// any options, we ask them if they'd like to setup passkey-login
//
// Otherwise we just continue with out login process...
// ================================================================
async function loginWithPassword(): Promise<void> {
  if (passwordFieldForm.value === null) {
    return;
  }
  const validate = await passwordFieldForm.value.validate();
  if (!validate.valid) {
    return;
  }
  let ignorePasskeyReminder = false;
  usernameLocked.value = false;
  error.value = false;
  const requestId: string = v4();
  log.info({ requestId, msg: 'loginWithPassword' });
  const be = Backend.getInstance();
  if (username.value === '' || password.value === '') {
    return;
  }
  loading.value = true;
  const loginResponse: LoginResponse = await be.loginWithPassword(
    requestId,
    username.value,
    password.value,
  );
  if (loginResponse.ignorePasskeyReminder === true) {
    ignorePasskeyReminder = true;
  }
  const loginResult = loginResponse.result;
  loading.value = false;
  switch (loginResult) {
    case LoginResult.LoginResultFailed:
      error.value = true;
      setTimeout(() => {
        error.value = false;
      }, 3000);
      return;
    case LoginResult.LoginResultForbidden:
      error.value = false;
      loggedOut.value = null;
      usernameLocked.value = true;
      return;
    default:
      break;
  }
  shouldAskUserForPasswordless.value =
    be.isLoggedIn() &&
    !be.hasAuthorizationOptions() &&
    hasWebAuthn &&
    !ignorePasskeyReminder;
  if (!shouldAskUserForPasswordless.value) {
    await finishLogin();
  }
}

async function enablePasswordLogin(): Promise<void> {
  canLoginWithPassword.value = true;
}

// ================================================================
// If the user logged in using Password because there was no passkey
// **and** they agreed to create one, we do so here.
// ================================================================
async function createPasskey(): Promise<void> {
  error.value = false;
  loading.value = false;
  shouldAskUserForPasswordless.value = false;
  creatingPasskey.value = true;
  const requestId: string = v4();
  log.info({ requestId, msg: 'createPasskey' });
  if (await Backend.getInstance().registerPasskey(requestId)) {
    passkeyRegistrationSucceeded.value = true;
  } else {
    passkeyRegistrationFailed.value = true;
  }
}

// ================================================================
// Auxiliary/helper functions
// ================================================================
function disableLoginButton(): boolean {
  return username.value === '' || password.value === '';
}

function passkeyRegistrationIcon(): string {
  if (
    passkeyRegistrationSucceeded.value === false &&
    passkeyRegistrationFailed.value === false
  ) {
    return 'fak fa-light-lock-keyhole-clock';
  } else if (passkeyRegistrationSucceeded.value === true) {
    return 'fak fa-light-lock-keyhole-circle-check';
  } else {
    return 'fak fa-light-lock-keyhole-circle-minus';
  }
}

function enableForwardIcon(): boolean {
  if (username.value === '' || username.value.length < 2) {
    hasLoginInfo.value = false;
  }
  return (
    username.value !== '' &&
    username.value.length >= 2 &&
    !canLoginWithPasskey() &&
    !canLoginWithPassword.value &&
    disableLoginButton()
  );
}

async function performTab(event: Event): Promise<boolean> {
  if (!hasLoginInfo.value && !canLoginWithPassword.value) {
    event.preventDefault();
    void getAuthorizationOptions();
  }
  return true;
}

function disableSubmitButton(): boolean {
  return answer.value === '' || question.value === '';
}

async function ignorePasskeyReminderAndLogin() {
  const requestId: string = v4();
  void Backend.getInstance().ignorePasskeyReminder(requestId);

  await finishLogin();
}

async function goHome() {
  window.open(t('footer.url.url'), '_blank');
}

async function goTac() {
  window.open(t('footer.tac.url'), '_blank');
}

async function goPrivacy() {
  window.open(t('footer.privacy.url'), '_blank');
}

async function goImprint() {
  window.open(t('footer.imprint.url'), '_blank');
}

async function goHelp() {
  window.open(t('footer.help.url'), '_blank');
}

// ================================================================
// Vuetify
// ================================================================
onBeforeMount(() => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  if (!urlParams.has('tid')) {
    window.location.assign('https://netfiles.de/');
    return;
  }

  Backend.initBackend();
});

onMounted(async () => {
  document.title =
    (t('general.appName') as string) + ': ' + (t('login.login') as string);

  uiTheme.value = window.matchMedia('(prefers-color-scheme:dark)').matches
    ? 'dark'
    : 'light';

  theme.global.name.value = uiTheme.value;
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has('reset')) {
    forgotPasswordDialog.value = true;
    if (searchParams.get('reset') !== 'true') {
      username.value = searchParams.get('reset') || '';
    }
  }
  if (searchParams.has('loggedout')) {
    loggedOut.value = LogoutType.AUTO_LOGOUT;
  } else if (searchParams.has('passwordchange')) {
    loggedOut.value = LogoutType.PASSWORD_CHANGE;
  } else {
    loggedOut.value = null;
  }
  // prevent zooming on iOS
  if (navigator.userAgent.indexOf('iPhone') > -1) {
    document
      .querySelector('[name=viewport]')
      ?.setAttribute(
        'content',
        'width=device-width, initial-scale=1, maximum-scale=1',
      );
  }
});

watch(predefinedQuestion, newQuestion => {
  if (newQuestion !== t('securityQuestion.otherQuestion')) {
    question.value = newQuestion;
  } else {
    question.value = '';
  }
});

onMounted(() => {});
</script>

<template>
  <confirm-dialog ref="confirm"></confirm-dialog>
  <v-app class="nf-font">
    <v-main class="nf-alternate-background">
      <div class="logo">
        <v-img
          v-if="uiTheme === 'light'"
          left
          alt="Netfiles Logo"
          class="shrink"
          @click="goHome"
          src="./assets/netfiles_logo.svg"
          transition="scale-transition"
          max-width="164"
        />
        <v-img
          v-else
          left
          @click="goHome"
          alt="Netfiles Logo"
          class="shrink"
          src="./assets/netfiles_logo_white.svg"
          transition="scale-transition"
          max-width="164"
        />
      </div>
      <v-dialog v-model="doLoginMessage" width="400" persistent>
        <v-card class="pa-2">
          <v-card-text class="pa-2">
            <v-container>
              <v-row>
                <v-col class="text-center">
                  <div v-html="loginMessage?.text"></div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mt-2"
              rounded="lg"
              variant="flat"
              color="primary"
              :loading="acceptLoginMessageLoading"
              @click="performAcceptLoginMessage"
              ><template #default>
                <span
                  :data-cy="
                    TestIdLoginAcceptLoginMessage.TEST_ID_LOGIN_ACCEPT_LOGIN_MESSAGE_Continue
                  "
                  class="text-white"
                  >{{ t('general.continue') }} ({{
                    loginMessageCountdown
                  }})</span
                >
              </template></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="creatingPasskey" max-width="400" persistent>
        <v-card class="pa-2">
          <v-card-item class="mb-4">
            <v-card-title>
              <v-icon
                start
                size="large"
                :icon="passkeyRegistrationIcon()"
                class="mr-3"
              ></v-icon>
              {{ $t('passkeyDialog.title') }}
            </v-card-title>
          </v-card-item>
          <v-card-text class="pa-2">
            <v-container>
              <v-row>
                <v-col>
                  <div
                    v-if="
                      !passkeyRegistrationSucceeded &&
                      !passkeyRegistrationFailed
                    "
                  >
                    {{ $t('passkeyDialog.messageWorking') }}
                  </div>
                  <div v-if="passkeyRegistrationSucceeded">
                    {{ $t('passkeyDialog.messageSucceeded') }}
                  </div>
                  <div v-if="passkeyRegistrationFailed">
                    {{ $t('passkeyDialog.messageFailed') }}
                  </div>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  !passkeyRegistrationSucceeded && !passkeyRegistrationFailed
                "
              >
                <v-col class="text-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="mt-2"
              rounded="lg"
              :loading="loading"
              variant="flat"
              color="primary"
              @click="finishLogin"
              ><span class="text-white">{{
                $t('passkeyDialog.dismiss')
              }}</span></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="shouldAskUserForPasswordless"
        max-width="600"
        persistent
      >
        <v-card class="pa-2">
          <v-card-item class="mb-4">
            <v-card-title
              :data-cy="
                TestIdLoginPasskeyDialog.TEST_ID_LOGIN_PASSKEY_DIALOG_Title
              "
            >
              <v-icon
                start
                size="large"
                icon="fak fa-light-lock-keyhole-open-circle-check"
                class="mr-3"
              ></v-icon>
              {{ $t('authDialog.enablePasskeyTitle') }}
            </v-card-title>
          </v-card-item>

          <v-card-text
            :data-cy="
              TestIdLoginPasskeyDialog.TEST_ID_LOGIN_PASSKEY_DIALOG_Message
            "
          >
            {{ $t('authDialog.enablePasskeyMessage') }}
            <br />
            <br />
            {{ $t('authDialog.enablePasskeyExplanation') }}
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              rounded="lg"
              variant="tonal"
              @click="ignorePasskeyReminderAndLogin"
              :data-cy="
                TestIdLoginPasskeyDialog.TEST_ID_LOGIN_PASSKEY_DIALOG_NotEnablePasskeyButton
              "
              >{{ $t('authDialog.doNotEnablePasskeyButton') }}</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              class="mt-2"
              rounded="lg"
              variant="flat"
              color="primary"
              @click="createPasskey"
              :data-cy="
                TestIdLoginPasskeyDialog.TEST_ID_LOGIN_PASSKEY_DIALOG_EnablePasskeyButton
              "
              ><span class="text-white">{{
                $t('authDialog.enablePasskeyButton')
              }}</span></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-container fill-height fluid class="px-0">
        <v-form
          v-if="forgotPasswordDialog"
          @submit.prevent
          @keyup.enter="forgotPassword"
          class="my-16 py-16"
        >
          <v-container>
            <v-card
              class="mx-auto pt-4 px-0 mx-0 nf-alternate-card"
              max-width="600"
              rounded="lg"
              elevation="1"
            >
              <v-card-title class="px-5 px-md-10">
                {{ $t('login.forgotPassword') }}
              </v-card-title>
              <div
                v-if="forgotPasswordConfirm"
                class="mx-6 my-8 pt-4 px-2 px-md-8"
              >
                <v-row class="py-0">
                  <v-col class="text-centers"
                    ><h3>
                      {{ $t('login.forgotPasswordConfirmTitle') }}
                    </h3></v-col
                  >
                </v-row>
                <v-row class="py-0">
                  <v-col class="text-centers mb-3">{{
                    $t('login.forgotPasswordConfirmText')
                  }}</v-col>
                </v-row>
              </div>
              <div v-else class="mx-6 my-8 pt-4 px-2 px-md-8">
                <v-row>
                  <v-text-field
                    v-model="username"
                    name="username"
                    id="username"
                    :label="$t('login.usernameEmail')"
                    required
                    validate-on="submit"
                    :rules="[translateRule(username, isValidUsernameOrEmail)]"
                    autocomplete="on"
                    autofocus
                    :data-cy="
                      TestIdLoginDialog.TEST_ID_LOGIN_DIALOG_FormField_Username
                    "
                  ></v-text-field>
                </v-row>
              </div>
              <v-card-actions v-if="!forgotPasswordConfirm">
                <v-spacer></v-spacer>
                <div class="px-2 px-md-8 pb-4">
                  <v-btn
                    @click="forgotPassword"
                    rounded="lg"
                    variant="flat"
                    :loading="loading"
                    color="primary"
                    :disabled="disableForgotPasswordButton()"
                    :data-cy="
                      TestIdLoginDialog.TEST_ID_LOGIN_DIALOG_ForgotPassword
                    "
                  >
                    <span class="text-white">
                      {{ $t('login.resetPassword') }}
                    </span>
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-container>
        </v-form>
        <div v-else class="my-16 py-16">
          <v-container>
            <v-card
              class="mx-auto py-6 px-0 mx-0"
              max-width="600"
              rounded="lg"
              elevation="1"
            >
              <v-toolbar
                v-if="loggedOut !== null || usernameLocked"
                :class="{
                  'ml-0': true,
                  'background-error': usernameLocked,
                  'background-info': loggedOut !== null,
                }"
                height="72"
              >
                <v-toolbar-title
                  class="ml-0 info-toolbar text-body text-text-primary h-100"
                >
                  <div
                    style="height: 72px !important; white-space: normal"
                    class="info d-flex pl-1 pr-4 py-5 align-center h-100"
                  >
                    <div class="px-5 d-flex">
                      <v-icon
                        v-if="usernameLocked"
                        style="font-size: 24px !important"
                        icon="fal fa-circle-exclamation"
                        color="primary"
                      ></v-icon>
                      <v-icon
                        v-else
                        style="font-size: 24px !important"
                        icon="fal fa-circle-info"
                      ></v-icon>
                    </div>
                    <div v-if="loggedOut === LogoutType.AUTO_LOGOUT">
                      {{ $t('login.autoLogoutMessage') }}
                    </div>
                    <div v-else-if="loggedOut === LogoutType.PASSWORD_CHANGE">
                      {{ $t('login.autoLogoutPasswordChangeMessage') }}
                    </div>
                    <div
                      v-else-if="
                        loggedOut === LogoutType.PASSWORD_CHECK_TIMEOUT
                      "
                    >
                      {{ $t('login.autoLogoutPasswordCheckTimeoutMessage') }}
                    </div>
                    <div
                      v-else-if="usernameLocked"
                      class="text-wrap text-body-2"
                    >
                      {{ $t('login.accountLocked') }}
                    </div>
                  </div>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-title class="px-5 px-md-10 mt-3">
                {{ $t('login.login') }}
              </v-card-title>
              <div
                v-if="!redirecting && doSetSecurityQuestion"
                class="mx-6 mt-4 mb-2 pt-4 px-2 px-md-8"
              >
                <div class="pa-2">
                  {{ $t('securityQuestion.securityQuestionText') }}
                </div>
                <v-select
                  :items="predefinedQuestions"
                  v-model="predefinedQuestion"
                  :data-cy="
                    TestIdLoginSetSecurityQuestion.TEST_ID_LOGIN_SET_SECURITY_QUESTION_Select
                  "
                ></v-select>
                <v-text-field
                  v-model="question"
                  :label="$t('securityQuestion.question') + kRequiredMarker"
                  dense
                  outlined
                  autofocus
                  required
                  autocomplete="new-password"
                  :disabled="!allowCustomQuestion"
                  :readonly="
                    predefinedQuestion !== t('securityQuestion.otherQuestion')
                  "
                  :data-cy="
                    TestIdLoginSetSecurityQuestion.TEST_ID_LOGIN_SET_SECURITY_QUESTION_Question
                  "
                  @update:model-value="error = false"
                ></v-text-field>
                <v-text-field
                  v-model="answer"
                  :label="$t('securityQuestion.answer') + kRequiredMarker"
                  dense
                  outlined
                  autofocus
                  required
                  autocomplete="off"
                  @update:model-value="error = false"
                  :data-cy="
                    TestIdLoginSetSecurityQuestion.TEST_ID_LOGIN_SET_SECURITY_QUESTION_answer
                  "
                ></v-text-field>
              </div>
              <div
                v-else-if="!redirecting && doEmailVerification"
                class="mx-6 mt-4 mb-2 pt-4 px-2 px-md-8"
              >
                <v-row class="pb-3">
                  {{ $t('login.verifyEmailMessage') }}
                </v-row>
                <v-row class="pb-3">
                  {{ $t('login.verifyEmailMessageSecond') }}
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="emailVerificationCode"
                    :label="$t('login.emailVerificationCode')"
                    @keyup.enter="verifyEmail"
                    autofocus
                    required
                    type="text"
                    autocomplete="off"
                    :data-cy="
                      TestIdLoginVerifyEmail.TEST_ID_LOGIN_VERIFY_EMAIL_Code
                    "
                  ></v-text-field>
                </v-row>
                <v-row v-if="showVerifyEmailError" class="py-0">
                  <v-col class="text-center text-red">{{
                    $t('login.verifyEmailError')
                  }}</v-col>
                </v-row>
              </div>
              <div
                v-else-if="!redirecting && doPasswordCheck"
                class="mx-6 mt-4 mb-2 pt-4 px-2 px-md-8"
              >
                <v-row class="pb-3">
                  {{ $t('login.verifyPasswordMessage') }}
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="password"
                    :label="$t('login.password')"
                    @keyup.enter="verifyPassword"
                    autofocus
                    required
                    type="password"
                    autocomplete="off"
                    :data-cy="
                      TestIdLoginVerifyPassword.TEST_ID_LOGIN_VERIFY_PASSWORD_Password
                    "
                  ></v-text-field>
                </v-row>
                <v-row v-if="showVerifyPasswordError" class="py-0">
                  <v-col class="text-center text-red">{{
                    $t('login.verifyPasswordError')
                  }}</v-col>
                </v-row>
              </div>
              <div
                v-else-if="
                  !redirecting &&
                  doTwoFa &&
                  tfaType === TwoFaType.TWO_FA_TYPE_SMS &&
                  mobileMissing
                "
                class="mx-6 mt-4 mb-2 pt-4 px-2 px-md-8"
              >
                <v-row class="pb-3">
                  {{ $t('twoFaDialog.setMobile.title') }}
                </v-row>
                <v-row class="pb-3">
                  {{ $t('twoFaDialog.setMobile.text_1') }}
                </v-row>
                <v-row class="pb-3">
                  {{ $t('twoFaDialog.setMobile.text_2') }}
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="tanSendAddress"
                    :label="$t('twoFaDialog.mobilePhoneNr')"
                    @keyup.enter="handleSaveMobile"
                    :rules="[translateRule(tanSendAddress, isIntPhoneNumber)]"
                    :error="showTwoFaError"
                    :error-messages="
                      showTwoFaError
                        ? [$t('twoFaDialog.mobilePhoneNrError')]
                        : undefined
                    "
                    required
                    autocomplete="off"
                    autofocus
                    :data-cy="
                      TestIdLoginTwoFaTypeSms.TEST_ID_LOGIN_TWO_FA_TYPE_SMS_Address
                    "
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="twoFaNewMobileEmailCode"
                    :label="$t('twoFaDialog.securityCode')"
                    @keyup.enter="handleSaveMobile"
                    :rules="[translateRule(twoFaNewMobileEmailCode, isEmpty)]"
                    :error="twoFaNewMobileEmailCodeError"
                    :error-messages="
                      twoFaNewMobileEmailCodeError
                        ? [$t('twoFaDialog.errorMessage')]
                        : undefined
                    "
                    required
                    autocomplete="off"
                    :data-cy="
                      TestIdLoginTwoFaTypeSms.TEST_ID_LOGIN_TWO_FA_TYPE_SMS_Email_code
                    "
                  ></v-text-field>
                </v-row>
              </div>
              <div
                v-else-if="!redirecting && doTwoFa"
                class="mx-6 mt-4 mb-2 pt-4 px-2 px-md-8"
              >
                <v-row class="pb-3">
                  {{
                    $t('twoFaDialog.text_' + tfaType, {
                      tanSendAddress: obfuscateTanAddress(
                        tanSendAddress,
                        tfaType,
                      ),
                      messageId: messageId.toString(),
                    })
                  }}
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="tfaInput"
                    :label="$t('twoFaDialog.securityCode')"
                    @keyup.enter="verify2fa"
                    :error="showTwoFaError"
                    :error-messages="
                      showTwoFaError
                        ? [$t('twoFaDialog.errorMessage')]
                        : undefined
                    "
                    @update:model-value="showTwoFaError = false"
                    autofocus
                    required
                    autocomplete="off"
                    :data-cy="
                      TestIdLoginTwoFaTypeSms.TEST_ID_LOGIN_TWO_FA_TYPE_SMS_Code
                    "
                  ></v-text-field>
                </v-row>
              </div>
              <div
                v-else-if="!redirecting"
                class="mx-6 mt-4 mb-2 pt-4 px-2 px-md-8"
              >
                <v-row>
                  <v-form
                    ref="usernameFieldForm"
                    class="w-100"
                    @submit.prevent
                    @keydown.tab="performTab"
                    @keyup.enter="
                      canLoginWithPasskey()
                        ? loginWithPasskey()
                        : canLoginWithPassword
                          ? loginWithPassword()
                          : getAuthorizationOptions()
                    "
                  >
                    <v-text-field
                      @update:model-value="usernameFieldForm?.resetValidation()"
                      v-model="username"
                      name="username"
                      id="username"
                      :label="$t('login.usernameEmail')"
                      :rules="[translateRule(username, isValidUsernameOrEmail)]"
                      autofocus
                      autocomplete="username"
                      :readonly="creatingPasskey"
                      required
                      validate-on="submit"
                      :data-cy="
                        TestIdLoginDialog.TEST_ID_LOGIN_DIALOG_FormField_Username
                      "
                    >
                      <template v-if="enableForwardIcon()" #append-inner>
                        <v-btn
                          @click="getAuthorizationOptions()"
                          variant="plain"
                          :loading="loading"
                          icon="fal fa-circle-arrow-right"
                        ></v-btn> </template
                    ></v-text-field>
                  </v-form>
                </v-row>
                <v-row v-if="canLoginWithPassword">
                  <v-form
                    ref="passwordFieldForm"
                    class="w-100"
                    @submit.prevent
                    @keyup.enter="loginWithPassword()"
                  >
                    <v-text-field
                      v-model="password"
                      @update:model-value="passwordFieldForm?.resetValidation()"
                      :label="$t('login.password')"
                      :rules="[isValidPassword]"
                      :readonly="creatingPasskey"
                      required
                      autofocus
                      autocomplete="off"
                      type="password"
                      :data-cy="
                        TestIdLoginDialog.TEST_ID_LOGIN_DIALOG_FormField_Password
                      "
                    ></v-text-field>
                  </v-form>
                </v-row>
                <v-row v-if="error" class="py-0">
                  <v-col class="text-center text-red">{{
                    $t('login.loginError')
                  }}</v-col>
                </v-row>
              </div>
              <div
                v-else
                class="my-6 mt-4 mb-2 py-8 px-2 px-md-8 text-h5 text-center"
              >
                <v-row>
                  <v-col center>
                    {{ $t('login.redirecting') }}
                  </v-col>
                </v-row>
              </div>
              <v-card-actions
                class="mx-2 px-2 px-md-8 d-flex flex-wrap-reverse justify-end flex-gap-20-32"
                v-if="!redirecting && doEmailVerification"
              >
                <v-btn
                  @click="verifyEmail"
                  rounded="lg"
                  :loading="loading"
                  variant="flat"
                  color="primary"
                  :disabled="emailVerificationCode === ''"
                  class="px-2 px-md-8 mr-1"
                  :data-cy="TestIdLoginDialog.TEST_ID_LOGIN_DIALOG_LoginButton"
                >
                  <span class="text-white">{{ t('login.verifyEmail') }}</span>
                </v-btn>
              </v-card-actions>
              <v-card-actions
                class="mx-2 px-2 px-md-8 d-flex flex-wrap-reverse justify-end flex-gap-20-32"
                v-else-if="!redirecting && doSetSecurityQuestion"
              >
                <v-btn
                  @click="performSetSecurityQuestion"
                  rounded="lg"
                  :loading="loading"
                  variant="flat"
                  color="primary"
                  :disabled="disableSubmitButton()"
                  class="px-2 px-md-8 mr-1"
                  :data-cy="TestIdLoginDialog.TEST_ID_LOGIN_DIALOG_LoginButton"
                  ><span class="text-white">
                    {{ t('securityQuestion.setSecurityQuestion') }}</span
                  >
                </v-btn>
              </v-card-actions>
              <v-card-actions
                v-else-if="!redirecting && doPasswordCheck"
                class="mx-2 px-2 px-md-8 d-flex flex-wrap-reverse justify-end flex-gap-20-32"
              >
                <v-btn
                  @click="verifyPassword"
                  rounded="lg"
                  :loading="loading"
                  variant="flat"
                  color="primary"
                  class="px-2 px-md-8 mr-1"
                  :data-cy="TestIdLoginDialog.TEST_ID_LOGIN_DIALOG_LoginButton"
                >
                  <template v-slot:prepend>
                    <v-icon
                      icon="fak fa-light-lock-keyhole-circle-check"
                      size="small"
                      color="white"
                    />
                  </template>
                  <span class="text-white">{{
                    t('checkPassword.checkPassword')
                  }}</span>
                </v-btn>
              </v-card-actions>
              <v-card-actions
                v-else-if="
                  !redirecting &&
                  canLoginWithPasskey() &&
                  !canLoginWithPassword &&
                  !doTwoFa
                "
                class="mx-2 px-2 px-md-8 d-flex flex-wrap-reverse justify-end flex-gap-20-32"
              >
                <v-spacer></v-spacer>
                <v-btn
                  variant="text"
                  class="mr-1 text-body-2"
                  @click="enablePasswordLogin"
                >
                  {{ $t('login.loginWithPassword') }}
                </v-btn>
                <v-btn
                  v-if="canLoginWithPasskey()"
                  @click="loginWithPasskey"
                  rounded="lg"
                  variant="flat"
                  color="primary"
                  class="px-2 px-md-8 mr-1"
                  :loading="loading"
                  :data-cy="TestIdLoginDialog.TEST_ID_LOGIN_DIALOG_LoginButton"
                >
                  <template v-slot:prepend>
                    <v-icon
                      icon="fak fa-light-lock-keyhole-circle-check"
                      size="small"
                      color="white"
                    />
                  </template>
                  <span class="text-white">
                    {{ $t('login.loginWithPasskey') }}</span
                  >
                </v-btn>
              </v-card-actions>
              <v-card-actions
                v-else-if="!redirecting && doTwoFa"
                class="mx-2 px-2 px-md-8 d-flex flex-wrap-reverse justify-end flex-gap-20-32"
              >
                <v-spacer></v-spacer>
                <span
                  class="text-caption"
                  v-if="
                    !(tfaType === TwoFaType.TWO_FA_TYPE_SMS && mobileMissing)
                  "
                >
                  <a
                    @click.prevent="handleResend"
                    href="#"
                    v-if="tfaType !== TwoFaType.TWO_FA_TYPE_OTP"
                    >{{ $t('twoFaDialog.confirmTitle') }}</a
                  >
                  <a @click.prevent="handleResendSms" href="#" v-else>{{
                    $t('twoFaDialog.confirmTitleSms')
                  }}</a>
                </span>
                <v-btn
                  rounded="lg"
                  variant="flat"
                  color="primary"
                  class="px-2 px-md-8"
                  :loading="loading"
                  :disabled="
                    isEmpty(twoFaNewMobileEmailCode) !== true ||
                    isIntPhoneNumber(tanSendAddress) !== true
                  "
                  @click="handleSaveMobile"
                  v-if="tfaType === TwoFaType.TWO_FA_TYPE_SMS && mobileMissing"
                  :data-cy="
                    TestIdLoginTwoFaTypeSms.TEST_ID_LOGIN_TWO_FA_TYPE_SMS_MOBILE_Save
                  "
                >
                  <span class="text-white">{{
                    $t('twoFaDialog.saveMobile')
                  }}</span>
                </v-btn>
                <v-btn
                  rounded="lg"
                  variant="flat"
                  color="primary"
                  class="px-2 px-md-8"
                  :loading="loading"
                  @click="verify2fa"
                  v-else
                >
                  <span class="text-white">{{ $t('twoFaDialog.title') }}</span>
                </v-btn>
              </v-card-actions>
              <v-card-actions
                v-else-if="!redirecting && canLoginWithPassword && !doTwoFa"
                class="mx-2 px-2 px-md-8 d-flex flex-wrap-reverse justify-end flex-gap-20-32"
              >
                <v-spacer></v-spacer>
                <span class="text-caption">
                  <a href="" @click.prevent="forgotPasswordDialog = true">{{
                    $t('login.forgotPassword')
                  }}</a>
                </span>
                <v-btn
                  @click="loginWithPassword"
                  rounded="lg"
                  variant="flat"
                  color="primary"
                  :loading="loading"
                  class="px-2 px-md-8"
                  :disabled="disableLoginButton()"
                  :data-cy="TestIdLoginDialog.TEST_ID_LOGIN_DIALOG_LoginButton"
                >
                  <span class="text-white">{{ $t('login.login') }}</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-container>
        </div>
      </v-container>
    </v-main>
    <v-footer elevation="0" app>
      <v-row justify="center" no-gutters>
        <!-- vvvv don't ever touch this here -->
        <v-icon
          icon="fal fa-face-smile-hearts"
          size="x-small"
          color="background"
        />
        <!-- ^^^^ don't ever touch this here -->
        <v-btn variant="plain" @click.stop="goHome">
          {{ $t('footer.url.title') }}
          <v-tooltip activator="parent" location="bottom">
            <div class="text-caption version">
              U:{{ appVersion }}@{{ gitInfo.hash }}
            </div>
          </v-tooltip>
        </v-btn>
        <v-btn variant="plain" @click.stop="goTac">{{
          $t('footer.tac.title')
        }}</v-btn>
        <v-btn variant="plain" @click.stop="goPrivacy">{{
          $t('footer.privacy.title')
        }}</v-btn>
        <v-btn variant="plain" @click.stop="goImprint">{{
          $t('footer.imprint.title')
        }}</v-btn>
        <v-btn variant="plain" @click.stop="goHelp">{{
          $t('footer.help.title')
        }}</v-btn>
        <!-- vvvv don't ever touch this here -->
        <v-icon
          icon="fal fa-face-smile-hearts"
          size="x-small"
          color="background"
        />
        <!-- ^^^^ don't ever touch this here -->
      </v-row>
    </v-footer>
  </v-app>
</template>

<style lang="scss">
@import '@/styles/nfstyles.scss';
</style>
<style>
@import '@/styles/font.css';

.flex-gap-20-32 {
  gap: 20px 32px;
}
.v-field--appended {
  padding-right: 0px !important;
}
</style>
<style scoped>
.logo {
  position: absolute;

  top: 25px;
  left: 35px;
  width: 100%;
}
</style>
<style lang="scss" scoped>
.v-card {
  padding-top: 0px !important;
}
.text-h6 {
  font-family: 'NF Sans' !important;
}
</style>
