/* eslint-disable */
export const protobufPackage = 'de.netfiles.pb';

/** Two-FA Type Enum */
export enum TwoFaType {
  TWO_FA_TYPE_UNSPECIFIED = 0,
  TWO_FA_TYPE_SMS = 1,
  TWO_FA_TYPE_EMAIL = 2,
  TWO_FA_TYPE_OTP = 3,
  UNRECOGNIZED = -1,
}

export function twoFaTypeFromJSON(object: any): TwoFaType {
  switch (object) {
    case 0:
    case 'TWO_FA_TYPE_UNSPECIFIED':
      return TwoFaType.TWO_FA_TYPE_UNSPECIFIED;
    case 1:
    case 'TWO_FA_TYPE_SMS':
      return TwoFaType.TWO_FA_TYPE_SMS;
    case 2:
    case 'TWO_FA_TYPE_EMAIL':
      return TwoFaType.TWO_FA_TYPE_EMAIL;
    case 3:
    case 'TWO_FA_TYPE_OTP':
      return TwoFaType.TWO_FA_TYPE_OTP;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return TwoFaType.UNRECOGNIZED;
  }
}

export function twoFaTypeToJSON(object: TwoFaType): string {
  switch (object) {
    case TwoFaType.TWO_FA_TYPE_UNSPECIFIED:
      return 'TWO_FA_TYPE_UNSPECIFIED';
    case TwoFaType.TWO_FA_TYPE_SMS:
      return 'TWO_FA_TYPE_SMS';
    case TwoFaType.TWO_FA_TYPE_EMAIL:
      return 'TWO_FA_TYPE_EMAIL';
    case TwoFaType.TWO_FA_TYPE_OTP:
      return 'TWO_FA_TYPE_OTP';
    default:
      return 'UNKNOWN';
  }
}

/** 2fa message */
export interface TwoFaInfo {
  twoFaType: TwoFaType;
  tan: string;
  tanId?: string | undefined;
}

/** If two-fa required */
export interface TwoFaRequired {
  required: boolean;
  twoFaType: TwoFaType;
  tanSendAddress?: string | undefined;
  tanId?: string | undefined;
  messageId?: number | undefined;
}

function createBaseTwoFaInfo(): TwoFaInfo {
  return { twoFaType: 0, tan: '', tanId: undefined };
}

export const TwoFaInfo = {
  fromJSON(object: any): TwoFaInfo {
    return {
      twoFaType: isSet(object.twoFaType)
        ? twoFaTypeFromJSON(object.twoFaType)
        : 0,
      tan: isSet(object.tan) ? String(object.tan) : '',
      tanId: isSet(object.tanId) ? String(object.tanId) : undefined,
    };
  },

  toJSON(message: TwoFaInfo): unknown {
    const obj: any = {};
    message.twoFaType !== undefined &&
      (obj.twoFaType = twoFaTypeToJSON(message.twoFaType));
    message.tan !== undefined && (obj.tan = message.tan);
    message.tanId !== undefined && (obj.tanId = message.tanId);
    return obj;
  },
};

function createBaseTwoFaRequired(): TwoFaRequired {
  return {
    required: false,
    twoFaType: 0,
    tanSendAddress: undefined,
    tanId: undefined,
    messageId: undefined,
  };
}

export const TwoFaRequired = {
  fromJSON(object: any): TwoFaRequired {
    return {
      required: isSet(object.required) ? Boolean(object.required) : false,
      twoFaType: isSet(object.twoFaType)
        ? twoFaTypeFromJSON(object.twoFaType)
        : 0,
      tanSendAddress: isSet(object.tanSendAddress)
        ? String(object.tanSendAddress)
        : undefined,
      tanId: isSet(object.tanId) ? String(object.tanId) : undefined,
      messageId: isSet(object.messageId) ? Number(object.messageId) : undefined,
    };
  },

  toJSON(message: TwoFaRequired): unknown {
    const obj: any = {};
    message.required !== undefined && (obj.required = message.required);
    message.twoFaType !== undefined &&
      (obj.twoFaType = twoFaTypeToJSON(message.twoFaType));
    message.tanSendAddress !== undefined &&
      (obj.tanSendAddress = message.tanSendAddress);
    message.tanId !== undefined && (obj.tanId = message.tanId);
    message.messageId !== undefined &&
      (obj.messageId = Math.round(message.messageId));
    return obj;
  },
};

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
