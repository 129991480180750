/* eslint-disable */
export const protobufPackage = 'de.netfiles.pb';

/** What kind of Profile Image Types do we support */
export enum ProfileImageType {
  PROFILE_IMAGE_TYPE_UNSPECIFIED = 0,
  PROFILE_IMAGE_TYPE_JPG = 1,
  PROFILE_IMAGE_TYPE_PNG = 2,
  PROFILE_IMAGE_TYPE_GIF = 3,
  UNRECOGNIZED = -1,
}

export function profileImageTypeFromJSON(object: any): ProfileImageType {
  switch (object) {
    case 0:
    case 'PROFILE_IMAGE_TYPE_UNSPECIFIED':
      return ProfileImageType.PROFILE_IMAGE_TYPE_UNSPECIFIED;
    case 1:
    case 'PROFILE_IMAGE_TYPE_JPG':
      return ProfileImageType.PROFILE_IMAGE_TYPE_JPG;
    case 2:
    case 'PROFILE_IMAGE_TYPE_PNG':
      return ProfileImageType.PROFILE_IMAGE_TYPE_PNG;
    case 3:
    case 'PROFILE_IMAGE_TYPE_GIF':
      return ProfileImageType.PROFILE_IMAGE_TYPE_GIF;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ProfileImageType.UNRECOGNIZED;
  }
}

export function profileImageTypeToJSON(object: ProfileImageType): string {
  switch (object) {
    case ProfileImageType.PROFILE_IMAGE_TYPE_UNSPECIFIED:
      return 'PROFILE_IMAGE_TYPE_UNSPECIFIED';
    case ProfileImageType.PROFILE_IMAGE_TYPE_JPG:
      return 'PROFILE_IMAGE_TYPE_JPG';
    case ProfileImageType.PROFILE_IMAGE_TYPE_PNG:
      return 'PROFILE_IMAGE_TYPE_PNG';
    case ProfileImageType.PROFILE_IMAGE_TYPE_GIF:
      return 'PROFILE_IMAGE_TYPE_GIF';
    default:
      return 'UNKNOWN';
  }
}

/** User collection */
export interface ProfileImage {
  imageType: ProfileImageType;
  imageData: Uint8Array;
}

function createBaseProfileImage(): ProfileImage {
  return { imageType: 0, imageData: new Uint8Array() };
}

export const ProfileImage = {
  fromJSON(object: any): ProfileImage {
    return {
      imageType: isSet(object.imageType)
        ? profileImageTypeFromJSON(object.imageType)
        : 0,
      imageData: isSet(object.imageData)
        ? bytesFromBase64(object.imageData)
        : new Uint8Array(),
    };
  },

  toJSON(message: ProfileImage): unknown {
    const obj: any = {};
    message.imageType !== undefined &&
      (obj.imageType = profileImageTypeToJSON(message.imageType));
    message.imageData !== undefined &&
      (obj.imageData = base64FromBytes(
        message.imageData !== undefined ? message.imageData : new Uint8Array(),
      ));
    return obj;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

const atob: (b64: string) => string =
  globalThis.atob ||
  (b64 => globalThis.Buffer.from(b64, 'base64').toString('binary'));
function bytesFromBase64(b64: string): Uint8Array {
  const bin = atob(b64);
  const arr = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i);
  }
  return arr;
}

const btoa: (bin: string) => string =
  globalThis.btoa ||
  (bin => globalThis.Buffer.from(bin, 'binary').toString('base64'));
function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = [];
  arr.forEach(byte => {
    bin.push(String.fromCharCode(byte));
  });
  return btoa(bin.join(''));
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
