import {
  AutoLogOff,
  DefaultVisibilityOptions,
  DownloadListFormat,
  EmailSenderCompanyType,
  EmptyTrashBin,
  HideExpiredDocuments,
  LoginVerification,
  PasswordChangeInterval,
  PasswordMinLength,
  PasswordResetOptions,
  SendInformationEmailsOptions,
  SortIndexOptions,
  CommentVisibilityOptions,
  UIColorScheme,
  LogoType,
  UserNotification,
  TwoFactorType,
  ThemeMode,
  Salutation,
  Language,
  Locale,
  ManagedFiletransferVisibility,
  InviteValidDays,
} from '../models/generated/settings';
import { Bookmark, BookmarkProperties } from '../models/generated/bookmark';
import {
  Collection,
  CollectionProperties,
  FolderNotification,
  RecursiveApproval,
} from '../models/generated/collection';
import { Comment } from '../models/generated/comment';
import { ConferencesRoot } from '../models/generated/conferences';
import { Dataroom } from '../models/generated/dataroom';
import { DatesRoot } from '../models/generated/dates';
import { DiscussionsRoot } from '../models/generated/discussions';
import {
  Document,
  DocumentProperties,
  DocumentStatus,
} from '../models/generated/document';
import {
  FilesItemAttributes,
  GroupAttributes,
  DataroomAttributes,
  UserAttributes,
} from './Attributes';
import { FilesRoot } from '../models/generated/files';
import { Group, GroupsRoot } from '../models/generated/groups';
import { InboxRoot, OutboxRoot } from './../models/generated/mft';
import {
  Meeting,
  MeetingTopic,
  MeetingTopicMinute,
  MeetingTopicTask,
} from '../models/generated/meeting';
import { MFTInboxItem, MFTOutboxItem } from '../models/generated/mft';
import { QandaRoot } from '../models/generated/qanda';
import { Shortcut } from '../models/generated/shortcut';
import { ShortcutProperties } from '../models/generated/shortcut';
import { TasksRoot } from '../models/generated/tasks';
import { TrashRoot } from '../models/generated/trash';
import { User } from '../models/generated/user';
import { Wiki } from '../models/generated/wiki';
import { DataroomExportItem } from '../models/generated/export';
import {
  ActivityLogItemEntry,
  ActivityLogItem,
} from '../models/generated/activitylog';
import { InvoiceItem, InvoicesRoot } from '../models/generated/invoices';
import { ContractItem, ContractRoot } from '../models/generated/contract';
import { PasswordRequirements } from '../models/generated/me_service';

export enum DataroomRootType {
  DataroomRootTypeFiles = 'projectfiles',
  DataroomRootTypeTrash = 'bin',
  DataroomRootTypeTasks = 'projecttasks',
  DataroomRootTypeConferences = 'conference',
  DataroomRootTypeDates = 'projectdates',
  DataroomRootTypeDiscussions = 'discussions',
  DataroomRootTypeQanda = 'qanda',
  DataroomRootTypeGroups = 'groups',
}

export enum ObjectType {
  ObjectTypeDocument = 'document',
  ObjectTypeCollection = 'collection',
  ObjectTypePerson = 'person',
}

export enum DocumentClass {
  DocumentClassNone = '',
  DocumentClassProject = 'project',
  DocumentClassUser = 'user',
  DocumentClassWiki = 'inlineWiki',
  DocumentClassMeeting = 'NF_Meeting',
  DocumentClassMeetingTopic = 'NF_MeetingTopic',
  DocumentClassMeetingTopicMinute = 'NF_MeetingTopicMinute',
  DocumentClassMeetingTopicTask = 'NF_MeetingTopicTask',
  DocumentClassShortcut = 'alias',
}

export enum ValidationErrorType {
  ValidationErrorNone = '',
  ValidationErrorRequired = 'forms.validation.required',
  ValidationErrorStringTooLong = 'forms.validation.tooLong',
  ValidationErrorNumberTooBig = 'forms.validation.tooLong',
  ValidationErrorDuplicateNameDocument = 'forms.validation.notUnique',
  ValidationErrorDuplicateNameGroup = 'forms.validation.notUnique',
  ValidationErrorDuplicateNameUser = 'forms.validation.emailExists',
  ValidationErrorInvalidValue = 'forms.validation.invalidValue',
  ValidationErrorNotAllowed = 'forms.validation.notAllowed',
  ValidationErrorStringContainsHtml = 'forms.validation.containsHtml',
  ValidationErrorInvalidIntPhoneNumber = 'forms.validation.invalidIntPhoneNumber',
}

export enum HttpResponseCode {
  HttpResponseCodeOk = 200,
  HttpResponseCodeAccepted = 202,
  HttpResponseCodeBadRequest = 400,
  HttpResponseCodeNotAuthorized = 401,
  HttpResponseCodeForbidden = 403,
  HttpResponseCodeNotFound = 404,
  HttpResponseCodeIAmATeapot = 418,
  HttpResponseCodeInternalError = 500,
}

export type DataroomItemType =
  | Dataroom
  | FilesRoot
  | ConferencesRoot
  | TasksRoot
  | DatesRoot
  | TrashRoot
  | DiscussionsRoot
  | QandaRoot
  | Collection
  | Document
  | Bookmark
  | Shortcut
  | Meeting
  | MeetingTopic
  | MeetingTopicTask
  | MeetingTopicMinute
  | User
  | Group
  | GroupsRoot
  | Comment
  | Wiki
  | InboxRoot
  | OutboxRoot
  | MFTOutboxItem
  | MFTInboxItem
  | DataroomExportItem
  | ActivityLogItem
  | ActivityLogItemEntry;

export type FilesItemType =
  | Collection
  | Document
  | Wiki
  | FilesRoot
  | Bookmark
  | Shortcut
  | TrashRoot;

export type MFTItemType = MFTOutboxItem | MFTInboxItem;

export type FilesItemChildrenType = Collection | Document | Bookmark | Shortcut;

export type InvoicesContractsType =
  | InvoiceItem
  | InvoicesRoot
  | ContractItem
  | ContractRoot;

export type FilesItemPropertiesType =
  | DocumentProperties
  | CollectionProperties
  | BookmarkProperties
  | ShortcutProperties;

export type GroupsItemType = Group | GroupsRoot;

export type NFObject = Record<string, unknown>;

export enum UserNotificationAction {
  UserNotificationAction_New = 'new',
  UserNotificationAction_Copy = 'copy',
  UserNotificationAction_Move = 'move',
  UserNotificationAction_Trash = 'trash',
  UserNotificationAction_Approve = 'approve',
  UserNotificationAction_Unpack = 'unpack',
  UserNotificationAction_Annotate = 'annotate',
  UserNotificationAction_Comment = 'comment',
  UserNotificationAction_AddToGroup = 'addtogroup',
  UserNotificationAction_RemoveFromGroup = 'removefromgroup',
}

export enum TwoFaCodeType {
  TwoFaCodeType_Otp = 'codetype_otp',
  TwoFaCodeType_Sms = 'codetype_sms',
}

export enum LogoDataType {
  LogoDataType_Dataroom = 'dataroom',
  LogoDataType_Email = 'email',
}

export enum FavoritesActions {
  FavoritesActions_Add = 'add',
  FavoritesActions_Delete = 'delete',
  FavoritesActions_Get = 'get',
  FavoritesActions_Sort = 'sort',
}

export enum SettingsFieldType {
  SettingsFieldType_Boolean = 'boolean',
  SettingsFieldType_Text = 'text',
  SettingsFieldType_Date = 'date',
  SettingsFieldType_Html = 'html',
  SettingsFieldType_Number = 'number',
  SettingsFieldType_Password = 'password',
  SettingsFieldType_EmptyTrashBin = 'EmptyTrashBin',
  SettingsFieldType_HideExpiredDocuments = 'HideExpiredDocuments',
  SettingsFieldType_DownloadListFormat = 'DownloadListFormat',
  SettingsFieldType_AutoLogOff = 'AutoLogOff',
  SettingsFieldType_LoginVerification = 'LoginVerification',
  SettingsFieldType_PasswordMinLength = 'PasswordMinLength',
  SettingsFieldType_PasswordChangeInterval = 'PasswordChangeInterval',
  SettingsFieldType_PasswordResetOptions = 'PasswordResetOptions',
  SettingsFieldType_EmailSenderCompany = 'EmailSenderCompany',
  SettingsFieldType_SortIndexOptions = 'SortIndexOptions',
  SettingsFieldType_SendInformationEmailsOptions = 'SendInformationEmailsOptions',
  SettingsFieldType_DefaultVisibilityOptions = 'DefaultVisibilityOptions',
  SettingsFieldType_CommentVisibilityOptions = 'CommentVisibilityOptions',
  SettingsFieldType_UIColorScheme = 'UIColorScheme',
  SettingsFieldType_Logo = 'LogoType',
  SettingsFieldType_UserNotification = 'UserNotification',
  SettingsFieldType_TwoFactorType = 'TwoFactorType',
  SettingsFieldType_Salutation = 'Salutation',
  SettingsFieldType_ThemeMode = 'ThemeMode',
  SettingsFieldType_Language = 'Language',

  SettingsFieldType_DocumentStatus = 'DocumentStatus',
  SettingsFieldType_RecursiveApproval = 'RecursiveApproval',

  SettingsFieldType_Textarea = 'Textarea',
  SettingsFieldType_ManagedFiletransferVisibility = 'ManagedFiletransferVisibility',
}

// needed, otherwise prettier interferes with eslint indend
/* prettier-ignore */
export const gFilesItemAttribuesTypeMap: Partial<Record<string, string | typeof DocumentStatus | typeof RecursiveApproval | typeof FolderNotification>> = {
  [FilesItemAttributes.FilesItemAttributes_title]:
    SettingsFieldType.SettingsFieldType_Text,
  [FilesItemAttributes.FilesItemAttributes_status]: DocumentStatus,
  [FilesItemAttributes.FilesItemAttributes_description]:
    SettingsFieldType.SettingsFieldType_Html,
  [FilesItemAttributes.FilesItemAttributes_documentSize]:
    SettingsFieldType.SettingsFieldType_Number,
  [FilesItemAttributes.FilesItemAttributes_author]:
    SettingsFieldType.SettingsFieldType_Text,
  [FilesItemAttributes.FilesItemAttributes_version]:
    SettingsFieldType.SettingsFieldType_Text,
  [FilesItemAttributes.FilesItemAttributes_versionOwner]:
    SettingsFieldType.SettingsFieldType_Text,
  [FilesItemAttributes.FilesItemAttributes_url]:
    SettingsFieldType.SettingsFieldType_Text,
  [FilesItemAttributes.FilesItemAttributes_expirationDate]:
    SettingsFieldType.SettingsFieldType_Date,
  [FilesItemAttributes.FilesItemAttributes_approvedBy]:
    SettingsFieldType.SettingsFieldType_Text,
  [FilesItemAttributes.FilesItemAttributes_approvedOn]:
    SettingsFieldType.SettingsFieldType_Date,
  [FilesItemAttributes.FilesItemAttributes_approvalGroup]:
    SettingsFieldType.SettingsFieldType_Text,
  [FilesItemAttributes.FilesItemAttributes_recursiveApproval]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [FilesItemAttributes.FilesItemAttributes_canApprove]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [FilesItemAttributes.FilesItemAttributes_sequence]:
    SettingsFieldType.SettingsFieldType_Number,
  [FilesItemAttributes.FilesItemAttributes_index]:
    SettingsFieldType.SettingsFieldType_Text,
  [FilesItemAttributes.FilesItemAttributes_tags]:
    SettingsFieldType.SettingsFieldType_Text,
  [FilesItemAttributes.FilesItemAttributes_modificationDate]:
    SettingsFieldType.SettingsFieldType_Date,
  [FilesItemAttributes.FilesItemAttributes_creationDate]:
    SettingsFieldType.SettingsFieldType_Date,
  [FilesItemAttributes.FilesItemAttributes_path]:
    SettingsFieldType.SettingsFieldType_Text,
  [FilesItemAttributes.FilesItemAttributes_shortUrl]:
    SettingsFieldType.SettingsFieldType_Text,
  [FilesItemAttributes.FilesItemAttributes_isRead]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [FilesItemAttributes.FilesItemAttributes_isApproved]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [FilesItemAttributes.FilesItemAttributes_subDocs]:
    SettingsFieldType.SettingsFieldType_Number,
  [FilesItemAttributes.FilesItemAttributes_collectionSize]:
    SettingsFieldType.SettingsFieldType_Number,
  [FilesItemAttributes.FilesItemAttributes_pdfPassword]:
    SettingsFieldType.SettingsFieldType_Password,
  [FilesItemAttributes.FilesItemAttributes_recursivePdfPasswordEnabled]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [FilesItemAttributes.FilesItemAttributes_setExpirationDate]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [FilesItemAttributes.FilesItemAttributes_recursiveSetExpirationDate]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [FilesItemAttributes.FilesItemAttributes_recursiveApprovalType]:
    RecursiveApproval,
  [FilesItemAttributes.FilesItemAttributes_notification]:FolderNotification,
  [FilesItemAttributes.FilesItemAttributes_recursiveNotifications]:SettingsFieldType.SettingsFieldType_Boolean,
  [FilesItemAttributes.FilesItemAttributes_userNotifications]:SettingsFieldType.SettingsFieldType_Text,
  [FilesItemAttributes.FilesItemAttributes_deletedOn]:SettingsFieldType.SettingsFieldType_Date,
};

export const gGroupAttributesFieldTypeMap: Partial<Record<string, string>> = {
  [GroupAttributes.GroupAttributes_title]:
    SettingsFieldType.SettingsFieldType_Text,
  [GroupAttributes.GroupAttributes_description]:
    SettingsFieldType.SettingsFieldType_Html,
  [GroupAttributes.GroupAttributes_labels]:
    SettingsFieldType.SettingsFieldType_Text,
  [GroupAttributes.GroupAttributes_groupAdmins]:
    SettingsFieldType.SettingsFieldType_Text,
};

// needed, otherwise prettier interferes with eslint indend
/* prettier-ignore */
export const gDataroomAttributesFieldTypeMap: Partial<Record<string, | string | typeof DownloadListFormat | typeof EmailSenderCompanyType | typeof HideExpiredDocuments | typeof EmptyTrashBin | typeof LoginVerification | typeof AutoLogOff | typeof PasswordMinLength | typeof PasswordChangeInterval | typeof PasswordResetOptions | typeof SortIndexOptions | typeof DefaultVisibilityOptions | typeof SendInformationEmailsOptions | typeof CommentVisibilityOptions | typeof UIColorScheme | typeof LogoType | typeof InviteValidDays>
> = {
  [DataroomAttributes.DataroomAttributes_dataroomName]:
    SettingsFieldType.SettingsFieldType_Text,
  // General
  [DataroomAttributes.DataroomAttributes_showFolderOnTop]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_showTimeCreated]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_showDateWithTime]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_showVersionAuthor]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_showNumberOfDocuments]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_logActivityRecursive]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_downloadListFormat]:
    DownloadListFormat,
  [DataroomAttributes.DataroomAttributes_emailSenderName]:
    EmailSenderCompanyType,
  // Document
  [DataroomAttributes.DataroomAttributes_requireDocumentApproval]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_notifyAboutChanges]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_notifyAboutChangesForUnconfirmedUsers]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_useVersionControl]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_useGroupLabels]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_usePrivateLabels]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_createOfficeDocuments]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_showOfficeDocumentsNavigable]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_createTextDocuments]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_downloadInsteadOpen]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_downloadZip]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_allowFolderDownload]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_useFulltextSearch]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_createDocumentAnnotations]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_showPermissionsIcon]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_daysExpiredDocumentsHidden]:
    HideExpiredDocuments,
  [DataroomAttributes.DataroomAttributes_daysEmptyTrash]: EmptyTrashBin,
  // security
  [DataroomAttributes.DataroomAttributes_showLoginActivity]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_sendEmailForNewDevices]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_autoLogoff]: AutoLogOff,
  [DataroomAttributes.DataroomAttributes_loginVerification]: LoginVerification,
  [DataroomAttributes.DataroomAttributes_excludeIpRange]:
    SettingsFieldType.SettingsFieldType_Text,
  [DataroomAttributes.DataroomAttributes_scanForVirus]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_encryptFiles]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_usePermissionReadOnly]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_usePermissionDownloadPdf]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_useWatermarksPdf]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_downloadPdfsEncrypted]:
    SettingsFieldType.SettingsFieldType_Boolean,
  // Password
  [DataroomAttributes.DataroomAttributes_passwordMinLength]: PasswordMinLength,
  [DataroomAttributes.DataroomAttributes_passwordRequiresSpecialChars]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_passwordRequiresNumbers]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_passwordRequiresMixedLetters]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_passwordChangeInterval]:
    PasswordChangeInterval,
  [DataroomAttributes.DataroomAttributes_passwordReset]: PasswordResetOptions,
  [DataroomAttributes.DataroomAttributes_passwordResetByAdmin]:
    SettingsFieldType.SettingsFieldType_Boolean,
  // Index
  [DataroomAttributes.DataroomAttributes_useFileIndex]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_spreadUploadByIndex]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_freezeIndex]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_sortIndex]: SortIndexOptions,

  // User
  [DataroomAttributes.DataroomAttributes_markUnseenDocuments]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_userMayChangeRights]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_userMayTrashFiles]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_showAdminGroup]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_defaultVisibility]:
    DefaultVisibilityOptions,
  [DataroomAttributes.DataroomAttributes_allowEmailsToUsers]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_allowEmailsAsPlaintextOnly]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_requireEmailsCopyToSender]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_sendObserverEmailOnInvite]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_sendInformationEmails]:
    SendInformationEmailsOptions,
  [DataroomAttributes.DataroomAttributes_showIntroductionDialog]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_defineUsernamesEnabled]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_inviteValidDays]:
    InviteValidDays,

  // Functions
  [DataroomAttributes.DataroomAttributes_useManagedFiletransfer]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_useManagedFiletransferLocalUpload]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_useManagedFiletransferPassword]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_useTabletApp]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_useWebdav]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_useSftp]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_useTasks]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_useAppointments]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_useVideoConferences]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_useDiscussions]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_useMeetings]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_useVotings]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_showFavorites]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_showNewFiles]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_showLockedFiles]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_showRecentComments]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_showPreferredFiles]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_useComments]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_commentVisibility]:
    CommentVisibilityOptions,

  // Disclaimer

  [DataroomAttributes.DataroomAttributes_disclaimerText]:
    SettingsFieldType.SettingsFieldType_Text,
  [DataroomAttributes.DataroomAttributes_useDisclaimer]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [DataroomAttributes.DataroomAttributes_reacceptDisclaimer]:
    SettingsFieldType.SettingsFieldType_Boolean,

  // UI

  [DataroomAttributes.DataroomAttributes_loginPageUrl]:
    SettingsFieldType.SettingsFieldType_Text,
  [DataroomAttributes.DataroomAttributes_colorScheme]: UIColorScheme,
  [DataroomAttributes.DataroomAttributes_emailLogo]: LogoType,
  [DataroomAttributes.DataroomAttributes_dataroomLogo]: LogoType,
  [DataroomAttributes.DataroomAttributes_onUploadScaleOnly]:
    SettingsFieldType.SettingsFieldType_Boolean,
};

// needed, otherwise prettier interferes with eslint indend
/* prettier-ignore */
export const gUserAttributesFieldTypeMap: Partial<Record<string, | string | typeof Salutation | typeof Language | typeof UserNotification | typeof TwoFactorType | typeof ThemeMode | typeof DefaultVisibilityOptions | typeof Locale | typeof ManagedFiletransferVisibility>> = {
  [UserAttributes.UserAttributes_username]:
    SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_firstName]:
    SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_lastName]:
    SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_email]:
    SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_salutation]: Salutation,
  [UserAttributes.UserAttributes_title]:
    SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_mobilePhoneNr]:
    SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_phoneNr]:
    SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_faxNr]:
    SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_department]:
    SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_position]:
    SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_address]:
    SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_website]:
    SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_company]:
    SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_realname]:
    SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_language]: Language,
  [UserAttributes.UserAttributes_notification]: UserNotification,
  [UserAttributes.UserAttributes_showWelcomeDialog]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_twoFactorEnabled]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_twoFactorType]: TwoFactorType,
  [UserAttributes.UserAttributes_themeMode]: ThemeMode,
  [UserAttributes.UserAttributes_receiveInfoMails]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_unpackArchivesOnUpload]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_hasPhoto]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_useExternalFiletransfer]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_managedFiletransferVisibility]:ManagedFiletransferVisibility,
  [UserAttributes.UserAttributes_allowAccess]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_useMsOffice]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_restrictAccessFrom]:
    SettingsFieldType.SettingsFieldType_Date,
  [UserAttributes.UserAttributes_restrictAccessTo]:
    SettingsFieldType.SettingsFieldType_Date,
  [UserAttributes.UserAttributes_useSftp]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_useWebdav]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_useTabletApp]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_downloadProtectedPdf]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_printProtectedPdf]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_mountpoint]:
    SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_createVideoConferences]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_visibility]: DefaultVisibilityOptions,
  [UserAttributes.UserAttributes_created]:
    SettingsFieldType.SettingsFieldType_Date,
  [UserAttributes.UserAttributes_logins]:
    SettingsFieldType.SettingsFieldType_Number,
  [UserAttributes.UserAttributes_groups]:
    SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_lastLogin]:
    SettingsFieldType.SettingsFieldType_Date,
  [UserAttributes.UserAttributes_invitationSent]:
    SettingsFieldType.SettingsFieldType_Date,
  [UserAttributes.UserAttributes_accessContractData]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_favorites]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_newFiles]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_preferredFiles]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_lockedFiles]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_recentComments]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_expiredFiles]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_unreadFiles]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_labels]:
    SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_signature]:
    SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_userFeatures]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_tobeapprovedFiles]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_inbox]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_outbox]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_tasks]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_appointments]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_videoconferences]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_discussions]:
    SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_singleSignOnId]: SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_singleSignOnEnabled] : SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_newsletter]: SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_sendInvitationLater]: SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_sendInvitationToMeOnly]:SettingsFieldType.SettingsFieldType_Boolean,
  [UserAttributes.UserAttributes_userActivationObserverEmail]:SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_costCenter]:SettingsFieldType.SettingsFieldType_Text,
  [UserAttributes.UserAttributes_messageToNewUser]:SettingsFieldType.SettingsFieldType_Textarea,
  [UserAttributes.UserAttributes_locale]: Locale,
};

export enum TooltipType {
  TooltipType_Upgradable = 'Upgradable',
  TooltipType_Locked = 'Locked',
  TooltipType_Values = 'Values',
  TooltipType_Default = 'default',
}

export enum GetReadAccessSettingsGroups {
  GET_READACCESS_SETTINGS_GROUPS_VISIBLE = 0, // return those groups of all objects the user is allowed to see
  GET_READACCESS_SETTINGS_GROUPS_MATCHING = 1, // return only those groups which exist on all objects
  GET_READACCESS_SETTINGS_GROUPS_ALL = 2, // return all groups of all objects
}

export enum GetReadAccessSettingsUsers {
  GET_READACCESS_SETTING_USERS_NO = 0, // do not return users at all
  GET_READACCESS_SETTING_USERS_MATCHING = 1, // return users only for matching groups
  GET_READACCESS_SETTING_USERS_ALL = 2, // return all users of the corresponding groups
}

export enum UsersType {
  USERSTYPE_NORMAL = 1, // means active and confirmed and invited and not access restricted
  USERSTYPE_INACTIVE = 2,
  USERSTYPE_UNCONFIRMED = 4,
  USERSTYPE_NOTINVITED = 8,
  USERSTYPE_ACCESSRESTRICTED = 16,
}

export type GetReadAccessSettingsParams = {
  users: GetReadAccessSettingsUsers;
  usersType: UsersType;
  groups: GetReadAccessSettingsGroups;
};

export type GetMultiFactorRequirementsResponseType = {
  user: TwoFactorType;
  dataroom: LoginVerification; // strictest requirement for all netfiles classic data rooms
  vuedataroom?: LoginVerification; // strictest requirement for all VUE data rooms
  sendEmailForNewDevices: boolean;
  excludeIPs: string;
  needsPWChange: boolean;
  needsPWChangeType: { interval: boolean; requirements: boolean }; // in case password must be changed, indicates the reason either due to requirements change or due to password chnge interval
  needsPWQuestion: boolean;
  needsEmailVerification: boolean; // indicates if extern enterprise user has to verifiy his email
  loginMessage: { text: string; id: string } | undefined; // maintenancew message to display if set
  enterpriseUserType: { admin: boolean; user: boolean } | undefined; // indicates enterprise user type, either if user is enterprise admin or normal user
  enterpriseAdmin2FARequired: boolean | undefined; // indicates if 2FA is required for enterprise admin or enterprise user admin
  passwordRequirements?: PasswordRequirements;
};
