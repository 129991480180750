"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilesItemProperties = exports.FilesItem = exports.FilesRoot = exports.protobufPackage = void 0;
/* eslint-disable */
const item_1 = require("./item");
const timestamp_1 = require("./google/protobuf/timestamp");
const permission_1 = require("./permission");
const collection_1 = require("./collection");
const document_1 = require("./document");
const wiki_1 = require("./wiki");
const bookmark_1 = require("./bookmark");
const shortcut_1 = require("./shortcut");
exports.protobufPackage = 'de.netfiles.pb';
function createBaseFilesRoot() {
    return {
        id: '',
        dataroomId: '',
        name: '',
        type: '',
        documentClass: '',
        author: undefined,
        title: '',
        path: '',
        version: '',
        creationDate: undefined,
        modificationDate: undefined,
        icon: '',
        numChildren: 0,
        effectivePermission: undefined,
        description: '',
        itemType: 0,
    };
}
exports.FilesRoot = {
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : '',
            dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
            name: isSet(object.name) ? String(object.name) : '',
            type: isSet(object.type) ? String(object.type) : '',
            documentClass: isSet(object.documentClass)
                ? String(object.documentClass)
                : '',
            author: isSet(object.author) ? String(object.author) : undefined,
            title: isSet(object.title) ? String(object.title) : '',
            path: isSet(object.path) ? String(object.path) : '',
            version: isSet(object.version) ? String(object.version) : '',
            creationDate: isSet(object.creationDate)
                ? fromJsonTimestamp(object.creationDate)
                : undefined,
            modificationDate: isSet(object.modificationDate)
                ? fromJsonTimestamp(object.modificationDate)
                : undefined,
            icon: isSet(object.icon) ? String(object.icon) : '',
            numChildren: isSet(object.numChildren) ? Number(object.numChildren) : 0,
            effectivePermission: isSet(object.effectivePermission)
                ? permission_1.EffectivePermission.fromJSON(object.effectivePermission)
                : undefined,
            description: isSet(object.description) ? String(object.description) : '',
            itemType: isSet(object.itemType) ? (0, item_1.itemTypeFromJSON)(object.itemType) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
        message.name !== undefined && (obj.name = message.name);
        message.type !== undefined && (obj.type = message.type);
        message.documentClass !== undefined &&
            (obj.documentClass = message.documentClass);
        message.author !== undefined && (obj.author = message.author);
        message.title !== undefined && (obj.title = message.title);
        message.path !== undefined && (obj.path = message.path);
        message.version !== undefined && (obj.version = message.version);
        message.creationDate !== undefined &&
            (obj.creationDate = message.creationDate.toISOString());
        message.modificationDate !== undefined &&
            (obj.modificationDate = message.modificationDate.toISOString());
        message.icon !== undefined && (obj.icon = message.icon);
        message.numChildren !== undefined &&
            (obj.numChildren = Math.round(message.numChildren));
        message.effectivePermission !== undefined &&
            (obj.effectivePermission = message.effectivePermission
                ? permission_1.EffectivePermission.toJSON(message.effectivePermission)
                : undefined);
        message.description !== undefined &&
            (obj.description = message.description);
        message.itemType !== undefined &&
            (obj.itemType = (0, item_1.itemTypeToJSON)(message.itemType));
        return obj;
    },
};
function createBaseFilesItem() {
    return {
        collection: undefined,
        document: undefined,
        wiki: undefined,
        filesRoot: undefined,
        bookmark: undefined,
        shortcut: undefined,
    };
}
exports.FilesItem = {
    fromJSON(object) {
        return {
            collection: isSet(object.collection)
                ? collection_1.Collection.fromJSON(object.collection)
                : undefined,
            document: isSet(object.document)
                ? document_1.Document.fromJSON(object.document)
                : undefined,
            wiki: isSet(object.wiki) ? wiki_1.Wiki.fromJSON(object.wiki) : undefined,
            filesRoot: isSet(object.filesRoot)
                ? exports.FilesRoot.fromJSON(object.filesRoot)
                : undefined,
            bookmark: isSet(object.bookmark)
                ? bookmark_1.Bookmark.fromJSON(object.bookmark)
                : undefined,
            shortcut: isSet(object.shortcut)
                ? shortcut_1.Shortcut.fromJSON(object.shortcut)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.collection !== undefined &&
            (obj.collection = message.collection
                ? collection_1.Collection.toJSON(message.collection)
                : undefined);
        message.document !== undefined &&
            (obj.document = message.document
                ? document_1.Document.toJSON(message.document)
                : undefined);
        message.wiki !== undefined &&
            (obj.wiki = message.wiki ? wiki_1.Wiki.toJSON(message.wiki) : undefined);
        message.filesRoot !== undefined &&
            (obj.filesRoot = message.filesRoot
                ? exports.FilesRoot.toJSON(message.filesRoot)
                : undefined);
        message.bookmark !== undefined &&
            (obj.bookmark = message.bookmark
                ? bookmark_1.Bookmark.toJSON(message.bookmark)
                : undefined);
        message.shortcut !== undefined &&
            (obj.shortcut = message.shortcut
                ? shortcut_1.Shortcut.toJSON(message.shortcut)
                : undefined);
        return obj;
    },
};
function createBaseFilesItemProperties() {
    return {
        collection: undefined,
        document: undefined,
        bookmark: undefined,
        shortcut: undefined,
    };
}
exports.FilesItemProperties = {
    fromJSON(object) {
        return {
            collection: isSet(object.collection)
                ? collection_1.CollectionProperties.fromJSON(object.collection)
                : undefined,
            document: isSet(object.document)
                ? document_1.DocumentProperties.fromJSON(object.document)
                : undefined,
            bookmark: isSet(object.bookmark)
                ? bookmark_1.BookmarkProperties.fromJSON(object.bookmark)
                : undefined,
            shortcut: isSet(object.shortcut)
                ? shortcut_1.ShortcutProperties.fromJSON(object.shortcut)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.collection !== undefined &&
            (obj.collection = message.collection
                ? collection_1.CollectionProperties.toJSON(message.collection)
                : undefined);
        message.document !== undefined &&
            (obj.document = message.document
                ? document_1.DocumentProperties.toJSON(message.document)
                : undefined);
        message.bookmark !== undefined &&
            (obj.bookmark = message.bookmark
                ? bookmark_1.BookmarkProperties.toJSON(message.bookmark)
                : undefined);
        message.shortcut !== undefined &&
            (obj.shortcut = message.shortcut
                ? shortcut_1.ShortcutProperties.toJSON(message.shortcut)
                : undefined);
        return obj;
    },
};
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis);
}
function fromJsonTimestamp(o) {
    if (o instanceof Date) {
        return o;
    }
    else if (typeof o === 'string') {
        return new Date(o);
    }
    else {
        return fromTimestamp(timestamp_1.Timestamp.fromJSON(o));
    }
}
function isSet(value) {
    return value !== null && value !== undefined;
}
