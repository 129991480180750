/* eslint-disable */
import { ItemType, itemTypeFromJSON, itemTypeToJSON } from './item';
import { Timestamp } from './google/protobuf/timestamp';
import { EffectivePermission } from './permission';

export const protobufPackage = 'de.netfiles.pb';

/** Dataroom types in Netfiles */
export enum DataroomType {
  DATAROOM_TYPE_UNSPECIFIED = 0,
  DATAROOM_TYPE_ESSENTIAL = 1,
  DATAROOM_TYPE_BASIC = 2,
  DATAROOM_TYPE_PROFESSIONAL = 3,
  DATAROOM_TYPE_DEALROOM = 4,
  DATAROOM_TYPE_BOARDROOM = 5,
  DATAROOM_TYPE_ENTERPRISE = 6,
  UNRECOGNIZED = -1,
}

export function dataroomTypeFromJSON(object: any): DataroomType {
  switch (object) {
    case 0:
    case 'DATAROOM_TYPE_UNSPECIFIED':
      return DataroomType.DATAROOM_TYPE_UNSPECIFIED;
    case 1:
    case 'DATAROOM_TYPE_ESSENTIAL':
      return DataroomType.DATAROOM_TYPE_ESSENTIAL;
    case 2:
    case 'DATAROOM_TYPE_BASIC':
      return DataroomType.DATAROOM_TYPE_BASIC;
    case 3:
    case 'DATAROOM_TYPE_PROFESSIONAL':
      return DataroomType.DATAROOM_TYPE_PROFESSIONAL;
    case 4:
    case 'DATAROOM_TYPE_DEALROOM':
      return DataroomType.DATAROOM_TYPE_DEALROOM;
    case 5:
    case 'DATAROOM_TYPE_BOARDROOM':
      return DataroomType.DATAROOM_TYPE_BOARDROOM;
    case 6:
    case 'DATAROOM_TYPE_ENTERPRISE':
      return DataroomType.DATAROOM_TYPE_ENTERPRISE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DataroomType.UNRECOGNIZED;
  }
}

export function dataroomTypeToJSON(object: DataroomType): string {
  switch (object) {
    case DataroomType.DATAROOM_TYPE_UNSPECIFIED:
      return 'DATAROOM_TYPE_UNSPECIFIED';
    case DataroomType.DATAROOM_TYPE_ESSENTIAL:
      return 'DATAROOM_TYPE_ESSENTIAL';
    case DataroomType.DATAROOM_TYPE_BASIC:
      return 'DATAROOM_TYPE_BASIC';
    case DataroomType.DATAROOM_TYPE_PROFESSIONAL:
      return 'DATAROOM_TYPE_PROFESSIONAL';
    case DataroomType.DATAROOM_TYPE_DEALROOM:
      return 'DATAROOM_TYPE_DEALROOM';
    case DataroomType.DATAROOM_TYPE_BOARDROOM:
      return 'DATAROOM_TYPE_BOARDROOM';
    case DataroomType.DATAROOM_TYPE_ENTERPRISE:
      return 'DATAROOM_TYPE_ENTERPRISE';
    default:
      return 'UNKNOWN';
  }
}

/**
 * Dataroom (Files) Socket Event
 * DEPRECATED!
 */
export enum DataroomSocketEvent {
  DATAROOM_SOCKET_EVENT_UPDATE = 0,
  UNRECOGNIZED = -1,
}

export function dataroomSocketEventFromJSON(object: any): DataroomSocketEvent {
  switch (object) {
    case 0:
    case 'DATAROOM_SOCKET_EVENT_UPDATE':
      return DataroomSocketEvent.DATAROOM_SOCKET_EVENT_UPDATE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DataroomSocketEvent.UNRECOGNIZED;
  }
}

export function dataroomSocketEventToJSON(object: DataroomSocketEvent): string {
  switch (object) {
    case DataroomSocketEvent.DATAROOM_SOCKET_EVENT_UPDATE:
      return 'DATAROOM_SOCKET_EVENT_UPDATE';
    default:
      return 'UNKNOWN';
  }
}

/** The Dataroom disclaimer, if any */
export interface Disclaimer {
  creationDate: Date | undefined;
  acceptanceDate?: Date | undefined;
  content: string;
}

/** Basic Item */
export interface Dataroom {
  id: string;
  name: string;
  type: string;
  documentClass?: string | undefined;
  author: string;
  title: string;
  path: string;
  creationDate?: Date | undefined;
  modificationDate?: Date | undefined;
  icon: string;
  numChildren: number;
  /** user dependend */
  accessAllowed?: boolean | undefined;
  /** user dependend */
  isDataroomAdmin?: boolean | undefined;
  /** user dependend */
  isGroupAdmin?: boolean | undefined;
  dataroomType: DataroomType;
  disclaimer?: Disclaimer | undefined;
  itemType: ItemType;
  effectivePermission: EffectivePermission | undefined;
  /** user dependend */
  isUnconfirmed?: boolean | undefined;
  /** user dependend */
  dateOpen?: Date | undefined;
  /** user dependend */
  dateClose?: Date | undefined;
  responsibleUsername?: string | undefined;
  responsibleUserRealname?: string | undefined;
  /** user dependend */
  mountpoint?: string | undefined;
  end2endEncrypted?: boolean | undefined;
  currentQuantum?: number | undefined;
  freeQuantum?: number | undefined;
  maxQuantum?: number | undefined;
  currentUsers?: number | undefined;
  maxUsers?: number | undefined;
  freeUsers?: number | undefined;
  maxFilesize?: number | undefined;
  dateLastChange?: Date | undefined;
  isVueDataroom?: boolean | undefined;
}

function createBaseDisclaimer(): Disclaimer {
  return { creationDate: undefined, acceptanceDate: undefined, content: '' };
}

export const Disclaimer = {
  fromJSON(object: any): Disclaimer {
    return {
      creationDate: isSet(object.creationDate)
        ? fromJsonTimestamp(object.creationDate)
        : undefined,
      acceptanceDate: isSet(object.acceptanceDate)
        ? fromJsonTimestamp(object.acceptanceDate)
        : undefined,
      content: isSet(object.content) ? String(object.content) : '',
    };
  },

  toJSON(message: Disclaimer): unknown {
    const obj: any = {};
    message.creationDate !== undefined &&
      (obj.creationDate = message.creationDate.toISOString());
    message.acceptanceDate !== undefined &&
      (obj.acceptanceDate = message.acceptanceDate.toISOString());
    message.content !== undefined && (obj.content = message.content);
    return obj;
  },
};

function createBaseDataroom(): Dataroom {
  return {
    id: '',
    name: '',
    type: '',
    documentClass: undefined,
    author: '',
    title: '',
    path: '',
    creationDate: undefined,
    modificationDate: undefined,
    icon: '',
    numChildren: 0,
    accessAllowed: undefined,
    isDataroomAdmin: undefined,
    isGroupAdmin: undefined,
    dataroomType: 0,
    disclaimer: undefined,
    itemType: 0,
    effectivePermission: undefined,
    isUnconfirmed: undefined,
    dateOpen: undefined,
    dateClose: undefined,
    responsibleUsername: undefined,
    responsibleUserRealname: undefined,
    mountpoint: undefined,
    end2endEncrypted: undefined,
    currentQuantum: undefined,
    freeQuantum: undefined,
    maxQuantum: undefined,
    currentUsers: undefined,
    maxUsers: undefined,
    freeUsers: undefined,
    maxFilesize: undefined,
    dateLastChange: undefined,
    isVueDataroom: undefined,
  };
}

export const Dataroom = {
  fromJSON(object: any): Dataroom {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name) ? String(object.name) : '',
      type: isSet(object.type) ? String(object.type) : '',
      documentClass: isSet(object.documentClass)
        ? String(object.documentClass)
        : undefined,
      author: isSet(object.author) ? String(object.author) : '',
      title: isSet(object.title) ? String(object.title) : '',
      path: isSet(object.path) ? String(object.path) : '',
      creationDate: isSet(object.creationDate)
        ? fromJsonTimestamp(object.creationDate)
        : undefined,
      modificationDate: isSet(object.modificationDate)
        ? fromJsonTimestamp(object.modificationDate)
        : undefined,
      icon: isSet(object.icon) ? String(object.icon) : '',
      numChildren: isSet(object.numChildren) ? Number(object.numChildren) : 0,
      accessAllowed: isSet(object.accessAllowed)
        ? Boolean(object.accessAllowed)
        : undefined,
      isDataroomAdmin: isSet(object.isDataroomAdmin)
        ? Boolean(object.isDataroomAdmin)
        : undefined,
      isGroupAdmin: isSet(object.isGroupAdmin)
        ? Boolean(object.isGroupAdmin)
        : undefined,
      dataroomType: isSet(object.dataroomType)
        ? dataroomTypeFromJSON(object.dataroomType)
        : 0,
      disclaimer: isSet(object.disclaimer)
        ? Disclaimer.fromJSON(object.disclaimer)
        : undefined,
      itemType: isSet(object.itemType) ? itemTypeFromJSON(object.itemType) : 0,
      effectivePermission: isSet(object.effectivePermission)
        ? EffectivePermission.fromJSON(object.effectivePermission)
        : undefined,
      isUnconfirmed: isSet(object.isUnconfirmed)
        ? Boolean(object.isUnconfirmed)
        : undefined,
      dateOpen: isSet(object.dateOpen)
        ? fromJsonTimestamp(object.dateOpen)
        : undefined,
      dateClose: isSet(object.dateClose)
        ? fromJsonTimestamp(object.dateClose)
        : undefined,
      responsibleUsername: isSet(object.responsibleUsername)
        ? String(object.responsibleUsername)
        : undefined,
      responsibleUserRealname: isSet(object.responsibleUserRealname)
        ? String(object.responsibleUserRealname)
        : undefined,
      mountpoint: isSet(object.mountpoint)
        ? String(object.mountpoint)
        : undefined,
      end2endEncrypted: isSet(object.end2endEncrypted)
        ? Boolean(object.end2endEncrypted)
        : undefined,
      currentQuantum: isSet(object.currentQuantum)
        ? Number(object.currentQuantum)
        : undefined,
      freeQuantum: isSet(object.freeQuantum)
        ? Number(object.freeQuantum)
        : undefined,
      maxQuantum: isSet(object.maxQuantum)
        ? Number(object.maxQuantum)
        : undefined,
      currentUsers: isSet(object.currentUsers)
        ? Number(object.currentUsers)
        : undefined,
      maxUsers: isSet(object.maxUsers) ? Number(object.maxUsers) : undefined,
      freeUsers: isSet(object.freeUsers) ? Number(object.freeUsers) : undefined,
      maxFilesize: isSet(object.maxFilesize)
        ? Number(object.maxFilesize)
        : undefined,
      dateLastChange: isSet(object.dateLastChange)
        ? fromJsonTimestamp(object.dateLastChange)
        : undefined,
      isVueDataroom: isSet(object.isVueDataroom)
        ? Boolean(object.isVueDataroom)
        : undefined,
    };
  },

  toJSON(message: Dataroom): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined && (obj.type = message.type);
    message.documentClass !== undefined &&
      (obj.documentClass = message.documentClass);
    message.author !== undefined && (obj.author = message.author);
    message.title !== undefined && (obj.title = message.title);
    message.path !== undefined && (obj.path = message.path);
    message.creationDate !== undefined &&
      (obj.creationDate = message.creationDate.toISOString());
    message.modificationDate !== undefined &&
      (obj.modificationDate = message.modificationDate.toISOString());
    message.icon !== undefined && (obj.icon = message.icon);
    message.numChildren !== undefined &&
      (obj.numChildren = Math.round(message.numChildren));
    message.accessAllowed !== undefined &&
      (obj.accessAllowed = message.accessAllowed);
    message.isDataroomAdmin !== undefined &&
      (obj.isDataroomAdmin = message.isDataroomAdmin);
    message.isGroupAdmin !== undefined &&
      (obj.isGroupAdmin = message.isGroupAdmin);
    message.dataroomType !== undefined &&
      (obj.dataroomType = dataroomTypeToJSON(message.dataroomType));
    message.disclaimer !== undefined &&
      (obj.disclaimer = message.disclaimer
        ? Disclaimer.toJSON(message.disclaimer)
        : undefined);
    message.itemType !== undefined &&
      (obj.itemType = itemTypeToJSON(message.itemType));
    message.effectivePermission !== undefined &&
      (obj.effectivePermission = message.effectivePermission
        ? EffectivePermission.toJSON(message.effectivePermission)
        : undefined);
    message.isUnconfirmed !== undefined &&
      (obj.isUnconfirmed = message.isUnconfirmed);
    message.dateOpen !== undefined &&
      (obj.dateOpen = message.dateOpen.toISOString());
    message.dateClose !== undefined &&
      (obj.dateClose = message.dateClose.toISOString());
    message.responsibleUsername !== undefined &&
      (obj.responsibleUsername = message.responsibleUsername);
    message.responsibleUserRealname !== undefined &&
      (obj.responsibleUserRealname = message.responsibleUserRealname);
    message.mountpoint !== undefined && (obj.mountpoint = message.mountpoint);
    message.end2endEncrypted !== undefined &&
      (obj.end2endEncrypted = message.end2endEncrypted);
    message.currentQuantum !== undefined &&
      (obj.currentQuantum = Math.round(message.currentQuantum));
    message.freeQuantum !== undefined &&
      (obj.freeQuantum = Math.round(message.freeQuantum));
    message.maxQuantum !== undefined &&
      (obj.maxQuantum = Math.round(message.maxQuantum));
    message.currentUsers !== undefined &&
      (obj.currentUsers = Math.round(message.currentUsers));
    message.maxUsers !== undefined &&
      (obj.maxUsers = Math.round(message.maxUsers));
    message.freeUsers !== undefined &&
      (obj.freeUsers = Math.round(message.freeUsers));
    message.maxFilesize !== undefined &&
      (obj.maxFilesize = Math.round(message.maxFilesize));
    message.dateLastChange !== undefined &&
      (obj.dateLastChange = message.dateLastChange.toISOString());
    message.isVueDataroom !== undefined &&
      (obj.isVueDataroom = message.isVueDataroom);
    return obj;
  },
};

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
