/* eslint-disable */
import {
  SortOrder,
  SortDirection,
  sortOrderFromJSON,
  sortDirectionFromJSON,
  sortOrderToJSON,
  sortDirectionToJSON,
} from './util';
import { User, UserLoginActivity } from './user';
import { Dataroom } from './dataroom';
import { ProfileImage } from './image';
import {
  PasswordResetOptions,
  UserSettings,
  passwordResetOptionsFromJSON,
  passwordResetOptionsToJSON,
} from './settings';
import { FilesItem } from './files';
import { MFTInboxItem, MFTOutboxItem } from './mft';

export const protobufPackage = 'de.netfiles.pb';

/** Response on the GetMe-Call */
export interface GetMeResponse {
  me?: User | undefined;
}

/** Update my data request */
export interface UpdateMeRequest {
  me: User | undefined;
}

/** Update My data response */
export interface UpdateMeResponse {
  me?: User | undefined;
}

/** The response from the GetMyDataroomsRequest */
export interface GetMyDataroomsResponse {
  datarooms: Dataroom[];
}

/** Get Profile Image Response */
export interface GetMyProfileImageResponse {
  image?: ProfileImage | undefined;
}

/** Set new Profile Image Request */
export interface SetMyProfileImageRequest {
  image: ProfileImage | undefined;
}

/** Change Password request data */
export interface ChangePasswordRequest {
  oldPassword: string;
  newPassword: string;
}

/** Password requirements data */
export interface PasswordRequirements {
  passwordMinLength: number;
  passwordRequiresNumbers: boolean;
  passwordRequiresSpecialChars: boolean;
  passwordRequiresMixedLetters: boolean;
  passwordBlacklist: string[];
  passwordReset?: PasswordResetOptions | undefined;
}

/** Password requirements */
export interface PasswordRequirementsResponse {
  dataroomSettings: PasswordRequirements | undefined;
}

/** Settings for change security question */
export interface SecurityQuestionRequirements {
  passwordReset?: PasswordResetOptions | undefined;
  predefinedQuestions: string[];
  question?: string | undefined;
  smsLock?: string | undefined;
  phone?: string | undefined;
  messageId?: number | undefined;
}

/** Security question settings response */
export interface SecurityQuestionRequirementsResponse {
  settings: SecurityQuestionRequirements | undefined;
}

/** Security question settings */
export interface ChangeSecurityQuestionRequest {
  question: string;
  answer: string;
  confirmPassword: string;
}

/** Get current user's settings => response */
export interface MySettingsResponse {
  settings?: UserSettings | undefined;
}

/** Change current user's settings */
export interface UpdateMySettingsRequest {
  settings: UserSettings | undefined;
}

/** Delete one of my iterators */
export interface DeleteIteratorRequest {
  dataroomId: string;
  iterator: string;
}

/** Get current user's favorites -> Response */
export interface MyFavoritesResponse {
  items: FilesItem[];
}

/** Get current user's favorites */
export interface GetMyFavoritesRequest {
  dataroomId: string;
}

/** The Favorites */
export interface Favorites {
  id: string;
  position: number;
}

/** Add current user's favorites */
export interface AddMyFavoritesRequest {
  items: Favorites[];
  dataroomId: string;
}

/** Delete current user's favorite */
export interface DeleteMyFavoriteRequest {
  itemId: string;
  dataroomId: string;
}

/** Sort current user's favorite */
export interface SortMyFavoriteRequest {
  itemId: string;
  position: number;
  dataroomId: string;
}

/** Get children of MFT box request */
export interface GetMFTBoxChildrenRequest {
  dataroomId: string;
  limit: number;
  iterator: string;
  startIndex: number;
  sortOrder: SortOrder;
  sortDirection: SortDirection;
}

/** Get children of inbox response */
export interface GetMFTInboxChildrenResponse {
  items: MFTInboxItem[];
  totalNumChildren: number;
  iterator: string;
  startIndex: number;
  sortOrder: SortOrder;
  sortDirection: SortDirection;
}

/** Get children of outbox response */
export interface GetMFTOutboxChildrenResponse {
  items: MFTOutboxItem[];
  totalNumChildren: number;
  iterator: string;
  startIndex: number;
  sortOrder: SortOrder;
  sortDirection: SortDirection;
}

/** Get files within box item */
export interface GetMFTBoxItemChildrenRequest {
  dataroomId: string;
  itemId: string;
  limit: number;
  iterator: string;
  startIndex: number;
  sortOrder: SortOrder;
  sortDirection: SortDirection;
}

/** Get MFT Box item */
export interface GetMFTBoxItemRequest {
  dataroomId: string;
  itemId: string;
}

/** Delete MFT Box items */
export interface DeleteMFTBoxItemRequest {
  dataroomId: string;
  itemIds: string[];
}

/** Get MFT Inbox item response */
export interface GetMFTInboxItemResponse {
  item: MFTInboxItem | undefined;
}

/** Create MFT Inbox item */
export interface CreateMFTInboxItemRequest {
  dataroomId: string;
  item: MFTInboxItem | undefined;
}

/** Create MFT Outbox item */
export interface CreateMFTOutboxItemRequest {
  dataroomId: string;
  item: MFTOutboxItem | undefined;
  uploadFinished: boolean;
}

/** Get MFT Outbox item response */
export interface GetMFTOutboxItemResponse {
  item: MFTOutboxItem | undefined;
}

/** Upload files to outbox */
export interface UploadToMFTOutboxRequest {
  itemId: string;
  dataroomId: string;
  uploadFinished: boolean;
  file: Uint8Array;
}

/** UpdateBoxItemRequest */
export interface UpdateInboxItemRequest {
  dataroomId: string;
  itemId: string;
  item: MFTInboxItem | undefined;
}

/** UpdateOutboxItemRequest */
export interface UpdateOutboxItemRequest {
  dataroomId: string;
  itemId: string;
  item: MFTOutboxItem | undefined;
}

/** GetInboxItem Response */
export interface UpdateInboxItemResponse {
  item?: MFTInboxItem | undefined;
}

/** GetItem Response */
export interface UpdateOutboxItemResponse {
  item?: MFTOutboxItem | undefined;
}

/** Accept disclaimer request */
export interface AcceptDisclaimerRequest {
  dataroomId: string;
  value: boolean;
}

/** Passkey Registration Options Request */
export interface PasskeyRegistrationOptionsRequest {
  username: string;
}

/** Passkey Registration Options Response */
export interface PasskeyRegistrationOptionsResponse {
  challengeId: string;
  pkro: string;
  ttl: number;
}

/** Register Passkey Request */
export interface RegisterPasskeyRequest {
  challengeId: string;
  pkrr: string;
  targetClientId: string;
  userOs: string;
  userApp: string;
}

/** Register Passkey Response */
export interface RegisterPasskeyResponse {
  verified?: boolean | undefined;
}

/**
 * Passkey Device Info
 *
 * This is a single passkey device for transport only.
 *
 * This does *not* include the PublicKey or the AuthenticatorDeviceFuture[]
 */
export interface PasskeyInfo {
  credentialId: string;
  dateCreated: string;
  platformCreated?: string | undefined;
  lastUseDate?: string | undefined;
  lastUseApp?: string | undefined;
}

/**
 * PasskeyInfo Response
 *
 * This is the response containing passkey information for the current
 * user
 */
export interface PasskeyInfoResponse {
  passkeys: PasskeyInfo[];
}

/** Delete Passkey Request */
export interface DeletePasskeyRequest {
  credentialId: string;
  username?: string | undefined;
  password?: string | undefined;
}

/** Has User Authenticated Response */
export interface HasUserAuthenticatedRecentlyResponse {
  authenticated: boolean;
}

/** Get login activity request */
export interface GetLoginActivityRequest {
  dataroomId: string;
  limit: number;
  iterator: string;
  startIndex: number;
  sortOrder: SortOrder;
  sortDirection: SortDirection;
}

/** Get login activity request */
export interface GetLoginActivityResponse {
  items: UserLoginActivity[];
  totalNumChildren: number;
  iterator: string;
  startIndex: number;
  sortOrder: SortOrder;
  sortDirection: SortDirection;
}

/** Delete login activity request */
export interface DeleteLoginActivityRequest {
  itemIds: string[];
  jobId?: string | undefined;
}

function createBaseGetMeResponse(): GetMeResponse {
  return { me: undefined };
}

export const GetMeResponse = {
  fromJSON(object: any): GetMeResponse {
    return {
      me: isSet(object.me) ? User.fromJSON(object.me) : undefined,
    };
  },

  toJSON(message: GetMeResponse): unknown {
    const obj: any = {};
    message.me !== undefined &&
      (obj.me = message.me ? User.toJSON(message.me) : undefined);
    return obj;
  },
};

function createBaseUpdateMeRequest(): UpdateMeRequest {
  return { me: undefined };
}

export const UpdateMeRequest = {
  fromJSON(object: any): UpdateMeRequest {
    return {
      me: isSet(object.me) ? User.fromJSON(object.me) : undefined,
    };
  },

  toJSON(message: UpdateMeRequest): unknown {
    const obj: any = {};
    message.me !== undefined &&
      (obj.me = message.me ? User.toJSON(message.me) : undefined);
    return obj;
  },
};

function createBaseUpdateMeResponse(): UpdateMeResponse {
  return { me: undefined };
}

export const UpdateMeResponse = {
  fromJSON(object: any): UpdateMeResponse {
    return {
      me: isSet(object.me) ? User.fromJSON(object.me) : undefined,
    };
  },

  toJSON(message: UpdateMeResponse): unknown {
    const obj: any = {};
    message.me !== undefined &&
      (obj.me = message.me ? User.toJSON(message.me) : undefined);
    return obj;
  },
};

function createBaseGetMyDataroomsResponse(): GetMyDataroomsResponse {
  return { datarooms: [] };
}

export const GetMyDataroomsResponse = {
  fromJSON(object: any): GetMyDataroomsResponse {
    return {
      datarooms: Array.isArray(object?.datarooms)
        ? object.datarooms.map((e: any) => Dataroom.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetMyDataroomsResponse): unknown {
    const obj: any = {};
    if (message.datarooms) {
      obj.datarooms = message.datarooms.map(e =>
        e ? Dataroom.toJSON(e) : undefined,
      );
    } else {
      obj.datarooms = [];
    }
    return obj;
  },
};

function createBaseGetMyProfileImageResponse(): GetMyProfileImageResponse {
  return { image: undefined };
}

export const GetMyProfileImageResponse = {
  fromJSON(object: any): GetMyProfileImageResponse {
    return {
      image: isSet(object.image)
        ? ProfileImage.fromJSON(object.image)
        : undefined,
    };
  },

  toJSON(message: GetMyProfileImageResponse): unknown {
    const obj: any = {};
    message.image !== undefined &&
      (obj.image = message.image
        ? ProfileImage.toJSON(message.image)
        : undefined);
    return obj;
  },
};

function createBaseSetMyProfileImageRequest(): SetMyProfileImageRequest {
  return { image: undefined };
}

export const SetMyProfileImageRequest = {
  fromJSON(object: any): SetMyProfileImageRequest {
    return {
      image: isSet(object.image)
        ? ProfileImage.fromJSON(object.image)
        : undefined,
    };
  },

  toJSON(message: SetMyProfileImageRequest): unknown {
    const obj: any = {};
    message.image !== undefined &&
      (obj.image = message.image
        ? ProfileImage.toJSON(message.image)
        : undefined);
    return obj;
  },
};

function createBaseChangePasswordRequest(): ChangePasswordRequest {
  return { oldPassword: '', newPassword: '' };
}

export const ChangePasswordRequest = {
  fromJSON(object: any): ChangePasswordRequest {
    return {
      oldPassword: isSet(object.oldPassword) ? String(object.oldPassword) : '',
      newPassword: isSet(object.newPassword) ? String(object.newPassword) : '',
    };
  },

  toJSON(message: ChangePasswordRequest): unknown {
    const obj: any = {};
    message.oldPassword !== undefined &&
      (obj.oldPassword = message.oldPassword);
    message.newPassword !== undefined &&
      (obj.newPassword = message.newPassword);
    return obj;
  },
};

function createBasePasswordRequirements(): PasswordRequirements {
  return {
    passwordMinLength: 0,
    passwordRequiresNumbers: false,
    passwordRequiresSpecialChars: false,
    passwordRequiresMixedLetters: false,
    passwordBlacklist: [],
    passwordReset: undefined,
  };
}

export const PasswordRequirements = {
  fromJSON(object: any): PasswordRequirements {
    return {
      passwordMinLength: isSet(object.passwordMinLength)
        ? Number(object.passwordMinLength)
        : 0,
      passwordRequiresNumbers: isSet(object.passwordRequiresNumbers)
        ? Boolean(object.passwordRequiresNumbers)
        : false,
      passwordRequiresSpecialChars: isSet(object.passwordRequiresSpecialChars)
        ? Boolean(object.passwordRequiresSpecialChars)
        : false,
      passwordRequiresMixedLetters: isSet(object.passwordRequiresMixedLetters)
        ? Boolean(object.passwordRequiresMixedLetters)
        : false,
      passwordBlacklist: Array.isArray(object?.passwordBlacklist)
        ? object.passwordBlacklist.map((e: any) => String(e))
        : [],
      passwordReset: isSet(object.passwordReset)
        ? passwordResetOptionsFromJSON(object.passwordReset)
        : undefined,
    };
  },

  toJSON(message: PasswordRequirements): unknown {
    const obj: any = {};
    message.passwordMinLength !== undefined &&
      (obj.passwordMinLength = Math.round(message.passwordMinLength));
    message.passwordRequiresNumbers !== undefined &&
      (obj.passwordRequiresNumbers = message.passwordRequiresNumbers);
    message.passwordRequiresSpecialChars !== undefined &&
      (obj.passwordRequiresSpecialChars = message.passwordRequiresSpecialChars);
    message.passwordRequiresMixedLetters !== undefined &&
      (obj.passwordRequiresMixedLetters = message.passwordRequiresMixedLetters);
    if (message.passwordBlacklist) {
      obj.passwordBlacklist = message.passwordBlacklist.map(e => e);
    } else {
      obj.passwordBlacklist = [];
    }
    message.passwordReset !== undefined &&
      (obj.passwordReset =
        message.passwordReset !== undefined
          ? passwordResetOptionsToJSON(message.passwordReset)
          : undefined);
    return obj;
  },
};

function createBasePasswordRequirementsResponse(): PasswordRequirementsResponse {
  return { dataroomSettings: undefined };
}

export const PasswordRequirementsResponse = {
  fromJSON(object: any): PasswordRequirementsResponse {
    return {
      dataroomSettings: isSet(object.dataroomSettings)
        ? PasswordRequirements.fromJSON(object.dataroomSettings)
        : undefined,
    };
  },

  toJSON(message: PasswordRequirementsResponse): unknown {
    const obj: any = {};
    message.dataroomSettings !== undefined &&
      (obj.dataroomSettings = message.dataroomSettings
        ? PasswordRequirements.toJSON(message.dataroomSettings)
        : undefined);
    return obj;
  },
};

function createBaseSecurityQuestionRequirements(): SecurityQuestionRequirements {
  return {
    passwordReset: undefined,
    predefinedQuestions: [],
    question: undefined,
    smsLock: undefined,
    phone: undefined,
    messageId: undefined,
  };
}

export const SecurityQuestionRequirements = {
  fromJSON(object: any): SecurityQuestionRequirements {
    return {
      passwordReset: isSet(object.passwordReset)
        ? passwordResetOptionsFromJSON(object.passwordReset)
        : undefined,
      predefinedQuestions: Array.isArray(object?.predefinedQuestions)
        ? object.predefinedQuestions.map((e: any) => String(e))
        : [],
      question: isSet(object.question) ? String(object.question) : undefined,
      smsLock: isSet(object.smsLock) ? String(object.smsLock) : undefined,
      phone: isSet(object.phone) ? String(object.phone) : undefined,
      messageId: isSet(object.messageId) ? Number(object.messageId) : undefined,
    };
  },

  toJSON(message: SecurityQuestionRequirements): unknown {
    const obj: any = {};
    message.passwordReset !== undefined &&
      (obj.passwordReset =
        message.passwordReset !== undefined
          ? passwordResetOptionsToJSON(message.passwordReset)
          : undefined);
    if (message.predefinedQuestions) {
      obj.predefinedQuestions = message.predefinedQuestions.map(e => e);
    } else {
      obj.predefinedQuestions = [];
    }
    message.question !== undefined && (obj.question = message.question);
    message.smsLock !== undefined && (obj.smsLock = message.smsLock);
    message.phone !== undefined && (obj.phone = message.phone);
    message.messageId !== undefined &&
      (obj.messageId = Math.round(message.messageId));
    return obj;
  },
};

function createBaseSecurityQuestionRequirementsResponse(): SecurityQuestionRequirementsResponse {
  return { settings: undefined };
}

export const SecurityQuestionRequirementsResponse = {
  fromJSON(object: any): SecurityQuestionRequirementsResponse {
    return {
      settings: isSet(object.settings)
        ? SecurityQuestionRequirements.fromJSON(object.settings)
        : undefined,
    };
  },

  toJSON(message: SecurityQuestionRequirementsResponse): unknown {
    const obj: any = {};
    message.settings !== undefined &&
      (obj.settings = message.settings
        ? SecurityQuestionRequirements.toJSON(message.settings)
        : undefined);
    return obj;
  },
};

function createBaseChangeSecurityQuestionRequest(): ChangeSecurityQuestionRequest {
  return { question: '', answer: '', confirmPassword: '' };
}

export const ChangeSecurityQuestionRequest = {
  fromJSON(object: any): ChangeSecurityQuestionRequest {
    return {
      question: isSet(object.question) ? String(object.question) : '',
      answer: isSet(object.answer) ? String(object.answer) : '',
      confirmPassword: isSet(object.confirmPassword)
        ? String(object.confirmPassword)
        : '',
    };
  },

  toJSON(message: ChangeSecurityQuestionRequest): unknown {
    const obj: any = {};
    message.question !== undefined && (obj.question = message.question);
    message.answer !== undefined && (obj.answer = message.answer);
    message.confirmPassword !== undefined &&
      (obj.confirmPassword = message.confirmPassword);
    return obj;
  },
};

function createBaseMySettingsResponse(): MySettingsResponse {
  return { settings: undefined };
}

export const MySettingsResponse = {
  fromJSON(object: any): MySettingsResponse {
    return {
      settings: isSet(object.settings)
        ? UserSettings.fromJSON(object.settings)
        : undefined,
    };
  },

  toJSON(message: MySettingsResponse): unknown {
    const obj: any = {};
    message.settings !== undefined &&
      (obj.settings = message.settings
        ? UserSettings.toJSON(message.settings)
        : undefined);
    return obj;
  },
};

function createBaseUpdateMySettingsRequest(): UpdateMySettingsRequest {
  return { settings: undefined };
}

export const UpdateMySettingsRequest = {
  fromJSON(object: any): UpdateMySettingsRequest {
    return {
      settings: isSet(object.settings)
        ? UserSettings.fromJSON(object.settings)
        : undefined,
    };
  },

  toJSON(message: UpdateMySettingsRequest): unknown {
    const obj: any = {};
    message.settings !== undefined &&
      (obj.settings = message.settings
        ? UserSettings.toJSON(message.settings)
        : undefined);
    return obj;
  },
};

function createBaseDeleteIteratorRequest(): DeleteIteratorRequest {
  return { dataroomId: '', iterator: '' };
}

export const DeleteIteratorRequest = {
  fromJSON(object: any): DeleteIteratorRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      iterator: isSet(object.iterator) ? String(object.iterator) : '',
    };
  },

  toJSON(message: DeleteIteratorRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.iterator !== undefined && (obj.iterator = message.iterator);
    return obj;
  },
};

function createBaseMyFavoritesResponse(): MyFavoritesResponse {
  return { items: [] };
}

export const MyFavoritesResponse = {
  fromJSON(object: any): MyFavoritesResponse {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => FilesItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MyFavoritesResponse): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map(e => (e ? FilesItem.toJSON(e) : undefined));
    } else {
      obj.items = [];
    }
    return obj;
  },
};

function createBaseGetMyFavoritesRequest(): GetMyFavoritesRequest {
  return { dataroomId: '' };
}

export const GetMyFavoritesRequest = {
  fromJSON(object: any): GetMyFavoritesRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
    };
  },

  toJSON(message: GetMyFavoritesRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    return obj;
  },
};

function createBaseFavorites(): Favorites {
  return { id: '', position: 0 };
}

export const Favorites = {
  fromJSON(object: any): Favorites {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      position: isSet(object.position) ? Number(object.position) : 0,
    };
  },

  toJSON(message: Favorites): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.position !== undefined &&
      (obj.position = Math.round(message.position));
    return obj;
  },
};

function createBaseAddMyFavoritesRequest(): AddMyFavoritesRequest {
  return { items: [], dataroomId: '' };
}

export const AddMyFavoritesRequest = {
  fromJSON(object: any): AddMyFavoritesRequest {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => Favorites.fromJSON(e))
        : [],
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
    };
  },

  toJSON(message: AddMyFavoritesRequest): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map(e => (e ? Favorites.toJSON(e) : undefined));
    } else {
      obj.items = [];
    }
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    return obj;
  },
};

function createBaseDeleteMyFavoriteRequest(): DeleteMyFavoriteRequest {
  return { itemId: '', dataroomId: '' };
}

export const DeleteMyFavoriteRequest = {
  fromJSON(object: any): DeleteMyFavoriteRequest {
    return {
      itemId: isSet(object.itemId) ? String(object.itemId) : '',
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
    };
  },

  toJSON(message: DeleteMyFavoriteRequest): unknown {
    const obj: any = {};
    message.itemId !== undefined && (obj.itemId = message.itemId);
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    return obj;
  },
};

function createBaseSortMyFavoriteRequest(): SortMyFavoriteRequest {
  return { itemId: '', position: 0, dataroomId: '' };
}

export const SortMyFavoriteRequest = {
  fromJSON(object: any): SortMyFavoriteRequest {
    return {
      itemId: isSet(object.itemId) ? String(object.itemId) : '',
      position: isSet(object.position) ? Number(object.position) : 0,
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
    };
  },

  toJSON(message: SortMyFavoriteRequest): unknown {
    const obj: any = {};
    message.itemId !== undefined && (obj.itemId = message.itemId);
    message.position !== undefined &&
      (obj.position = Math.round(message.position));
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    return obj;
  },
};

function createBaseGetMFTBoxChildrenRequest(): GetMFTBoxChildrenRequest {
  return {
    dataroomId: '',
    limit: 0,
    iterator: '',
    startIndex: 0,
    sortOrder: 0,
    sortDirection: 0,
  };
}

export const GetMFTBoxChildrenRequest = {
  fromJSON(object: any): GetMFTBoxChildrenRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      limit: isSet(object.limit) ? Number(object.limit) : 0,
      iterator: isSet(object.iterator) ? String(object.iterator) : '',
      startIndex: isSet(object.startIndex) ? Number(object.startIndex) : 0,
      sortOrder: isSet(object.sortOrder)
        ? sortOrderFromJSON(object.sortOrder)
        : 0,
      sortDirection: isSet(object.sortDirection)
        ? sortDirectionFromJSON(object.sortDirection)
        : 0,
    };
  },

  toJSON(message: GetMFTBoxChildrenRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.limit !== undefined && (obj.limit = Math.round(message.limit));
    message.iterator !== undefined && (obj.iterator = message.iterator);
    message.startIndex !== undefined &&
      (obj.startIndex = Math.round(message.startIndex));
    message.sortOrder !== undefined &&
      (obj.sortOrder = sortOrderToJSON(message.sortOrder));
    message.sortDirection !== undefined &&
      (obj.sortDirection = sortDirectionToJSON(message.sortDirection));
    return obj;
  },
};

function createBaseGetMFTInboxChildrenResponse(): GetMFTInboxChildrenResponse {
  return {
    items: [],
    totalNumChildren: 0,
    iterator: '',
    startIndex: 0,
    sortOrder: 0,
    sortDirection: 0,
  };
}

export const GetMFTInboxChildrenResponse = {
  fromJSON(object: any): GetMFTInboxChildrenResponse {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => MFTInboxItem.fromJSON(e))
        : [],
      totalNumChildren: isSet(object.totalNumChildren)
        ? Number(object.totalNumChildren)
        : 0,
      iterator: isSet(object.iterator) ? String(object.iterator) : '',
      startIndex: isSet(object.startIndex) ? Number(object.startIndex) : 0,
      sortOrder: isSet(object.sortOrder)
        ? sortOrderFromJSON(object.sortOrder)
        : 0,
      sortDirection: isSet(object.sortDirection)
        ? sortDirectionFromJSON(object.sortDirection)
        : 0,
    };
  },

  toJSON(message: GetMFTInboxChildrenResponse): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map(e =>
        e ? MFTInboxItem.toJSON(e) : undefined,
      );
    } else {
      obj.items = [];
    }
    message.totalNumChildren !== undefined &&
      (obj.totalNumChildren = Math.round(message.totalNumChildren));
    message.iterator !== undefined && (obj.iterator = message.iterator);
    message.startIndex !== undefined &&
      (obj.startIndex = Math.round(message.startIndex));
    message.sortOrder !== undefined &&
      (obj.sortOrder = sortOrderToJSON(message.sortOrder));
    message.sortDirection !== undefined &&
      (obj.sortDirection = sortDirectionToJSON(message.sortDirection));
    return obj;
  },
};

function createBaseGetMFTOutboxChildrenResponse(): GetMFTOutboxChildrenResponse {
  return {
    items: [],
    totalNumChildren: 0,
    iterator: '',
    startIndex: 0,
    sortOrder: 0,
    sortDirection: 0,
  };
}

export const GetMFTOutboxChildrenResponse = {
  fromJSON(object: any): GetMFTOutboxChildrenResponse {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => MFTOutboxItem.fromJSON(e))
        : [],
      totalNumChildren: isSet(object.totalNumChildren)
        ? Number(object.totalNumChildren)
        : 0,
      iterator: isSet(object.iterator) ? String(object.iterator) : '',
      startIndex: isSet(object.startIndex) ? Number(object.startIndex) : 0,
      sortOrder: isSet(object.sortOrder)
        ? sortOrderFromJSON(object.sortOrder)
        : 0,
      sortDirection: isSet(object.sortDirection)
        ? sortDirectionFromJSON(object.sortDirection)
        : 0,
    };
  },

  toJSON(message: GetMFTOutboxChildrenResponse): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map(e =>
        e ? MFTOutboxItem.toJSON(e) : undefined,
      );
    } else {
      obj.items = [];
    }
    message.totalNumChildren !== undefined &&
      (obj.totalNumChildren = Math.round(message.totalNumChildren));
    message.iterator !== undefined && (obj.iterator = message.iterator);
    message.startIndex !== undefined &&
      (obj.startIndex = Math.round(message.startIndex));
    message.sortOrder !== undefined &&
      (obj.sortOrder = sortOrderToJSON(message.sortOrder));
    message.sortDirection !== undefined &&
      (obj.sortDirection = sortDirectionToJSON(message.sortDirection));
    return obj;
  },
};

function createBaseGetMFTBoxItemChildrenRequest(): GetMFTBoxItemChildrenRequest {
  return {
    dataroomId: '',
    itemId: '',
    limit: 0,
    iterator: '',
    startIndex: 0,
    sortOrder: 0,
    sortDirection: 0,
  };
}

export const GetMFTBoxItemChildrenRequest = {
  fromJSON(object: any): GetMFTBoxItemChildrenRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      itemId: isSet(object.itemId) ? String(object.itemId) : '',
      limit: isSet(object.limit) ? Number(object.limit) : 0,
      iterator: isSet(object.iterator) ? String(object.iterator) : '',
      startIndex: isSet(object.startIndex) ? Number(object.startIndex) : 0,
      sortOrder: isSet(object.sortOrder)
        ? sortOrderFromJSON(object.sortOrder)
        : 0,
      sortDirection: isSet(object.sortDirection)
        ? sortDirectionFromJSON(object.sortDirection)
        : 0,
    };
  },

  toJSON(message: GetMFTBoxItemChildrenRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.itemId !== undefined && (obj.itemId = message.itemId);
    message.limit !== undefined && (obj.limit = Math.round(message.limit));
    message.iterator !== undefined && (obj.iterator = message.iterator);
    message.startIndex !== undefined &&
      (obj.startIndex = Math.round(message.startIndex));
    message.sortOrder !== undefined &&
      (obj.sortOrder = sortOrderToJSON(message.sortOrder));
    message.sortDirection !== undefined &&
      (obj.sortDirection = sortDirectionToJSON(message.sortDirection));
    return obj;
  },
};

function createBaseGetMFTBoxItemRequest(): GetMFTBoxItemRequest {
  return { dataroomId: '', itemId: '' };
}

export const GetMFTBoxItemRequest = {
  fromJSON(object: any): GetMFTBoxItemRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      itemId: isSet(object.itemId) ? String(object.itemId) : '',
    };
  },

  toJSON(message: GetMFTBoxItemRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.itemId !== undefined && (obj.itemId = message.itemId);
    return obj;
  },
};

function createBaseDeleteMFTBoxItemRequest(): DeleteMFTBoxItemRequest {
  return { dataroomId: '', itemIds: [] };
}

export const DeleteMFTBoxItemRequest = {
  fromJSON(object: any): DeleteMFTBoxItemRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      itemIds: Array.isArray(object?.itemIds)
        ? object.itemIds.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: DeleteMFTBoxItemRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    if (message.itemIds) {
      obj.itemIds = message.itemIds.map(e => e);
    } else {
      obj.itemIds = [];
    }
    return obj;
  },
};

function createBaseGetMFTInboxItemResponse(): GetMFTInboxItemResponse {
  return { item: undefined };
}

export const GetMFTInboxItemResponse = {
  fromJSON(object: any): GetMFTInboxItemResponse {
    return {
      item: isSet(object.item) ? MFTInboxItem.fromJSON(object.item) : undefined,
    };
  },

  toJSON(message: GetMFTInboxItemResponse): unknown {
    const obj: any = {};
    message.item !== undefined &&
      (obj.item = message.item ? MFTInboxItem.toJSON(message.item) : undefined);
    return obj;
  },
};

function createBaseCreateMFTInboxItemRequest(): CreateMFTInboxItemRequest {
  return { dataroomId: '', item: undefined };
}

export const CreateMFTInboxItemRequest = {
  fromJSON(object: any): CreateMFTInboxItemRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      item: isSet(object.item) ? MFTInboxItem.fromJSON(object.item) : undefined,
    };
  },

  toJSON(message: CreateMFTInboxItemRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.item !== undefined &&
      (obj.item = message.item ? MFTInboxItem.toJSON(message.item) : undefined);
    return obj;
  },
};

function createBaseCreateMFTOutboxItemRequest(): CreateMFTOutboxItemRequest {
  return { dataroomId: '', item: undefined, uploadFinished: false };
}

export const CreateMFTOutboxItemRequest = {
  fromJSON(object: any): CreateMFTOutboxItemRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      item: isSet(object.item)
        ? MFTOutboxItem.fromJSON(object.item)
        : undefined,
      uploadFinished: isSet(object.uploadFinished)
        ? Boolean(object.uploadFinished)
        : false,
    };
  },

  toJSON(message: CreateMFTOutboxItemRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.item !== undefined &&
      (obj.item = message.item
        ? MFTOutboxItem.toJSON(message.item)
        : undefined);
    message.uploadFinished !== undefined &&
      (obj.uploadFinished = message.uploadFinished);
    return obj;
  },
};

function createBaseGetMFTOutboxItemResponse(): GetMFTOutboxItemResponse {
  return { item: undefined };
}

export const GetMFTOutboxItemResponse = {
  fromJSON(object: any): GetMFTOutboxItemResponse {
    return {
      item: isSet(object.item)
        ? MFTOutboxItem.fromJSON(object.item)
        : undefined,
    };
  },

  toJSON(message: GetMFTOutboxItemResponse): unknown {
    const obj: any = {};
    message.item !== undefined &&
      (obj.item = message.item
        ? MFTOutboxItem.toJSON(message.item)
        : undefined);
    return obj;
  },
};

function createBaseUploadToMFTOutboxRequest(): UploadToMFTOutboxRequest {
  return {
    itemId: '',
    dataroomId: '',
    uploadFinished: false,
    file: new Uint8Array(),
  };
}

export const UploadToMFTOutboxRequest = {
  fromJSON(object: any): UploadToMFTOutboxRequest {
    return {
      itemId: isSet(object.itemId) ? String(object.itemId) : '',
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      uploadFinished: isSet(object.uploadFinished)
        ? Boolean(object.uploadFinished)
        : false,
      file: isSet(object.file)
        ? bytesFromBase64(object.file)
        : new Uint8Array(),
    };
  },

  toJSON(message: UploadToMFTOutboxRequest): unknown {
    const obj: any = {};
    message.itemId !== undefined && (obj.itemId = message.itemId);
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.uploadFinished !== undefined &&
      (obj.uploadFinished = message.uploadFinished);
    message.file !== undefined &&
      (obj.file = base64FromBytes(
        message.file !== undefined ? message.file : new Uint8Array(),
      ));
    return obj;
  },
};

function createBaseUpdateInboxItemRequest(): UpdateInboxItemRequest {
  return { dataroomId: '', itemId: '', item: undefined };
}

export const UpdateInboxItemRequest = {
  fromJSON(object: any): UpdateInboxItemRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      itemId: isSet(object.itemId) ? String(object.itemId) : '',
      item: isSet(object.item) ? MFTInboxItem.fromJSON(object.item) : undefined,
    };
  },

  toJSON(message: UpdateInboxItemRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.itemId !== undefined && (obj.itemId = message.itemId);
    message.item !== undefined &&
      (obj.item = message.item ? MFTInboxItem.toJSON(message.item) : undefined);
    return obj;
  },
};

function createBaseUpdateOutboxItemRequest(): UpdateOutboxItemRequest {
  return { dataroomId: '', itemId: '', item: undefined };
}

export const UpdateOutboxItemRequest = {
  fromJSON(object: any): UpdateOutboxItemRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      itemId: isSet(object.itemId) ? String(object.itemId) : '',
      item: isSet(object.item)
        ? MFTOutboxItem.fromJSON(object.item)
        : undefined,
    };
  },

  toJSON(message: UpdateOutboxItemRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.itemId !== undefined && (obj.itemId = message.itemId);
    message.item !== undefined &&
      (obj.item = message.item
        ? MFTOutboxItem.toJSON(message.item)
        : undefined);
    return obj;
  },
};

function createBaseUpdateInboxItemResponse(): UpdateInboxItemResponse {
  return { item: undefined };
}

export const UpdateInboxItemResponse = {
  fromJSON(object: any): UpdateInboxItemResponse {
    return {
      item: isSet(object.item) ? MFTInboxItem.fromJSON(object.item) : undefined,
    };
  },

  toJSON(message: UpdateInboxItemResponse): unknown {
    const obj: any = {};
    message.item !== undefined &&
      (obj.item = message.item ? MFTInboxItem.toJSON(message.item) : undefined);
    return obj;
  },
};

function createBaseUpdateOutboxItemResponse(): UpdateOutboxItemResponse {
  return { item: undefined };
}

export const UpdateOutboxItemResponse = {
  fromJSON(object: any): UpdateOutboxItemResponse {
    return {
      item: isSet(object.item)
        ? MFTOutboxItem.fromJSON(object.item)
        : undefined,
    };
  },

  toJSON(message: UpdateOutboxItemResponse): unknown {
    const obj: any = {};
    message.item !== undefined &&
      (obj.item = message.item
        ? MFTOutboxItem.toJSON(message.item)
        : undefined);
    return obj;
  },
};

function createBaseAcceptDisclaimerRequest(): AcceptDisclaimerRequest {
  return { dataroomId: '', value: false };
}

export const AcceptDisclaimerRequest = {
  fromJSON(object: any): AcceptDisclaimerRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      value: isSet(object.value) ? Boolean(object.value) : false,
    };
  },

  toJSON(message: AcceptDisclaimerRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },
};

function createBasePasskeyRegistrationOptionsRequest(): PasskeyRegistrationOptionsRequest {
  return { username: '' };
}

export const PasskeyRegistrationOptionsRequest = {
  fromJSON(object: any): PasskeyRegistrationOptionsRequest {
    return {
      username: isSet(object.username) ? String(object.username) : '',
    };
  },

  toJSON(message: PasskeyRegistrationOptionsRequest): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    return obj;
  },
};

function createBasePasskeyRegistrationOptionsResponse(): PasskeyRegistrationOptionsResponse {
  return { challengeId: '', pkro: '', ttl: 0 };
}

export const PasskeyRegistrationOptionsResponse = {
  fromJSON(object: any): PasskeyRegistrationOptionsResponse {
    return {
      challengeId: isSet(object.challengeId) ? String(object.challengeId) : '',
      pkro: isSet(object.pkro) ? String(object.pkro) : '',
      ttl: isSet(object.ttl) ? Number(object.ttl) : 0,
    };
  },

  toJSON(message: PasskeyRegistrationOptionsResponse): unknown {
    const obj: any = {};
    message.challengeId !== undefined &&
      (obj.challengeId = message.challengeId);
    message.pkro !== undefined && (obj.pkro = message.pkro);
    message.ttl !== undefined && (obj.ttl = Math.round(message.ttl));
    return obj;
  },
};

function createBaseRegisterPasskeyRequest(): RegisterPasskeyRequest {
  return {
    challengeId: '',
    pkrr: '',
    targetClientId: '',
    userOs: '',
    userApp: '',
  };
}

export const RegisterPasskeyRequest = {
  fromJSON(object: any): RegisterPasskeyRequest {
    return {
      challengeId: isSet(object.challengeId) ? String(object.challengeId) : '',
      pkrr: isSet(object.pkrr) ? String(object.pkrr) : '',
      targetClientId: isSet(object.targetClientId)
        ? String(object.targetClientId)
        : '',
      userOs: isSet(object.userOs) ? String(object.userOs) : '',
      userApp: isSet(object.userApp) ? String(object.userApp) : '',
    };
  },

  toJSON(message: RegisterPasskeyRequest): unknown {
    const obj: any = {};
    message.challengeId !== undefined &&
      (obj.challengeId = message.challengeId);
    message.pkrr !== undefined && (obj.pkrr = message.pkrr);
    message.targetClientId !== undefined &&
      (obj.targetClientId = message.targetClientId);
    message.userOs !== undefined && (obj.userOs = message.userOs);
    message.userApp !== undefined && (obj.userApp = message.userApp);
    return obj;
  },
};

function createBaseRegisterPasskeyResponse(): RegisterPasskeyResponse {
  return { verified: undefined };
}

export const RegisterPasskeyResponse = {
  fromJSON(object: any): RegisterPasskeyResponse {
    return {
      verified: isSet(object.verified) ? Boolean(object.verified) : undefined,
    };
  },

  toJSON(message: RegisterPasskeyResponse): unknown {
    const obj: any = {};
    message.verified !== undefined && (obj.verified = message.verified);
    return obj;
  },
};

function createBasePasskeyInfo(): PasskeyInfo {
  return {
    credentialId: '',
    dateCreated: '',
    platformCreated: undefined,
    lastUseDate: undefined,
    lastUseApp: undefined,
  };
}

export const PasskeyInfo = {
  fromJSON(object: any): PasskeyInfo {
    return {
      credentialId: isSet(object.credentialId)
        ? String(object.credentialId)
        : '',
      dateCreated: isSet(object.dateCreated) ? String(object.dateCreated) : '',
      platformCreated: isSet(object.platformCreated)
        ? String(object.platformCreated)
        : undefined,
      lastUseDate: isSet(object.lastUseDate)
        ? String(object.lastUseDate)
        : undefined,
      lastUseApp: isSet(object.lastUseApp)
        ? String(object.lastUseApp)
        : undefined,
    };
  },

  toJSON(message: PasskeyInfo): unknown {
    const obj: any = {};
    message.credentialId !== undefined &&
      (obj.credentialId = message.credentialId);
    message.dateCreated !== undefined &&
      (obj.dateCreated = message.dateCreated);
    message.platformCreated !== undefined &&
      (obj.platformCreated = message.platformCreated);
    message.lastUseDate !== undefined &&
      (obj.lastUseDate = message.lastUseDate);
    message.lastUseApp !== undefined && (obj.lastUseApp = message.lastUseApp);
    return obj;
  },
};

function createBasePasskeyInfoResponse(): PasskeyInfoResponse {
  return { passkeys: [] };
}

export const PasskeyInfoResponse = {
  fromJSON(object: any): PasskeyInfoResponse {
    return {
      passkeys: Array.isArray(object?.passkeys)
        ? object.passkeys.map((e: any) => PasskeyInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PasskeyInfoResponse): unknown {
    const obj: any = {};
    if (message.passkeys) {
      obj.passkeys = message.passkeys.map(e =>
        e ? PasskeyInfo.toJSON(e) : undefined,
      );
    } else {
      obj.passkeys = [];
    }
    return obj;
  },
};

function createBaseDeletePasskeyRequest(): DeletePasskeyRequest {
  return { credentialId: '', username: undefined, password: undefined };
}

export const DeletePasskeyRequest = {
  fromJSON(object: any): DeletePasskeyRequest {
    return {
      credentialId: isSet(object.credentialId)
        ? String(object.credentialId)
        : '',
      username: isSet(object.username) ? String(object.username) : undefined,
      password: isSet(object.password) ? String(object.password) : undefined,
    };
  },

  toJSON(message: DeletePasskeyRequest): unknown {
    const obj: any = {};
    message.credentialId !== undefined &&
      (obj.credentialId = message.credentialId);
    message.username !== undefined && (obj.username = message.username);
    message.password !== undefined && (obj.password = message.password);
    return obj;
  },
};

function createBaseHasUserAuthenticatedRecentlyResponse(): HasUserAuthenticatedRecentlyResponse {
  return { authenticated: false };
}

export const HasUserAuthenticatedRecentlyResponse = {
  fromJSON(object: any): HasUserAuthenticatedRecentlyResponse {
    return {
      authenticated: isSet(object.authenticated)
        ? Boolean(object.authenticated)
        : false,
    };
  },

  toJSON(message: HasUserAuthenticatedRecentlyResponse): unknown {
    const obj: any = {};
    message.authenticated !== undefined &&
      (obj.authenticated = message.authenticated);
    return obj;
  },
};

function createBaseGetLoginActivityRequest(): GetLoginActivityRequest {
  return {
    dataroomId: '',
    limit: 0,
    iterator: '',
    startIndex: 0,
    sortOrder: 0,
    sortDirection: 0,
  };
}

export const GetLoginActivityRequest = {
  fromJSON(object: any): GetLoginActivityRequest {
    return {
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      limit: isSet(object.limit) ? Number(object.limit) : 0,
      iterator: isSet(object.iterator) ? String(object.iterator) : '',
      startIndex: isSet(object.startIndex) ? Number(object.startIndex) : 0,
      sortOrder: isSet(object.sortOrder)
        ? sortOrderFromJSON(object.sortOrder)
        : 0,
      sortDirection: isSet(object.sortDirection)
        ? sortDirectionFromJSON(object.sortDirection)
        : 0,
    };
  },

  toJSON(message: GetLoginActivityRequest): unknown {
    const obj: any = {};
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.limit !== undefined && (obj.limit = Math.round(message.limit));
    message.iterator !== undefined && (obj.iterator = message.iterator);
    message.startIndex !== undefined &&
      (obj.startIndex = Math.round(message.startIndex));
    message.sortOrder !== undefined &&
      (obj.sortOrder = sortOrderToJSON(message.sortOrder));
    message.sortDirection !== undefined &&
      (obj.sortDirection = sortDirectionToJSON(message.sortDirection));
    return obj;
  },
};

function createBaseGetLoginActivityResponse(): GetLoginActivityResponse {
  return {
    items: [],
    totalNumChildren: 0,
    iterator: '',
    startIndex: 0,
    sortOrder: 0,
    sortDirection: 0,
  };
}

export const GetLoginActivityResponse = {
  fromJSON(object: any): GetLoginActivityResponse {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => UserLoginActivity.fromJSON(e))
        : [],
      totalNumChildren: isSet(object.totalNumChildren)
        ? Number(object.totalNumChildren)
        : 0,
      iterator: isSet(object.iterator) ? String(object.iterator) : '',
      startIndex: isSet(object.startIndex) ? Number(object.startIndex) : 0,
      sortOrder: isSet(object.sortOrder)
        ? sortOrderFromJSON(object.sortOrder)
        : 0,
      sortDirection: isSet(object.sortDirection)
        ? sortDirectionFromJSON(object.sortDirection)
        : 0,
    };
  },

  toJSON(message: GetLoginActivityResponse): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map(e =>
        e ? UserLoginActivity.toJSON(e) : undefined,
      );
    } else {
      obj.items = [];
    }
    message.totalNumChildren !== undefined &&
      (obj.totalNumChildren = Math.round(message.totalNumChildren));
    message.iterator !== undefined && (obj.iterator = message.iterator);
    message.startIndex !== undefined &&
      (obj.startIndex = Math.round(message.startIndex));
    message.sortOrder !== undefined &&
      (obj.sortOrder = sortOrderToJSON(message.sortOrder));
    message.sortDirection !== undefined &&
      (obj.sortDirection = sortDirectionToJSON(message.sortDirection));
    return obj;
  },
};

function createBaseDeleteLoginActivityRequest(): DeleteLoginActivityRequest {
  return { itemIds: [], jobId: undefined };
}

export const DeleteLoginActivityRequest = {
  fromJSON(object: any): DeleteLoginActivityRequest {
    return {
      itemIds: Array.isArray(object?.itemIds)
        ? object.itemIds.map((e: any) => String(e))
        : [],
      jobId: isSet(object.jobId) ? String(object.jobId) : undefined,
    };
  },

  toJSON(message: DeleteLoginActivityRequest): unknown {
    const obj: any = {};
    if (message.itemIds) {
      obj.itemIds = message.itemIds.map(e => e);
    } else {
      obj.itemIds = [];
    }
    message.jobId !== undefined && (obj.jobId = message.jobId);
    return obj;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

const atob: (b64: string) => string =
  globalThis.atob ||
  (b64 => globalThis.Buffer.from(b64, 'base64').toString('binary'));
function bytesFromBase64(b64: string): Uint8Array {
  const bin = atob(b64);
  const arr = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i);
  }
  return arr;
}

const btoa: (bin: string) => string =
  globalThis.btoa ||
  (bin => globalThis.Buffer.from(bin, 'binary').toString('base64'));
function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = [];
  arr.forEach(byte => {
    bin.push(String.fromCharCode(byte));
  });
  return btoa(bin.join(''));
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
