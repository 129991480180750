/*
 * Various Utility functions
 */

export function stringToBase64(str: string): string {
  const buffer = new TextEncoder().encode(str);
  const bytes = new Uint8Array(buffer);
  let binary = '';
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

export function stringFromBase64(str: string): string {
  const binary = atob(str);
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  const charCodes = new Uint8Array(bytes.buffer);
  let result = '';
  for (let i = 0; i < charCodes.length; i++) {
    result += String.fromCharCode(charCodes[i]);
  }
  return result;
}

function getExtension(filename: string): string {
  const dotIndex = filename.lastIndexOf('.');
  if (dotIndex === -1) {
    return '';
  }
  return filename.substring(dotIndex + 1);
}

export function stripIllegalChars(name: string): string {
  // Checking for non-printable chars is needed here
  // eslint-disable-next-line no-control-regex
  return name.replace(/[\]*{}:|\\/?"<>[\x00-\x1F\x7F]/g, '_');
}

export function sanitizeFilename(filename: string): string {
  filename = filename.normalize();
  if (filename.length == 0) {
    return filename;
  }
  if (filename.startsWith('.')) {
    filename = '_' + filename.substring(1);
  }
  if (filename.includes('/')) {
    filename = filename.replace(/\//g, '_');
  }

  filename = stripIllegalChars(filename);

  // Cut filename to 100 chars
  const ext = getExtension(filename);
  const name = filename.slice(0, -ext.length);
  const maxLength = 98 - ext.length;
  if (name.length > maxLength) {
    filename = name.substring(0, maxLength) + '.' + ext;
  }

  return filename;
}

/**
 * Checks if the given item is undefined
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isUndefined(item: any): boolean {
  return typeof item === 'undefined';
}

/**
 * Checks if the given item is null
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isNull(item: any): boolean {
  return item === null;
}

/**
 * Checks if the given item is undefined or null
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isUndefinedOrNull(item: any): boolean {
  return isUndefined(item) || isNull(item);
}

export function clientSecretSalt(delay = 0): string {
  const kSt = 'xhduy39937&#&@(((@JDHJADSMmndsfhjwo[pl;';
  const now = new Date();

  now.setHours(now.getHours() - delay);

  const day = now.getUTCDate();
  const month = now.getUTCDay();
  const year = now.getUTCFullYear();
  const hours = now.getUTCHours();
  return `${kSt}.${day}.${month}.${year}.${hours}`;
}

const kExtToLang: Record<string, string | undefined> = {
  '1c': '1c',
  abnf: 'abnf',
  accesslog: 'accesslog',
  actionscript: 'actionscript',
  ada: 'ada',
  all: 'all',
  angelscript: 'angelscript',
  apache: 'apache',
  applescript: 'applescript',
  arcade: 'arcade',
  arduino: 'arduino',
  armasm: 'armasm',
  asciidoc: 'asciidoc',
  asm: 'x86asm',
  aspectj: 'aspectj',
  autohotkey: 'autohotkey',
  autoit: 'autoit',
  avrasm: 'avrasm',
  awk: 'awk',
  axapta: 'axapta',
  bash: 'bash',
  basic: 'basic',
  bf: 'brainfuck',
  bnf: 'bnf',
  brainfuck: 'brainfuck',
  c: 'c',
  cc: 'cpp',
  'c++': 'cpp',
  cal: 'cal',
  capnproto: 'capnproto',
  ceylon: 'ceylon',
  clean: 'clean',
  clojure: 'clojure',
  'clojure-repl': 'clojure-repl',
  cmake: 'cmake',
  coffeescript: 'coffeescript',
  coq: 'coq',
  cos: 'cos',
  cpp: 'cpp',
  crmsh: 'crmsh',
  crystal: 'crystal',
  cs: 'cs',
  csp: 'csp',
  css: 'css',
  d: 'd',
  dart: 'dart',
  delphi: 'delphi',
  diff: 'diff',
  django: 'django',
  dns: 'dns',
  dockerfile: 'dockerfile',
  dos: 'dos',
  dsconfig: 'dsconfig',
  dts: 'dts',
  dust: 'dust',
  ebnf: 'ebnf',
  elixir: 'elixir',
  elm: 'elm',
  erb: 'erb',
  erlang: 'erlang',
  'erlang-repl': 'erlang-repl',
  excel: 'excel',
  'f#': 'fsharp',
  fix: 'fix',
  flix: 'flix',
  fortran: 'fortran',
  fs: 'fsharp',
  fsharp: 'fsharp',
  gams: 'gams',
  gauss: 'gauss',
  gcode: 'gcode',
  gherkin: 'gherkin',
  glsl: 'glsl',
  gml: 'gml',
  gn: 'gn',
  go: 'go',
  golo: 'golo',
  gradle: 'gradle',
  graphql: 'graphql',
  groovy: 'groovy',
  h: 'cpp',
  haml: 'haml',
  handlebars: 'handlebars',
  haskell: 'haskell',
  haxe: 'haxe',
  hpp: 'cpp',
  hsp: 'hsp',
  http: 'http',
  hxx: 'cpp',
  hy: 'hy',
  inform7: 'inform7',
  ini: 'ini',
  irpf90: 'irpf90',
  isbl: 'isbl',
  j: 'julia',
  java: 'java',
  javascript: 'javascript',
  'jboss-cli': 'jboss-cli',
  js: 'javascript',
  json: 'json',
  julia: 'julia',
  'julia-repl': 'julia-repl',
  kotlin: 'kotlin',
  kt: 'kotlin',
  lasso: 'lasso',
  ldif: 'ldif',
  leaf: 'leaf',
  less: 'less',
  lisp: 'lisp',
  livecodeserver: 'livecodeserver',
  livescript: 'livescript',
  llvm: 'llvm',
  lsl: 'lsl',
  lua: 'lua',
  m: 'objectivec',
  makefile: 'makefile',
  markdown: 'markdown',
  mathematica: 'mathematica',
  matlab: 'matlab',
  maxima: 'maxima',
  md: 'markdown',
  mel: 'mel',
  mercury: 'mercury',
  mipsasm: 'mipsasm',
  mizar: 'mizar',
  ml: 'matlab',
  mm: 'objectivecpp',
  mojolicious: 'mojolicious',
  monkey: 'monkey',
  moonscript: 'moonscript',
  n1ql: 'n1ql',
  nginx: 'nginx',
  nimrod: 'nimrod',
  nix: 'nix',
  nsis: 'nsis',
  objectivec: 'objectivec',
  ocaml: 'ocaml',
  openscad: 'openscad',
  oxygene: 'oxygene',
  parser3: 'parser3',
  pb: 'purebasic',
  perl: 'perl',
  pf: 'pf',
  pgsql: 'pgsql',
  php: 'php',
  pl: 'perl',
  plaintext: 'plaintext',
  plg: 'prolog',
  pony: 'pony',
  powershell: 'powershell',
  processing: 'processing',
  prof: 'profile',
  profile: 'profile',
  prolog: 'prolog',
  prop: 'properties',
  properties: 'properties',
  proto: 'protobuf',
  protobuf: 'protobuf',
  puppet: 'puppet',
  purebasic: 'purebasic',
  py: 'python',
  python: 'python',
  q: 'q',
  qml: 'qml',
  r: 'r',
  rb: 'ruby',
  reasonml: 'reasonml',
  rib: 'rib',
  roboconf: 'roboconf',
  routeros: 'routeros',
  rs: 'rust',
  rsl: 'rsl',
  ruby: 'ruby',
  ruleslanguage: 'ruleslanguage',
  rust: 'rust',
  sas: 'sas',
  scala: 'scala',
  scheme: 'scheme',
  scilab: 'scilab',
  scss: 'scss',
  sh: 'bash',
  shell: 'shell',
  sl: 'scala',
  smali: 'smali',
  smalltalk: 'smalltalk',
  sml: 'sml',
  solidity: 'solidity',
  sqf: 'sqf',
  sql: 'sql',
  st: 'smalltalk',
  stan: 'stan',
  stata: 'stata',
  step21: 'step21',
  stylus: 'stylus',
  subunit: 'subunit',
  swift: 'swift',
  taggerscript: 'taggerscript',
  tap: 'tap',
  tcl: 'tcl',
  tex: 'tex',
  thrift: 'thrift',
  tp: 'tp',
  ts: 'typescript',
  twig: 'twig',
  typescript: 'typescript',
  vala: 'vala',
  vb: 'vbscript',
  vbnet: 'vbnet',
  vbs: 'vbscript',
  vbscript: 'vbscript',
  verilog: 'verilog',
  vhdl: 'vhdl',
  vim: 'vim',
  vue: 'vue',
  x86asm: 'x86asm',
  xl: 'xl',
  xml: 'xml',
  xquery: 'xquery',
  yaml: 'yaml',
  yml: 'yaml',
  zephir: 'zephir',
};

export function markdownFileType(filename: string): string {
  const ext = getExtension(filename);
  if (ext === '') {
    return 'plaintext';
  }
  const type = kExtToLang[ext];
  if (type === undefined) {
    return 'plaintext';
  }
  return type;
}

export function isMarkdownFile(filename: string): boolean {
  const ext = getExtension(filename);
  if (ext === '') {
    return false;
  }
  return kExtToLang[ext] !== undefined;
}
