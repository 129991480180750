"use strict";
//Test-ids for cypress testing
Object.defineProperty(exports, "__esModule", { value: true });
exports.TestIdSelectGroupsUsersDialog = exports.TestIdSendInternalLinkDialog = exports.TestIdPropertiesDialog = exports.TestIdAccessPermissionsDialog = exports.TestIdDeleteUserDialog = exports.TestIdCreateUserDialog = exports.TestIdGroupDialog = exports.TestIdBookmarkDialog = exports.TestIdDocumentDialog = exports.TestIdFolderDialog = exports.TestIdDialogMoveCopy = exports.TestIdUserSettingsDialogResetPassword = exports.TestIdUserSettingsDialogPhoto = exports.TestIdUserSettingsDialogOptions = exports.TestIdUserSettingsDialogPersonalDataAdress = exports.TestIdUserSettingsDialogNavigationBar = exports.TestIdUserSettingsDialog = exports.TestIdProjectSettings = exports.TestIdDialog = exports.TestIdCommentBar = exports.TestIdInfoBar = exports.TestIdMftItemActionBar = exports.TestIdMftRootActionBar = exports.TestIdTrashActionBar = exports.TestIdUsersActionBar = exports.TestIdGroupsActionBar = exports.TestIdDocumentsActionBar = exports.TestIdProjectsActions = exports.TestIdNavigationBar = exports.TestIdProjectsNavigation = exports.TestIdBarTabs = exports.TestIdUserGroupWizard = exports.TestIdTipTapEditor = exports.TestIdDocumentViewer = exports.TestIdFooter = exports.TestIdDataview = exports.TestIdSortingBar = exports.TestIdListingToolbar = exports.TestIdProjectsListing = exports.TestIdUserSettingsDropdownMenu = exports.TestIdHeader = exports.TestIdLoginAcceptLoginMessage = exports.TestIdLoginTwoFaTypeSms = exports.TestIdLoginVerifyPassword = exports.TestIdLoginVerifyEmail = exports.TestIdLoginSetSecurityQuestion = exports.TestIdLoginPasskeyDialog = exports.TestIdLoginResetPasswordDialogSucceeded = exports.TestIdLoginResetPasswordDialog = exports.TestIdLoginDialog = void 0;
exports.TestIdMftOutboxDialog = exports.TestIdMftInboxDialog = exports.TestIdIndexDialog = exports.TestIdAlertDialog = exports.TestIdSendInvitationDialog = void 0;
//---------------------------------------------------------------------
/** LOGIN/RESET **/
/** -----login-----  **/
var TestIdLoginDialog;
(function (TestIdLoginDialog) {
    TestIdLoginDialog["TEST_ID_LOGIN_DIALOG_FormField_Username"] = "loginDialog_formField_username";
    TestIdLoginDialog["TEST_ID_LOGIN_DIALOG_FormField_Password"] = "loginDialog_formField_password";
    TestIdLoginDialog["TEST_ID_LOGIN_DIALOG_LoginButton"] = "loginDialog_loginButton";
    TestIdLoginDialog["TEST_ID_LOGIN_DIALOG_InvalidMessage"] = "loginDialog_invalidMessage";
    TestIdLoginDialog["TEST_ID_LOGIN_DIALOG_ForgotPassword"] = "loginDialog_forgotPassword";
})(TestIdLoginDialog || (exports.TestIdLoginDialog = TestIdLoginDialog = {}));
/** -----reset----- **/
var TestIdLoginResetPasswordDialog;
(function (TestIdLoginResetPasswordDialog) {
    TestIdLoginResetPasswordDialog["TEST_ID_LOGIN_RESET_PASSWORD_DIALOG_IntroText"] = "loginResetPasswordDialog_introText";
    TestIdLoginResetPasswordDialog["TEST_ID_LOGIN_RESET_PASSWORD_DIALOG_FormField_Username"] = "loginResetPasswordDialog_username";
    TestIdLoginResetPasswordDialog["TEST_ID_LOGIN_RESET_PASSWORD_DIALOG_ResetButton"] = "loginResetPasswordDialog_resetButton";
    TestIdLoginResetPasswordDialog["TEST_ID_LOGIN_RESET_PASSWORD_DIALOG_BackToLoginButton"] = "loginResetPasswordDialog_backToLoginButton";
})(TestIdLoginResetPasswordDialog || (exports.TestIdLoginResetPasswordDialog = TestIdLoginResetPasswordDialog = {}));
var TestIdLoginResetPasswordDialogSucceeded;
(function (TestIdLoginResetPasswordDialogSucceeded) {
    TestIdLoginResetPasswordDialogSucceeded["TEST_ID_LOGIN_LOGIN_RESET_PASSWORD_DIALOG_SUCCEEDED_Title"] = "loginResetPasswordSuccessDialog_title";
    TestIdLoginResetPasswordDialogSucceeded["TEST_ID_LOGIN_RESET_PASSWORD_DIALOG_SUCCEEDED_Text"] = "loginResetPasswordSuccessDialog_firstText";
})(TestIdLoginResetPasswordDialogSucceeded || (exports.TestIdLoginResetPasswordDialogSucceeded = TestIdLoginResetPasswordDialogSucceeded = {}));
var TestIdLoginPasskeyDialog;
(function (TestIdLoginPasskeyDialog) {
    TestIdLoginPasskeyDialog["TEST_ID_LOGIN_PASSKEY_DIALOG_Dialog"] = "dialogPasskey_dialog";
    TestIdLoginPasskeyDialog["TEST_ID_LOGIN_PASSKEY_DIALOG_Title"] = "dialogPasskey_title";
    TestIdLoginPasskeyDialog["TEST_ID_LOGIN_PASSKEY_DIALOG_Message"] = "dialogPasskey_message";
    TestIdLoginPasskeyDialog["TEST_ID_LOGIN_PASSKEY_DIALOG_Explanation"] = "dialogPasskey_explanation";
    TestIdLoginPasskeyDialog["TEST_ID_LOGIN_PASSKEY_DIALOG_NotEnablePasskeyButton"] = "dialogPasskey_notEnablePasskeyButton";
    TestIdLoginPasskeyDialog["TEST_ID_LOGIN_PASSKEY_DIALOG_EnablePasskeyButton"] = "dialogPasskey_enablePasskeyButton";
})(TestIdLoginPasskeyDialog || (exports.TestIdLoginPasskeyDialog = TestIdLoginPasskeyDialog = {}));
/** --auth server-- */
var TestIdLoginSetSecurityQuestion;
(function (TestIdLoginSetSecurityQuestion) {
    TestIdLoginSetSecurityQuestion["TEST_ID_LOGIN_SET_SECURITY_QUESTION_Select"] = "loginSetSecurityQuestion_select";
    TestIdLoginSetSecurityQuestion["TEST_ID_LOGIN_SET_SECURITY_QUESTION_Question"] = "loginSetSecurityQuestion_question";
    TestIdLoginSetSecurityQuestion["TEST_ID_LOGIN_SET_SECURITY_QUESTION_answer"] = "loginSetSecurityQuestion_answer";
})(TestIdLoginSetSecurityQuestion || (exports.TestIdLoginSetSecurityQuestion = TestIdLoginSetSecurityQuestion = {}));
var TestIdLoginVerifyEmail;
(function (TestIdLoginVerifyEmail) {
    TestIdLoginVerifyEmail["TEST_ID_LOGIN_VERIFY_EMAIL_Code"] = "loginVerifyEmail_code";
})(TestIdLoginVerifyEmail || (exports.TestIdLoginVerifyEmail = TestIdLoginVerifyEmail = {}));
var TestIdLoginVerifyPassword;
(function (TestIdLoginVerifyPassword) {
    TestIdLoginVerifyPassword["TEST_ID_LOGIN_VERIFY_PASSWORD_Password"] = "loginVerifyPassword_password";
})(TestIdLoginVerifyPassword || (exports.TestIdLoginVerifyPassword = TestIdLoginVerifyPassword = {}));
var TestIdLoginTwoFaTypeSms;
(function (TestIdLoginTwoFaTypeSms) {
    TestIdLoginTwoFaTypeSms["TEST_ID_LOGIN_TWO_FA_TYPE_SMS_Address"] = "loginTwoFaTypeSms_address";
    TestIdLoginTwoFaTypeSms["TEST_ID_LOGIN_TWO_FA_TYPE_SMS_Email_code"] = "loginTwoFaTypeSms_Email_code";
    TestIdLoginTwoFaTypeSms["TEST_ID_LOGIN_TWO_FA_TYPE_SMS_Code"] = "loginTwoFaTypeSms_code";
    TestIdLoginTwoFaTypeSms["TEST_ID_LOGIN_TWO_FA_TYPE_SMS_MOBILE_Save"] = "loginTwoFaTypeSms_Mobile_save";
})(TestIdLoginTwoFaTypeSms || (exports.TestIdLoginTwoFaTypeSms = TestIdLoginTwoFaTypeSms = {}));
var TestIdLoginAcceptLoginMessage;
(function (TestIdLoginAcceptLoginMessage) {
    TestIdLoginAcceptLoginMessage["TEST_ID_LOGIN_ACCEPT_LOGIN_MESSAGE_Continue"] = "loginAcceptLoginMessage_continue";
})(TestIdLoginAcceptLoginMessage || (exports.TestIdLoginAcceptLoginMessage = TestIdLoginAcceptLoginMessage = {}));
//----------------------------------------------------------------------
/** -----header----- **/
var TestIdHeader;
(function (TestIdHeader) {
    TestIdHeader["TEST_ID_HEADER_NetfilesIcon"] = "header_iconNetfiles";
    TestIdHeader["TEST_ID_HEADER_Projectname"] = "header_projectname";
    TestIdHeader["TEST_ID_HEADER_ProjectSettingsIcon"] = "header_projectSettingsIcon";
    TestIdHeader["TEST_ID_HEADER_SearchField"] = "header_searchField";
    TestIdHeader["TEST_ID_HEADER_SearchWholeDataroom"] = "header_searchWholeDataroom";
    TestIdHeader["TEST_ID_HEADER_SearchInName"] = "header_searchInName";
    TestIdHeader["TEST_ID_HEADER_SearchInKeywords"] = "header_searchInKeywords";
    TestIdHeader["TEST_ID_HEADER_SearchInContent"] = "header_searchInContent";
    TestIdHeader["TEST_ID_HEADER_UserIcon"] = "header_iconUser";
    TestIdHeader["TEST_ID_HEADER_UserIconImage"] = "header_iconUserImage";
    TestIdHeader["TEST_ID_HEADER_UserIconOneInitial"] = "header_iconUserOneInitial";
    TestIdHeader["TEST_ID_HEADER_UserIconTwoInitials"] = "header_iconUserTwoInitials";
    TestIdHeader["TEST_ID_HEADER_HelpIcon"] = "header_iconHelp";
    TestIdHeader["TEST_ID_HEADER_LogoutIcon"] = "header_iconLogout";
    TestIdHeader["TEST_ID_HEADER_LeaveProjectIcon"] = "header_iconLeaveProject";
})(TestIdHeader || (exports.TestIdHeader = TestIdHeader = {}));
var TestIdUserSettingsDropdownMenu;
(function (TestIdUserSettingsDropdownMenu) {
    TestIdUserSettingsDropdownMenu["TEST_ID_USER_SETTINGS_DROPDOWN_MENU"] = "userSettingsDropdownMenu";
})(TestIdUserSettingsDropdownMenu || (exports.TestIdUserSettingsDropdownMenu = TestIdUserSettingsDropdownMenu = {}));
/** ----listings/dataview----- **/
var TestIdProjectsListing;
(function (TestIdProjectsListing) {
    TestIdProjectsListing["TEST_ID_PROJECT_LISTING_Dataview"] = "projectsListing_dataview";
    TestIdProjectsListing["TEST_ID_PROJECT_LISTING_Project"] = "projectsListing_project";
    TestIdProjectsListing["TEST_ID_PROJECT_LISTING_ProjectIcon"] = "projectsListing_iconProject";
})(TestIdProjectsListing || (exports.TestIdProjectsListing = TestIdProjectsListing = {}));
var TestIdListingToolbar;
(function (TestIdListingToolbar) {
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_NavigationArrowRight"] = "listingToolbar_navigationArrowRight";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_NavigationArrowLeft"] = "listingToolbar_navigationArrowLeft";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_NavigationDropDown"] = "listingToolbar_navigationDropdown";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_Edit"] = "listingToolbar_edit";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_Filter"] = "listingToolbar_filter";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_Upload"] = "listingToolbar_upload";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_Download"] = "listingToolbar_download";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_AccessRights"] = "listingToolbar_accessRights";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_Notifications"] = "listingToolbar_notifications";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_Contextmenu"] = "listingToolbar_contextmenu";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_ViewsDropDown"] = "listingToolbar_viewsDropDown";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_ListView"] = "listingToolbar_listView";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_ExplorerView"] = "listingToolbar_explorerView";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_IconView"] = "listingToolbar_iconView";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_ScopeOption"] = "listingToolbar_scopeOption";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_PeriodOption"] = "listingToolbar_periodOption";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_Labels"] = "listingToolbar_labels";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_LabelsValue"] = "listingToolbar_labelsValue";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_NewGroup"] = "listingToolbar_newGroup";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_MftFilterSwitcher"] = "listingToolbar_mftFilterSwitcher";
    TestIdListingToolbar["TEST_ID_LISTING_TOOLBAR_SidebarFlip"] = "listingToolbar_sidebarFlip";
})(TestIdListingToolbar || (exports.TestIdListingToolbar = TestIdListingToolbar = {}));
var TestIdSortingBar;
(function (TestIdSortingBar) {
    TestIdSortingBar["TEST_ID_SORTING_BAR_SortingIcon"] = "sortingBar_iconSorting";
    TestIdSortingBar["TEST_ID_SORTING_BAR_Name"] = "sortingBar_name";
    TestIdSortingBar["TEST_ID_SORTING_BAR_Filter"] = "sortingBar_filter";
    TestIdSortingBar["TEST_ID_SORTING_BAR_VersionOwner"] = "sortingBar_versionOwner";
    TestIdSortingBar["TEST_ID_SORTING_BAR_Size"] = "sortingBar_size";
    TestIdSortingBar["TEST_ID_SORTING_BAR_Modified"] = "sortingBar_modified";
    TestIdSortingBar["TEST_ID_SORTING_BAR_LastLogin"] = "sortingBar_lastLogin";
    TestIdSortingBar["TEST_ID_SORTING_BAR_LoginCount"] = "sortingBar_loginCount";
    TestIdSortingBar["TEST_ID_SORTING_BAR_SelectCheckbox"] = "sortingBar_selectCheckbox";
})(TestIdSortingBar || (exports.TestIdSortingBar = TestIdSortingBar = {}));
var TestIdDataview;
(function (TestIdDataview) {
    TestIdDataview["TEST_ID_DATAVIEW_Listing"] = "dataview_listing";
    TestIdDataview["TEST_ID_DATAVIEW_Item"] = "dataview_item";
    TestIdDataview["TEST_ID_DATAVIEW_Empty"] = "dataview_empty";
    TestIdDataview["TEST_ID_DATAVIEW_FolderViewIcon"] = "dataview_folderViewIcon";
    TestIdDataview["TEST_ID_DATAVIEW_FolderDownloadIcon"] = "dataview_folderDownloadIcon";
    TestIdDataview["TEST_ID_DATAVIEW_AccessPermissionsIcon"] = "dataview_accessPermissionsIcon";
    TestIdDataview["TEST_ID_DATAVIEW_WarningIcon"] = "dataview_warningIcon";
    TestIdDataview["TEST_ID_DATAVIEW_ItemContextMenu"] = "dataview_itemContextMenu";
    TestIdDataview["TEST_ID_DATAVIEW_SourceFolderIcon"] = "dataview_sourceFolderIcon";
    TestIdDataview["TEST_ID_DATAVIEW_RenameFileWarningTooltip"] = "dataview_renameFileWarningTooltip";
    TestIdDataview["TEST_ID_DATAVIEW_ShortcutIcon"] = "dataview_shortcutIcon";
    TestIdDataview["TEST_ID_DATAVIEW_CommentsIcon"] = "dataview_commentsIcon";
    TestIdDataview["TEST_ID_DATAVIEW_CommentsTooltip"] = "dataview_commentsTooltip";
})(TestIdDataview || (exports.TestIdDataview = TestIdDataview = {}));
/** ----footer---- **/
var TestIdFooter;
(function (TestIdFooter) {
    TestIdFooter["TEST_ID_FOOTER_Path"] = "footer_path";
    TestIdFooter["TEST_ID_FOOTER_Copyright"] = "footer_copyright";
    TestIdFooter["TEST_ID_FOOTER_Dataroomtype"] = "footer_dataroomtype";
    TestIdFooter["TEST_ID_FOOTER_CountInfo"] = "footer_countinfo";
})(TestIdFooter || (exports.TestIdFooter = TestIdFooter = {}));
/** -----document viewer----- **/
var TestIdDocumentViewer;
(function (TestIdDocumentViewer) {
    TestIdDocumentViewer["TEST_ID_DOCUMENT_VIEWER_GeneratingPdfAlert"] = "documentViewer_generatingPdfAlert";
    TestIdDocumentViewer["TEST_ID_DOCUMENT_VIEWER_DocumentIcon"] = "documentViewer_documentIcon";
    TestIdDocumentViewer["TEST_ID_DOCUMENT_VIEWER_Title"] = "documentViewer_title";
    TestIdDocumentViewer["TEST_ID_DOCUMENT_VIEWER_DownloadButton"] = "documentViewer_downloadButton";
    TestIdDocumentViewer["TEST_ID_DOCUMENT_VIEWER_CloseButton"] = "documentViewer_closeButton";
    TestIdDocumentViewer["TEST_ID_DOCUMENT_VIEWER_TextContainer"] = "documentViewer_textContainer";
})(TestIdDocumentViewer || (exports.TestIdDocumentViewer = TestIdDocumentViewer = {}));
/** -----tiptap editor----- **/
var TestIdTipTapEditor;
(function (TestIdTipTapEditor) {
    TestIdTipTapEditor["TEST_ID_TIPTAP_EDITOR"] = "tiptapEditor";
    TestIdTipTapEditor["TEST_ID_TIPTAP_EDITOR_Title"] = "tiptapEditor_title";
    TestIdTipTapEditor["TEST_ID_TIPTAP_EDITOR_ToolbarUndo"] = "tiptapEditor_toolbarUndo";
    TestIdTipTapEditor["TEST_ID_TIPTAP_EDITOR_ToolbarRedo"] = "tiptapEditor_toolbarRedo";
    TestIdTipTapEditor["TEST_ID_TIPTAP_EDITOR_ToolbarParagraph"] = "tiptapEditor_toolbarParagraph";
    TestIdTipTapEditor["TEST_ID_TIPTAP_EDITOR_ToolbarBold"] = "tiptapEditor_toolbarBold";
    TestIdTipTapEditor["TEST_ID_TIPTAP_EDITOR_ToolbarItalic"] = "tiptapEditor_toolbarItalic";
    TestIdTipTapEditor["TEST_ID_TIPTAP_EDITOR_ToolbarUnderline"] = "tiptapEditor_toolbarUnderline";
    TestIdTipTapEditor["TEST_ID_TIPTAP_EDITOR_ToolbarStrike"] = "tiptapEditor_toolbarStrike";
    TestIdTipTapEditor["TEST_ID_TIPTAP_EDITOR_ToolbarColor"] = "tiptapEditor_toolbarColor";
    TestIdTipTapEditor["TEST_ID_TIPTAP_EDITOR_ToolbarHighlight"] = "tiptapEditor_toolbarHighlight";
    TestIdTipTapEditor["TEST_ID_TIPTAP_EDITOR_ToolbarLink"] = "tiptapEditor_toolbarLink";
    TestIdTipTapEditor["TEST_ID_TIPTAP_EDITOR_ToolbarImage"] = "tiptapEditor_toolbarImage";
    TestIdTipTapEditor["TEST_ID_TIPTAP_EDITOR_ToolbarTable"] = "tiptapEditor_toolbarTable";
    TestIdTipTapEditor["TEST_ID_TIPTAP_EDITOR_ToolbarFullscreen"] = "tiptapEditor_toolbarFullscreen";
    TestIdTipTapEditor["TEST_ID_TIPTAP_EDITOR_Textarea"] = "tiptapEditor_textarea";
    TestIdTipTapEditor["TEST_ID_TIPTAP_EDITOR_CancelButton"] = "tiptapEditor_cancelButton";
    TestIdTipTapEditor["TEST_ID_TIPTAP_EDITOR_SaveButton"] = "tiptapEditor_saveButton";
})(TestIdTipTapEditor || (exports.TestIdTipTapEditor = TestIdTipTapEditor = {}));
/** -----group wizard----- **/
var TestIdUserGroupWizard;
(function (TestIdUserGroupWizard) {
    TestIdUserGroupWizard["TEST_ID_USER_GROUP_WIZARD_Title"] = "userGroupWizard_title";
    TestIdUserGroupWizard["TEST_ID_USER_GROUP_WIZARD_Filter"] = "userGroupWizard_filter";
    TestIdUserGroupWizard["TEST_ID_USER_GROUP_WIZARD_ListHeaderGroup"] = "userGroupWizard_listHeaderGroup";
    TestIdUserGroupWizard["TEST_ID_USER_GROUP_WIZARD_ListHeaderUser"] = "userGroupWizard_listHeaderUser";
    TestIdUserGroupWizard["TEST_ID_USER_GROUP_WIZARD_GroupList"] = "userGroupWizard_groupList";
    TestIdUserGroupWizard["TEST_ID_USER_GROUP_WIZARD_UserList"] = "userGroupWizard_userList";
    TestIdUserGroupWizard["TEST_ID_USER_GROUP_WIZARD_Scrollbar"] = "userGroupWizard_scrollbar";
    TestIdUserGroupWizard["TEST_ID_USER_GROUP_WIZARD_CancelButton"] = "userGroupWizard_cancelButton";
    TestIdUserGroupWizard["TEST_ID_USER_GROUP_WIZARD_SelectButton"] = "userGroupWizard_selectButton";
})(TestIdUserGroupWizard || (exports.TestIdUserGroupWizard = TestIdUserGroupWizard = {}));
//----------------------------------------------------------------------------
/** BARTABS **/
var TestIdBarTabs;
(function (TestIdBarTabs) {
    TestIdBarTabs["TEST_ID_BAR_TABS_Actions"] = "barTab_actions";
    TestIdBarTabs["TEST_ID_BAR_TABS_ActionsIcon"] = "barTab_iconActions";
    TestIdBarTabs["TEST_ID_BAR_TABS_Info"] = "barTab_info";
    TestIdBarTabs["TEST_ID_BAR_TABS_InfoIcon"] = "barTab_iconInfo";
    TestIdBarTabs["TEST_ID_BAR_TABS_Comments"] = "barTab_comments";
    TestIdBarTabs["TEST_ID_BAR_TABS_CommentsIcon"] = "barTab_iconComments";
    TestIdBarTabs["TEST_ID_BAR_TABS_ArrowRight"] = "barTab_rightArrow";
    TestIdBarTabs["TEST_ID_BAR_TABS_ArrowLeft"] = "barTab_leftArrow";
})(TestIdBarTabs || (exports.TestIdBarTabs = TestIdBarTabs = {}));
/** -----navigationbar----- **/
var TestIdProjectsNavigation;
(function (TestIdProjectsNavigation) {
    TestIdProjectsNavigation["TEST_ID_PROJECT_NAVIGATION_DataRooms"] = "projectsNavigation_dataRooms";
})(TestIdProjectsNavigation || (exports.TestIdProjectsNavigation = TestIdProjectsNavigation = {}));
var TestIdNavigationBar;
(function (TestIdNavigationBar) {
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_Documents"] = "navigationBar_documents";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_Trash"] = "navigationBar_trash";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_NewChanged"] = "navigationBar_newChanged";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_RecentlyUsed"] = "navigationBar_recentlyUsed";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_ToBeApproved"] = "navigationBar_toBeApproved";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_Locked"] = "navigationBar_locked";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_Expired"] = "navigationBar_expired";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_Unread"] = "navigationBar_unread";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_WithLabel"] = "navigationBar_withLabel";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_RecentComments"] = "navigationBar_recentComments";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_GroupsUsers"] = "navigationBar_groupsUsers";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_Information"] = "navigationBar_information";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_Settings"] = "navigationBar_settings";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_EmptyFavorites"] = "navigationBar_emptyFavorites";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_FavoritesList"] = "navigationBar_favoritesList";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_Tasks"] = "navigationBar_tasks";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_Appointments"] = "navigationBar_appointments";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_Videoconferences"] = "navigationBar_videoconferences";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_Discussions"] = "navigationBar_discussions";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_Received"] = "navigationBar_received";
    TestIdNavigationBar["TEST_ID_NAVIGATION_BAR_Sent"] = "navigationBar_sent";
})(TestIdNavigationBar || (exports.TestIdNavigationBar = TestIdNavigationBar = {}));
/** -----actionsbar----- **/
var TestIdProjectsActions;
(function (TestIdProjectsActions) {
    TestIdProjectsActions["TEST_ID_PROJECT_ACTIONS_DataRoomAccess"] = "projectsActions_dataRoomAccess";
    TestIdProjectsActions["TEST_ID_PROJECT_ACTIONS_AcceptInvitation"] = "projectsActions_acceptInvitation";
    TestIdProjectsActions["TEST_ID_PROJECT_ACTIONS_RefuseInvitation"] = "projectsActions_refuseInvitation";
    TestIdProjectsActions["TEST_ID_PROJECT_ACTIONS_QuitAccess"] = "projectsActions_quitAccess";
    TestIdProjectsActions["TEST_ID_PROJECT_ACTIONS_PriceCalculator"] = "projectsActions_priceCalculator";
})(TestIdProjectsActions || (exports.TestIdProjectsActions = TestIdProjectsActions = {}));
var TestIdDocumentsActionBar;
(function (TestIdDocumentsActionBar) {
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_View"] = "documentsActionBar_view";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_Upload"] = "documentsActionBar_upload";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_Download"] = "documentsActionBar_download";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_AccessPermissions"] = "documentsActionBar_accessPermissions";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_Notifications"] = "documentsActionBar_notifications";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_ShowCreateActions"] = "documentsActionBar_showCreateActions";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_Folder"] = "documentsActionBar_folder";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_Wiki"] = "documentsActionBar_wiki";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_Document"] = "documentsActionBar_document";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_DocumentTextFile"] = "documentsActionBar_documentTextFile";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_DocumentBookmark"] = "documentsActionBar_documentBookmark";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_DocumentWordFile"] = "documentsActionBar_documentWordFile";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_DocumentExcelFile"] = "documentsActionBar_documentExcelFile";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_DocumentPowerpointFile"] = "documentsActionBar_documentPowerpointFile";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_Meeting"] = "documentsActionBar_meeting";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_ShowEditActions"] = "documentsActionBar_showEditActions";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_Edit"] = "documentsActionBar_edit";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_UploadNewVersion"] = "documentsActionBar_uploadNewVersion";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_Delete"] = "documentsActionBar_delete";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_Copy"] = "documentsActionBar_copy";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_Move"] = "documentsActionBar_move";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_Labels"] = "documentsActionBar_labels";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_CreateShortcut"] = "documentsActionBar_createShortcut";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_ShowSendActions"] = "documentsActionBar_showSendActions";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_SendLinkInternally"] = "documentsActionBar_sendLinkInternally";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_ShowInfoActions"] = "documentsActionBar_showInfoActions";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_Properties"] = "documentsActionBar_properties";
    TestIdDocumentsActionBar["TEST_ID_DOCUMENTS_ACTION_BAR_Activity"] = "documentsActionBar_activity";
})(TestIdDocumentsActionBar || (exports.TestIdDocumentsActionBar = TestIdDocumentsActionBar = {}));
var TestIdGroupsActionBar;
(function (TestIdGroupsActionBar) {
    TestIdGroupsActionBar["TEST_ID_GROUPS_ACTION_BAR_CreateGroup"] = "groupsActionBar_newGroup";
    TestIdGroupsActionBar["TEST_ID_GROUPS_ACTION_BAR_Properties"] = "groupsActionBar_properties";
    TestIdGroupsActionBar["TEST_ID_GROUPS_ACTION_BAR_DeleteGroup"] = "groupsActionBar_deleteGroup";
    TestIdGroupsActionBar["TEST_ID_GROUPS_ACTION_BAR_AccessibleFiles"] = "groupsActionBar_accessibleFiles";
    TestIdGroupsActionBar["TEST_ID_GROUPS_USERS_ACTION_BAR_GroupLabels"] = "groupsActionBar_groupLabels";
    TestIdGroupsActionBar["TEST_ID_GROUPS_USERS_ACTION_BAR_ImportUsers"] = "groupsActionBar_importUsers";
})(TestIdGroupsActionBar || (exports.TestIdGroupsActionBar = TestIdGroupsActionBar = {}));
var TestIdUsersActionBar;
(function (TestIdUsersActionBar) {
    TestIdUsersActionBar["TEST_ID_USERS_ACTION_BAR_CreateUser"] = "usersActionBar_newUser";
    TestIdUsersActionBar["TEST_ID_USERS_ACTION_BAR_AddToGroup"] = "usersActionBar_addToGroup";
    TestIdUsersActionBar["TEST_ID_USERS_ACTION_BAR_RemoveFromGroup"] = "usersActionBar_removeFromGroup";
    TestIdUsersActionBar["TEST_ID_USERS_ACTION_BAR_ShowEditActions"] = "usersActionBar_showEditActions";
    TestIdUsersActionBar["TEST_ID_USERS_ACTION_BAR_SendInvitation"] = "usersActionBar_sendInvitation";
    TestIdUsersActionBar["TEST_ID_USERS_ACTION_BAR_RestrictAccess"] = "usersActionBar_restrictAccess";
    TestIdUsersActionBar["TEST_ID_USERS_ACTION_BAR_ResetPassword"] = "usersActionBar_resetPassword";
    TestIdUsersActionBar["TEST_ID_USERS_ACTION_BAR_DeleteUser"] = "usersActionBar_deleteUser";
    TestIdUsersActionBar["TEST_ID_USERS_ACTION_BAR_GroupLabels"] = "usersActionBar_groupLabels";
    TestIdUsersActionBar["TEST_ID_USERS_ACTION_BAR_ShowInfoActions"] = "usersActionBar_showInfoActions";
    TestIdUsersActionBar["TEST_ID_USERS_ACTION_BAR_Properties"] = "usersActionBar_properties";
    TestIdUsersActionBar["TEST_ID_USERS_ACTION_BAR_AccessibleFiles"] = "usersActionBar_accessibleFiles";
    TestIdUsersActionBar["TEST_ID_USERS_ACTION_BAR_VisibleUser"] = "usersActionBar_visibleUser";
    TestIdUsersActionBar["TEST_ID_USERS_ACTION_BAR_Activity"] = "usersActionBar_activity";
})(TestIdUsersActionBar || (exports.TestIdUsersActionBar = TestIdUsersActionBar = {}));
var TestIdTrashActionBar;
(function (TestIdTrashActionBar) {
    TestIdTrashActionBar["TEST_ID_TRASH_ACTION_BAR_EmptyTrash"] = "trashActionBar_emptyTrash";
    TestIdTrashActionBar["TEST_ID_TRASH_ACTION_BAR_DeletePermanently"] = "trashActionBar_deletePermanently";
    TestIdTrashActionBar["TEST_ID_TRASH_ACTION_BAR_Restore"] = "trashActionBar_restore";
    TestIdTrashActionBar["TEST_ID_TRASH_ACTION_BAR_ShowEditActions"] = "trashActionBar_showEditActions";
    TestIdTrashActionBar["TEST_ID_TRASH_ACTION_BAR_View"] = "trashActionBar_view";
    TestIdTrashActionBar["TEST_ID_TRASH_ACTION_BAR_Download"] = "trashActionBar_download";
    TestIdTrashActionBar["TEST_ID_TRASH_ACTION_BAR_Copy"] = "trashActionBar_copy";
    TestIdTrashActionBar["TEST_ID_TRASH_ACTION_BAR_Move"] = "trashActionBar_move";
    TestIdTrashActionBar["TEST_ID_TRASH_ACTION_BAR_ShowInfoActions"] = "trashActionBar_showInfoActions";
    TestIdTrashActionBar["TEST_ID_TRASH_ACTION_BAR_Properties"] = "trashActionBar_properties";
    TestIdTrashActionBar["TEST_ID_TRASH_ACTION_BAR_Activity"] = "trashActionBar_activity";
})(TestIdTrashActionBar || (exports.TestIdTrashActionBar = TestIdTrashActionBar = {}));
var TestIdMftRootActionBar;
(function (TestIdMftRootActionBar) {
    TestIdMftRootActionBar["TEST_ID_MFT_ROOT_ACTION_BAR_ExternalFileTransfer"] = "mftRootActionBar_externalFileTransfer";
    TestIdMftRootActionBar["TEST_ID_MFT_ROOT_ACTION_BAR_ReceiveFilesSetUp"] = "mftRootActionBar_receiveFilesSetUp";
    TestIdMftRootActionBar["TEST_ID_MFT_ROOT_ACTION_BAR_DeletePermanently"] = "mftRootActionBar_deletePermanently";
    TestIdMftRootActionBar["TEST_ID_MFT_ROOT_ACTION_BAR_Lock"] = "mftRootActionBar_lock";
    TestIdMftRootActionBar["TEST_ID_MFT_ROOT_ACTION_BAR_Unlock"] = "mftRootActionBar_unlock";
    TestIdMftRootActionBar["TEST_ID_MFT_ROOT_ACTION_BAR_Edit"] = "mftRootActionBar_edit";
    TestIdMftRootActionBar["TEST_ID_MFT_ROOT_ACTION_BAR_Activity"] = "mftRootActionBar_activity";
})(TestIdMftRootActionBar || (exports.TestIdMftRootActionBar = TestIdMftRootActionBar = {}));
var TestIdMftItemActionBar;
(function (TestIdMftItemActionBar) {
    TestIdMftItemActionBar["TEST_ID_MFT_ITEM_ACTION_BAR_View"] = "mftItemActionBar_view";
    TestIdMftItemActionBar["TEST_ID_MFT_ITEM_ACTION_BAR_Download"] = "mftItemActionBar_download";
    TestIdMftItemActionBar["TEST_ID_MFT_ITEM_ACTION_BAR_Lock"] = "mftItemActionBar_lock";
    TestIdMftItemActionBar["TEST_ID_MFT_ITEM_ACTION_BAR_Unlock"] = "mftItemActionBar_unlock";
    TestIdMftItemActionBar["TEST_ID_MFT_ITEM_ACTION_BAR_Edit"] = "mftItemActionBar_edit";
    TestIdMftItemActionBar["TEST_ID_MFT_ITEM_ACTION_BAR_Activity"] = "mftItemActionBar_activity";
})(TestIdMftItemActionBar || (exports.TestIdMftItemActionBar = TestIdMftItemActionBar = {}));
/** -----infobar----- **/
var TestIdInfoBar;
(function (TestIdInfoBar) {
    TestIdInfoBar["TEST_ID_INFO_BAR_InfoContent"] = "infoBar_infoContent";
    TestIdInfoBar["TEST_ID_INFO_BAR_FileIcon"] = "infoBar_fileIcon";
    TestIdInfoBar["TEST_ID_INFO_BAR_FileName"] = "infoBar_fileName";
    TestIdInfoBar["TEST_ID_INFO_BAR_FileDescription"] = "infoBar_fileDescription";
    TestIdInfoBar["TEST_ID_INFO_BAR_CreationDate"] = "infoBar_creationDate";
    TestIdInfoBar["TEST_ID_INFO_BAR_Modified"] = "infoBar_modified";
    TestIdInfoBar["TEST_ID_INFO_BAR_Size"] = "infoBar_size";
    TestIdInfoBar["TEST_ID_INFO_BAR_AccessCounter"] = "infoBar_accessCounter";
    TestIdInfoBar["TEST_ID_INFO_BAR_AccessRights"] = "infoBar_accessRights";
})(TestIdInfoBar || (exports.TestIdInfoBar = TestIdInfoBar = {}));
/** -----commentsbar----- **/
var TestIdCommentBar;
(function (TestIdCommentBar) {
    TestIdCommentBar["TEST_ID_COMMENT_BAR_CommentsContent"] = "commentBar_commentsContent";
    TestIdCommentBar["TEST_ID_COMMENT_BAR_DatviewItemIcon"] = "commentBar_dataviewItemIcon";
    TestIdCommentBar["TEST_ID_COMMENT_BAR_DatviewItemTitle"] = "commentBar_dataviewItemTitle";
    TestIdCommentBar["TEST_ID_COMMENT_BAR_CommentTextarea"] = "commentBar_commentTextarea";
    TestIdCommentBar["TEST_ID_COMMENT_BAR_CancelButton"] = "commentBar_cancelButton";
    TestIdCommentBar["TEST_ID_COMMENT_BAR_SendButton"] = "commentBar_sendButton";
    TestIdCommentBar["TEST_ID_COMMENT_BAR_CommentDetails"] = "commentBar_commentDetails";
    TestIdCommentBar["TEST_ID_COMMENT_BAR_Author"] = "commentBar_author";
    TestIdCommentBar["TEST_ID_COMMENT_BAR_Modified"] = "commentBar_modified";
    TestIdCommentBar["TEST_ID_COMMENT_BAR_Comment"] = "commentBar_comment";
    TestIdCommentBar["TEST_ID_COMMENT_BAR_DeleteComment"] = "commentBar_deleteComment";
})(TestIdCommentBar || (exports.TestIdCommentBar = TestIdCommentBar = {}));
//---------------------------------------------------------------------------
/** DIALOGS **/
var TestIdDialog;
(function (TestIdDialog) {
    TestIdDialog["TEST_ID_DIALOG_SelectedFiles"] = "dialog_selectedFiles";
    TestIdDialog["TEST_ID_DIALOG_CheckBox"] = "dialog_checkBox";
    TestIdDialog["TEST_ID_DIALOG_CancelButton"] = "dialog_cancelButton";
    TestIdDialog["TEST_ID_DIALOG_ConfirmButton"] = "dialog_confirmButton";
})(TestIdDialog || (exports.TestIdDialog = TestIdDialog = {}));
/** -----projectsettings dialog----- **/
var TestIdProjectSettings;
(function (TestIdProjectSettings) {
    TestIdProjectSettings["TEST_ID_PROJECTSETTINGS_SWITCH"] = "projectSettingsSwitch_";
    TestIdProjectSettings["TEST_ID_PROJECTSETTINGS_RADIO"] = "projectSettingsRadio_";
    TestIdProjectSettings["TEST_ID_PROJECTSETTINGS_TEXT"] = "projectSettingsText_";
    TestIdProjectSettings["TEST_ID_PROJECTSETTINGS_NAVIGATION"] = "projectSettingsNavigation_";
    TestIdProjectSettings["TEST_ID_PROJECTSETTINGS_CloseButton"] = "projectSettings_closeButton";
    TestIdProjectSettings["TEST_ID_PROJECTSETTINGS_SaveButton"] = "projectSettings_saveButton";
    TestIdProjectSettings["TEST_ID_PROJECTSETTINGS_ResetButton"] = "projectSettings_resetButton";
})(TestIdProjectSettings || (exports.TestIdProjectSettings = TestIdProjectSettings = {}));
/** -----usersettings dialog----- **/
var TestIdUserSettingsDialog;
(function (TestIdUserSettingsDialog) {
    TestIdUserSettingsDialog["TEST_ID_USER_SETTINGS_DIALOG_SettingsTitle"] = "userSettingsDialog_settingsTitle";
    TestIdUserSettingsDialog["TEST_ID_USER_SETTINGS_DIALOG_PersonalData"] = "userSettingsDialog_personalData";
    TestIdUserSettingsDialog["TEST_ID_USER_SETTINGS_DIALOG_Options"] = "userSettingsDialog_options";
    TestIdUserSettingsDialog["TEST_ID_USER_SETTINGS_DIALOG_ResetButton"] = "userSettingsDialog_resetButton";
    TestIdUserSettingsDialog["TEST_ID_USER_SETTINGS_DIALOG_CloseButton"] = "userSettingsDialog_closeButton";
})(TestIdUserSettingsDialog || (exports.TestIdUserSettingsDialog = TestIdUserSettingsDialog = {}));
var TestIdUserSettingsDialogNavigationBar;
(function (TestIdUserSettingsDialogNavigationBar) {
    TestIdUserSettingsDialogNavigationBar["TEST_ID_USER_SETTINGS_DIALOG_NAVIGATION_BAR"] = "userSettingsDialogNavigationBar";
})(TestIdUserSettingsDialogNavigationBar || (exports.TestIdUserSettingsDialogNavigationBar = TestIdUserSettingsDialogNavigationBar = {}));
var TestIdUserSettingsDialogPersonalDataAdress;
(function (TestIdUserSettingsDialogPersonalDataAdress) {
    TestIdUserSettingsDialogPersonalDataAdress["TEST_ID_USER_SETTINGS_DIALOG_PERSONAL_DATA_Street"] = "userSettingsDialogPersonalData_street";
    TestIdUserSettingsDialogPersonalDataAdress["TEST_ID_USER_SETTINGS_DIALOG_PERSONAL_DATA_ZipCode"] = "userSettingsDialogPersonalData_zipCode";
    TestIdUserSettingsDialogPersonalDataAdress["TEST_ID_USER_SETTINGS_DIALOG_PERSONAL_DATA_City"] = "userSettingsDialogPersonalData_city";
    TestIdUserSettingsDialogPersonalDataAdress["TEST_ID_USER_SETTINGS_DIALOG_PERSONAL_DATA_Country"] = "userSettingsDialogPersonalData_country";
})(TestIdUserSettingsDialogPersonalDataAdress || (exports.TestIdUserSettingsDialogPersonalDataAdress = TestIdUserSettingsDialogPersonalDataAdress = {}));
var TestIdUserSettingsDialogOptions;
(function (TestIdUserSettingsDialogOptions) {
    TestIdUserSettingsDialogOptions["TEST_ID_USER_SETTINGS_DIALOG_OPTIONS_FunctionsDownloadProtectedPDF"] = "userSettingsDialogOptions_functionsDownloadProtectedPDF";
    TestIdUserSettingsDialogOptions["TEST_ID_USER_SETTINGS_DIALOG_OPTIONS_FunctionsPrintProtectedPDF"] = "userSettingsDialogOptions_functionsPrintProtectedPDF";
    TestIdUserSettingsDialogOptions["TEST_ID_USER_SETTINGS_DIALOG_OPTIONS_FunctionsEFT"] = "userSettingsDialogOptions_functionsEFT";
    TestIdUserSettingsDialogOptions["TEST_ID_USER_SETTINGS_DIALOG_OPTIONS_FunctionsVideoConference"] = "userSettingsDialogOptions_functionsVideoConference";
    TestIdUserSettingsDialogOptions["TEST_ID_USER_SETTINGS_DIALOG_OPTIONS_EditDocumentsMicrosoftOffice"] = "userSettingsDialogOptions_editDocumentsMicrosoftOffice";
    TestIdUserSettingsDialogOptions["TEST_ID_USER_SETTINGS_DIALOG_OPTIONS_EditDocumentsCollabora"] = "userSettingsDialogOptions_editDocumentsCollabora";
    TestIdUserSettingsDialogOptions["TEST_ID_USER_SETTINGS_DIALOG_OPTIONS_ZipArchiveUploadExtract"] = "userSettingsDialogOptions_zipArchiveUploadExtract";
    TestIdUserSettingsDialogOptions["TEST_ID_USER_SETTINGS_DIALOG_OPTIONS_UIThemeLight"] = "userSettingsDialogOptions_uiThemeLight";
    TestIdUserSettingsDialogOptions["TEST_ID_USER_SETTINGS_DIALOG_OPTIONS_UIThemeDark"] = "userSettingsDialogOptions_uiThemeDark";
    TestIdUserSettingsDialogOptions["TEST_ID_USER_SETTINGS_DIALOG_OPTIONS_UIThemeAutomatic"] = "userSettingsDialogOptions_uiThemeAutomatic";
    TestIdUserSettingsDialogOptions["TEST_ID_USER_SETTINGS_DIALOG_OPTIONS_NotificationsDropdownMenu"] = "userSettingsDialogOptions_notificationsDropdownMenu";
    TestIdUserSettingsDialogOptions["TEST_ID_USER_SETTINGS_DIALOG_OPTIONS_Newsletter"] = "userSettingsDialogOptions_newsletter";
    TestIdUserSettingsDialogOptions["TEST_ID_USER_SETTINGS_DIALOG_OPTIONS_VisibilityDropdownMenu"] = "userSettingsDialogOptions_visibilityDropdownMenu";
    TestIdUserSettingsDialogOptions["TEST_ID_USER_SETTINGS_DIALOG_OPTIONS_UserCreated"] = "userSettingsDialogOptions_userCreated";
    TestIdUserSettingsDialogOptions["TEST_ID_USER_SETTINGS_DIALOG_OPTIONS_InvitationSent"] = "userSettingsDialogOptions_invitationSent";
    TestIdUserSettingsDialogOptions["TEST_ID_USER_SETTINGS_DIALOG_OPTIONS_LoginCount"] = "userSettingsDialogOptions_loginCount";
    TestIdUserSettingsDialogOptions["TEST_ID_USER_SETTINGS_DIALOG_OPTIONS_LastLogin"] = "userSettingsDialogOptions_lastLogin";
    TestIdUserSettingsDialogOptions["TEST_ID_USER_SETTINGS_DIALOG_OPTIONS_MemberOfGroups"] = "userSettingsDialogOptions_memberOfGroups";
})(TestIdUserSettingsDialogOptions || (exports.TestIdUserSettingsDialogOptions = TestIdUserSettingsDialogOptions = {}));
var TestIdUserSettingsDialogPhoto;
(function (TestIdUserSettingsDialogPhoto) {
    TestIdUserSettingsDialogPhoto["TEST_ID_USER_SETTINGS_DIALOG_UserIcon"] = "userSettingsDialog_userIcon";
    TestIdUserSettingsDialogPhoto["TEST_ID_USER_SETTINGS_DIALOG_DeletePhotoButton"] = "userSettingsDialog_deletePhotoButton";
    TestIdUserSettingsDialogPhoto["TEST_ID_USER_SETTINGS_DIALOG_BrowsePhotoButton"] = "userSettingsDialog_browsePhotoButton";
    TestIdUserSettingsDialogPhoto["TEST_ID_USER_SETTINGS_DIALOG_PhotoInfo"] = "userSettingsDialog_photoInfo";
})(TestIdUserSettingsDialogPhoto || (exports.TestIdUserSettingsDialogPhoto = TestIdUserSettingsDialogPhoto = {}));
var TestIdUserSettingsDialogResetPassword;
(function (TestIdUserSettingsDialogResetPassword) {
    TestIdUserSettingsDialogResetPassword["TEST_ID_USER_SETTINGS_DIALOG_RESET_PASSWORD_OldPassword"] = "userSettingsDialogResetPassword_oldPassword";
    TestIdUserSettingsDialogResetPassword["TEST_ID_USER_SETTINGS_DIALOG_RESET_PASSWORD_NewPassword"] = "userSettingsDialogResetPassword_newPassword";
    TestIdUserSettingsDialogResetPassword["TEST_ID_USER_SETTINGS_DIALOG_RESET_PASSWORD_RepeatPassword"] = "userSettingsDialogResetPassword_repeatPassword";
    TestIdUserSettingsDialogResetPassword["TEST_ID_USER_SETTINGS_DIALOG_RESET_PASSWORD_ErroMessageLessRequirements"] = "userSettingsDialogResetPassword_errorMessageLessRequirements";
    TestIdUserSettingsDialogResetPassword["TEST_ID_USER_SETTINGS_DIALOG_RESET_PASSWORD_ErroMessageWrongRepeat"] = "userSettingsDialogResetPassword_errorMessageWrongRepeat";
    TestIdUserSettingsDialogResetPassword["TEST_ID_USER_SETTINGS_DIALOG_RESET_PASSWORD_HintMessage"] = "userSettingsDialogResetPassword_hintMessage";
    TestIdUserSettingsDialogResetPassword["TEST_ID_USER_SETTINGS_DIALOG_RESET_PASSWORD_CancelButton"] = "userSettingsDialogResetPassword_cancelButton";
    TestIdUserSettingsDialogResetPassword["TEST_ID_USER_SETTINGS_DIALOG_RESET_PASSWORD_ChangePasswordButton"] = "userSettingsDialogResetPassword_changePasswordButton";
})(TestIdUserSettingsDialogResetPassword || (exports.TestIdUserSettingsDialogResetPassword = TestIdUserSettingsDialogResetPassword = {}));
/** -----movecopy dialog ----- **/
var TestIdDialogMoveCopy;
(function (TestIdDialogMoveCopy) {
    TestIdDialogMoveCopy["TEST_ID_DIALOG_MOVECOPY_ToolbarTitleMove"] = "dialogMoveCopy_toolbarTitleMove";
    TestIdDialogMoveCopy["TEST_ID_DIALOG_MOVECOPY_ToolbarTitleCopy"] = "dialogMoveCopy_toolbarTitleCopy";
    TestIdDialogMoveCopy["TEST_ID_DIALOG_MOVECOPY_FileListing"] = "dialogMoveCopy_fileListing";
    TestIdDialogMoveCopy["TEST_ID_DIALOG_MOVECOPY_FileItem"] = "dialogMoveCopy_fileItem";
    TestIdDialogMoveCopy["TEST_ID_DIALOG_MOVECOPY_ApplyAccessRights"] = "dialogMoveCopy_applyAccessRights";
    TestIdDialogMoveCopy["TEST_ID_DIALOG_MOVECOPY_KeepAccessRights"] = "dialogMoveCopy_keepAccessRights";
    TestIdDialogMoveCopy["TEST_ID_DIALOG_MOVECOPY_GoToDestination"] = "dialogMoveCopy_goToDestination";
    TestIdDialogMoveCopy["TEST_ID_DIALOG_MOVECOPY_DontShowAgain"] = "dialogMoveCopy_DontShowAgain";
    TestIdDialogMoveCopy["TEST_ID_DIALOG_MOVECOPY_CancelButton"] = "dialogMoveCopy_cancelButton";
    TestIdDialogMoveCopy["TEST_ID_DIALOG_MOVECOPY_MoveButton"] = "dialogMoveCopy_moveButton";
    TestIdDialogMoveCopy["TEST_ID_DIALOG_MOVECOPY_CopyButton"] = "dialogMoveCopy_copyButton";
})(TestIdDialogMoveCopy || (exports.TestIdDialogMoveCopy = TestIdDialogMoveCopy = {}));
/** -----folder dialog----- **/
var TestIdFolderDialog;
(function (TestIdFolderDialog) {
    TestIdFolderDialog["TEST_ID_FOLDER_DIALOG_FolderName"] = "folderDialog_folderName";
    TestIdFolderDialog["TEST_ID_FOLDER_DIALOG_Description"] = "folderDialog_description";
    TestIdFolderDialog["TEST_ID_FOLDER_DIALOG_CreateFolderButton"] = "folderDialog_createButton";
    TestIdFolderDialog["TEST_ID_FOLDER_DIALOG_CancelFolderButton"] = "folderDialog_cancelButton";
    TestIdFolderDialog["TEST_ID_FOLDER_DIALOG_SelectedElementsList"] = "folderDialog_selectedElementsList";
    TestIdFolderDialog["TEST_ID_FOLDER_DIALOG_IndexNumber"] = "folderDialog_indexNumber";
    TestIdFolderDialog["TEST_ID_FOLDER_DIALOG_IndexCheckbox"] = "folderDialog_indexCheckbox";
})(TestIdFolderDialog || (exports.TestIdFolderDialog = TestIdFolderDialog = {}));
/** -----document dialog ----- **/
var TestIdDocumentDialog;
(function (TestIdDocumentDialog) {
    TestIdDocumentDialog["TEST_ID_DOCUMENT_DIALOG_DocumentName"] = "documentDialog_documentName";
    TestIdDocumentDialog["TEST_ID_DOCUMENT_DIALOG_Description"] = "documentDialog_description";
    TestIdDocumentDialog["TEST_ID_DOCUMENT_DIALOG_CancelButton"] = "documentDialog_cancelButton";
    TestIdDocumentDialog["TEST_ID_DOCUMENT_DIALOG_CreateButton"] = "documentDialog_createButton";
    TestIdDocumentDialog["TEST_ID_DOCUMENT_DIALOG_IndexNumber"] = "documentDialog_indexNumber";
    TestIdDocumentDialog["TEST_ID_DOCUMENT_DIALOG_IndexCheckbox"] = "documentDialog_indexCheckbox";
})(TestIdDocumentDialog || (exports.TestIdDocumentDialog = TestIdDocumentDialog = {}));
/** -----bookmark dialog----- **/
var TestIdBookmarkDialog;
(function (TestIdBookmarkDialog) {
    TestIdBookmarkDialog["TEST_ID_BOOKMARK_DIALOG_BookmarkName"] = "bookmarkDialog_bookmarkName";
    TestIdBookmarkDialog["TEST_ID_BOOKMARK_DIALOG_Description"] = "bookmarkDialog_description";
    TestIdBookmarkDialog["TEST_ID_BOOKMARK_DIALOG_Webaddress"] = "bookmarkDialog_webadress";
    TestIdBookmarkDialog["TEST_ID_BOOKMARK_DIALOG_CancelButton"] = "bookmarkDialog_cancelButton";
    TestIdBookmarkDialog["TEST_ID_BOOKMARK_DIALOG_CreateButton"] = "bookmarkDialog_createButton";
    TestIdBookmarkDialog["TEST_ID_BOOKMARK_DIALOG_IndexNumber"] = "bookmarkDialog_indexNumber";
    TestIdBookmarkDialog["TEST_ID_BOOKMARK_DIALOG_IndexCheckbox"] = "bookmarkDialog_indexCheckbox";
})(TestIdBookmarkDialog || (exports.TestIdBookmarkDialog = TestIdBookmarkDialog = {}));
/** -----groupuser dialog----- **/
var TestIdGroupDialog;
(function (TestIdGroupDialog) {
    TestIdGroupDialog["TEST_ID_GROUP_DIALOG_GroupName"] = "groupDialog_groupName";
    TestIdGroupDialog["TEST_ID_GROUP_DIALOG_Description"] = "groupDialog_description";
    TestIdGroupDialog["TEST_ID_GROUP_DIALOG_CreateGroupButton"] = "groupDialog_createButton";
    TestIdGroupDialog["TEST_ID_GROUP_DIALOG_CancelGroupButton"] = "groupDialog_cancelButton";
})(TestIdGroupDialog || (exports.TestIdGroupDialog = TestIdGroupDialog = {}));
var TestIdCreateUserDialog;
(function (TestIdCreateUserDialog) {
    TestIdCreateUserDialog["TEST_ID_CREATE_USER_DIALOG_CancelButton"] = "createUserDialog_cancelButton";
    TestIdCreateUserDialog["TEST_ID_CREATE_USER_DIALOG_CreateButton"] = "createUserDialog_createButton";
})(TestIdCreateUserDialog || (exports.TestIdCreateUserDialog = TestIdCreateUserDialog = {}));
var TestIdDeleteUserDialog;
(function (TestIdDeleteUserDialog) {
    TestIdDeleteUserDialog["TEST_ID_DELETE_USER_DIALOG_UserList"] = "deleteUserDialog_userList";
    TestIdDeleteUserDialog["TEST_ID_DELETE_USER_DIALOG_InformCheckbox"] = "deleteUserDialog_informCheckbox";
    TestIdDeleteUserDialog["TEST_ID_DELETE_USER_DIALOG_NotRemoveYourself"] = "deleteUserDialog_notRemoveYourself";
})(TestIdDeleteUserDialog || (exports.TestIdDeleteUserDialog = TestIdDeleteUserDialog = {}));
/** -----access permissions dialog----- **/
var TestIdAccessPermissionsDialog;
(function (TestIdAccessPermissionsDialog) {
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_SelectAllGroups"] = "accessPermissionsDialog_selectAllGroups";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_SelectGroupsPermissions"] = "accessPermissionsDialog_selectGroupsPermissions";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_Filter"] = "accessPermissionsDialog_filter";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_Type"] = "accessPermissionsDialog_type";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_Name"] = "accessPermissionsDialog_name";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_TooltipNone"] = "accessPermissionsDialog_tooltipNone";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_TooltipReadOnly"] = "accessPermissionsDialog_tooltipReadOnly";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_TooltipDownloadPDF"] = "accessPermissionsDialog_tooltipDownloadPDF";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_TooltipDownload"] = "accessPermissionsDialog_tooltipDownload";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_TooltipWrite"] = "accessPermissionsDialog_tooltipWrite";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_TooltipActive"] = "accessPermissionsDialog_tooltipActive";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_ListingGroup"] = "accessPermissionsDialog_listingGroup";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_ListingGroupIcon"] = "accessPermissionsDialog_listingGroupIcon";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_ListingGroupName"] = "accessPermissionsDialog_listingGroupName";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_RadioNone"] = "accessPermissionsDialog_radioNone";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_RadioReadOnly"] = "accessPermissionsDialog_radioReadOnly";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_RadioDownloadPDF"] = "accessPermissionsDialog_radioDownloadPDF";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_RadioDownload"] = "accessPermissionsDialog_radioDownload";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_RadioWrite"] = "accessPermissionsDialog_radioWrite";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_Checkbox"] = "accessPermissionsDialog_checkBox";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_SelectOnlyCurrentFolder"] = "accessPermissionsDialog_selectOnlyCurrentFolder";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_SelectRecursiveChosenGroup"] = "accessPermissionsDialog_selectRecursiveChosenGroup";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_SelectRecursiveAllGroups"] = "accessPermissionsDialog_selectRecursiveAllGroups";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_Cancel"] = "accessPermissionsDialog_cancelButton";
    TestIdAccessPermissionsDialog["TEST_ID_ACCESS_PERMISSIONS_DIALOG_SavePermissions"] = "accessPermissionsDialog_savePermissionsButton";
})(TestIdAccessPermissionsDialog || (exports.TestIdAccessPermissionsDialog = TestIdAccessPermissionsDialog = {}));
/** -----properties dialog----- **/
var TestIdPropertiesDialog;
(function (TestIdPropertiesDialog) {
    TestIdPropertiesDialog["TEST_ID_PROPERTIES_DIALOG"] = "propertiesDialog";
    TestIdPropertiesDialog["TEST_ID_PROPERTIES_DIALOG_CalculateSize"] = "propertiesDialog_calculateSize";
    TestIdPropertiesDialog["TEST_ID_PROPERTIES_DIALOG_DocSize"] = "propertiesDialog_docSize";
    TestIdPropertiesDialog["TEST_ID_PROPERTIES_DIALOG_NavItem"] = "propertiesDialog_navItem";
    TestIdPropertiesDialog["TEST_ID_PROPERTIES_DIALOG_PropertiesTitle"] = "propertiesDialog_propertiesTitle";
    TestIdPropertiesDialog["TEST_ID_PROPERTIES_DIALOG_UserIcon"] = "propertiesDialog_userIcon";
    TestIdPropertiesDialog["TEST_ID_PROPERTIES_DIALOG_ResetButton"] = "propertiesDialog_resetButton";
    TestIdPropertiesDialog["TEST_ID_PROPERTIES_DIALOG_CloseButton"] = "propertiesDialog_closeButton";
})(TestIdPropertiesDialog || (exports.TestIdPropertiesDialog = TestIdPropertiesDialog = {}));
/** -----send internal link dialog----- **/
var TestIdSendInternalLinkDialog;
(function (TestIdSendInternalLinkDialog) {
    TestIdSendInternalLinkDialog["TEST_ID_SEND_INTERNAL_LINK_DIALOG_To"] = "sendInternalLinkDialog_to";
    TestIdSendInternalLinkDialog["TEST_ID_SEND_INTERNAL_LINK_DIALOG_Cc"] = "sendInternalLinkDialog_cc";
    TestIdSendInternalLinkDialog["TEST_ID_SEND_INTERNAL_LINK_DIALOG_UserIconTo"] = "sendInternalLinkDialog_userIconTo";
    TestIdSendInternalLinkDialog["TEST_ID_SEND_INTERNAL_LINK_DIALOG_UserIconCc"] = "sendInternalLinkDialog_userIconCc";
    TestIdSendInternalLinkDialog["TEST_ID_SEND_INTERNAL_LINK_DIALOG_AutoCompleteListItemTo"] = "sendInternalLinkDialog_autoCompleteListItemTo";
    TestIdSendInternalLinkDialog["TEST_ID_SEND_INTERNAL_LINK_DIALOG_AutoCompleteArrowIconTo"] = "sendInternalLinkDialog_autoCompleteArrowIconTo";
    TestIdSendInternalLinkDialog["TEST_ID_SEND_INTERNAL_LINK_DIALOG_AutoCompleteListItemCc"] = "sendInternalLinkDialog_autoCompleteListItemCc";
    TestIdSendInternalLinkDialog["TEST_ID_SEND_INTERNAL_LINK_DIALOG_AutoCompleteArrowIconCc"] = "sendInternalLinkDialog_autoCompleteArrowIconCc";
    TestIdSendInternalLinkDialog["TEST_ID_SEND_INTERNAL_LINK_DIALOG_Subject"] = "sendInternalLinkDialog_subject";
    TestIdSendInternalLinkDialog["TEST_ID_SEND_INTERNAL_LINK_DIALOG_Message"] = "sendInternalLinkDialog_message";
    TestIdSendInternalLinkDialog["TEST_ID_SEND_INTERNAL_LINK_DIALOG_SelectedObjectsListEmpty"] = "sendInternalLinkDialog_selectedObjectsListEmpty";
    TestIdSendInternalLinkDialog["TEST_ID_SEND_INTERNAL_LINK_DIALOG_SelectedObjectsListItem"] = "sendInternalLinkDialog_selectedObjectsListItem";
    TestIdSendInternalLinkDialog["TEST_ID_SEND_INTERNAL_LINK_DIALOG_RemoveSelectedObjectsListItem"] = "sendInternalLinkDialog_removeSelectedObjectsListItem";
    TestIdSendInternalLinkDialog["TEST_ID_SEND_INTERNAL_LINK_DIALOG_SelectedObjectsButton"] = "sendInternalLinkDialog_selectedObjectsButton";
    TestIdSendInternalLinkDialog["TEST_ID_SEND_INTERNAL_LINK_DIALOG_SendCopy"] = "sendInternalLinkDialog_sendCopy";
    TestIdSendInternalLinkDialog["TEST_ID_SEND_INTERNAL_LINK_DIALOG_NotShowReceivers"] = "sendInternalLinkDialog_notShowReceivers";
    TestIdSendInternalLinkDialog["TEST_ID_SEND_INTERNAL_LINK_DIALOG_PlainText"] = "sendInternalLinkDialog_plainText";
    TestIdSendInternalLinkDialog["TEST_ID_SEND_INTERNAL_LINK_DIALOG_CancelButton"] = "sendInternalLinkDialog_cancelButton";
    TestIdSendInternalLinkDialog["TEST_ID_SEND_INTERNAL_LINK_DIALOG_sendLinkButton"] = "sendInternalLinkDialog_sendLinkButton";
})(TestIdSendInternalLinkDialog || (exports.TestIdSendInternalLinkDialog = TestIdSendInternalLinkDialog = {}));
/** -----select groupuser dialog----- **/
var TestIdSelectGroupsUsersDialog;
(function (TestIdSelectGroupsUsersDialog) {
    TestIdSelectGroupsUsersDialog["TEST_ID_SELECT_GROUPS_USERS_DIALOG_Filter"] = "selectGroupsUsersDialog_filter";
    TestIdSelectGroupsUsersDialog["TEST_ID_SELECT_GROUPS_USERS_DIALOG_GroupsUsersList"] = "selectGroupsUsersDialog_groupsUsersList";
    TestIdSelectGroupsUsersDialog["TEST_ID_SELECT_GROUPS_USERS_DIALOG_Checkbox"] = "selectGroupsUsersDialog_checkbox";
    TestIdSelectGroupsUsersDialog["TEST_ID_SELECT_GROUPS_USERS_DIALOG_CancelButton"] = "selectGroupsUsersDialog_cancelButton";
    TestIdSelectGroupsUsersDialog["TEST_ID_SELECT_GROUPS_USERS_DIALOG_SelectGroupsUsersButton"] = "selectGroupsUsersDialog_selectGroupsUsersButton";
})(TestIdSelectGroupsUsersDialog || (exports.TestIdSelectGroupsUsersDialog = TestIdSelectGroupsUsersDialog = {}));
/** -----send invitation dialog----- **/
var TestIdSendInvitationDialog;
(function (TestIdSendInvitationDialog) {
    TestIdSendInvitationDialog["TEST_ID_SEND_INVITATION_DIALOG"] = "sendInvitationDialog";
    TestIdSendInvitationDialog["TEST_ID_SEND_INVITATION_DIALOG_Title"] = "sendInvitationDialog_title";
    TestIdSendInvitationDialog["TEST_ID_SEND_INVITATION_DIALOG_UsersList"] = "sendInvitationDialog_usersList";
    TestIdSendInvitationDialog["TEST_ID_SEND_INVITATION_DIALOG_Message"] = "sendInvitationDialog_message";
    TestIdSendInvitationDialog["TEST_ID_SEND_INVITATION_DIALOG_InvalidUsers"] = "sendInvitationDialog_invalidUsers";
    TestIdSendInvitationDialog["TEST_ID_SEND_INVITATION_DIALOG_CancelButton"] = "sendInvitationDialog_cancelButton";
    TestIdSendInvitationDialog["TEST_ID_SEND_INVITATION_DIALOG_SendButton"] = "sendInvitationDialog_sendButton";
})(TestIdSendInvitationDialog || (exports.TestIdSendInvitationDialog = TestIdSendInvitationDialog = {}));
/** -----alert dialog----- **/
var TestIdAlertDialog;
(function (TestIdAlertDialog) {
    TestIdAlertDialog["TEST_ID_ALERT_DIALOG_DialogTitle"] = "alertDialog_dialogTitle";
    TestIdAlertDialog["TEST_ID_ALERT_DIALOG_DialogText"] = "alertDialog_dialogText";
    TestIdAlertDialog["TEST_ID_ALERT_DIALOG_SubmitButton"] = "alertDialog_submitButton";
    TestIdAlertDialog["TEST_ID_ALERT_DIALOG_ErrorMessage"] = "alertDialog_errorMessage";
})(TestIdAlertDialog || (exports.TestIdAlertDialog = TestIdAlertDialog = {}));
/** -----index dialog----- **/
var TestIdIndexDialog;
(function (TestIdIndexDialog) {
    TestIdIndexDialog["TEST_ID_INDEX_DIALOG"] = "dialogIndex";
    TestIdIndexDialog["TEST_ID_INDEX_DIALOG_TabCreateIndex"] = "dialogIndex_tabCreateIndex";
    TestIdIndexDialog["TEST_ID_INDEX_DIALOG_TabAdjustIndex"] = "dialogIndex_tabAdjustIndex";
    TestIdIndexDialog["TEST_ID_INDEX_DIALOG_AdjustIndexInfoText"] = "dialogIndex_adjustIndexInfoText";
    TestIdIndexDialog["TEST_ID_INDEX_DIALOG_Title"] = "dialogIndex_title";
    TestIdIndexDialog["TEST_ID_INDEX_DIALOG_DueDiligence"] = "dialogIndex_dueDiligence";
    TestIdIndexDialog["TEST_ID_INDEX_DIALOG_CommercialRealEstateTransactions"] = "dialogIndex_commercialRealEstateTransactions";
    TestIdIndexDialog["TEST_ID_INDEX_DIALOG_OwnIndex"] = "dialogIndex_ownIndex";
    TestIdIndexDialog["TEST_ID_INDEX_DIALOG_InfoIconDueDiligence"] = "dialogIndex_infoIconDueDiligence";
    TestIdIndexDialog["TEST_ID_INDEX_DIALOG_InfoIconCommercialRealEstate"] = "dialogIndex_infoIconCommercialRealEstate";
    TestIdIndexDialog["TEST_ID_INDEX_DIALOG_InfoIconOwnIndex"] = "dialogIndex_infoIconOwnIndex";
    TestIdIndexDialog["TEST_ID_INDEX_DIALOG_TooltipDueDiligence"] = "dialogIndex_tooltipDueDiligence";
    TestIdIndexDialog["TEST_ID_INDEX_DIALOG_TooltipCommercialRealEstate"] = "dialogIndex_tooltipCommercialRealEstate";
    TestIdIndexDialog["TEST_ID_INDEX_DIALOG_TooltipOwnIndex"] = "dialogIndex_tooltipOwnIndex";
    TestIdIndexDialog["TEST_ID_INDEX_DIALOG_PaperclipIcon"] = "dialogIndex_paperclipIcon";
    TestIdIndexDialog["TEST_ID_INDEX_DIALOG_ChooseFileTextfield"] = "dialogIndex_chooseFileTextfield";
    TestIdIndexDialog["TEST_ID_INDEX_DIALOG_CancelButton"] = "dialogIndex_cancelButton";
    TestIdIndexDialog["TEST_ID_INDEX_DIALOG_CreateIndexButton"] = "dialogIndex_createIndexButton";
})(TestIdIndexDialog || (exports.TestIdIndexDialog = TestIdIndexDialog = {}));
/** -----mft inbox dialog----- **/
var TestIdMftInboxDialog;
(function (TestIdMftInboxDialog) {
    TestIdMftInboxDialog["TEST_ID_MFT_INBOX_DIALOG"] = "dialogMftInbox";
    TestIdMftInboxDialog["TEST_ID_MFT_INBOX_DIALOG_TransferDirect"] = "dialogMftInbox_transferDirect";
    TestIdMftInboxDialog["TEST_ID_MFT_INBOX_DIALOG_TransferOwn"] = "dialogMftInbox_transferOwn";
    TestIdMftInboxDialog["TEST_ID_MFT_INBOX_DIALOG_ReceiverEmail"] = "dialogMftInbox_receiverEmail";
    TestIdMftInboxDialog["TEST_ID_MFT_INBOX_DIALOG_Groups"] = "dialogMftInbox_groups";
    TestIdMftInboxDialog["TEST_ID_MFT_INBOX_DIALOG_Subject"] = "dialogMftInbox_subject";
    TestIdMftInboxDialog["TEST_ID_MFT_INBOX_DIALOG_Message"] = "dialogMftInbox_message";
    TestIdMftInboxDialog["TEST_ID_MFT_INBOX_DIALOG_LanguageDE"] = "dialogMftInbox_languageDE";
    TestIdMftInboxDialog["TEST_ID_MFT_INBOX_DIALOG_LanguageEN"] = "dialogMftInbox_languageEN";
    TestIdMftInboxDialog["TEST_ID_MFT_INBOX_DIALOG_Password"] = "dialogMftInbox_password";
    TestIdMftInboxDialog["TEST_ID_MFT_INBOX_DIALOG_RestrictAccessFrom"] = "dialogMftInbox_restrictAccessFrom";
    TestIdMftInboxDialog["TEST_ID_MFT_INBOX_DIALOG_RestrictAccessTo"] = "dialogMftInbox_restrictAccessTo";
    TestIdMftInboxDialog["TEST_ID_MFT_INBOX_DIALOG_Verification"] = "dialogMftInbox_verification";
    TestIdMftInboxDialog["TEST_ID_MFT_INBOX_DIALOG_SendLinkButton"] = "dialogMftInbox_sendLinkButton";
    TestIdMftInboxDialog["TEST_ID_MFT_INBOX_DIALOG_CancelButton"] = "dialogMftInbox_cancelButton";
})(TestIdMftInboxDialog || (exports.TestIdMftInboxDialog = TestIdMftInboxDialog = {}));
/** -----mft outbox dialog----- **/
var TestIdMftOutboxDialog;
(function (TestIdMftOutboxDialog) {
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG"] = "dialogMftOutbox";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_TransferDirect"] = "dialogMftOutbox_transferDirect";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_TransferOwn"] = "dialogMftOutbox_transferOwn";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_ReceiverEmail"] = "dialogMftOutbox_receiverEmail";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_Groups"] = "dialogMftOutbox_groups";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_Subject"] = "dialogMftOutbox_subject";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_Message"] = "dialogMftOutbox_message";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_EmptyFileListing"] = "dialogMftOutbox_emptyFileListing";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_FileListing"] = "dialogMftOutbox_fileListing";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_ChooseFilesButton"] = "dialogMftOutbox_chooseFilesButton";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_SelectFromDataroomButton"] = "dialogMftOutbox_selectFromDataroomButton";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_LanguageDE"] = "dialogMftOutbox_languageDE";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_LanguageEN"] = "dialogMftOutbox_languageEN";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_Password"] = "dialogMftOutbox_password";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_RestrictAccessFrom"] = "dialogMftOutbox_restrictAccessFrom";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_RestrictAccessTo"] = "dialogMftOutbox_restrictAccessTo";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_Verification"] = "dialogMftOutbox_verification";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_NotifyOnDownload"] = "dialogMftOutbox_notifyOnDownload";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_SendCopy"] = "dialogMftOutbox_sendCopy";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_PlainText"] = "dialogMftOutbox_plainText";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_SendProtectedPdf"] = "dialogMftOutbox_sendProtectedPdf";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_Watermarks"] = "dialogMftOutbox_watermarks";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_SendLinkButton"] = "dialogMftOutbox_sendLinkButton";
    TestIdMftOutboxDialog["TEST_ID_MFT_OUTBOX_DIALOG_CancelButton"] = "dialogMftOutbox_cancelButton";
})(TestIdMftOutboxDialog || (exports.TestIdMftOutboxDialog = TestIdMftOutboxDialog = {}));
//------------------------------------------------------------------------
