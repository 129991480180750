"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupProperties = exports.GroupSettingsEntryAsGroupAdmins = exports.GroupSettingsEntryAsGroupLabel = exports.GroupLabelEntry = exports.GroupLabel = exports.Group = exports.GroupsItem = exports.GroupsRoot = exports.GroupAdmins = exports.GroupAdmin = exports.protobufPackage = void 0;
/* eslint-disable */
const item_1 = require("./item");
const timestamp_1 = require("./google/protobuf/timestamp");
const permission_1 = require("./permission");
const settings_1 = require("./settings");
exports.protobufPackage = 'de.netfiles.pb';
function createBaseGroupAdmin() {
    return { id: '', title: '' };
}
exports.GroupAdmin = {
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : '',
            title: isSet(object.title) ? String(object.title) : '',
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.title !== undefined && (obj.title = message.title);
        return obj;
    },
};
function createBaseGroupAdmins() {
    return { items: [] };
}
exports.GroupAdmins = {
    fromJSON(object) {
        return {
            items: Array.isArray(object?.items)
                ? object.items.map((e) => exports.GroupAdmin.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.items) {
            obj.items = message.items.map(e => e ? exports.GroupAdmin.toJSON(e) : undefined);
        }
        else {
            obj.items = [];
        }
        return obj;
    },
};
function createBaseGroupsRoot() {
    return {
        id: '',
        dataroomId: '',
        name: '',
        type: '',
        documentClass: '',
        author: undefined,
        title: '',
        creationDate: undefined,
        modificationDate: undefined,
        effectivePermission: undefined,
        description: '',
        numChildren: 0,
        itemType: 0,
    };
}
exports.GroupsRoot = {
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : '',
            dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
            name: isSet(object.name) ? String(object.name) : '',
            type: isSet(object.type) ? String(object.type) : '',
            documentClass: isSet(object.documentClass)
                ? String(object.documentClass)
                : '',
            author: isSet(object.author) ? String(object.author) : undefined,
            title: isSet(object.title) ? String(object.title) : '',
            creationDate: isSet(object.creationDate)
                ? fromJsonTimestamp(object.creationDate)
                : undefined,
            modificationDate: isSet(object.modificationDate)
                ? fromJsonTimestamp(object.modificationDate)
                : undefined,
            effectivePermission: isSet(object.effectivePermission)
                ? permission_1.EffectivePermission.fromJSON(object.effectivePermission)
                : undefined,
            description: isSet(object.description) ? String(object.description) : '',
            numChildren: isSet(object.numChildren) ? Number(object.numChildren) : 0,
            itemType: isSet(object.itemType) ? (0, item_1.itemTypeFromJSON)(object.itemType) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
        message.name !== undefined && (obj.name = message.name);
        message.type !== undefined && (obj.type = message.type);
        message.documentClass !== undefined &&
            (obj.documentClass = message.documentClass);
        message.author !== undefined && (obj.author = message.author);
        message.title !== undefined && (obj.title = message.title);
        message.creationDate !== undefined &&
            (obj.creationDate = message.creationDate.toISOString());
        message.modificationDate !== undefined &&
            (obj.modificationDate = message.modificationDate.toISOString());
        message.effectivePermission !== undefined &&
            (obj.effectivePermission = message.effectivePermission
                ? permission_1.EffectivePermission.toJSON(message.effectivePermission)
                : undefined);
        message.description !== undefined &&
            (obj.description = message.description);
        message.numChildren !== undefined &&
            (obj.numChildren = Math.round(message.numChildren));
        message.itemType !== undefined &&
            (obj.itemType = (0, item_1.itemTypeToJSON)(message.itemType));
        return obj;
    },
};
function createBaseGroupsItem() {
    return { group: undefined, groupsRoot: undefined };
}
exports.GroupsItem = {
    fromJSON(object) {
        return {
            group: isSet(object.group) ? exports.Group.fromJSON(object.group) : undefined,
            groupsRoot: isSet(object.groupsRoot)
                ? exports.GroupsRoot.fromJSON(object.groupsRoot)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.group !== undefined &&
            (obj.group = message.group ? exports.Group.toJSON(message.group) : undefined);
        message.groupsRoot !== undefined &&
            (obj.groupsRoot = message.groupsRoot
                ? exports.GroupsRoot.toJSON(message.groupsRoot)
                : undefined);
        return obj;
    },
};
function createBaseGroup() {
    return {
        id: '',
        dataroomId: undefined,
        name: '',
        type: undefined,
        documentClass: undefined,
        author: undefined,
        title: '',
        creationDate: undefined,
        modificationDate: undefined,
        effectivePermission: undefined,
        description: undefined,
        numChildren: undefined,
        groupAdmins: undefined,
        labels: undefined,
        itemType: 0,
        itemSubtype: undefined,
    };
}
exports.Group = {
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : '',
            dataroomId: isSet(object.dataroomId)
                ? String(object.dataroomId)
                : undefined,
            name: isSet(object.name) ? String(object.name) : '',
            type: isSet(object.type) ? String(object.type) : undefined,
            documentClass: isSet(object.documentClass)
                ? String(object.documentClass)
                : undefined,
            author: isSet(object.author) ? String(object.author) : undefined,
            title: isSet(object.title) ? String(object.title) : '',
            creationDate: isSet(object.creationDate)
                ? fromJsonTimestamp(object.creationDate)
                : undefined,
            modificationDate: isSet(object.modificationDate)
                ? fromJsonTimestamp(object.modificationDate)
                : undefined,
            effectivePermission: isSet(object.effectivePermission)
                ? permission_1.EffectivePermission.fromJSON(object.effectivePermission)
                : undefined,
            description: isSet(object.description)
                ? String(object.description)
                : undefined,
            numChildren: isSet(object.numChildren)
                ? Number(object.numChildren)
                : undefined,
            groupAdmins: isSet(object.groupAdmins)
                ? exports.GroupAdmins.fromJSON(object.groupAdmins)
                : undefined,
            labels: isSet(object.labels)
                ? exports.GroupLabel.fromJSON(object.labels)
                : undefined,
            itemType: isSet(object.itemType) ? (0, item_1.itemTypeFromJSON)(object.itemType) : 0,
            itemSubtype: isSet(object.itemSubtype)
                ? (0, item_1.itemSubTypeFromJSON)(object.itemSubtype)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
        message.name !== undefined && (obj.name = message.name);
        message.type !== undefined && (obj.type = message.type);
        message.documentClass !== undefined &&
            (obj.documentClass = message.documentClass);
        message.author !== undefined && (obj.author = message.author);
        message.title !== undefined && (obj.title = message.title);
        message.creationDate !== undefined &&
            (obj.creationDate = message.creationDate.toISOString());
        message.modificationDate !== undefined &&
            (obj.modificationDate = message.modificationDate.toISOString());
        message.effectivePermission !== undefined &&
            (obj.effectivePermission = message.effectivePermission
                ? permission_1.EffectivePermission.toJSON(message.effectivePermission)
                : undefined);
        message.description !== undefined &&
            (obj.description = message.description);
        message.numChildren !== undefined &&
            (obj.numChildren = Math.round(message.numChildren));
        message.groupAdmins !== undefined &&
            (obj.groupAdmins = message.groupAdmins
                ? exports.GroupAdmins.toJSON(message.groupAdmins)
                : undefined);
        message.labels !== undefined &&
            (obj.labels = message.labels
                ? exports.GroupLabel.toJSON(message.labels)
                : undefined);
        message.itemType !== undefined &&
            (obj.itemType = (0, item_1.itemTypeToJSON)(message.itemType));
        message.itemSubtype !== undefined &&
            (obj.itemSubtype =
                message.itemSubtype !== undefined
                    ? (0, item_1.itemSubTypeToJSON)(message.itemSubtype)
                    : undefined);
        return obj;
    },
};
function createBaseGroupLabel() {
    return {
        label1: undefined,
        label2: undefined,
        label3: undefined,
        label4: undefined,
        label5: undefined,
        label6: undefined,
        label7: undefined,
    };
}
exports.GroupLabel = {
    fromJSON(object) {
        return {
            label1: isSet(object.label1)
                ? exports.GroupLabelEntry.fromJSON(object.label1)
                : undefined,
            label2: isSet(object.label2)
                ? exports.GroupLabelEntry.fromJSON(object.label2)
                : undefined,
            label3: isSet(object.label3)
                ? exports.GroupLabelEntry.fromJSON(object.label3)
                : undefined,
            label4: isSet(object.label4)
                ? exports.GroupLabelEntry.fromJSON(object.label4)
                : undefined,
            label5: isSet(object.label5)
                ? exports.GroupLabelEntry.fromJSON(object.label5)
                : undefined,
            label6: isSet(object.label6)
                ? exports.GroupLabelEntry.fromJSON(object.label6)
                : undefined,
            label7: isSet(object.label7)
                ? exports.GroupLabelEntry.fromJSON(object.label7)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.label1 !== undefined &&
            (obj.label1 = message.label1
                ? exports.GroupLabelEntry.toJSON(message.label1)
                : undefined);
        message.label2 !== undefined &&
            (obj.label2 = message.label2
                ? exports.GroupLabelEntry.toJSON(message.label2)
                : undefined);
        message.label3 !== undefined &&
            (obj.label3 = message.label3
                ? exports.GroupLabelEntry.toJSON(message.label3)
                : undefined);
        message.label4 !== undefined &&
            (obj.label4 = message.label4
                ? exports.GroupLabelEntry.toJSON(message.label4)
                : undefined);
        message.label5 !== undefined &&
            (obj.label5 = message.label5
                ? exports.GroupLabelEntry.toJSON(message.label5)
                : undefined);
        message.label6 !== undefined &&
            (obj.label6 = message.label6
                ? exports.GroupLabelEntry.toJSON(message.label6)
                : undefined);
        message.label7 !== undefined &&
            (obj.label7 = message.label7
                ? exports.GroupLabelEntry.toJSON(message.label7)
                : undefined);
        return obj;
    },
};
function createBaseGroupLabelEntry() {
    return { text: '', enabled: false };
}
exports.GroupLabelEntry = {
    fromJSON(object) {
        return {
            text: isSet(object.text) ? String(object.text) : '',
            enabled: isSet(object.enabled) ? Boolean(object.enabled) : false,
        };
    },
    toJSON(message) {
        const obj = {};
        message.text !== undefined && (obj.text = message.text);
        message.enabled !== undefined && (obj.enabled = message.enabled);
        return obj;
    },
};
function createBaseGroupSettingsEntryAsGroupLabel() {
    return {
        value: undefined,
        editable: false,
        fieldType: undefined,
        tooltipType: undefined,
    };
}
exports.GroupSettingsEntryAsGroupLabel = {
    fromJSON(object) {
        return {
            value: isSet(object.value)
                ? exports.GroupLabel.fromJSON(object.value)
                : undefined,
            editable: isSet(object.editable) ? Boolean(object.editable) : false,
            fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
            tooltipType: isSet(object.tooltipType)
                ? String(object.tooltipType)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.value !== undefined &&
            (obj.value = message.value
                ? exports.GroupLabel.toJSON(message.value)
                : undefined);
        message.editable !== undefined && (obj.editable = message.editable);
        message.fieldType !== undefined && (obj.fieldType = message.fieldType);
        message.tooltipType !== undefined &&
            (obj.tooltipType = message.tooltipType);
        return obj;
    },
};
function createBaseGroupSettingsEntryAsGroupAdmins() {
    return {
        value: undefined,
        editable: false,
        fieldType: undefined,
        tooltipType: undefined,
    };
}
exports.GroupSettingsEntryAsGroupAdmins = {
    fromJSON(object) {
        return {
            value: isSet(object.value)
                ? exports.GroupAdmins.fromJSON(object.value)
                : undefined,
            editable: isSet(object.editable) ? Boolean(object.editable) : false,
            fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
            tooltipType: isSet(object.tooltipType)
                ? String(object.tooltipType)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.value !== undefined &&
            (obj.value = message.value
                ? exports.GroupAdmins.toJSON(message.value)
                : undefined);
        message.editable !== undefined && (obj.editable = message.editable);
        message.fieldType !== undefined && (obj.fieldType = message.fieldType);
        message.tooltipType !== undefined &&
            (obj.tooltipType = message.tooltipType);
        return obj;
    },
};
function createBaseGroupProperties() {
    return {
        id: '',
        dataroomId: undefined,
        name: undefined,
        title: undefined,
        description: undefined,
        labels: undefined,
        groupAdmins: undefined,
        itemType: 0,
        itemSubtype: undefined,
    };
}
exports.GroupProperties = {
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : '',
            dataroomId: isSet(object.dataroomId)
                ? String(object.dataroomId)
                : undefined,
            name: isSet(object.name)
                ? settings_1.SettingsEntryAsString.fromJSON(object.name)
                : undefined,
            title: isSet(object.title)
                ? settings_1.SettingsEntryAsString.fromJSON(object.title)
                : undefined,
            description: isSet(object.description)
                ? settings_1.SettingsEntryAsString.fromJSON(object.description)
                : undefined,
            labels: isSet(object.labels)
                ? exports.GroupSettingsEntryAsGroupLabel.fromJSON(object.labels)
                : undefined,
            groupAdmins: isSet(object.groupAdmins)
                ? exports.GroupSettingsEntryAsGroupAdmins.fromJSON(object.groupAdmins)
                : undefined,
            itemType: isSet(object.itemType) ? (0, item_1.itemTypeFromJSON)(object.itemType) : 0,
            itemSubtype: isSet(object.itemSubtype)
                ? (0, item_1.itemSubTypeFromJSON)(object.itemSubtype)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
        message.name !== undefined &&
            (obj.name = message.name
                ? settings_1.SettingsEntryAsString.toJSON(message.name)
                : undefined);
        message.title !== undefined &&
            (obj.title = message.title
                ? settings_1.SettingsEntryAsString.toJSON(message.title)
                : undefined);
        message.description !== undefined &&
            (obj.description = message.description
                ? settings_1.SettingsEntryAsString.toJSON(message.description)
                : undefined);
        message.labels !== undefined &&
            (obj.labels = message.labels
                ? exports.GroupSettingsEntryAsGroupLabel.toJSON(message.labels)
                : undefined);
        message.groupAdmins !== undefined &&
            (obj.groupAdmins = message.groupAdmins
                ? exports.GroupSettingsEntryAsGroupAdmins.toJSON(message.groupAdmins)
                : undefined);
        message.itemType !== undefined &&
            (obj.itemType = (0, item_1.itemTypeToJSON)(message.itemType));
        message.itemSubtype !== undefined &&
            (obj.itemSubtype =
                message.itemSubtype !== undefined
                    ? (0, item_1.itemSubTypeToJSON)(message.itemSubtype)
                    : undefined);
        return obj;
    },
};
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis);
}
function fromJsonTimestamp(o) {
    if (o instanceof Date) {
        return o;
    }
    else if (typeof o === 'string') {
        return new Date(o);
    }
    else {
        return fromTimestamp(timestamp_1.Timestamp.fromJSON(o));
    }
}
function isSet(value) {
    return value !== null && value !== undefined;
}
