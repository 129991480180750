/* eslint-disable */
import {
  ItemType,
  ItemSubType,
  itemTypeFromJSON,
  itemTypeToJSON,
  itemSubTypeFromJSON,
  itemSubTypeToJSON,
} from './item';
import { Timestamp } from './google/protobuf/timestamp';
import { Group } from './groups';
import { EffectivePermission, ItemPermissionsEntry } from './permission';
import { Parents, Parent } from './document';
import { Labels } from './label';
import {
  SettingsEntryAsString,
  SettingsEntryAsBool,
  SettingsEntryAsDate,
  SettingsEntryAsNumber,
} from './settings';

export const protobufPackage = 'de.netfiles.pb';

export enum RecursiveApproval {
  RECURSIVEAPPROVAL_UNSPECIFIED = 0,
  RECURSIVEAPPROVAL_ALL = 1,
  RECURSIVEAPPROVAL_NEW = 2,
  /** RECURSIVEAPPROVAL_NEW_NOT_RECURSIVE - for backwards compatibility only, remove later on */
  RECURSIVEAPPROVAL_NEW_NOT_RECURSIVE = 3,
  /** RECURSIVEAPPROVAL_ALL_NOT_RECURSIVE - for backwards compatibility only, remove later on */
  RECURSIVEAPPROVAL_ALL_NOT_RECURSIVE = 4,
  UNRECOGNIZED = -1,
}

export function recursiveApprovalFromJSON(object: any): RecursiveApproval {
  switch (object) {
    case 0:
    case 'RECURSIVEAPPROVAL_UNSPECIFIED':
      return RecursiveApproval.RECURSIVEAPPROVAL_UNSPECIFIED;
    case 1:
    case 'RECURSIVEAPPROVAL_ALL':
      return RecursiveApproval.RECURSIVEAPPROVAL_ALL;
    case 2:
    case 'RECURSIVEAPPROVAL_NEW':
      return RecursiveApproval.RECURSIVEAPPROVAL_NEW;
    case 3:
    case 'RECURSIVEAPPROVAL_NEW_NOT_RECURSIVE':
      return RecursiveApproval.RECURSIVEAPPROVAL_NEW_NOT_RECURSIVE;
    case 4:
    case 'RECURSIVEAPPROVAL_ALL_NOT_RECURSIVE':
      return RecursiveApproval.RECURSIVEAPPROVAL_ALL_NOT_RECURSIVE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return RecursiveApproval.UNRECOGNIZED;
  }
}

export function recursiveApprovalToJSON(object: RecursiveApproval): string {
  switch (object) {
    case RecursiveApproval.RECURSIVEAPPROVAL_UNSPECIFIED:
      return 'RECURSIVEAPPROVAL_UNSPECIFIED';
    case RecursiveApproval.RECURSIVEAPPROVAL_ALL:
      return 'RECURSIVEAPPROVAL_ALL';
    case RecursiveApproval.RECURSIVEAPPROVAL_NEW:
      return 'RECURSIVEAPPROVAL_NEW';
    case RecursiveApproval.RECURSIVEAPPROVAL_NEW_NOT_RECURSIVE:
      return 'RECURSIVEAPPROVAL_NEW_NOT_RECURSIVE';
    case RecursiveApproval.RECURSIVEAPPROVAL_ALL_NOT_RECURSIVE:
      return 'RECURSIVEAPPROVAL_ALL_NOT_RECURSIVE';
    default:
      return 'UNKNOWN';
  }
}

export enum FolderNotification {
  FOLDERNOTIFICATION_UNSPECIFIED = 0,
  FOLDERNOTIFICATION_DO_NOTIFY = 1,
  FOLDERNOTIFICATION_DONT_NOTIFY = 2,
  FOLDERNOTIFICATION_KEEP = 3,
  UNRECOGNIZED = -1,
}

export function folderNotificationFromJSON(object: any): FolderNotification {
  switch (object) {
    case 0:
    case 'FOLDERNOTIFICATION_UNSPECIFIED':
      return FolderNotification.FOLDERNOTIFICATION_UNSPECIFIED;
    case 1:
    case 'FOLDERNOTIFICATION_DO_NOTIFY':
      return FolderNotification.FOLDERNOTIFICATION_DO_NOTIFY;
    case 2:
    case 'FOLDERNOTIFICATION_DONT_NOTIFY':
      return FolderNotification.FOLDERNOTIFICATION_DONT_NOTIFY;
    case 3:
    case 'FOLDERNOTIFICATION_KEEP':
      return FolderNotification.FOLDERNOTIFICATION_KEEP;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return FolderNotification.UNRECOGNIZED;
  }
}

export function folderNotificationToJSON(object: FolderNotification): string {
  switch (object) {
    case FolderNotification.FOLDERNOTIFICATION_UNSPECIFIED:
      return 'FOLDERNOTIFICATION_UNSPECIFIED';
    case FolderNotification.FOLDERNOTIFICATION_DO_NOTIFY:
      return 'FOLDERNOTIFICATION_DO_NOTIFY';
    case FolderNotification.FOLDERNOTIFICATION_DONT_NOTIFY:
      return 'FOLDERNOTIFICATION_DONT_NOTIFY';
    case FolderNotification.FOLDERNOTIFICATION_KEEP:
      return 'FOLDERNOTIFICATION_KEEP';
    default:
      return 'UNKNOWN';
  }
}

/** Collection Socket Event */
export enum CollectionSocketEvent {
  COLLECTION_SOCKET_EVENT_UPDATE = 0,
  UNRECOGNIZED = -1,
}

export function collectionSocketEventFromJSON(
  object: any,
): CollectionSocketEvent {
  switch (object) {
    case 0:
    case 'COLLECTION_SOCKET_EVENT_UPDATE':
      return CollectionSocketEvent.COLLECTION_SOCKET_EVENT_UPDATE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CollectionSocketEvent.UNRECOGNIZED;
  }
}

export function collectionSocketEventToJSON(
  object: CollectionSocketEvent,
): string {
  switch (object) {
    case CollectionSocketEvent.COLLECTION_SOCKET_EVENT_UPDATE:
      return 'COLLECTION_SOCKET_EVENT_UPDATE';
    default:
      return 'UNKNOWN';
  }
}

/** Property for recursive approval */
export interface SettingsEntryAsRecursiveApproval {
  value: RecursiveApproval;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
}

/** Property for approval group */
export interface SettingsEntryAsApprovalGroup {
  value: string;
  availableGroups: Group[];
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
}

/** Identifies a user or group */
export interface UserOrGroup {
  title: string;
  id: string;
  itemType: ItemType;
}

/** Property for notifications */
export interface SettingsEntryAsNotificationsAdmin {
  value: UserOrGroup[];
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
}

/** Property for notification for non-admin */
export interface SettingsEntryAsNotification {
  value: FolderNotification;
  editable: boolean;
  fieldType?: string | undefined;
  tooltipType?: string | undefined;
}

/** Collection */
export interface Collection {
  id: string;
  dataroomId: string;
  parentId: string;
  name: string;
  type: string;
  documentClass: string;
  author?: string | undefined;
  title: string;
  path?: string | undefined;
  version: string;
  description: string;
  creationDate: Date | undefined;
  modificationDate: Date | undefined;
  icon: string;
  numChildren?: number | undefined;
  effectivePermission: EffectivePermission | undefined;
  subDocs?: number | undefined;
  permissions: ItemPermissionsEntry[];
  restorePath?: string | undefined;
  sequence?: string | undefined;
  index?: string | undefined;
  noIndex?: boolean | undefined;
  parents?: Parents | undefined;
  parentTitle?: string | undefined;
  labels?: Labels | undefined;
  approvalGroup?: string | undefined;
  notification?: FolderNotification | undefined;
  itemType: ItemType;
  hasDifferentParentRights?: boolean | undefined;
  numberOfComments?: number | undefined;
  parent?: Parent | undefined;
  hasPdfPassword?: boolean | undefined;
  hasChildren?: boolean | undefined;
}

/** Collection Properties */
export interface CollectionProperties {
  id: string;
  name?: SettingsEntryAsString | undefined;
  author?: SettingsEntryAsString | undefined;
  title?: SettingsEntryAsString | undefined;
  sequence?: SettingsEntryAsString | undefined;
  index?: SettingsEntryAsString | undefined;
  noIndex?: SettingsEntryAsBool | undefined;
  path?: SettingsEntryAsString | undefined;
  creationDate?: SettingsEntryAsDate | undefined;
  modificationDate?: SettingsEntryAsDate | undefined;
  tags?: SettingsEntryAsString | undefined;
  url?: SettingsEntryAsString | undefined;
  description?: SettingsEntryAsString | undefined;
  approvalGroup?: SettingsEntryAsApprovalGroup | undefined;
  recursiveApprovalType?: SettingsEntryAsRecursiveApproval | undefined;
  recursiveApproval?: SettingsEntryAsBool | undefined;
  pdfPassword?: SettingsEntryAsString | undefined;
  recursivePdfPasswordEnabled?: SettingsEntryAsBool | undefined;
  setExpirationDate?: SettingsEntryAsBool | undefined;
  recursiveSetExpirationDate?: SettingsEntryAsBool | undefined;
  collectionSize?: SettingsEntryAsNumber | undefined;
  subDocs?: SettingsEntryAsNumber | undefined;
  shortUrl?: SettingsEntryAsString | undefined;
  userNotifications?: SettingsEntryAsNotificationsAdmin | undefined;
  notification?: SettingsEntryAsNotification | undefined;
  recursiveNotifications?: SettingsEntryAsBool | undefined;
  deletedOn?: SettingsEntryAsDate | undefined;
  hasDifferentParentRights?: SettingsEntryAsBool | undefined;
  itemType: ItemType;
  itemSubtype?: ItemSubType | undefined;
}

function createBaseSettingsEntryAsRecursiveApproval(): SettingsEntryAsRecursiveApproval {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
  };
}

export const SettingsEntryAsRecursiveApproval = {
  fromJSON(object: any): SettingsEntryAsRecursiveApproval {
    return {
      value: isSet(object.value) ? recursiveApprovalFromJSON(object.value) : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsRecursiveApproval): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = recursiveApprovalToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    return obj;
  },
};

function createBaseSettingsEntryAsApprovalGroup(): SettingsEntryAsApprovalGroup {
  return {
    value: '',
    availableGroups: [],
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
  };
}

export const SettingsEntryAsApprovalGroup = {
  fromJSON(object: any): SettingsEntryAsApprovalGroup {
    return {
      value: isSet(object.value) ? String(object.value) : '',
      availableGroups: Array.isArray(object?.availableGroups)
        ? object.availableGroups.map((e: any) => Group.fromJSON(e))
        : [],
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsApprovalGroup): unknown {
    const obj: any = {};
    message.value !== undefined && (obj.value = message.value);
    if (message.availableGroups) {
      obj.availableGroups = message.availableGroups.map(e =>
        e ? Group.toJSON(e) : undefined,
      );
    } else {
      obj.availableGroups = [];
    }
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    return obj;
  },
};

function createBaseUserOrGroup(): UserOrGroup {
  return { title: '', id: '', itemType: 0 };
}

export const UserOrGroup = {
  fromJSON(object: any): UserOrGroup {
    return {
      title: isSet(object.title) ? String(object.title) : '',
      id: isSet(object.id) ? String(object.id) : '',
      itemType: isSet(object.itemType) ? itemTypeFromJSON(object.itemType) : 0,
    };
  },

  toJSON(message: UserOrGroup): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.id !== undefined && (obj.id = message.id);
    message.itemType !== undefined &&
      (obj.itemType = itemTypeToJSON(message.itemType));
    return obj;
  },
};

function createBaseSettingsEntryAsNotificationsAdmin(): SettingsEntryAsNotificationsAdmin {
  return {
    value: [],
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
  };
}

export const SettingsEntryAsNotificationsAdmin = {
  fromJSON(object: any): SettingsEntryAsNotificationsAdmin {
    return {
      value: Array.isArray(object?.value)
        ? object.value.map((e: any) => UserOrGroup.fromJSON(e))
        : [],
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsNotificationsAdmin): unknown {
    const obj: any = {};
    if (message.value) {
      obj.value = message.value.map(e =>
        e ? UserOrGroup.toJSON(e) : undefined,
      );
    } else {
      obj.value = [];
    }
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    return obj;
  },
};

function createBaseSettingsEntryAsNotification(): SettingsEntryAsNotification {
  return {
    value: 0,
    editable: false,
    fieldType: undefined,
    tooltipType: undefined,
  };
}

export const SettingsEntryAsNotification = {
  fromJSON(object: any): SettingsEntryAsNotification {
    return {
      value: isSet(object.value) ? folderNotificationFromJSON(object.value) : 0,
      editable: isSet(object.editable) ? Boolean(object.editable) : false,
      fieldType: isSet(object.fieldType) ? String(object.fieldType) : undefined,
      tooltipType: isSet(object.tooltipType)
        ? String(object.tooltipType)
        : undefined,
    };
  },

  toJSON(message: SettingsEntryAsNotification): unknown {
    const obj: any = {};
    message.value !== undefined &&
      (obj.value = folderNotificationToJSON(message.value));
    message.editable !== undefined && (obj.editable = message.editable);
    message.fieldType !== undefined && (obj.fieldType = message.fieldType);
    message.tooltipType !== undefined &&
      (obj.tooltipType = message.tooltipType);
    return obj;
  },
};

function createBaseCollection(): Collection {
  return {
    id: '',
    dataroomId: '',
    parentId: '',
    name: '',
    type: '',
    documentClass: '',
    author: undefined,
    title: '',
    path: undefined,
    version: '',
    description: '',
    creationDate: undefined,
    modificationDate: undefined,
    icon: '',
    numChildren: undefined,
    effectivePermission: undefined,
    subDocs: undefined,
    permissions: [],
    restorePath: undefined,
    sequence: undefined,
    index: undefined,
    noIndex: undefined,
    parents: undefined,
    parentTitle: undefined,
    labels: undefined,
    approvalGroup: undefined,
    notification: undefined,
    itemType: 0,
    hasDifferentParentRights: undefined,
    numberOfComments: undefined,
    parent: undefined,
    hasPdfPassword: undefined,
    hasChildren: undefined,
  };
}

export const Collection = {
  fromJSON(object: any): Collection {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      parentId: isSet(object.parentId) ? String(object.parentId) : '',
      name: isSet(object.name) ? String(object.name) : '',
      type: isSet(object.type) ? String(object.type) : '',
      documentClass: isSet(object.documentClass)
        ? String(object.documentClass)
        : '',
      author: isSet(object.author) ? String(object.author) : undefined,
      title: isSet(object.title) ? String(object.title) : '',
      path: isSet(object.path) ? String(object.path) : undefined,
      version: isSet(object.version) ? String(object.version) : '',
      description: isSet(object.description) ? String(object.description) : '',
      creationDate: isSet(object.creationDate)
        ? fromJsonTimestamp(object.creationDate)
        : undefined,
      modificationDate: isSet(object.modificationDate)
        ? fromJsonTimestamp(object.modificationDate)
        : undefined,
      icon: isSet(object.icon) ? String(object.icon) : '',
      numChildren: isSet(object.numChildren)
        ? Number(object.numChildren)
        : undefined,
      effectivePermission: isSet(object.effectivePermission)
        ? EffectivePermission.fromJSON(object.effectivePermission)
        : undefined,
      subDocs: isSet(object.subDocs) ? Number(object.subDocs) : undefined,
      permissions: Array.isArray(object?.permissions)
        ? object.permissions.map((e: any) => ItemPermissionsEntry.fromJSON(e))
        : [],
      restorePath: isSet(object.restorePath)
        ? String(object.restorePath)
        : undefined,
      sequence: isSet(object.sequence) ? String(object.sequence) : undefined,
      index: isSet(object.index) ? String(object.index) : undefined,
      noIndex: isSet(object.noIndex) ? Boolean(object.noIndex) : undefined,
      parents: isSet(object.parents)
        ? Parents.fromJSON(object.parents)
        : undefined,
      parentTitle: isSet(object.parentTitle)
        ? String(object.parentTitle)
        : undefined,
      labels: isSet(object.labels) ? Labels.fromJSON(object.labels) : undefined,
      approvalGroup: isSet(object.approvalGroup)
        ? String(object.approvalGroup)
        : undefined,
      notification: isSet(object.notification)
        ? folderNotificationFromJSON(object.notification)
        : undefined,
      itemType: isSet(object.itemType) ? itemTypeFromJSON(object.itemType) : 0,
      hasDifferentParentRights: isSet(object.hasDifferentParentRights)
        ? Boolean(object.hasDifferentParentRights)
        : undefined,
      numberOfComments: isSet(object.numberOfComments)
        ? Number(object.numberOfComments)
        : undefined,
      parent: isSet(object.parent) ? Parent.fromJSON(object.parent) : undefined,
      hasPdfPassword: isSet(object.hasPdfPassword)
        ? Boolean(object.hasPdfPassword)
        : undefined,
      hasChildren: isSet(object.hasChildren)
        ? Boolean(object.hasChildren)
        : undefined,
    };
  },

  toJSON(message: Collection): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.parentId !== undefined && (obj.parentId = message.parentId);
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined && (obj.type = message.type);
    message.documentClass !== undefined &&
      (obj.documentClass = message.documentClass);
    message.author !== undefined && (obj.author = message.author);
    message.title !== undefined && (obj.title = message.title);
    message.path !== undefined && (obj.path = message.path);
    message.version !== undefined && (obj.version = message.version);
    message.description !== undefined &&
      (obj.description = message.description);
    message.creationDate !== undefined &&
      (obj.creationDate = message.creationDate.toISOString());
    message.modificationDate !== undefined &&
      (obj.modificationDate = message.modificationDate.toISOString());
    message.icon !== undefined && (obj.icon = message.icon);
    message.numChildren !== undefined &&
      (obj.numChildren = Math.round(message.numChildren));
    message.effectivePermission !== undefined &&
      (obj.effectivePermission = message.effectivePermission
        ? EffectivePermission.toJSON(message.effectivePermission)
        : undefined);
    message.subDocs !== undefined &&
      (obj.subDocs = Math.round(message.subDocs));
    if (message.permissions) {
      obj.permissions = message.permissions.map(e =>
        e ? ItemPermissionsEntry.toJSON(e) : undefined,
      );
    } else {
      obj.permissions = [];
    }
    message.restorePath !== undefined &&
      (obj.restorePath = message.restorePath);
    message.sequence !== undefined && (obj.sequence = message.sequence);
    message.index !== undefined && (obj.index = message.index);
    message.noIndex !== undefined && (obj.noIndex = message.noIndex);
    message.parents !== undefined &&
      (obj.parents = message.parents
        ? Parents.toJSON(message.parents)
        : undefined);
    message.parentTitle !== undefined &&
      (obj.parentTitle = message.parentTitle);
    message.labels !== undefined &&
      (obj.labels = message.labels ? Labels.toJSON(message.labels) : undefined);
    message.approvalGroup !== undefined &&
      (obj.approvalGroup = message.approvalGroup);
    message.notification !== undefined &&
      (obj.notification =
        message.notification !== undefined
          ? folderNotificationToJSON(message.notification)
          : undefined);
    message.itemType !== undefined &&
      (obj.itemType = itemTypeToJSON(message.itemType));
    message.hasDifferentParentRights !== undefined &&
      (obj.hasDifferentParentRights = message.hasDifferentParentRights);
    message.numberOfComments !== undefined &&
      (obj.numberOfComments = Math.round(message.numberOfComments));
    message.parent !== undefined &&
      (obj.parent = message.parent ? Parent.toJSON(message.parent) : undefined);
    message.hasPdfPassword !== undefined &&
      (obj.hasPdfPassword = message.hasPdfPassword);
    message.hasChildren !== undefined &&
      (obj.hasChildren = message.hasChildren);
    return obj;
  },
};

function createBaseCollectionProperties(): CollectionProperties {
  return {
    id: '',
    name: undefined,
    author: undefined,
    title: undefined,
    sequence: undefined,
    index: undefined,
    noIndex: undefined,
    path: undefined,
    creationDate: undefined,
    modificationDate: undefined,
    tags: undefined,
    url: undefined,
    description: undefined,
    approvalGroup: undefined,
    recursiveApprovalType: undefined,
    recursiveApproval: undefined,
    pdfPassword: undefined,
    recursivePdfPasswordEnabled: undefined,
    setExpirationDate: undefined,
    recursiveSetExpirationDate: undefined,
    collectionSize: undefined,
    subDocs: undefined,
    shortUrl: undefined,
    userNotifications: undefined,
    notification: undefined,
    recursiveNotifications: undefined,
    deletedOn: undefined,
    hasDifferentParentRights: undefined,
    itemType: 0,
    itemSubtype: undefined,
  };
}

export const CollectionProperties = {
  fromJSON(object: any): CollectionProperties {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name)
        ? SettingsEntryAsString.fromJSON(object.name)
        : undefined,
      author: isSet(object.author)
        ? SettingsEntryAsString.fromJSON(object.author)
        : undefined,
      title: isSet(object.title)
        ? SettingsEntryAsString.fromJSON(object.title)
        : undefined,
      sequence: isSet(object.sequence)
        ? SettingsEntryAsString.fromJSON(object.sequence)
        : undefined,
      index: isSet(object.index)
        ? SettingsEntryAsString.fromJSON(object.index)
        : undefined,
      noIndex: isSet(object.noIndex)
        ? SettingsEntryAsBool.fromJSON(object.noIndex)
        : undefined,
      path: isSet(object.path)
        ? SettingsEntryAsString.fromJSON(object.path)
        : undefined,
      creationDate: isSet(object.creationDate)
        ? SettingsEntryAsDate.fromJSON(object.creationDate)
        : undefined,
      modificationDate: isSet(object.modificationDate)
        ? SettingsEntryAsDate.fromJSON(object.modificationDate)
        : undefined,
      tags: isSet(object.tags)
        ? SettingsEntryAsString.fromJSON(object.tags)
        : undefined,
      url: isSet(object.url)
        ? SettingsEntryAsString.fromJSON(object.url)
        : undefined,
      description: isSet(object.description)
        ? SettingsEntryAsString.fromJSON(object.description)
        : undefined,
      approvalGroup: isSet(object.approvalGroup)
        ? SettingsEntryAsApprovalGroup.fromJSON(object.approvalGroup)
        : undefined,
      recursiveApprovalType: isSet(object.recursiveApprovalType)
        ? SettingsEntryAsRecursiveApproval.fromJSON(
            object.recursiveApprovalType,
          )
        : undefined,
      recursiveApproval: isSet(object.recursiveApproval)
        ? SettingsEntryAsBool.fromJSON(object.recursiveApproval)
        : undefined,
      pdfPassword: isSet(object.pdfPassword)
        ? SettingsEntryAsString.fromJSON(object.pdfPassword)
        : undefined,
      recursivePdfPasswordEnabled: isSet(object.recursivePdfPasswordEnabled)
        ? SettingsEntryAsBool.fromJSON(object.recursivePdfPasswordEnabled)
        : undefined,
      setExpirationDate: isSet(object.setExpirationDate)
        ? SettingsEntryAsBool.fromJSON(object.setExpirationDate)
        : undefined,
      recursiveSetExpirationDate: isSet(object.recursiveSetExpirationDate)
        ? SettingsEntryAsBool.fromJSON(object.recursiveSetExpirationDate)
        : undefined,
      collectionSize: isSet(object.collectionSize)
        ? SettingsEntryAsNumber.fromJSON(object.collectionSize)
        : undefined,
      subDocs: isSet(object.subDocs)
        ? SettingsEntryAsNumber.fromJSON(object.subDocs)
        : undefined,
      shortUrl: isSet(object.shortUrl)
        ? SettingsEntryAsString.fromJSON(object.shortUrl)
        : undefined,
      userNotifications: isSet(object.userNotifications)
        ? SettingsEntryAsNotificationsAdmin.fromJSON(object.userNotifications)
        : undefined,
      notification: isSet(object.notification)
        ? SettingsEntryAsNotification.fromJSON(object.notification)
        : undefined,
      recursiveNotifications: isSet(object.recursiveNotifications)
        ? SettingsEntryAsBool.fromJSON(object.recursiveNotifications)
        : undefined,
      deletedOn: isSet(object.deletedOn)
        ? SettingsEntryAsDate.fromJSON(object.deletedOn)
        : undefined,
      hasDifferentParentRights: isSet(object.hasDifferentParentRights)
        ? SettingsEntryAsBool.fromJSON(object.hasDifferentParentRights)
        : undefined,
      itemType: isSet(object.itemType) ? itemTypeFromJSON(object.itemType) : 0,
      itemSubtype: isSet(object.itemSubtype)
        ? itemSubTypeFromJSON(object.itemSubtype)
        : undefined,
    };
  },

  toJSON(message: CollectionProperties): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined &&
      (obj.name = message.name
        ? SettingsEntryAsString.toJSON(message.name)
        : undefined);
    message.author !== undefined &&
      (obj.author = message.author
        ? SettingsEntryAsString.toJSON(message.author)
        : undefined);
    message.title !== undefined &&
      (obj.title = message.title
        ? SettingsEntryAsString.toJSON(message.title)
        : undefined);
    message.sequence !== undefined &&
      (obj.sequence = message.sequence
        ? SettingsEntryAsString.toJSON(message.sequence)
        : undefined);
    message.index !== undefined &&
      (obj.index = message.index
        ? SettingsEntryAsString.toJSON(message.index)
        : undefined);
    message.noIndex !== undefined &&
      (obj.noIndex = message.noIndex
        ? SettingsEntryAsBool.toJSON(message.noIndex)
        : undefined);
    message.path !== undefined &&
      (obj.path = message.path
        ? SettingsEntryAsString.toJSON(message.path)
        : undefined);
    message.creationDate !== undefined &&
      (obj.creationDate = message.creationDate
        ? SettingsEntryAsDate.toJSON(message.creationDate)
        : undefined);
    message.modificationDate !== undefined &&
      (obj.modificationDate = message.modificationDate
        ? SettingsEntryAsDate.toJSON(message.modificationDate)
        : undefined);
    message.tags !== undefined &&
      (obj.tags = message.tags
        ? SettingsEntryAsString.toJSON(message.tags)
        : undefined);
    message.url !== undefined &&
      (obj.url = message.url
        ? SettingsEntryAsString.toJSON(message.url)
        : undefined);
    message.description !== undefined &&
      (obj.description = message.description
        ? SettingsEntryAsString.toJSON(message.description)
        : undefined);
    message.approvalGroup !== undefined &&
      (obj.approvalGroup = message.approvalGroup
        ? SettingsEntryAsApprovalGroup.toJSON(message.approvalGroup)
        : undefined);
    message.recursiveApprovalType !== undefined &&
      (obj.recursiveApprovalType = message.recursiveApprovalType
        ? SettingsEntryAsRecursiveApproval.toJSON(message.recursiveApprovalType)
        : undefined);
    message.recursiveApproval !== undefined &&
      (obj.recursiveApproval = message.recursiveApproval
        ? SettingsEntryAsBool.toJSON(message.recursiveApproval)
        : undefined);
    message.pdfPassword !== undefined &&
      (obj.pdfPassword = message.pdfPassword
        ? SettingsEntryAsString.toJSON(message.pdfPassword)
        : undefined);
    message.recursivePdfPasswordEnabled !== undefined &&
      (obj.recursivePdfPasswordEnabled = message.recursivePdfPasswordEnabled
        ? SettingsEntryAsBool.toJSON(message.recursivePdfPasswordEnabled)
        : undefined);
    message.setExpirationDate !== undefined &&
      (obj.setExpirationDate = message.setExpirationDate
        ? SettingsEntryAsBool.toJSON(message.setExpirationDate)
        : undefined);
    message.recursiveSetExpirationDate !== undefined &&
      (obj.recursiveSetExpirationDate = message.recursiveSetExpirationDate
        ? SettingsEntryAsBool.toJSON(message.recursiveSetExpirationDate)
        : undefined);
    message.collectionSize !== undefined &&
      (obj.collectionSize = message.collectionSize
        ? SettingsEntryAsNumber.toJSON(message.collectionSize)
        : undefined);
    message.subDocs !== undefined &&
      (obj.subDocs = message.subDocs
        ? SettingsEntryAsNumber.toJSON(message.subDocs)
        : undefined);
    message.shortUrl !== undefined &&
      (obj.shortUrl = message.shortUrl
        ? SettingsEntryAsString.toJSON(message.shortUrl)
        : undefined);
    message.userNotifications !== undefined &&
      (obj.userNotifications = message.userNotifications
        ? SettingsEntryAsNotificationsAdmin.toJSON(message.userNotifications)
        : undefined);
    message.notification !== undefined &&
      (obj.notification = message.notification
        ? SettingsEntryAsNotification.toJSON(message.notification)
        : undefined);
    message.recursiveNotifications !== undefined &&
      (obj.recursiveNotifications = message.recursiveNotifications
        ? SettingsEntryAsBool.toJSON(message.recursiveNotifications)
        : undefined);
    message.deletedOn !== undefined &&
      (obj.deletedOn = message.deletedOn
        ? SettingsEntryAsDate.toJSON(message.deletedOn)
        : undefined);
    message.hasDifferentParentRights !== undefined &&
      (obj.hasDifferentParentRights = message.hasDifferentParentRights
        ? SettingsEntryAsBool.toJSON(message.hasDifferentParentRights)
        : undefined);
    message.itemType !== undefined &&
      (obj.itemType = itemTypeToJSON(message.itemType));
    message.itemSubtype !== undefined &&
      (obj.itemSubtype =
        message.itemSubtype !== undefined
          ? itemSubTypeToJSON(message.itemSubtype)
          : undefined);
    return obj;
  },
};

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
