/* eslint-disable @typescript-eslint/no-explicit-any */
import { ILogObj, Logger } from 'tslog';

export type LogFormat = {
  apiCallUrl?: any;
  baseUrl?: string;
  call?: string;
  callback?: any;
  challenge?: string;
  challengeId?: string;
  children?: any;
  clientIp?: string;
  commentId?: string;
  dataroom?: any;
  dataroomId?: any;
  description?: string;
  details?: any;
  error?: Error;
  filename?: any;
  function?: any;
  folder?: any;
  itemId?: string;
  itemIds?: string[];
  iterator?: any;
  jobId?: string;
  msg?: string;
  newUser?: any;
  options?: any;
  params?: any;
  passkeyData?: any;
  payload?: any;
  pkcro?: any;
  pkrr?: any;
  pkrrJson?: any;
  preserve?: boolean;
  registrationResponse?: any;
  requestId: string;
  response?: any;
  result?: any;
  retval?: any;
  rpID?: any;
  selection?: any;
  token?: any;
  tokenData?: any;
  user?: any;
  // properties that are removed (i.e. not sent to server)
  path?: any;
  socket?: string;
  req?: any;
  res?: any;
  parser?: any;
  request?: any;
  _meta?: any;
};

export class MainLogger<LogObj> extends Logger<LogObj> {
  public override error(args: ILogObj) {
    return super.error(args);
  }

  public override getSubLogger(options?: any): MainLogger<LogObj> {
    const subLogger = super.getSubLogger(options);
    Object.setPrototypeOf(subLogger, MainLogger.prototype);
    return subLogger as MainLogger<LogObj>;
  }

  public infoType(args: LogFormat) {
    return super.info(args);
  }

  public debugType(args: LogFormat) {
    return super.debug(args);
  }

  public errorType(args: LogFormat) {
    return super.error(args);
  }

  public traceType(args: LogFormat) {
    return super.trace(args);
  }

  public warnType(args: LogFormat) {
    return super.warn(args);
  }

  public sillyType(args: LogFormat) {
    return super.silly(args);
  }

  public fatalType(args: LogFormat) {
    return super.fatal(args);
  }
}
