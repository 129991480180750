/* eslint-disable */
export const protobufPackage = 'de.netfiles.pb';

/** DownloadAccessRightType : is download allowed and if so, how? */
export enum DownloadRight {
  DOWNLOAD_RIGHT_UNSPECIFIED = 0,
  DOWNLOAD_RIGHT_NONE = 1,
  DOWNLOAD_RIGHT_ORIGINAL = 2,
  DOWNLOAD_RIGHT_PDF_ONLY = 3,
  DOWNLOAD_RIGHT_PDF_WITH_WATERMARK = 4,
  UNRECOGNIZED = -1,
}

export function downloadRightFromJSON(object: any): DownloadRight {
  switch (object) {
    case 0:
    case 'DOWNLOAD_RIGHT_UNSPECIFIED':
      return DownloadRight.DOWNLOAD_RIGHT_UNSPECIFIED;
    case 1:
    case 'DOWNLOAD_RIGHT_NONE':
      return DownloadRight.DOWNLOAD_RIGHT_NONE;
    case 2:
    case 'DOWNLOAD_RIGHT_ORIGINAL':
      return DownloadRight.DOWNLOAD_RIGHT_ORIGINAL;
    case 3:
    case 'DOWNLOAD_RIGHT_PDF_ONLY':
      return DownloadRight.DOWNLOAD_RIGHT_PDF_ONLY;
    case 4:
    case 'DOWNLOAD_RIGHT_PDF_WITH_WATERMARK':
      return DownloadRight.DOWNLOAD_RIGHT_PDF_WITH_WATERMARK;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DownloadRight.UNRECOGNIZED;
  }
}

export function downloadRightToJSON(object: DownloadRight): string {
  switch (object) {
    case DownloadRight.DOWNLOAD_RIGHT_UNSPECIFIED:
      return 'DOWNLOAD_RIGHT_UNSPECIFIED';
    case DownloadRight.DOWNLOAD_RIGHT_NONE:
      return 'DOWNLOAD_RIGHT_NONE';
    case DownloadRight.DOWNLOAD_RIGHT_ORIGINAL:
      return 'DOWNLOAD_RIGHT_ORIGINAL';
    case DownloadRight.DOWNLOAD_RIGHT_PDF_ONLY:
      return 'DOWNLOAD_RIGHT_PDF_ONLY';
    case DownloadRight.DOWNLOAD_RIGHT_PDF_WITH_WATERMARK:
      return 'DOWNLOAD_RIGHT_PDF_WITH_WATERMARK';
    default:
      return 'UNKNOWN';
  }
}

/** Types of possible permission entries */
export enum PermissionEntryType {
  PERMISSION_ENTRY_TYPE_UNSPECIFIED = 0,
  PERMISSION_ENTRY_TYPE_USER = 1,
  PERMISSION_ENTRY_TYPE_GROUP = 2,
  UNRECOGNIZED = -1,
}

export function permissionEntryTypeFromJSON(object: any): PermissionEntryType {
  switch (object) {
    case 0:
    case 'PERMISSION_ENTRY_TYPE_UNSPECIFIED':
      return PermissionEntryType.PERMISSION_ENTRY_TYPE_UNSPECIFIED;
    case 1:
    case 'PERMISSION_ENTRY_TYPE_USER':
      return PermissionEntryType.PERMISSION_ENTRY_TYPE_USER;
    case 2:
    case 'PERMISSION_ENTRY_TYPE_GROUP':
      return PermissionEntryType.PERMISSION_ENTRY_TYPE_GROUP;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PermissionEntryType.UNRECOGNIZED;
  }
}

export function permissionEntryTypeToJSON(object: PermissionEntryType): string {
  switch (object) {
    case PermissionEntryType.PERMISSION_ENTRY_TYPE_UNSPECIFIED:
      return 'PERMISSION_ENTRY_TYPE_UNSPECIFIED';
    case PermissionEntryType.PERMISSION_ENTRY_TYPE_USER:
      return 'PERMISSION_ENTRY_TYPE_USER';
    case PermissionEntryType.PERMISSION_ENTRY_TYPE_GROUP:
      return 'PERMISSION_ENTRY_TYPE_GROUP';
    default:
      return 'UNKNOWN';
  }
}

export enum PermissionType {
  PERMISSION_TYPE_UNSPECIFIED = 0,
  PERMISSION_TYPE_NONE = 1,
  PERMISSION_TYPE_READONLY = 2,
  PERMISSION_TYPE_DOWNLOAD_PDF = 3,
  PERMISSION_TYPE_DOWNLOAD_ORIGINAL = 4,
  PERMISSION_TYPE_WRITE = 5,
  UNRECOGNIZED = -1,
}

export function permissionTypeFromJSON(object: any): PermissionType {
  switch (object) {
    case 0:
    case 'PERMISSION_TYPE_UNSPECIFIED':
      return PermissionType.PERMISSION_TYPE_UNSPECIFIED;
    case 1:
    case 'PERMISSION_TYPE_NONE':
      return PermissionType.PERMISSION_TYPE_NONE;
    case 2:
    case 'PERMISSION_TYPE_READONLY':
      return PermissionType.PERMISSION_TYPE_READONLY;
    case 3:
    case 'PERMISSION_TYPE_DOWNLOAD_PDF':
      return PermissionType.PERMISSION_TYPE_DOWNLOAD_PDF;
    case 4:
    case 'PERMISSION_TYPE_DOWNLOAD_ORIGINAL':
      return PermissionType.PERMISSION_TYPE_DOWNLOAD_ORIGINAL;
    case 5:
    case 'PERMISSION_TYPE_WRITE':
      return PermissionType.PERMISSION_TYPE_WRITE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PermissionType.UNRECOGNIZED;
  }
}

export function permissionTypeToJSON(object: PermissionType): string {
  switch (object) {
    case PermissionType.PERMISSION_TYPE_UNSPECIFIED:
      return 'PERMISSION_TYPE_UNSPECIFIED';
    case PermissionType.PERMISSION_TYPE_NONE:
      return 'PERMISSION_TYPE_NONE';
    case PermissionType.PERMISSION_TYPE_READONLY:
      return 'PERMISSION_TYPE_READONLY';
    case PermissionType.PERMISSION_TYPE_DOWNLOAD_PDF:
      return 'PERMISSION_TYPE_DOWNLOAD_PDF';
    case PermissionType.PERMISSION_TYPE_DOWNLOAD_ORIGINAL:
      return 'PERMISSION_TYPE_DOWNLOAD_ORIGINAL';
    case PermissionType.PERMISSION_TYPE_WRITE:
      return 'PERMISSION_TYPE_WRITE';
    default:
      return 'UNKNOWN';
  }
}

/** Access Rights */
export interface Permission {
  read: boolean;
  write: boolean;
  delete: boolean;
  admin: boolean;
  downloadRight: DownloadRight;
  readChildren: boolean;
  writeChildren: boolean;
  deleteChildren: boolean;
}

/** One entry (group or user) of permissions */
export interface ItemPermissionsEntry {
  id: string;
  title: string;
  permission: PermissionType;
  type: PermissionEntryType;
  isEqual?: boolean | undefined;
  foundInItems?: number | undefined;
}

/** Additional Settings for permissions set by client */
export interface PermissionSettings {
  changeAllGroups?: boolean | undefined;
  recursive?: boolean | undefined;
}

/** The item-based effective access right for the current user */
export interface EffectivePermission {
  read: boolean;
  write: boolean;
  delete: boolean;
  admin: boolean;
  downloadRight: DownloadRight;
  readChildren: boolean;
  writeChildren: boolean;
  deleteChildren: boolean;
  readMetadata?: boolean | undefined;
}

function createBasePermission(): Permission {
  return {
    read: false,
    write: false,
    delete: false,
    admin: false,
    downloadRight: 0,
    readChildren: false,
    writeChildren: false,
    deleteChildren: false,
  };
}

export const Permission = {
  fromJSON(object: any): Permission {
    return {
      read: isSet(object.read) ? Boolean(object.read) : false,
      write: isSet(object.write) ? Boolean(object.write) : false,
      delete: isSet(object.delete) ? Boolean(object.delete) : false,
      admin: isSet(object.admin) ? Boolean(object.admin) : false,
      downloadRight: isSet(object.downloadRight)
        ? downloadRightFromJSON(object.downloadRight)
        : 0,
      readChildren: isSet(object.readChildren)
        ? Boolean(object.readChildren)
        : false,
      writeChildren: isSet(object.writeChildren)
        ? Boolean(object.writeChildren)
        : false,
      deleteChildren: isSet(object.deleteChildren)
        ? Boolean(object.deleteChildren)
        : false,
    };
  },

  toJSON(message: Permission): unknown {
    const obj: any = {};
    message.read !== undefined && (obj.read = message.read);
    message.write !== undefined && (obj.write = message.write);
    message.delete !== undefined && (obj.delete = message.delete);
    message.admin !== undefined && (obj.admin = message.admin);
    message.downloadRight !== undefined &&
      (obj.downloadRight = downloadRightToJSON(message.downloadRight));
    message.readChildren !== undefined &&
      (obj.readChildren = message.readChildren);
    message.writeChildren !== undefined &&
      (obj.writeChildren = message.writeChildren);
    message.deleteChildren !== undefined &&
      (obj.deleteChildren = message.deleteChildren);
    return obj;
  },
};

function createBaseItemPermissionsEntry(): ItemPermissionsEntry {
  return {
    id: '',
    title: '',
    permission: 0,
    type: 0,
    isEqual: undefined,
    foundInItems: undefined,
  };
}

export const ItemPermissionsEntry = {
  fromJSON(object: any): ItemPermissionsEntry {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      title: isSet(object.title) ? String(object.title) : '',
      permission: isSet(object.permission)
        ? permissionTypeFromJSON(object.permission)
        : 0,
      type: isSet(object.type) ? permissionEntryTypeFromJSON(object.type) : 0,
      isEqual: isSet(object.isEqual) ? Boolean(object.isEqual) : undefined,
      foundInItems: isSet(object.foundInItems)
        ? Number(object.foundInItems)
        : undefined,
    };
  },

  toJSON(message: ItemPermissionsEntry): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.title !== undefined && (obj.title = message.title);
    message.permission !== undefined &&
      (obj.permission = permissionTypeToJSON(message.permission));
    message.type !== undefined &&
      (obj.type = permissionEntryTypeToJSON(message.type));
    message.isEqual !== undefined && (obj.isEqual = message.isEqual);
    message.foundInItems !== undefined &&
      (obj.foundInItems = Math.round(message.foundInItems));
    return obj;
  },
};

function createBasePermissionSettings(): PermissionSettings {
  return { changeAllGroups: undefined, recursive: undefined };
}

export const PermissionSettings = {
  fromJSON(object: any): PermissionSettings {
    return {
      changeAllGroups: isSet(object.changeAllGroups)
        ? Boolean(object.changeAllGroups)
        : undefined,
      recursive: isSet(object.recursive)
        ? Boolean(object.recursive)
        : undefined,
    };
  },

  toJSON(message: PermissionSettings): unknown {
    const obj: any = {};
    message.changeAllGroups !== undefined &&
      (obj.changeAllGroups = message.changeAllGroups);
    message.recursive !== undefined && (obj.recursive = message.recursive);
    return obj;
  },
};

function createBaseEffectivePermission(): EffectivePermission {
  return {
    read: false,
    write: false,
    delete: false,
    admin: false,
    downloadRight: 0,
    readChildren: false,
    writeChildren: false,
    deleteChildren: false,
    readMetadata: undefined,
  };
}

export const EffectivePermission = {
  fromJSON(object: any): EffectivePermission {
    return {
      read: isSet(object.read) ? Boolean(object.read) : false,
      write: isSet(object.write) ? Boolean(object.write) : false,
      delete: isSet(object.delete) ? Boolean(object.delete) : false,
      admin: isSet(object.admin) ? Boolean(object.admin) : false,
      downloadRight: isSet(object.downloadRight)
        ? downloadRightFromJSON(object.downloadRight)
        : 0,
      readChildren: isSet(object.readChildren)
        ? Boolean(object.readChildren)
        : false,
      writeChildren: isSet(object.writeChildren)
        ? Boolean(object.writeChildren)
        : false,
      deleteChildren: isSet(object.deleteChildren)
        ? Boolean(object.deleteChildren)
        : false,
      readMetadata: isSet(object.readMetadata)
        ? Boolean(object.readMetadata)
        : undefined,
    };
  },

  toJSON(message: EffectivePermission): unknown {
    const obj: any = {};
    message.read !== undefined && (obj.read = message.read);
    message.write !== undefined && (obj.write = message.write);
    message.delete !== undefined && (obj.delete = message.delete);
    message.admin !== undefined && (obj.admin = message.admin);
    message.downloadRight !== undefined &&
      (obj.downloadRight = downloadRightToJSON(message.downloadRight));
    message.readChildren !== undefined &&
      (obj.readChildren = message.readChildren);
    message.writeChildren !== undefined &&
      (obj.writeChildren = message.writeChildren);
    message.deleteChildren !== undefined &&
      (obj.deleteChildren = message.deleteChildren);
    message.readMetadata !== undefined &&
      (obj.readMetadata = message.readMetadata);
    return obj;
  },
};

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
