// Styles
import '@/assets/fontawesome/css/all.min.css';
import 'vuetify/styles';
import { aliases, fa } from 'vuetify/iconsets/fa';

// Vuetify
import { createVuetify } from 'vuetify';

export default createVuetify({
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
    },
  },
  display: {
    mobileBreakpoint: 'md',
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: {
          tint: '#A60B16',
          background: '#FFFFFF',
          'background-variant': '#F5F6F7',
          'background-variant-lighten-1': '#F5F6F7',
          'background-variant-lighten-2': '#F5F6F7',
          'background-elevated': '#F5F6F7',
          'background-selected': '#303540',
          'background-selected-lighten-1': '#303540',
          'background-selected-lighten-2': '#303540',
          border: '#303540',
          surface: '#FFFFFF',
          primary: '#A60B16',
          'text-primary': '#242933',
          'text-secondary': '#9DA0A6',
          'primary-darken-1': '#74080F',
          secondary: '#6B6E73',
          'secondary-darken-1': '#393C40',
          error: '#A60B16',
          info: '#242933',
          success: '#26BF59',
          warning: '#F7950C',
          accent: '#E6E8EB',
          'on-primary': '#FFFFFF',
          'on-secondary': '#FFFFFF',
          'on-background': '#303540',
          'on-surface': '#303540',
          'toggle-off-background': '#9DA0A6',
          'toggle-off-toggle': '#FFFFFF',
          'toggle-on-background': '#303540',
          'toggle-on-toggle': '#FFFFFF',
          'toggle-on-inside': '#303540',
        },
      },
      dark: {
        dark: true,
        colors: {
          tint: '#A60B16',
          background: '#3D424D',
          'background-variant': '#303540',
          'background-variant-lighten-1': '#303540',
          'background-variant-lighten-2': '#303540',
          'background-elevated': '#303540',
          'background-selected': '#FFFFFF',
          border: '#303540',
          surface: '#3D424D',
          primary: '#A60B16',
          'text-primary': '#FFFFFF',
          'text-secondary': '#9DA0A6',
          'primary-darken-1': '#E61728',
          secondary: '#6B6E73',
          'secondary-darken-1': '#393C40',
          error: '#F33F4B', // orig: hsl(356, 88%, 35%) hsl(356, 88%, 60%)
          info: '#242933',
          success: '#4CD97B',
          warning: '#FFAA33',
          accent: '#3A3C40',
          'on-primary': '#FFFFFF',
          'on-secondary': '#FFFFFF',
          'on-background': '#FFFFFF',
          'on-surface': '#ffffff',
          'toggle-off-background': '#9DA0A6',
          'toggle-off-toggle': '#3D424D',
          'toggle-on-background': '#FFFFFF',
          'toggle-on-toggle': '#3D424D',
          'toggle-on-inside': '#FFFFFF',
        },
      },
    },
  },
  defaults: {
    VTextField: {
      variant: 'solo-filled',
      flat: true,
      color: 'background-selected',
      'bg-color': 'surface',
    },
    VTextarea: {
      variant: 'solo-filled',
      flat: true,
      color: 'background-selected',
    },
    VSelect: {
      variant: 'solo-filled',
      flat: true,
      color: 'background-selected',
      menuIcon: 'fal fa-angle-down',
    },
    VAutocomplete: {
      variant: 'solo-filled',
      flat: true,
      color: 'background-selected',
    },
    VCombobox: {
      variant: 'solo-filled',
      flat: true,
      color: 'background-selected',
    },
    VFileInput: { variant: 'solo', flat: true, color: 'background-selected' },
    VListSubheader: { color: 'text-primary' },
    VListItem: { color: 'text-primary' },
    VListItemTitle: { color: 'text-primary' },
    VListItemSubTitle: { color: 'text-primary' },
    VLabel: { class: 'text-text-primary' },
    VBtn: {
      class: 'text-text-primary',
      rounded: 'lg',
    },
    VCardActions: {
      class: 'text-text-primary',
    },
    VToolbarTitle: {
      class: 'text-text-primary',
    },
    VSwitch: {
      trueIcon: 'fa fa-check',
    },
  },
});
