export const gitInfo: Record<string, string> = {
  'dirty': 'true',
  'hash': 'gf3ff3d2',
  'distance': '225',
  'tag': 'v1.0.0stg',
  'semver': 'null',
  'suffix': '225-gf3ff3d2-dirty',
  'semverString': '',
};

export const appVersion: string = '1.0.1+8';
