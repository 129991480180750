<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import { TestIdDialog } from '@/shared/types/CypressIds';
import { VBtn } from 'vuetify/lib/components';
import { ConfirmDialogReturn } from '@/Types';
import { useDisplay } from 'vuetify/lib/framework.mjs';

/* Confirm dialog with 1 to 3 buttons
   title and icon are optional.
*/
type ConfirmDialogType = {
  title?: string;
  message: string;
  icon?: string;
  iconSize?: string | number; // icons size in px or x-small, small, default, large, and x-large. Default is 50(px)
  cancelButton: string; // bottom right button; the least dangerous, i.e. Cancel
  proceedButton?: string; // second button to right; normally submit button
  otherButton?: string; // extra button on the left
  focusProceed?: boolean; // set focus on proceed button instead of cancel
  width?: number;
};

const dialog = ref(false);
const title = ref<string | null>(null);
const message = ref('');
const icon = ref<string | null>(null);
const iconSize = ref<string | number>(50);
const cancelButtonText = ref('OK');
const proceedButtonText = ref<string | null>(null);
const otherButtonText = ref<string | null>(null);
const focusProceed = ref(false);
const width = ref(500);
const { mobile } = useDisplay();

type CallbackFunction = (ret: ConfirmDialogReturn) => void;

let resolve: CallbackFunction | null = null;

const cancelButton = ref<typeof VBtn | null>(null);

const proceedButton = ref<typeof VBtn | null>(null);

const open = async (
  params: ConfirmDialogType,
): Promise<ConfirmDialogReturn> => {
  // required
  message.value = params.message;
  cancelButtonText.value = params.cancelButton;
  proceedButtonText.value = '';
  otherButtonText.value = '';
  // optional
  if (params.title) {
    title.value = params.title;
  }
  if (params.icon) {
    icon.value = params.icon;
  }
  if (params.iconSize) {
    iconSize.value = params.iconSize;
  }
  if (params.proceedButton) {
    proceedButtonText.value = params.proceedButton;
  }
  if (params.otherButton) {
    otherButtonText.value = params.otherButton;
  }
  if (params.focusProceed) {
    focusProceed.value = params.focusProceed;
  }
  if (params.width) {
    width.value = params.width;
  }
  dialog.value = true;
  return new Promise(res => {
    resolve = res as CallbackFunction;
  });
};

const closeAndResolve = (returnValue: ConfirmDialogReturn) => {
  dialog.value = false;
  if (resolve) {
    resolve(returnValue);
  }
};

watchEffect(() => {
  // set focus on default button
  if (dialog.value === true) {
    setTimeout(() => {
      if (focusProceed.value) {
        proceedButton.value?.$el.focus();
        return;
      }
      cancelButton.value?.$el.focus();
    }, 200);
  }
});

defineExpose({
  open,
});
</script>

<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    :width="mobile ? '100%' : width"
    :fullscreen="mobile"
    height="auto"
    @keydown.esc="
      closeAndResolve(ConfirmDialogReturn.CONFIRM_DIALOG_RETURN_Cancel)
    "
  >
    <v-card>
      <v-toolbar v-if="title" dark dense flat>
        <v-toolbar-title class="text-body font-weight-bold grey--text">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <div :style="mobile ? 'width: 90%' : ''">
        <v-card-text style="max-height: 450px; overflow-y: auto" v-if="icon">
          <v-row>
            <v-col cols="2" alignSelf="center" class="hidden-sm-and-down"
              ><v-icon :size="iconSize" :icon="icon"></v-icon
            ></v-col>
            <v-col alignSelf="center"
              ><div v-html="message" />
              <slot
            /></v-col>
          </v-row>
        </v-card-text>
        <v-card-text style="max-height: 450px; overflow-y: auto" v-else
          ><div v-html="message" />
          <slot
        /></v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="flex-wrap">
          <v-btn
            v-if="otherButtonText"
            variant="outlined"
            @click="
              closeAndResolve(ConfirmDialogReturn.CONFIRM_DIALOG_RETURN_Other)
            "
          >
            {{ otherButtonText }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            variant="tonal"
            @click="
              closeAndResolve(ConfirmDialogReturn.CONFIRM_DIALOG_RETURN_Cancel)
            "
            ref="cancelButton"
            :data-cy="TestIdDialog.TEST_ID_DIALOG_CancelButton"
          >
            {{ cancelButtonText }}
          </v-btn>
          <v-btn
            v-if="proceedButtonText"
            variant="outlined"
            @click="
              closeAndResolve(ConfirmDialogReturn.CONFIRM_DIALOG_RETURN_Proceed)
            "
            ref="proceedButton"
            :data-cy="TestIdDialog.TEST_ID_DIALOG_ConfirmButton"
          >
            {{ proceedButtonText }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>
