/* eslint-disable */
export const protobufPackage = 'de.netfiles.pb';

/** Represents one label, either group lable or private label */
export interface Label {
  label: string;
  groupId?: string | undefined;
}

/** List of labels for an object */
export interface Labels {
  itemLabels: Label[];
  id?: string | undefined;
}

function createBaseLabel(): Label {
  return { label: '', groupId: undefined };
}

export const Label = {
  fromJSON(object: any): Label {
    return {
      label: isSet(object.label) ? String(object.label) : '',
      groupId: isSet(object.groupId) ? String(object.groupId) : undefined,
    };
  },

  toJSON(message: Label): unknown {
    const obj: any = {};
    message.label !== undefined && (obj.label = message.label);
    message.groupId !== undefined && (obj.groupId = message.groupId);
    return obj;
  },
};

function createBaseLabels(): Labels {
  return { itemLabels: [], id: undefined };
}

export const Labels = {
  fromJSON(object: any): Labels {
    return {
      itemLabels: Array.isArray(object?.itemLabels)
        ? object.itemLabels.map((e: any) => Label.fromJSON(e))
        : [],
      id: isSet(object.id) ? String(object.id) : undefined,
    };
  },

  toJSON(message: Labels): unknown {
    const obj: any = {};
    if (message.itemLabels) {
      obj.itemLabels = message.itemLabels.map(e =>
        e ? Label.toJSON(e) : undefined,
      );
    } else {
      obj.itemLabels = [];
    }
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },
};

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
