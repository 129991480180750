export default {
  general: {
    appName: 'netfiles',
    cancel: 'Abbrechen',
    logout: 'Ausloggen',
    continue: 'Weiter',
  },
  login: {
    accept: 'Einladung annehmen',
    backToLogin: 'Zurück zu Login',
    forgotPassword: 'Kennwort vergessen',
    forgotPasswordConfirmText:
      'Falls die angegebene E-Mail Adresse/Benutzername existiert, schicken wir Ihnen eine E Mail mit einem Link zum Zurücksetzen des Kennwortes. Sollten Sie diese E Mail nicht innerhalb der nächsten Minuten erhalten, überprüfen Sie bitte Ihren Spam Ordner.',
    forgotPasswordConfirmTitle: 'Überprüfen Sie bitte Ihren E-Mail-Posteingang',
    login: 'Login',
    loginError:
      'Benutzername oder Passwort falsch. Versuchen Sie es bitte nochmals.',
    verifyPasswordError: 'Passwort falsch. Versuchen Sie es bitte nochmals.',
    verifyPasswordMessage:
      'Die Passwortanforderungen haben sich geändert, bitte geben Sie ihr Passwort ein um es zu verifizieren.',
    verifyEmailMessage:
      'Die Sicherheitsbestimmungen des von Ihnen genutzten Netfiles Datenraumes erfordern eine regelmäßige Bestätigung Ihrer E-Mail Adresse.',
    verifyEmailMessageSecond:
      'Um die Gültigkeit ihrer E-Mail Adresse zu bestätigen, geben Sie bitte den Code ein, den Sie in einer Email erhalten.',
    verifyEmailError: 'Die Email konnte nicht Bestätigt werden.',
    verifyEmail: 'E-Mail Bestätigen',
    emailVerificationCode: 'Sicherheitscode',
    loginWithPasskey: 'Login mit Passkey',
    loginWithPassword: 'Login mit Kennwort',
    passkey: 'Passkey',
    password: 'Kennwort',
    redirecting: 'Sie werden angemeldet...',
    resetPassword: 'Kennwort zurücksetzen',
    usernameEmail: 'Benutzername / E-Mail Adresse',
    autoLogoutMessage: 'Sie wurden aus Sicherheitsgründen ausgeloggt.',
    autoLogoutPasswordChangeMessage:
      'Sie haben Ihr Passwort erfolgreich geändert, bitte loggen Sie sich erneut ein.',
    autoLogoutPasswordCheckTimeoutMessage:
      'Sie wurden aus Sicherheitsgründen ausgeloggt.',
    accountLocked:
      'Dieser Benutzername wurde gesperrt. Falls es ein Konto mit diesem Benutzernamen gibt, wurde eine entsprechende E-Mail an die gespeichert E-Mail Adresse versandt.',
  },
  authDialog: {
    enablePasskeyTitle: 'Passkey aktivieren',
    enablePasskeyMessage:
      'Sie haben Sich erfolgreich angemeldet. Um die Anmeldung zukünftig einfacher und sicherer zu gestalten, können Sie jetzt einen Passkey für den netfiles Login erstellen.',
    enablePasskeyExplanation:
      'Passkeys bieten Ihnen die Möglichkeit, Ihre Identität mit Ihrem Fingerabdruck, Ihrem Gesicht oder Ihrer Displaysperre zu bestätigen. Sie sollten nur einen Passkey erstellen, wenn dies Ihr Gerät ist.',
    enablePasskeyButton: 'Weiter',
    doNotEnablePasskeyButton: 'Nicht jetzt',
  },
  passkeyDialog: {
    title: 'Passkey erstellen',
    messageWorking:
      'Passkey wird erstellt. Der Vorgang kann einige Sekunden dauern. Bitte warten Sie.',
    messageSucceeded:
      'Passkey erfolgreich eingerichtet. Ab sofort können Sie sich ohne Kennwort in netfiles anmelden. Klicken Sie auf "Weiter", um in den Datenraum weitergeleitet zu werden.',
    messageFailed:
      'Passkey konnte nicht erstellt werden. Versuchen Sie es beim nächsten Login noch einmal.',
    dismiss: 'Weiter',
  },
  twoFaDialog: {
    securityCode: 'Sicherheitscode',
    title: 'Sicherheitscode eingeben',
    text_1:
      'Um den Login zu autorisieren, geben Sie bitte den Sicherheitscode ein, den wir Ihnen per SMS gesendet haben  ({tanSendAddress}) (SMS-ID: {messageId}).',
    text_2:
      'Um den Login zu autorisieren, geben Sie bitte den Sicherheitscode ein, den wir Ihnen per E-Mail gesendet haben  ({tanSendAddress}) (SMS-ID: {messageId}).',
    text_3:
      'Um den Login zu autorisieren, geben Sie bitte den Sicherheitscode von ihrer Authenticator/OTP-App ein',
    errorMessage: 'Sicherheitscode konnte nicht verifiziert werden',
    confirmTitle: 'Keinen Sicherheitscode erhalten? ',
    confirmText:
      'Falls Sie keinen Sicherheitscode erhalten haben, warten Sie bitte zwei Minuten. Klicken Sie dann auf “Code erneut senden”. Wenn Sie auch dann keinen Code erhalten, kontaktieren Sie den Administrator des Datenraumes. <br><br> netfiles empfiehlt die 2-Faktor-Authentifizierung mit einem Einmalkennwort per Authenticator/OTP-App. So sind Sie nicht auf die Zustellung des Sicherheitscodes mittels SMS/E-Mail angewiesen. Informationen zu OTP finden Sie in der netfiles Hilfe.',
    confirmProceed: 'Sicherheitscode erneut senden',
    confirmTitleSms: 'Probleme mit dem Einmalkennwort?',
    confirmTextSms:
      'Wenn Sie Probleme bei der Verwendung des Einmalkennworts haben, versuchen Sie es mit der alternativen Login Verifikation per SMS an Ihr Mobiltelefon.',
    confirmProceedSms: 'Sicherheitscode senden',
    mobilePhoneNr: 'Mobil',
    mobileText: 'Eine Mobilnummer ist notwendig um SMS zu erhalten.',
    saveMobile: 'Mobil speichern und Sicherheitscode senden',
    setMobile: {
      title: 'Mobilnummer eingeben/zurücksetzen',
      text_1:
        'Für die 2-Faktor-Authentifizierung mittels SMS muss eine gültige Mobilnummer für Ihr Benutzerkonto hinterlegt werden. Bitte stellen Sie sicher, dass die eingegebenen Nummer über das Mobilfunknetz erreichbar ist.',
      text_2:
        'Der notwendige Sicherheitscode wurde Ihnen gerade per E-Mail zugesandt.',
    },
  },
  securityQuestion: {
    securityQuestion: 'Sicherheitsfrage',
    securityQuestionText:
      'Wählen Sie hier eine Sicherheitsfrage aus und definieren Sie eine Antwort. Die Sicherheitsfrage muss von Ihnen korrekt beantwortet werden, wenn Ihr Login Kennwort zurückgesetzt werden soll.',
    question: 'Frage',
    setSecurityQuestion: 'Sicherheitsfrage setzen',
    answer: 'Antwort',
  },
  forms: {
    validation: {
      required: 'Dieses Feld ist notwendig.',
      invalidEmail: 'Ungültige E-Mail-Adresse.',
      invalidUsername: 'Ungültiger Benutzername.',
      invalidPassword: 'Ungültiges Passwort.',
      invalidIntPhoneNumber:
        'Bitte benutzen Sie das internationale Format +49 123 12…',
    },
  },
  checkPassword: {
    checkPassword: 'Passwort überprüfen',
  },
  footer: {
    url: {
      title: 'www.netfiles.com',
      url: 'https://www.netfiles.com/de',
    },
    tac: {
      title: 'Nutzungsbedingungen',
      url: 'https://www.netfiles.com/de/ueber-uns/nutzungsbedingungen',
    },
    privacy: {
      title: 'Datenschutzinfo',
      url: 'https://www.netfiles.com/de/ueber-uns/datenschutz',
    },
    imprint: {
      title: 'Impressum',
      url: 'https://www.netfiles.com/de/impressum',
    },
    help: {
      title: 'Hilfe-Center',
      url: 'https://help.netfiles.de/',
    },
  },
};
