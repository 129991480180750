/* eslint-disable */
import {
  TwoFaType,
  TwoFaRequired,
  twoFaTypeFromJSON,
  twoFaTypeToJSON,
} from './two_fa';
import { EmailVerificationRequired } from './email_verify';

export const protobufPackage = 'de.netfiles.pb';

/** Auth Challanges */
export enum ChallangeType {
  CHALLANGE_TYPE_2FA = 0,
  CHALLANGE_TYPE_PASSWORD_CHECK = 1,
  CHALLANGE_TYPE_PASSWORD_CHANGE = 2,
  CHALLANGE_TYPE_SECURITY_QUESTION = 3,
  CHALLANGE_TYPE_EMAIL_VERIFICATION = 4,
  CHALLANGE_TYPE_LOGIN_MESSAGE = 5,
  UNRECOGNIZED = -1,
}

export function challangeTypeFromJSON(object: any): ChallangeType {
  switch (object) {
    case 0:
    case 'CHALLANGE_TYPE_2FA':
      return ChallangeType.CHALLANGE_TYPE_2FA;
    case 1:
    case 'CHALLANGE_TYPE_PASSWORD_CHECK':
      return ChallangeType.CHALLANGE_TYPE_PASSWORD_CHECK;
    case 2:
    case 'CHALLANGE_TYPE_PASSWORD_CHANGE':
      return ChallangeType.CHALLANGE_TYPE_PASSWORD_CHANGE;
    case 3:
    case 'CHALLANGE_TYPE_SECURITY_QUESTION':
      return ChallangeType.CHALLANGE_TYPE_SECURITY_QUESTION;
    case 4:
    case 'CHALLANGE_TYPE_EMAIL_VERIFICATION':
      return ChallangeType.CHALLANGE_TYPE_EMAIL_VERIFICATION;
    case 5:
    case 'CHALLANGE_TYPE_LOGIN_MESSAGE':
      return ChallangeType.CHALLANGE_TYPE_LOGIN_MESSAGE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ChallangeType.UNRECOGNIZED;
  }
}

export function challangeTypeToJSON(object: ChallangeType): string {
  switch (object) {
    case ChallangeType.CHALLANGE_TYPE_2FA:
      return 'CHALLANGE_TYPE_2FA';
    case ChallangeType.CHALLANGE_TYPE_PASSWORD_CHECK:
      return 'CHALLANGE_TYPE_PASSWORD_CHECK';
    case ChallangeType.CHALLANGE_TYPE_PASSWORD_CHANGE:
      return 'CHALLANGE_TYPE_PASSWORD_CHANGE';
    case ChallangeType.CHALLANGE_TYPE_SECURITY_QUESTION:
      return 'CHALLANGE_TYPE_SECURITY_QUESTION';
    case ChallangeType.CHALLANGE_TYPE_EMAIL_VERIFICATION:
      return 'CHALLANGE_TYPE_EMAIL_VERIFICATION';
    case ChallangeType.CHALLANGE_TYPE_LOGIN_MESSAGE:
      return 'CHALLANGE_TYPE_LOGIN_MESSAGE';
    default:
      return 'UNKNOWN';
  }
}

/** Request to authenticate, using username & password */
export interface AuthRequest {
  clientId: string;
  clientSecret: string;
  username: string;
  password: string;
}

/** We then get a token that we can use from then on in the auth-interceptor */
export interface AccessTokenData {
  accessToken: string;
  tokenType: string;
  expires: number;
  refreshToken: string;
  scope: string;
  serverVersion: string;
  serverCommitHash: string;
  jsonApiVersion: string;
  jsonApiCommitHash: string;
}

/** The actual authentication response */
export interface AuthResponse {
  accessTokenData?: AccessTokenData | undefined;
}

/**
 * ClientRedirectRequest
 *
 * This is the request used when the user has been logged in
 * using username + password and everyhing has been done on the
 * auth-server side, so that a redirect to the final app can be
 * done.
 *
 * This is special as in case of username + password, the auth-server
 * receives the TokenData in order for it to be able to create a passkey
 * if the user asks for it.
 *
 * But once that is done, the user needs to be redirected to a client
 * which then needs the tokenId (`AuthorizeClientRequest`), which is
 * generated by the server.
 */
export interface ClientRedirectRequest {
  clientId: string;
  clientSecret: string;
  username: string;
  password: string;
  targetClientId: string;
}

/**
 * ClientRedirectResponse
 *
 * The response for it
 */
export interface ClientRedirectResponse {
  tokenId: string;
  ttl: number;
  challangeType?: ChallangeType | undefined;
  challangeResponseData?: ChallangeResponseData | undefined;
  userId?: string | undefined;
  baseUrl?: string | undefined;
  isNetfilesClassic?: boolean | undefined;
}

/** Passkey Authorization Options Request */
export interface PasskeyAuthorizationOptionsRequest {
  clientId: string;
  clientSecret: string;
  username: string;
}

/** The response for `LoginInfoRequest` */
export interface PasskeyAuthorizationOptionsResponse {
  userId: string;
  username: string;
  challengeId: string;
  /**
   * Look: this is not a string, but a JSON struct, but
   * at the moment, I'm having problems finding it.
   * I will transfer this as a string for the time being
   */
  pkcro: string;
  ttl: number;
}

/** Verify Passkey Request */
export interface VerifyPasskeyRequest {
  clientId: string;
  clientSecret: string;
  username: string;
  userId: string;
  challengeId: string;
  targetClientId: string;
  /**
   * Also, this is a complicated structure I'm trying to find...
   * I will transfer this as a string for the time being
   */
  arjs: string;
  /** client info */
  userOs: string;
  userApp: string;
  /**
   * Set this one to just verify, without setting any cookies
   * and/or returning TokenData
   */
  verifyOnly: boolean;
}

/** Verfiy Password Request */
export interface VerifyPasswordRequest {
  password: string;
  clientId: string;
}

/** The response for the request */
export interface VerifyPasswordResponse {
  verified: boolean;
  challangeResponseData?: ChallangeResponseData | undefined;
  challangeType?: ChallangeType | undefined;
  userId?: string | undefined;
  tokenId?: string | undefined;
  baseUrl?: string | undefined;
  isNetfilesClassic?: boolean | undefined;
}

/** Verify Passkey Response */
export interface VerifyPasskeyResponse {
  verified: boolean;
  ttl: number;
  tokenId?: string | undefined;
  challangeResponseData?: ChallangeResponseData | undefined;
  challangeType?: ChallangeType | undefined;
  userId?: string | undefined;
  baseUrl?: string | undefined;
  isNetfilesClassic?: boolean | undefined;
}

/**
 * Client Base Url Request
 *
 * This is used to retrieve the base URL for a clientID
 */
export interface ClientBaseUrlRequest {
  clientId: string;
  clientSecret: string;
  targetClientId: string;
}

/** Client Base Url Response */
export interface ClientBaseUrlResponse {
  baseUrl?: string | undefined;
  isNetfilesClassic?: boolean | undefined;
}

/**
 * Authorize Client request
 *
 * This is the request sent by an app, like netfiles, after
 * the auth process has finished and auth-server has redirected
 * to this client
 */
export interface AuthorizeClientRequest {
  clientId: string;
  clientSecret: string;
  clientTokenId: string;
  verifyOnly: boolean;
}

/**
 * Authorize Server Request
 *
 * This is the request that comes in from waveslave and only
 * used for netfiles classic.
 */
export interface AuthorizeServerRequest {
  serverId: string;
  serverSecret: string;
  targetClientId: string;
  targetClientTokenId: string;
  verifyOnly: boolean;
}

/** The Authorize Server Response */
export interface AuthorizeServerResponse {
  username?: string | undefined;
}

/**
 * PrepareRedirectBackRequest
 *
 * This request is used by waveslave when a user leaves a dataroom in
 * netfiles classic. This then prepares some data on the server, which
 * the receiving Vueture client can use to authenticate itself using
 * /authorize/client
 *
 * This is used when a user leaves the data room in netfiles classic and
 * returned back to Vueture for data room lists.
 */
export interface PrepareRedirectBackRequest {
  username: string;
  serverId: string;
  serverSecret: string;
  targetClientId: string;
}

/**
 * PrepareRedirectBackResponse
 *
 * This is the reponse for it
 */
export interface PrepareRedirectBackResponse {
  tokenId: string;
  ttl: number;
  targetClientUrl: string;
  userId?: string | undefined;
}

/** AuthGenerate2Fa Request */
export interface AuthGenerate2faRequest {
  type: TwoFaType;
  mobile?: string | undefined;
  emailCode?: string | undefined;
}

/** The Response */
export interface AuthGenerate2faResponse {
  twoFaRequired: TwoFaRequired | undefined;
}

/** AuthValdudate 2 FA request */
export interface AuthValidate2faRequest {
  lock: string;
  key: string;
  fingerprint: string;
}

/** Validate OTP */
export interface AuthValidate2faOtpRequest {
  otp: string;
  fingerprint: string;
}

/** Password change required */
export interface PasswordChangeRequired {
  required: boolean;
  link: string;
}

/** Login message required */
export interface LoginMessageRequired {
  required: boolean;
  text: string;
}

/** Security Question needed */
export interface DefineSecurityQuestionRequest {
  question: string;
  answer: string;
}

/** AuthValdudate 2 FA request */
export interface EmailVerificationRequest {
  lock: string;
  key: string;
  fingerprint: string;
}

/** Redirect from Vue to Classic */
export interface RedirectUrlResponse {
  url?: string | undefined;
}

export interface ChallangeResponseData {
  twoFaRequired?: TwoFaRequired | undefined;
  passwordChangeRequired?: PasswordChangeRequired | undefined;
  passwordCheckRequired?: boolean | undefined;
  securityQuestionRequired?: boolean | undefined;
  emailVerificationRequired?: EmailVerificationRequired | undefined;
  loginMessageRequired?: LoginMessageRequired | undefined;
}

function createBaseAuthRequest(): AuthRequest {
  return { clientId: '', clientSecret: '', username: '', password: '' };
}

export const AuthRequest = {
  fromJSON(object: any): AuthRequest {
    return {
      clientId: isSet(object.clientId) ? String(object.clientId) : '',
      clientSecret: isSet(object.clientSecret)
        ? String(object.clientSecret)
        : '',
      username: isSet(object.username) ? String(object.username) : '',
      password: isSet(object.password) ? String(object.password) : '',
    };
  },

  toJSON(message: AuthRequest): unknown {
    const obj: any = {};
    message.clientId !== undefined && (obj.clientId = message.clientId);
    message.clientSecret !== undefined &&
      (obj.clientSecret = message.clientSecret);
    message.username !== undefined && (obj.username = message.username);
    message.password !== undefined && (obj.password = message.password);
    return obj;
  },
};

function createBaseAccessTokenData(): AccessTokenData {
  return {
    accessToken: '',
    tokenType: '',
    expires: 0,
    refreshToken: '',
    scope: '',
    serverVersion: '',
    serverCommitHash: '',
    jsonApiVersion: '',
    jsonApiCommitHash: '',
  };
}

export const AccessTokenData = {
  fromJSON(object: any): AccessTokenData {
    return {
      accessToken: isSet(object.accessToken) ? String(object.accessToken) : '',
      tokenType: isSet(object.tokenType) ? String(object.tokenType) : '',
      expires: isSet(object.expires) ? Number(object.expires) : 0,
      refreshToken: isSet(object.refreshToken)
        ? String(object.refreshToken)
        : '',
      scope: isSet(object.scope) ? String(object.scope) : '',
      serverVersion: isSet(object.serverVersion)
        ? String(object.serverVersion)
        : '',
      serverCommitHash: isSet(object.serverCommitHash)
        ? String(object.serverCommitHash)
        : '',
      jsonApiVersion: isSet(object.jsonApiVersion)
        ? String(object.jsonApiVersion)
        : '',
      jsonApiCommitHash: isSet(object.jsonApiCommitHash)
        ? String(object.jsonApiCommitHash)
        : '',
    };
  },

  toJSON(message: AccessTokenData): unknown {
    const obj: any = {};
    message.accessToken !== undefined &&
      (obj.accessToken = message.accessToken);
    message.tokenType !== undefined && (obj.tokenType = message.tokenType);
    message.expires !== undefined &&
      (obj.expires = Math.round(message.expires));
    message.refreshToken !== undefined &&
      (obj.refreshToken = message.refreshToken);
    message.scope !== undefined && (obj.scope = message.scope);
    message.serverVersion !== undefined &&
      (obj.serverVersion = message.serverVersion);
    message.serverCommitHash !== undefined &&
      (obj.serverCommitHash = message.serverCommitHash);
    message.jsonApiVersion !== undefined &&
      (obj.jsonApiVersion = message.jsonApiVersion);
    message.jsonApiCommitHash !== undefined &&
      (obj.jsonApiCommitHash = message.jsonApiCommitHash);
    return obj;
  },
};

function createBaseAuthResponse(): AuthResponse {
  return { accessTokenData: undefined };
}

export const AuthResponse = {
  fromJSON(object: any): AuthResponse {
    return {
      accessTokenData: isSet(object.accessTokenData)
        ? AccessTokenData.fromJSON(object.accessTokenData)
        : undefined,
    };
  },

  toJSON(message: AuthResponse): unknown {
    const obj: any = {};
    message.accessTokenData !== undefined &&
      (obj.accessTokenData = message.accessTokenData
        ? AccessTokenData.toJSON(message.accessTokenData)
        : undefined);
    return obj;
  },
};

function createBaseClientRedirectRequest(): ClientRedirectRequest {
  return {
    clientId: '',
    clientSecret: '',
    username: '',
    password: '',
    targetClientId: '',
  };
}

export const ClientRedirectRequest = {
  fromJSON(object: any): ClientRedirectRequest {
    return {
      clientId: isSet(object.clientId) ? String(object.clientId) : '',
      clientSecret: isSet(object.clientSecret)
        ? String(object.clientSecret)
        : '',
      username: isSet(object.username) ? String(object.username) : '',
      password: isSet(object.password) ? String(object.password) : '',
      targetClientId: isSet(object.targetClientId)
        ? String(object.targetClientId)
        : '',
    };
  },

  toJSON(message: ClientRedirectRequest): unknown {
    const obj: any = {};
    message.clientId !== undefined && (obj.clientId = message.clientId);
    message.clientSecret !== undefined &&
      (obj.clientSecret = message.clientSecret);
    message.username !== undefined && (obj.username = message.username);
    message.password !== undefined && (obj.password = message.password);
    message.targetClientId !== undefined &&
      (obj.targetClientId = message.targetClientId);
    return obj;
  },
};

function createBaseClientRedirectResponse(): ClientRedirectResponse {
  return {
    tokenId: '',
    ttl: 0,
    challangeType: undefined,
    challangeResponseData: undefined,
    userId: undefined,
    baseUrl: undefined,
    isNetfilesClassic: undefined,
  };
}

export const ClientRedirectResponse = {
  fromJSON(object: any): ClientRedirectResponse {
    return {
      tokenId: isSet(object.tokenId) ? String(object.tokenId) : '',
      ttl: isSet(object.ttl) ? Number(object.ttl) : 0,
      challangeType: isSet(object.challangeType)
        ? challangeTypeFromJSON(object.challangeType)
        : undefined,
      challangeResponseData: isSet(object.challangeResponseData)
        ? ChallangeResponseData.fromJSON(object.challangeResponseData)
        : undefined,
      userId: isSet(object.userId) ? String(object.userId) : undefined,
      baseUrl: isSet(object.baseUrl) ? String(object.baseUrl) : undefined,
      isNetfilesClassic: isSet(object.isNetfilesClassic)
        ? Boolean(object.isNetfilesClassic)
        : undefined,
    };
  },

  toJSON(message: ClientRedirectResponse): unknown {
    const obj: any = {};
    message.tokenId !== undefined && (obj.tokenId = message.tokenId);
    message.ttl !== undefined && (obj.ttl = Math.round(message.ttl));
    message.challangeType !== undefined &&
      (obj.challangeType =
        message.challangeType !== undefined
          ? challangeTypeToJSON(message.challangeType)
          : undefined);
    message.challangeResponseData !== undefined &&
      (obj.challangeResponseData = message.challangeResponseData
        ? ChallangeResponseData.toJSON(message.challangeResponseData)
        : undefined);
    message.userId !== undefined && (obj.userId = message.userId);
    message.baseUrl !== undefined && (obj.baseUrl = message.baseUrl);
    message.isNetfilesClassic !== undefined &&
      (obj.isNetfilesClassic = message.isNetfilesClassic);
    return obj;
  },
};

function createBasePasskeyAuthorizationOptionsRequest(): PasskeyAuthorizationOptionsRequest {
  return { clientId: '', clientSecret: '', username: '' };
}

export const PasskeyAuthorizationOptionsRequest = {
  fromJSON(object: any): PasskeyAuthorizationOptionsRequest {
    return {
      clientId: isSet(object.clientId) ? String(object.clientId) : '',
      clientSecret: isSet(object.clientSecret)
        ? String(object.clientSecret)
        : '',
      username: isSet(object.username) ? String(object.username) : '',
    };
  },

  toJSON(message: PasskeyAuthorizationOptionsRequest): unknown {
    const obj: any = {};
    message.clientId !== undefined && (obj.clientId = message.clientId);
    message.clientSecret !== undefined &&
      (obj.clientSecret = message.clientSecret);
    message.username !== undefined && (obj.username = message.username);
    return obj;
  },
};

function createBasePasskeyAuthorizationOptionsResponse(): PasskeyAuthorizationOptionsResponse {
  return { userId: '', username: '', challengeId: '', pkcro: '', ttl: 0 };
}

export const PasskeyAuthorizationOptionsResponse = {
  fromJSON(object: any): PasskeyAuthorizationOptionsResponse {
    return {
      userId: isSet(object.userId) ? String(object.userId) : '',
      username: isSet(object.username) ? String(object.username) : '',
      challengeId: isSet(object.challengeId) ? String(object.challengeId) : '',
      pkcro: isSet(object.pkcro) ? String(object.pkcro) : '',
      ttl: isSet(object.ttl) ? Number(object.ttl) : 0,
    };
  },

  toJSON(message: PasskeyAuthorizationOptionsResponse): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.username !== undefined && (obj.username = message.username);
    message.challengeId !== undefined &&
      (obj.challengeId = message.challengeId);
    message.pkcro !== undefined && (obj.pkcro = message.pkcro);
    message.ttl !== undefined && (obj.ttl = Math.round(message.ttl));
    return obj;
  },
};

function createBaseVerifyPasskeyRequest(): VerifyPasskeyRequest {
  return {
    clientId: '',
    clientSecret: '',
    username: '',
    userId: '',
    challengeId: '',
    targetClientId: '',
    arjs: '',
    userOs: '',
    userApp: '',
    verifyOnly: false,
  };
}

export const VerifyPasskeyRequest = {
  fromJSON(object: any): VerifyPasskeyRequest {
    return {
      clientId: isSet(object.clientId) ? String(object.clientId) : '',
      clientSecret: isSet(object.clientSecret)
        ? String(object.clientSecret)
        : '',
      username: isSet(object.username) ? String(object.username) : '',
      userId: isSet(object.userId) ? String(object.userId) : '',
      challengeId: isSet(object.challengeId) ? String(object.challengeId) : '',
      targetClientId: isSet(object.targetClientId)
        ? String(object.targetClientId)
        : '',
      arjs: isSet(object.arjs) ? String(object.arjs) : '',
      userOs: isSet(object.userOs) ? String(object.userOs) : '',
      userApp: isSet(object.userApp) ? String(object.userApp) : '',
      verifyOnly: isSet(object.verifyOnly) ? Boolean(object.verifyOnly) : false,
    };
  },

  toJSON(message: VerifyPasskeyRequest): unknown {
    const obj: any = {};
    message.clientId !== undefined && (obj.clientId = message.clientId);
    message.clientSecret !== undefined &&
      (obj.clientSecret = message.clientSecret);
    message.username !== undefined && (obj.username = message.username);
    message.userId !== undefined && (obj.userId = message.userId);
    message.challengeId !== undefined &&
      (obj.challengeId = message.challengeId);
    message.targetClientId !== undefined &&
      (obj.targetClientId = message.targetClientId);
    message.arjs !== undefined && (obj.arjs = message.arjs);
    message.userOs !== undefined && (obj.userOs = message.userOs);
    message.userApp !== undefined && (obj.userApp = message.userApp);
    message.verifyOnly !== undefined && (obj.verifyOnly = message.verifyOnly);
    return obj;
  },
};

function createBaseVerifyPasswordRequest(): VerifyPasswordRequest {
  return { password: '', clientId: '' };
}

export const VerifyPasswordRequest = {
  fromJSON(object: any): VerifyPasswordRequest {
    return {
      password: isSet(object.password) ? String(object.password) : '',
      clientId: isSet(object.clientId) ? String(object.clientId) : '',
    };
  },

  toJSON(message: VerifyPasswordRequest): unknown {
    const obj: any = {};
    message.password !== undefined && (obj.password = message.password);
    message.clientId !== undefined && (obj.clientId = message.clientId);
    return obj;
  },
};

function createBaseVerifyPasswordResponse(): VerifyPasswordResponse {
  return {
    verified: false,
    challangeResponseData: undefined,
    challangeType: undefined,
    userId: undefined,
    tokenId: undefined,
    baseUrl: undefined,
    isNetfilesClassic: undefined,
  };
}

export const VerifyPasswordResponse = {
  fromJSON(object: any): VerifyPasswordResponse {
    return {
      verified: isSet(object.verified) ? Boolean(object.verified) : false,
      challangeResponseData: isSet(object.challangeResponseData)
        ? ChallangeResponseData.fromJSON(object.challangeResponseData)
        : undefined,
      challangeType: isSet(object.challangeType)
        ? challangeTypeFromJSON(object.challangeType)
        : undefined,
      userId: isSet(object.userId) ? String(object.userId) : undefined,
      tokenId: isSet(object.tokenId) ? String(object.tokenId) : undefined,
      baseUrl: isSet(object.baseUrl) ? String(object.baseUrl) : undefined,
      isNetfilesClassic: isSet(object.isNetfilesClassic)
        ? Boolean(object.isNetfilesClassic)
        : undefined,
    };
  },

  toJSON(message: VerifyPasswordResponse): unknown {
    const obj: any = {};
    message.verified !== undefined && (obj.verified = message.verified);
    message.challangeResponseData !== undefined &&
      (obj.challangeResponseData = message.challangeResponseData
        ? ChallangeResponseData.toJSON(message.challangeResponseData)
        : undefined);
    message.challangeType !== undefined &&
      (obj.challangeType =
        message.challangeType !== undefined
          ? challangeTypeToJSON(message.challangeType)
          : undefined);
    message.userId !== undefined && (obj.userId = message.userId);
    message.tokenId !== undefined && (obj.tokenId = message.tokenId);
    message.baseUrl !== undefined && (obj.baseUrl = message.baseUrl);
    message.isNetfilesClassic !== undefined &&
      (obj.isNetfilesClassic = message.isNetfilesClassic);
    return obj;
  },
};

function createBaseVerifyPasskeyResponse(): VerifyPasskeyResponse {
  return {
    verified: false,
    ttl: 0,
    tokenId: undefined,
    challangeResponseData: undefined,
    challangeType: undefined,
    userId: undefined,
    baseUrl: undefined,
    isNetfilesClassic: undefined,
  };
}

export const VerifyPasskeyResponse = {
  fromJSON(object: any): VerifyPasskeyResponse {
    return {
      verified: isSet(object.verified) ? Boolean(object.verified) : false,
      ttl: isSet(object.ttl) ? Number(object.ttl) : 0,
      tokenId: isSet(object.tokenId) ? String(object.tokenId) : undefined,
      challangeResponseData: isSet(object.challangeResponseData)
        ? ChallangeResponseData.fromJSON(object.challangeResponseData)
        : undefined,
      challangeType: isSet(object.challangeType)
        ? challangeTypeFromJSON(object.challangeType)
        : undefined,
      userId: isSet(object.userId) ? String(object.userId) : undefined,
      baseUrl: isSet(object.baseUrl) ? String(object.baseUrl) : undefined,
      isNetfilesClassic: isSet(object.isNetfilesClassic)
        ? Boolean(object.isNetfilesClassic)
        : undefined,
    };
  },

  toJSON(message: VerifyPasskeyResponse): unknown {
    const obj: any = {};
    message.verified !== undefined && (obj.verified = message.verified);
    message.ttl !== undefined && (obj.ttl = Math.round(message.ttl));
    message.tokenId !== undefined && (obj.tokenId = message.tokenId);
    message.challangeResponseData !== undefined &&
      (obj.challangeResponseData = message.challangeResponseData
        ? ChallangeResponseData.toJSON(message.challangeResponseData)
        : undefined);
    message.challangeType !== undefined &&
      (obj.challangeType =
        message.challangeType !== undefined
          ? challangeTypeToJSON(message.challangeType)
          : undefined);
    message.userId !== undefined && (obj.userId = message.userId);
    message.baseUrl !== undefined && (obj.baseUrl = message.baseUrl);
    message.isNetfilesClassic !== undefined &&
      (obj.isNetfilesClassic = message.isNetfilesClassic);
    return obj;
  },
};

function createBaseClientBaseUrlRequest(): ClientBaseUrlRequest {
  return { clientId: '', clientSecret: '', targetClientId: '' };
}

export const ClientBaseUrlRequest = {
  fromJSON(object: any): ClientBaseUrlRequest {
    return {
      clientId: isSet(object.clientId) ? String(object.clientId) : '',
      clientSecret: isSet(object.clientSecret)
        ? String(object.clientSecret)
        : '',
      targetClientId: isSet(object.targetClientId)
        ? String(object.targetClientId)
        : '',
    };
  },

  toJSON(message: ClientBaseUrlRequest): unknown {
    const obj: any = {};
    message.clientId !== undefined && (obj.clientId = message.clientId);
    message.clientSecret !== undefined &&
      (obj.clientSecret = message.clientSecret);
    message.targetClientId !== undefined &&
      (obj.targetClientId = message.targetClientId);
    return obj;
  },
};

function createBaseClientBaseUrlResponse(): ClientBaseUrlResponse {
  return { baseUrl: undefined, isNetfilesClassic: undefined };
}

export const ClientBaseUrlResponse = {
  fromJSON(object: any): ClientBaseUrlResponse {
    return {
      baseUrl: isSet(object.baseUrl) ? String(object.baseUrl) : undefined,
      isNetfilesClassic: isSet(object.isNetfilesClassic)
        ? Boolean(object.isNetfilesClassic)
        : undefined,
    };
  },

  toJSON(message: ClientBaseUrlResponse): unknown {
    const obj: any = {};
    message.baseUrl !== undefined && (obj.baseUrl = message.baseUrl);
    message.isNetfilesClassic !== undefined &&
      (obj.isNetfilesClassic = message.isNetfilesClassic);
    return obj;
  },
};

function createBaseAuthorizeClientRequest(): AuthorizeClientRequest {
  return {
    clientId: '',
    clientSecret: '',
    clientTokenId: '',
    verifyOnly: false,
  };
}

export const AuthorizeClientRequest = {
  fromJSON(object: any): AuthorizeClientRequest {
    return {
      clientId: isSet(object.clientId) ? String(object.clientId) : '',
      clientSecret: isSet(object.clientSecret)
        ? String(object.clientSecret)
        : '',
      clientTokenId: isSet(object.clientTokenId)
        ? String(object.clientTokenId)
        : '',
      verifyOnly: isSet(object.verifyOnly) ? Boolean(object.verifyOnly) : false,
    };
  },

  toJSON(message: AuthorizeClientRequest): unknown {
    const obj: any = {};
    message.clientId !== undefined && (obj.clientId = message.clientId);
    message.clientSecret !== undefined &&
      (obj.clientSecret = message.clientSecret);
    message.clientTokenId !== undefined &&
      (obj.clientTokenId = message.clientTokenId);
    message.verifyOnly !== undefined && (obj.verifyOnly = message.verifyOnly);
    return obj;
  },
};

function createBaseAuthorizeServerRequest(): AuthorizeServerRequest {
  return {
    serverId: '',
    serverSecret: '',
    targetClientId: '',
    targetClientTokenId: '',
    verifyOnly: false,
  };
}

export const AuthorizeServerRequest = {
  fromJSON(object: any): AuthorizeServerRequest {
    return {
      serverId: isSet(object.serverId) ? String(object.serverId) : '',
      serverSecret: isSet(object.serverSecret)
        ? String(object.serverSecret)
        : '',
      targetClientId: isSet(object.targetClientId)
        ? String(object.targetClientId)
        : '',
      targetClientTokenId: isSet(object.targetClientTokenId)
        ? String(object.targetClientTokenId)
        : '',
      verifyOnly: isSet(object.verifyOnly) ? Boolean(object.verifyOnly) : false,
    };
  },

  toJSON(message: AuthorizeServerRequest): unknown {
    const obj: any = {};
    message.serverId !== undefined && (obj.serverId = message.serverId);
    message.serverSecret !== undefined &&
      (obj.serverSecret = message.serverSecret);
    message.targetClientId !== undefined &&
      (obj.targetClientId = message.targetClientId);
    message.targetClientTokenId !== undefined &&
      (obj.targetClientTokenId = message.targetClientTokenId);
    message.verifyOnly !== undefined && (obj.verifyOnly = message.verifyOnly);
    return obj;
  },
};

function createBaseAuthorizeServerResponse(): AuthorizeServerResponse {
  return { username: undefined };
}

export const AuthorizeServerResponse = {
  fromJSON(object: any): AuthorizeServerResponse {
    return {
      username: isSet(object.username) ? String(object.username) : undefined,
    };
  },

  toJSON(message: AuthorizeServerResponse): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    return obj;
  },
};

function createBasePrepareRedirectBackRequest(): PrepareRedirectBackRequest {
  return { username: '', serverId: '', serverSecret: '', targetClientId: '' };
}

export const PrepareRedirectBackRequest = {
  fromJSON(object: any): PrepareRedirectBackRequest {
    return {
      username: isSet(object.username) ? String(object.username) : '',
      serverId: isSet(object.serverId) ? String(object.serverId) : '',
      serverSecret: isSet(object.serverSecret)
        ? String(object.serverSecret)
        : '',
      targetClientId: isSet(object.targetClientId)
        ? String(object.targetClientId)
        : '',
    };
  },

  toJSON(message: PrepareRedirectBackRequest): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    message.serverId !== undefined && (obj.serverId = message.serverId);
    message.serverSecret !== undefined &&
      (obj.serverSecret = message.serverSecret);
    message.targetClientId !== undefined &&
      (obj.targetClientId = message.targetClientId);
    return obj;
  },
};

function createBasePrepareRedirectBackResponse(): PrepareRedirectBackResponse {
  return { tokenId: '', ttl: 0, targetClientUrl: '', userId: undefined };
}

export const PrepareRedirectBackResponse = {
  fromJSON(object: any): PrepareRedirectBackResponse {
    return {
      tokenId: isSet(object.tokenId) ? String(object.tokenId) : '',
      ttl: isSet(object.ttl) ? Number(object.ttl) : 0,
      targetClientUrl: isSet(object.targetClientUrl)
        ? String(object.targetClientUrl)
        : '',
      userId: isSet(object.userId) ? String(object.userId) : undefined,
    };
  },

  toJSON(message: PrepareRedirectBackResponse): unknown {
    const obj: any = {};
    message.tokenId !== undefined && (obj.tokenId = message.tokenId);
    message.ttl !== undefined && (obj.ttl = Math.round(message.ttl));
    message.targetClientUrl !== undefined &&
      (obj.targetClientUrl = message.targetClientUrl);
    message.userId !== undefined && (obj.userId = message.userId);
    return obj;
  },
};

function createBaseAuthGenerate2faRequest(): AuthGenerate2faRequest {
  return { type: 0, mobile: undefined, emailCode: undefined };
}

export const AuthGenerate2faRequest = {
  fromJSON(object: any): AuthGenerate2faRequest {
    return {
      type: isSet(object.type) ? twoFaTypeFromJSON(object.type) : 0,
      mobile: isSet(object.mobile) ? String(object.mobile) : undefined,
      emailCode: isSet(object.emailCode) ? String(object.emailCode) : undefined,
    };
  },

  toJSON(message: AuthGenerate2faRequest): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = twoFaTypeToJSON(message.type));
    message.mobile !== undefined && (obj.mobile = message.mobile);
    message.emailCode !== undefined && (obj.emailCode = message.emailCode);
    return obj;
  },
};

function createBaseAuthGenerate2faResponse(): AuthGenerate2faResponse {
  return { twoFaRequired: undefined };
}

export const AuthGenerate2faResponse = {
  fromJSON(object: any): AuthGenerate2faResponse {
    return {
      twoFaRequired: isSet(object.twoFaRequired)
        ? TwoFaRequired.fromJSON(object.twoFaRequired)
        : undefined,
    };
  },

  toJSON(message: AuthGenerate2faResponse): unknown {
    const obj: any = {};
    message.twoFaRequired !== undefined &&
      (obj.twoFaRequired = message.twoFaRequired
        ? TwoFaRequired.toJSON(message.twoFaRequired)
        : undefined);
    return obj;
  },
};

function createBaseAuthValidate2faRequest(): AuthValidate2faRequest {
  return { lock: '', key: '', fingerprint: '' };
}

export const AuthValidate2faRequest = {
  fromJSON(object: any): AuthValidate2faRequest {
    return {
      lock: isSet(object.lock) ? String(object.lock) : '',
      key: isSet(object.key) ? String(object.key) : '',
      fingerprint: isSet(object.fingerprint) ? String(object.fingerprint) : '',
    };
  },

  toJSON(message: AuthValidate2faRequest): unknown {
    const obj: any = {};
    message.lock !== undefined && (obj.lock = message.lock);
    message.key !== undefined && (obj.key = message.key);
    message.fingerprint !== undefined &&
      (obj.fingerprint = message.fingerprint);
    return obj;
  },
};

function createBaseAuthValidate2faOtpRequest(): AuthValidate2faOtpRequest {
  return { otp: '', fingerprint: '' };
}

export const AuthValidate2faOtpRequest = {
  fromJSON(object: any): AuthValidate2faOtpRequest {
    return {
      otp: isSet(object.otp) ? String(object.otp) : '',
      fingerprint: isSet(object.fingerprint) ? String(object.fingerprint) : '',
    };
  },

  toJSON(message: AuthValidate2faOtpRequest): unknown {
    const obj: any = {};
    message.otp !== undefined && (obj.otp = message.otp);
    message.fingerprint !== undefined &&
      (obj.fingerprint = message.fingerprint);
    return obj;
  },
};

function createBasePasswordChangeRequired(): PasswordChangeRequired {
  return { required: false, link: '' };
}

export const PasswordChangeRequired = {
  fromJSON(object: any): PasswordChangeRequired {
    return {
      required: isSet(object.required) ? Boolean(object.required) : false,
      link: isSet(object.link) ? String(object.link) : '',
    };
  },

  toJSON(message: PasswordChangeRequired): unknown {
    const obj: any = {};
    message.required !== undefined && (obj.required = message.required);
    message.link !== undefined && (obj.link = message.link);
    return obj;
  },
};

function createBaseLoginMessageRequired(): LoginMessageRequired {
  return { required: false, text: '' };
}

export const LoginMessageRequired = {
  fromJSON(object: any): LoginMessageRequired {
    return {
      required: isSet(object.required) ? Boolean(object.required) : false,
      text: isSet(object.text) ? String(object.text) : '',
    };
  },

  toJSON(message: LoginMessageRequired): unknown {
    const obj: any = {};
    message.required !== undefined && (obj.required = message.required);
    message.text !== undefined && (obj.text = message.text);
    return obj;
  },
};

function createBaseDefineSecurityQuestionRequest(): DefineSecurityQuestionRequest {
  return { question: '', answer: '' };
}

export const DefineSecurityQuestionRequest = {
  fromJSON(object: any): DefineSecurityQuestionRequest {
    return {
      question: isSet(object.question) ? String(object.question) : '',
      answer: isSet(object.answer) ? String(object.answer) : '',
    };
  },

  toJSON(message: DefineSecurityQuestionRequest): unknown {
    const obj: any = {};
    message.question !== undefined && (obj.question = message.question);
    message.answer !== undefined && (obj.answer = message.answer);
    return obj;
  },
};

function createBaseEmailVerificationRequest(): EmailVerificationRequest {
  return { lock: '', key: '', fingerprint: '' };
}

export const EmailVerificationRequest = {
  fromJSON(object: any): EmailVerificationRequest {
    return {
      lock: isSet(object.lock) ? String(object.lock) : '',
      key: isSet(object.key) ? String(object.key) : '',
      fingerprint: isSet(object.fingerprint) ? String(object.fingerprint) : '',
    };
  },

  toJSON(message: EmailVerificationRequest): unknown {
    const obj: any = {};
    message.lock !== undefined && (obj.lock = message.lock);
    message.key !== undefined && (obj.key = message.key);
    message.fingerprint !== undefined &&
      (obj.fingerprint = message.fingerprint);
    return obj;
  },
};

function createBaseRedirectUrlResponse(): RedirectUrlResponse {
  return { url: undefined };
}

export const RedirectUrlResponse = {
  fromJSON(object: any): RedirectUrlResponse {
    return {
      url: isSet(object.url) ? String(object.url) : undefined,
    };
  },

  toJSON(message: RedirectUrlResponse): unknown {
    const obj: any = {};
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },
};

function createBaseChallangeResponseData(): ChallangeResponseData {
  return {
    twoFaRequired: undefined,
    passwordChangeRequired: undefined,
    passwordCheckRequired: undefined,
    securityQuestionRequired: undefined,
    emailVerificationRequired: undefined,
    loginMessageRequired: undefined,
  };
}

export const ChallangeResponseData = {
  fromJSON(object: any): ChallangeResponseData {
    return {
      twoFaRequired: isSet(object.twoFaRequired)
        ? TwoFaRequired.fromJSON(object.twoFaRequired)
        : undefined,
      passwordChangeRequired: isSet(object.passwordChangeRequired)
        ? PasswordChangeRequired.fromJSON(object.passwordChangeRequired)
        : undefined,
      passwordCheckRequired: isSet(object.passwordCheckRequired)
        ? Boolean(object.passwordCheckRequired)
        : undefined,
      securityQuestionRequired: isSet(object.securityQuestionRequired)
        ? Boolean(object.securityQuestionRequired)
        : undefined,
      emailVerificationRequired: isSet(object.emailVerificationRequired)
        ? EmailVerificationRequired.fromJSON(object.emailVerificationRequired)
        : undefined,
      loginMessageRequired: isSet(object.loginMessageRequired)
        ? LoginMessageRequired.fromJSON(object.loginMessageRequired)
        : undefined,
    };
  },

  toJSON(message: ChallangeResponseData): unknown {
    const obj: any = {};
    message.twoFaRequired !== undefined &&
      (obj.twoFaRequired = message.twoFaRequired
        ? TwoFaRequired.toJSON(message.twoFaRequired)
        : undefined);
    message.passwordChangeRequired !== undefined &&
      (obj.passwordChangeRequired = message.passwordChangeRequired
        ? PasswordChangeRequired.toJSON(message.passwordChangeRequired)
        : undefined);
    message.passwordCheckRequired !== undefined &&
      (obj.passwordCheckRequired = message.passwordCheckRequired);
    message.securityQuestionRequired !== undefined &&
      (obj.securityQuestionRequired = message.securityQuestionRequired);
    message.emailVerificationRequired !== undefined &&
      (obj.emailVerificationRequired = message.emailVerificationRequired
        ? EmailVerificationRequired.toJSON(message.emailVerificationRequired)
        : undefined);
    message.loginMessageRequired !== undefined &&
      (obj.loginMessageRequired = message.loginMessageRequired
        ? LoginMessageRequired.toJSON(message.loginMessageRequired)
        : undefined);
    return obj;
  },
};

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
