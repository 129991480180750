import axios, { AxiosResponse, ResponseType } from 'axios';
import { ApiError } from '../shared/types/Errors';
import {
  AuthenticationResponseJSON,
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
} from '@simplewebauthn/types';
import {
  browserSupportsWebAuthn,
  startAuthentication,
  startRegistration,
} from '@simplewebauthn/browser';
import { clientSecretSalt } from '../shared/utils/UtilFunctions';
import { ForgotPasswordRequest } from '@/shared/models/generated/users_service';
import { getMainLogger } from '../shared/utils/logging';
import { HttpResponseCode } from '@/shared/types/Types';
import { kClientId, kCS } from '@/ClientInfo';
import {
  PasskeyAuthorizationOptionsResponse,
  VerifyPasskeyRequest,
  VerifyPasskeyResponse,
  ClientBaseUrlResponse,
  ClientRedirectResponse,
  ClientRedirectRequest,
  VerifyPasswordResponse,
  VerifyPasswordRequest,
  DefineSecurityQuestionRequest,
  EmailVerificationRequest,
} from '@/shared/models/generated/auth_service';
import {
  PasskeyRegistrationOptionsResponse,
  RegisterPasskeyRequest,
  SecurityQuestionRequirements,
  SecurityQuestionRequirementsResponse,
} from '@/shared/models/generated/me_service';
import { TokenData } from '@/shared/models/token_data';
import { v4 } from 'uuid';
import { UAParser } from 'ua-parser-js';
import { TwoFaRequired, TwoFaType } from '@/shared/models/generated/two_fa';
import { Validate2faRequest } from '@/shared/models/generated/users_service';

const loggingEndpoint =
  process.env.NODE_ENV === 'development' ? 'console' : '/';
const l = window.location;
const log = getMainLogger(
  loggingEndpoint,
  'Auth',
  3,
  l.protocol + l.host,
).getSubLogger({
  name: 'Backend',
});

export enum BackendError {
  NoError = 'BackendError_NoError',
  MissingUsernamePassword = 'BackendError_MissingUsernamePassword',
  TwoFactorAuthRequired = 'BackendError_TwoFactorAuthRequired',
  PermissionDenied = 'BackendError_PermissionDenied',
  SomeOtherError = 'BackendError_SomeOtherError',
  MissingDataroomName = 'BackendError_MissingDataroomName',
}

export enum HttpMethod {
  Get,
  Post,
  Patch,
  Delete,
}

export enum AcceptType {
  AcceptTypeJson = 'application/json',
  AcceptTypeOctetStream = 'application/octet-stream',
  AcceptTypeFormUrlEncoded = 'application/x-www-form-urlencoded',
  AcceptTypeAny = '*/*',
}

export type LoginResponse = {
  ignorePasskeyReminder?: boolean;
  result: LoginResult;
};

export enum LoginResult {
  LoginResultSuccess = 'OK',
  LoginResultFailed = 'Unauthorized',
  LoginResultForbidden = 'Forbidden',
}

export type CallbackType = () => void;
export type ProgressCallbackType = (progressEvent: ProgressEvent) => void;

async function hashWeb(clearText: string): Promise<string> {
  const utf8 = new TextEncoder().encode(clearText);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map(bytes => bytes.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
}

async function clientSecretHash(): Promise<string> {
  return hashWeb(kCS + clientSecretSalt());
}

export default class Backend {
  static instance: Backend | null = null;

  configuration: Record<string, string | boolean> = {};

  tokenData: TokenData | null = null;

  passkeyVerificationResponse: VerifyPasskeyResponse | null = null;

  authorizationOptions: PasskeyAuthorizationOptionsResponse | null = null;

  targetClientId: string = 'netfiles.de';

  redirectParam: string | null = null;

  static counter = 0;

  constructor() {
    this.configuration = {
      proto: '',
      server: '',
      port: '', // see proxy in vue.config.js
      deviceId: '',
      apiRoot: '/nf/api/v1',
      isProduction: true,
    };

    if (process.env.VUE_APP_API_SERVER) {
      this.configuration.server = process.env.VUE_APP_API_SERVER;
      this.configuration.isProduction = false;
    }
    if (process.env.VUE_APP_API_PORT) {
      this.configuration.port = process.env.VUE_APP_API_PORT;
      this.configuration.isProduction = false;
    }
    if (process.env.VUE_APP_API_PROTO) {
      this.configuration.proto = process.env.VUE_APP_API_PROTO;
      this.configuration.isProduction = false;
    }
  }

  public static initBackend(): Backend {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let tcid: string | null = 'netfiles.de';
    let rdr: string | null = null;
    if (urlParams.has('tid')) {
      tcid = urlParams.get('tid');
      if (tcid === null || tcid === '') {
        tcid = 'netfiles.de';
      }
    }
    if (urlParams.has('rdr')) {
      rdr = urlParams.get('rdr');
      if (rdr === null || rdr === '') {
        rdr = null;
      }
    }
    log.info({ msg: 'onBeforeMount', targetClientId: tcid });
    return Backend.createInstance(tcid, rdr);
  }

  public static getInstance(): Backend {
    if (Backend.instance === null) {
      return Backend.createInstance('netfiles.de', null);
    }
    return Backend.instance;
  }

  public static createInstance(tcid: string, rdr: string | null): Backend {
    if (Backend.instance === null) {
      Backend.instance = new Backend();
    }
    Backend.instance.targetClientId = tcid;
    Backend.instance.redirectParam = rdr;
    return Backend.instance;
  }

  protected _getApiBase(): string {
    if (this.configuration.isProduction) {
      return this.configuration.apiRoot as string;
    }
    let apiBase = '';
    if (
      this.configuration.server !== 'localhost' ||
      this.configuration.port !== '8080'
    ) {
      apiBase =
        apiBase + this.configuration.proto + '://' + this.configuration.server;
      if (this.configuration.port) {
        apiBase = apiBase + ':' + this.configuration.port;
      }
    }
    return apiBase + this.configuration.apiRoot;
  }

  private async _performServerCall(
    requestId: string | null,
    call: string,
    accept: string,
    method: HttpMethod,
    params: Record<string, unknown> | FormData | null = null,
    responseType: ResponseType | undefined = undefined,
    callback: {
      onUploadBegin?: CallbackType | null;
      onUploadEnd?: CallbackType | null;
      onUploadProgress?: ProgressCallbackType | null;
      onUploadCancel?: CallbackType | null;
      onUploadError?: CallbackType | null;
      onDownloadBegin?: CallbackType | null;
      onDownloadEnd?: CallbackType | null;
      onDownloadProgress?: ProgressCallbackType | null;
      onDownloadCancel?: CallbackType | null;
      onDownloadError?: CallbackType | null;
    } | null = null,
    extraHeaders: Record<string, string | number | boolean> | null = null,
  ): Promise<AxiosResponse> {
    let headers: Record<string, string | number | boolean> = {
      Accept: accept,
    };
    requestId = requestId ?? v4().toUpperCase();
    headers['X-Request-Id'] = requestId + '-' + (Backend.counter++).toString();
    if (extraHeaders !== null) {
      headers = { ...headers, ...extraHeaders };
    }
    if (this.tokenData !== null) {
      headers['X-JWT-Token'] = this.tokenData.accessToken;
    }
    let options = {
      headers: headers,
      crossdomain: !this.configuration.isProduction,
      withCredentials: false,
      responseType,
    };
    if (callback !== null) {
      options = { ...options, ...callback };
    }
    log.info({ requestId, msg: 'performServerCall', call, options });
    switch (method) {
      case HttpMethod.Get:
        return axios.get(call, options);
      case HttpMethod.Post:
        return axios.post(call, params, options);
      case HttpMethod.Patch:
        return axios.patch(call, params, options);
      case HttpMethod.Delete:
        return axios.delete(call, options);
    }
  }

  private async _performAuthenticatedCall(
    requestId: string | null,
    call: string,
    accept: string,
    method: HttpMethod = HttpMethod.Get,
    params: Record<string, unknown> | null = null,
    extraHeaders: Record<string, string | number | boolean> | null = null,
  ): Promise<AxiosResponse> {
    return this._performServerCall(
      requestId,
      this._getApiBase() + call,
      accept,
      method,
      params,
      undefined,
      null,
      extraHeaders,
    );
  }

  private async _performAnonymousCall(
    requestId: string | null,
    call: string,
    accept = AcceptType.AcceptTypeAny,
    method: HttpMethod = HttpMethod.Get,
    params: Record<string, unknown> | null = null,
    extraHeaders: Record<string, string | number | boolean> | null = null,
  ): Promise<AxiosResponse> {
    return this._performServerCall(
      requestId,
      this._getApiBase() + call,
      accept,
      method,
      params,
      undefined,
      null,
      extraHeaders,
    );
  }

  // =========================================================================
  // Login with Password part.
  //
  // If the user is logged in using username + password, we ask them if they'd
  // like to create a passkey. For this, we need the TokenData, as we can't
  // otherwise create the passkey.
  // =========================================================================
  public isLoggedIn(): boolean {
    return this.tokenData !== null;
  }

  public async acceptLoginMessage(
    requestId: string | null,
    fingerprint?: string,
  ): Promise<ClientRedirectResponse | null> {
    const call = '/auth/acceptloginmessage';
    return this._performAuthenticatedCall(
      requestId,
      call,
      AcceptType.AcceptTypeJson,
      HttpMethod.Post,
      null,
      fingerprint !== undefined
        ? {
          fingerprint,
        }
        : null,
    )
      .then(response => {
        if (response.status === HttpResponseCode.HttpResponseCodeOk) {
          return response.data as ClientRedirectResponse;
        }
        return null;
      })
      .catch(error => {
        log.error({ requestId, msg: 'acceptLoginMessage', error });
        return null;
      });
  }

  public async ignorePasskeyReminder(requestId: string | null) {
    const call = '/auth/ignorepasskeyreminder';
    return this._performAuthenticatedCall(
      requestId,
      call,
      AcceptType.AcceptTypeJson,
      HttpMethod.Post,
    )
      .then(response => {
        if (response.status === HttpResponseCode.HttpResponseCodeOk) {
          return true;
        }
        return false;
      })
      .catch(error => {
        log.error({ requestId, msg: 'ignorePasskeyReminder', error });
        return false;
      });
  }

  public async validate2fa(
    requestId: string | null,
    fingerprint: string,
    lock: string,
    otp: string,
  ) {
    requestId = requestId ?? v4();

    const body = Validate2faRequest.fromJSON({});
    body.dataroomId = 'NONE';
    body.fingerprint = fingerprint;
    body.key = otp;
    body.lock = lock;

    return this._performAuthenticatedCall(
      requestId,
      '/auth/2fa/validate',
      AcceptType.AcceptTypeJson,
      HttpMethod.Post,
      { ...body },
    )
      .then(response => {
        if (response.status === HttpResponseCode.HttpResponseCodeOk) {
          return response.data as VerifyPasskeyResponse;
        }
        return null;
      })
      .catch(error => {
        log.error({ requestId, msg: 'validate2fa', error });
        return null;
      });
  }

  public async validate2faOtp(
    requestId: string | null,
    fingerprint: string,
    otp: string,
  ): Promise<VerifyPasskeyResponse | null> {
    const call = '/auth/2fa/otp/validate';
    return this._performAuthenticatedCall(
      requestId,
      call,
      AcceptType.AcceptTypeJson,
      HttpMethod.Post,
      {
        otp,
        fingerprint,
      },
    )
      .then(response => {
        if (response.status === HttpResponseCode.HttpResponseCodeOk) {
          return response.data as VerifyPasskeyResponse;
        }
        return null;
      })
      .catch(error => {
        log.error({ requestId, msg: 'validate2faOtp', error });
        return null;
      });
  }

  public async generate2fa(
    requestId: string | null,
    type: TwoFaType,
    mobile?: string,
    emailCode?: string,
    lock?: string,
  ): Promise<TwoFaRequired | null> {
    const call = '/auth/2fa/generate';
    const data =
      mobile !== undefined
        ? { mobile, type, emailCode, lock }
        : { type, emailCode, lock };
    return this._performAuthenticatedCall(
      requestId,
      call,
      AcceptType.AcceptTypeJson,
      HttpMethod.Post,
      data,
    )
      .then(response => {
        if (response.status === HttpResponseCode.HttpResponseCodeOk) {
          return response.data as TwoFaRequired;
        }
        return null;
      })
      .catch(error => {
        log.error({ requestId, msg: 'generate2fa', error });
        return null;
      });
  }

  public async loginWithPassword(
    requestId: string | null,
    username: string,
    password: string,
    fingerprint?: string,
  ): Promise<LoginResponse> {
    log.debug({ requestId, msg: 'loginWithPassword', username, password });
    const cs = await clientSecretHash();
    return this._performAnonymousCall(
      requestId,
      '/auth/auths',
      AcceptType.AcceptTypeAny,
      HttpMethod.Post,
      {
        clientId: kClientId,
        clientSecret: cs,
        username: username,
        password: password,
        targetClientId: this.targetClientId,
      },
      fingerprint !== undefined
        ? {
          fingerprint,
        }
        : null,
    )
      .then(function (response) {
        switch (response.status) {
          case 200:
            Backend.getInstance().tokenData = response.data
              .tokenData as TokenData;
            return {
              result: LoginResult.LoginResultSuccess,
              ignorePasskeyReminder: response.data.ignorePasskeyReminder as
                | boolean
                | undefined,
            };
          case 403:
            return {
              result: LoginResult.LoginResultForbidden,
            };
          default:
            return {
              result: LoginResult.LoginResultFailed,
            };
        }
      })
      .catch(error => {
        if (error.response?.status === 401) {
          log.info({ requestId, msg: 'Wrong username or password' });
        } else if (error.response?.status === 403) {
          log.warn({ requestId, msg: 'Account locked' });
        } else {
          log.error({ requestId, msg: 'loginWithPassword', error });
        }

        if (error.response?.status === 403) {
          return {
            result: LoginResult.LoginResultForbidden,
          };
        }
        return {
          result: LoginResult.LoginResultFailed,
        };
      });
  }

  public async _getPasskeyRegistrationOptions(
    requestId: string | null,
  ): Promise<PasskeyRegistrationOptionsResponse | ApiError | null> {
    log.debug({ requestId, msg: 'getPasskeyRegistrationOptions' });
    return this._performAuthenticatedCall(
      requestId,
      '/me/passkey/options',
      AcceptType.AcceptTypeJson,
      HttpMethod.Get,
    )
      .then(response => {
        if (response.status === HttpResponseCode.HttpResponseCodeOk) {
          return response.data as PasskeyRegistrationOptionsResponse;
        }
        log.error({
          requestId,
          msg: 'getPasskeyRegistrationOptions',
          details: 'failed',
          error: response.status,
        });

        return null;
      })
      .catch(error => {
        log.error({ requestId, msg: 'getPasskeyRegistrationOptions', error });
        if (
          axios.isAxiosError(error) &&
          error.response?.status === HttpResponseCode.HttpResponseCodeBadRequest
        ) {
          const apiError = Object.create(ApiError.prototype);
          apiError.errors = error.response.data.errors;
          return apiError;
        }
        return null;
      });
  }

  public async registerPasskey(requestId: string | null): Promise<boolean> {
    if (!browserSupportsWebAuthn()) {
      return false;
    }
    log.debug({ requestId, msg: 'registerPasskey' });
    //
    // 1. Get the registration options from the backend
    const options = await this._getPasskeyRegistrationOptions(requestId);

    // On error, cancel and return
    if (options === null) {
      return false;
    }
    if (options instanceof ApiError) {
      return false;
    }

    log.info({ requestId, msg: 'registerPasskey', options });

    // 2. Start the local registration
    let registrationResponse;
    try {
      const pkro: PublicKeyCredentialCreationOptionsJSON = JSON.parse(
        options.pkro,
      );
      registrationResponse = await startRegistration(pkro);
    } catch (error) {
      log.error({
        requestId,
        msg: 'registerPasskey',
        step: 'startRegistration',
        error,
      });
      return false;
    }

    log.info({ requestId, msg: 'registerPasskey', registrationResponse });

    // 3. Confirm registration on the server
    let response: RegisterPasskeyRequest | null = null;
    try {
      const parser = new UAParser();
      const uaInfo = parser.getResult();
      let osInfo = uaInfo.os.name ?? '';
      if (
        !osInfo.toUpperCase().includes('MACOS') &&
        !osInfo.toUpperCase().includes('MAC OS')
      ) {
        osInfo = osInfo + ' (' + uaInfo.os.version + ')';
      }
      let browser = uaInfo.browser.name ?? '??';
      const bv = uaInfo.browser.version;
      if (bv !== undefined) {
        browser = browser + ' ' + bv;
      }
      response = {
        challengeId: options.challengeId,
        pkrr: JSON.stringify(registrationResponse),
        targetClientId: this.targetClientId,
        userOs: osInfo,
        userApp: browser,
      };
    } catch (error) {
      log.error({ requestId, msg: 'registerPasskey -> JSON.stringfy', error });
      return false;
    }
    return this._performAuthenticatedCall(
      requestId,
      '/me/passkey/add',
      AcceptType.AcceptTypeJson,
      HttpMethod.Post,
      response as unknown as Record<string, unknown>,
    )
      .then(resp => {
        return (
          resp.status === HttpResponseCode.HttpResponseCodeOk &&
          resp.data !== null &&
          resp.data.verified !== undefined &&
          resp.data.verified === true
        );
      })
      .catch(error => {
        log.error({
          requestId,
          msg: 'registerPasskey',
          step: 'addPasskey',
          error,
        });
        return false;
      });
  }

  // =========================================================================
  // Login with Passkey / Hardware Key, etc.
  //
  // When the login with passkey (passwordless login) succeeds, we don't really
  // need to do anything else than redirecting the user to the final destination
  //
  // Thus, we don't need the TokenData here (and we shouldn't have it)
  // =========================================================================
  public hasAuthorizationOptions(): boolean {
    return this.authorizationOptions !== null;
  }

  public async getAuthorizationOptions(
    requestId: string | null,
    username: string,
  ): Promise<boolean> {
    log.debug({ requestId, msg: 'getAuthorizationOptions' });
    const cs = await clientSecretHash();
    return this._performAnonymousCall(
      requestId,
      '/auth/passkey/options/' + kClientId + '/' + cs + '/' + username,
      AcceptType.AcceptTypeJson,
      HttpMethod.Get,
    )
      .then(function (response) {
        if (response.status === 200) {
          log.info({
            requestId,
            msg: 'getAuthorizationOptions',
            data: response.data as PasskeyAuthorizationOptionsResponse,
          });
          Backend.getInstance().authorizationOptions =
            response.data as PasskeyAuthorizationOptionsResponse;
          return true;
        }
        return false;
      })
      .catch(error => {
        if (
          error.response?.status === HttpResponseCode.HttpResponseCodeNotFound
        ) {
          return false;
        }
        log.error({ requestId, msg: 'getAuthorizationOptions', error });
        return false;
      });
  }

  public async _verifyPasskey(
    requestId: string | null,
    verifyPasskeyRequest: VerifyPasskeyRequest,
    fingerprint?: string,
  ): Promise<boolean> {
    log.debug({ requestId, msg: 'verifyPasskey', verifyPasskeyRequest });
    return this._performAnonymousCall(
      requestId,
      '/auth/passkey/verify',
      AcceptType.AcceptTypeJson,
      HttpMethod.Post,
      verifyPasskeyRequest as unknown as Record<string, unknown>,
      fingerprint !== undefined
        ? {
          fingerprint,
        }
        : null,
    )
      .then(function (response) {
        if (response.status === 200) {
          Backend.getInstance().passkeyVerificationResponse =
            response.data as VerifyPasskeyResponse;
          return true;
        }
        return false;
      })
      .catch(error => {
        log.error({ requestId, msg: 'verifyPasskey', error });
        return false;
      });
  }

  public async loginWithPasskey(
    requestId: string | null,
    fingerprint?: string,
  ): Promise<boolean> {
    log.debug({ requestId, msg: 'loginWithPasskey' });
    const pkao = this.authorizationOptions;

    if (pkao === null) {
      log.error({ requestId, msg: 'loginWithPasskey', error: 'no pkao' });
      return false;
    }

    let pkcro: PublicKeyCredentialRequestOptionsJSON | null = null;
    let authResponseJSON: AuthenticationResponseJSON | null = null;
    try {
      pkcro = JSON.parse(pkao.pkcro);
      if (pkcro === null) {
        log.error({ requestId, msg: 'loginWithPasskey', error: 'no pkcro' });
        return false;
      }
      log.info({ requestId, msg: 'loginWithPasskey', pkcro });
      authResponseJSON = await startAuthentication(pkcro);
    } catch (error) {
      log.error({ requestId, msg: 'loginWithPasskey', error });
      return false;
    }
    const cs = await clientSecretHash();
    const parser = new UAParser();
    const uaInfo = parser.getResult();
    let osInfo = uaInfo.os.name ?? '';
    if (
      !osInfo.toUpperCase().includes('MACOS') &&
      !osInfo.toUpperCase().includes('MAC OS')
    ) {
      osInfo = osInfo + '(' + uaInfo.os.version + ')';
    }
    let browser = uaInfo.browser.name ?? '??';
    const bv = uaInfo.browser.version;
    if (bv !== undefined) {
      browser = browser + ' ' + bv;
    }
    const vpkr: VerifyPasskeyRequest = {
      clientId: kClientId,
      clientSecret: cs,
      targetClientId: this.targetClientId,
      username: pkao.username,
      userId: pkao.userId,
      challengeId: pkao.challengeId,
      arjs: JSON.stringify(authResponseJSON),
      userOs: osInfo,
      userApp: browser,
      verifyOnly: false,
    };
    log.info({ requestId, msg: 'loginWithPasskey -> will call verifyPasskey' });
    return this._verifyPasskey(requestId, vpkr, fingerprint);
  }

  public async requestClientRedirectStart(
    requestId: string | null,
    username: string,
    password: string,
    fingerprint?: string,
  ): Promise<null | ClientRedirectResponse> {
    log.debug({ requestId, msg: 'requestClientRedirectStart' });
    const cs = await clientSecretHash();
    const crr: ClientRedirectRequest = {
      clientId: kClientId,
      clientSecret: cs,
      username,
      password,
      targetClientId: this.targetClientId,
    };
    return this._performAnonymousCall(
      requestId,
      '/auth/rcr',
      AcceptType.AcceptTypeJson,
      HttpMethod.Post,
      crr as unknown as Record<string, unknown>,
      fingerprint !== undefined
        ? {
          fingerprint,
        }
        : null,
    )
      .then(function (response) {
        if (response.status === 200) {
          return response.data as ClientRedirectResponse;
        }
        return null;
      })
      .catch(error => {
        log.error({ requestId, msg: 'requestClientRedirectStart', error });
        return null;
      });
  }

  // =========================================================================
  // TODO: deprecated Get the redirect URL
  // =========================================================================
  public async getTargetClientBaseUrl(
    requestId: string | null,
  ): Promise<ClientBaseUrlResponse | null> {
    log.info({
      requestId,
      msg: 'requestClientRedirectStart',
      targetClientId: this.targetClientId,
    });
    const cs = await clientSecretHash();
    return this._performAnonymousCall(
      requestId,
      '/auth/baseurl',
      AcceptType.AcceptTypeJson,
      HttpMethod.Post,
      {
        clientId: kClientId,
        clientSecret: cs,
        targetClientId: this.targetClientId,
      },
    )
      .then(function (response) {
        if (response.status === 200) {
          return response.data as ClientBaseUrlResponse;
        }
        return null;
      })
      .catch(error => {
        log.error({ requestId, msg: 'requestClientRedirectStart', error });
        return null;
      });
  }

  // =========================================================================
  // Forgot Password
  // =========================================================================
  public async forgotPassword(
    requestId: string,
    username: string,
  ): Promise<boolean | null> {
    log.debug({ requestId, msg: 'forgotPassord', username });
    const call = '/anon/forgotpassword';
    const params: Omit<ForgotPasswordRequest, ''> = {
      username,
      tid: this.targetClientId,
    };
    return this._performAnonymousCall(
      requestId,
      call,
      AcceptType.AcceptTypeJson,
      HttpMethod.Post,
      params,
    )
      .then(function (response) {
        if (response.status === HttpResponseCode.HttpResponseCodeOk) {
          return true;
        }

        return null;
      })
      .catch(error => {
        log.error({ requestId, msg: 'forgotPassord -> Error = ', error });

        return null;
      });
  }

  // =========================================================================
  // VerifyPassword on PasskeyLogin
  // =========================================================================
  public async verifyPassword(
    requestId: string,
    password: string,
  ): Promise<VerifyPasswordResponse | null | false> {
    log.debug({ requestId, msg: 'verifyPassword' });
    const call = '/auth/verifypassword';
    const params: Omit<VerifyPasswordRequest, ''> = {
      password,
      clientId: kClientId,
    };
    return this._performAnonymousCall(
      requestId,
      call,
      AcceptType.AcceptTypeJson,
      HttpMethod.Post,
      params,
    )
      .then(function (response) {
        if (response.status === HttpResponseCode.HttpResponseCodeOk) {
          return response.data as VerifyPasswordResponse;
        }

        if (
          response.status === HttpResponseCode.HttpResponseCodeNotAuthorized
        ) {
          return false;
        }

        return null;
      })
      .catch(error => {
        if (
          error.response.status ===
          HttpResponseCode.HttpResponseCodeNotAuthorized
        ) {
          return false;
        }
        log.error({ requestId, msg: 'verifyPassword -> Error = ', error });

        return null;
      });
  }

  public async verifyEmail(
    requestId: string,
    key: string,
    lock: string,
    fingerprint: string,
  ): Promise<ClientRedirectResponse | null | false> {
    log.debug({ requestId, msg: 'verifyEmail' });
    const call = '/auth/verifyemail';
    const params: Omit<EmailVerificationRequest, ''> = {
      key,
      lock,
      fingerprint,
    };
    return this._performAnonymousCall(
      requestId,
      call,
      AcceptType.AcceptTypeJson,
      HttpMethod.Post,
      params,
    )
      .then(function (response) {
        if (response.status === HttpResponseCode.HttpResponseCodeOk) {
          return response.data as ClientRedirectResponse;
        }

        if (
          response.status === HttpResponseCode.HttpResponseCodeNotAuthorized
        ) {
          return false;
        }

        return null;
      })
      .catch(error => {
        if (
          error.response.status ===
          HttpResponseCode.HttpResponseCodeNotAuthorized
        ) {
          return false;
        }
        log.error({ requestId, msg: 'verifyEmail -> Error = ', error });

        return null;
      });
  }

  public async redirectClient(
    requestId: string,
    tokenId: string,
    redirectUrl: ClientBaseUrlResponse | null,
    userId?: string,
  ): Promise<void> {
    if (redirectUrl === null) {
      log.error({
        requestId,
        msg: 'redirectClient',
        error: 'redirectUrl === null',
      });
      this.redirectDefault();
      return;
    }
    let baseUrl = redirectUrl.baseUrl;
    if (baseUrl === undefined) {
      log.error({
        requestId,
        msg: 'redirectClient',
        error: 'baseUrl === undefned',
      });
      this.redirectDefault();
      return;
    }
    if (!baseUrl.endsWith('/')) {
      baseUrl = baseUrl + '/';
    }
    if (redirectUrl.isNetfilesClassic === true) {
      // different call for netfiles classic
      baseUrl = baseUrl + ';internal&action=authsso?tid=' + tokenId;
      if (userId !== undefined) {
        baseUrl += '&uid=' + userId;
      }
      const rdr = this.redirectParam;
      if (rdr !== null) {
        baseUrl = baseUrl + '&rdr=' + rdr;
      }
    } else {
      baseUrl = baseUrl + 'sso/' + tokenId;
      if (userId !== undefined) {
        baseUrl += '/' + userId;
      }
      const rdr = this.redirectParam;
      if (rdr !== null) {
        baseUrl = baseUrl + '?rdr=' + rdr;
      }
    }
    window.location.replace(baseUrl);
  }

  public async getSecurityQuestionRequirements(
    requestId: string | null,
  ): Promise<SecurityQuestionRequirements | null> {
    log.debug({ requestId, msg: 'getSecurityQuestionRequirements' });
    const call = '/me/changesecurityquestion';
    return this._performAuthenticatedCall(
      requestId,
      call,
      AcceptType.AcceptTypeJson,
      HttpMethod.Get,
    )
      .then(function (response) {
        if (response.status === HttpResponseCode.HttpResponseCodeOk) {
          return (
            (response.data as SecurityQuestionRequirementsResponse).settings ??
            null
          );
        }
        return null;
      })
      .catch(error => {
        log.error({ requestId, msg: 'getSecurityQuestionRequirements', error });
        return null;
      });
  }

  public async changeSecurityQuestion(
    requestId: string | null,
    question: string,
    answer: string,
  ): Promise<ClientRedirectResponse | null> {
    log.debug({ requestId, msg: 'changeSecurityQuestion' });
    const call = '/auth/definesecurityquestion';
    const params: Omit<DefineSecurityQuestionRequest, ''> = {
      question,
      answer,
    };
    return this._performAuthenticatedCall(
      requestId,
      call,
      AcceptType.AcceptTypeJson,
      HttpMethod.Post,
      params,
    )
      .then(response => {
        if (
          response.status === HttpResponseCode.HttpResponseCodeOk &&
          response.data.tokenId !== undefined
        ) {
          return response.data as ClientRedirectResponse;
        }
        return null;
      })
      .catch(error => {
        log.error({ requestId, msg: 'changeSecurityQuestion', error });
        return null;
      });
  }

  public redirectDefault() {
    window.location.assign('https://netfiles.de/');
  }
}
