/* eslint-disable */
import { ItemType, itemTypeFromJSON, itemTypeToJSON } from './item';
import { Timestamp } from './google/protobuf/timestamp';
import { EffectivePermission, ItemPermissionsEntry } from './permission';
import { Labels } from './label';
import { Parent } from './document';
import {
  SettingsEntryAsString,
  SettingsEntryAsBool,
  SettingsEntryAsDate,
} from './settings';

export const protobufPackage = 'de.netfiles.pb';

/** Shortcut Object */
export interface Shortcut {
  id: string;
  dataroomId: string;
  parentId: string;
  name: string;
  type: string;
  author: string;
  title: string;
  path?: string | undefined;
  creationDate: Date | undefined;
  modificationDate: Date | undefined;
  effectivePermission: EffectivePermission | undefined;
  mimeType: string;
  sequence?: string | undefined;
  index?: string | undefined;
  noIndex?: boolean | undefined;
  description: string;
  documentClass: string;
  permissions: ItemPermissionsEntry[];
  labels?: Labels | undefined;
  targetId?: string | undefined;
  targetVersion?: string | undefined;
  parentTitle?: string | undefined;
  itemType: ItemType;
  hasDifferentParentRights?: boolean | undefined;
  targetType?: ItemType | undefined;
  numberOfComments?: number | undefined;
  parent?: Parent | undefined;
}

/** Document Properties */
export interface ShortcutProperties {
  id: string;
  name?: SettingsEntryAsString | undefined;
  author?: SettingsEntryAsString | undefined;
  title?: SettingsEntryAsString | undefined;
  sequence?: SettingsEntryAsString | undefined;
  index?: SettingsEntryAsString | undefined;
  noIndex?: SettingsEntryAsBool | undefined;
  path?: SettingsEntryAsString | undefined;
  tags?: SettingsEntryAsString | undefined;
  creationDate?: SettingsEntryAsDate | undefined;
  modificationDate?: SettingsEntryAsDate | undefined;
  description?: SettingsEntryAsString | undefined;
  targetTitle?: SettingsEntryAsString | undefined;
  targetPath?: SettingsEntryAsString | undefined;
  deletedOn?: SettingsEntryAsDate | undefined;
  hasDifferentParentRights?: SettingsEntryAsBool | undefined;
  itemType: ItemType;
  targetType?: ItemType | undefined;
}

function createBaseShortcut(): Shortcut {
  return {
    id: '',
    dataroomId: '',
    parentId: '',
    name: '',
    type: '',
    author: '',
    title: '',
    path: undefined,
    creationDate: undefined,
    modificationDate: undefined,
    effectivePermission: undefined,
    mimeType: '',
    sequence: undefined,
    index: undefined,
    noIndex: undefined,
    description: '',
    documentClass: '',
    permissions: [],
    labels: undefined,
    targetId: undefined,
    targetVersion: undefined,
    parentTitle: undefined,
    itemType: 0,
    hasDifferentParentRights: undefined,
    targetType: undefined,
    numberOfComments: undefined,
    parent: undefined,
  };
}

export const Shortcut = {
  fromJSON(object: any): Shortcut {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      dataroomId: isSet(object.dataroomId) ? String(object.dataroomId) : '',
      parentId: isSet(object.parentId) ? String(object.parentId) : '',
      name: isSet(object.name) ? String(object.name) : '',
      type: isSet(object.type) ? String(object.type) : '',
      author: isSet(object.author) ? String(object.author) : '',
      title: isSet(object.title) ? String(object.title) : '',
      path: isSet(object.path) ? String(object.path) : undefined,
      creationDate: isSet(object.creationDate)
        ? fromJsonTimestamp(object.creationDate)
        : undefined,
      modificationDate: isSet(object.modificationDate)
        ? fromJsonTimestamp(object.modificationDate)
        : undefined,
      effectivePermission: isSet(object.effectivePermission)
        ? EffectivePermission.fromJSON(object.effectivePermission)
        : undefined,
      mimeType: isSet(object.mimeType) ? String(object.mimeType) : '',
      sequence: isSet(object.sequence) ? String(object.sequence) : undefined,
      index: isSet(object.index) ? String(object.index) : undefined,
      noIndex: isSet(object.noIndex) ? Boolean(object.noIndex) : undefined,
      description: isSet(object.description) ? String(object.description) : '',
      documentClass: isSet(object.documentClass)
        ? String(object.documentClass)
        : '',
      permissions: Array.isArray(object?.permissions)
        ? object.permissions.map((e: any) => ItemPermissionsEntry.fromJSON(e))
        : [],
      labels: isSet(object.labels) ? Labels.fromJSON(object.labels) : undefined,
      targetId: isSet(object.targetId) ? String(object.targetId) : undefined,
      targetVersion: isSet(object.targetVersion)
        ? String(object.targetVersion)
        : undefined,
      parentTitle: isSet(object.parentTitle)
        ? String(object.parentTitle)
        : undefined,
      itemType: isSet(object.itemType) ? itemTypeFromJSON(object.itemType) : 0,
      hasDifferentParentRights: isSet(object.hasDifferentParentRights)
        ? Boolean(object.hasDifferentParentRights)
        : undefined,
      targetType: isSet(object.targetType)
        ? itemTypeFromJSON(object.targetType)
        : undefined,
      numberOfComments: isSet(object.numberOfComments)
        ? Number(object.numberOfComments)
        : undefined,
      parent: isSet(object.parent) ? Parent.fromJSON(object.parent) : undefined,
    };
  },

  toJSON(message: Shortcut): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.dataroomId !== undefined && (obj.dataroomId = message.dataroomId);
    message.parentId !== undefined && (obj.parentId = message.parentId);
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined && (obj.type = message.type);
    message.author !== undefined && (obj.author = message.author);
    message.title !== undefined && (obj.title = message.title);
    message.path !== undefined && (obj.path = message.path);
    message.creationDate !== undefined &&
      (obj.creationDate = message.creationDate.toISOString());
    message.modificationDate !== undefined &&
      (obj.modificationDate = message.modificationDate.toISOString());
    message.effectivePermission !== undefined &&
      (obj.effectivePermission = message.effectivePermission
        ? EffectivePermission.toJSON(message.effectivePermission)
        : undefined);
    message.mimeType !== undefined && (obj.mimeType = message.mimeType);
    message.sequence !== undefined && (obj.sequence = message.sequence);
    message.index !== undefined && (obj.index = message.index);
    message.noIndex !== undefined && (obj.noIndex = message.noIndex);
    message.description !== undefined &&
      (obj.description = message.description);
    message.documentClass !== undefined &&
      (obj.documentClass = message.documentClass);
    if (message.permissions) {
      obj.permissions = message.permissions.map(e =>
        e ? ItemPermissionsEntry.toJSON(e) : undefined,
      );
    } else {
      obj.permissions = [];
    }
    message.labels !== undefined &&
      (obj.labels = message.labels ? Labels.toJSON(message.labels) : undefined);
    message.targetId !== undefined && (obj.targetId = message.targetId);
    message.targetVersion !== undefined &&
      (obj.targetVersion = message.targetVersion);
    message.parentTitle !== undefined &&
      (obj.parentTitle = message.parentTitle);
    message.itemType !== undefined &&
      (obj.itemType = itemTypeToJSON(message.itemType));
    message.hasDifferentParentRights !== undefined &&
      (obj.hasDifferentParentRights = message.hasDifferentParentRights);
    message.targetType !== undefined &&
      (obj.targetType =
        message.targetType !== undefined
          ? itemTypeToJSON(message.targetType)
          : undefined);
    message.numberOfComments !== undefined &&
      (obj.numberOfComments = Math.round(message.numberOfComments));
    message.parent !== undefined &&
      (obj.parent = message.parent ? Parent.toJSON(message.parent) : undefined);
    return obj;
  },
};

function createBaseShortcutProperties(): ShortcutProperties {
  return {
    id: '',
    name: undefined,
    author: undefined,
    title: undefined,
    sequence: undefined,
    index: undefined,
    noIndex: undefined,
    path: undefined,
    tags: undefined,
    creationDate: undefined,
    modificationDate: undefined,
    description: undefined,
    targetTitle: undefined,
    targetPath: undefined,
    deletedOn: undefined,
    hasDifferentParentRights: undefined,
    itemType: 0,
    targetType: undefined,
  };
}

export const ShortcutProperties = {
  fromJSON(object: any): ShortcutProperties {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name)
        ? SettingsEntryAsString.fromJSON(object.name)
        : undefined,
      author: isSet(object.author)
        ? SettingsEntryAsString.fromJSON(object.author)
        : undefined,
      title: isSet(object.title)
        ? SettingsEntryAsString.fromJSON(object.title)
        : undefined,
      sequence: isSet(object.sequence)
        ? SettingsEntryAsString.fromJSON(object.sequence)
        : undefined,
      index: isSet(object.index)
        ? SettingsEntryAsString.fromJSON(object.index)
        : undefined,
      noIndex: isSet(object.noIndex)
        ? SettingsEntryAsBool.fromJSON(object.noIndex)
        : undefined,
      path: isSet(object.path)
        ? SettingsEntryAsString.fromJSON(object.path)
        : undefined,
      tags: isSet(object.tags)
        ? SettingsEntryAsString.fromJSON(object.tags)
        : undefined,
      creationDate: isSet(object.creationDate)
        ? SettingsEntryAsDate.fromJSON(object.creationDate)
        : undefined,
      modificationDate: isSet(object.modificationDate)
        ? SettingsEntryAsDate.fromJSON(object.modificationDate)
        : undefined,
      description: isSet(object.description)
        ? SettingsEntryAsString.fromJSON(object.description)
        : undefined,
      targetTitle: isSet(object.targetTitle)
        ? SettingsEntryAsString.fromJSON(object.targetTitle)
        : undefined,
      targetPath: isSet(object.targetPath)
        ? SettingsEntryAsString.fromJSON(object.targetPath)
        : undefined,
      deletedOn: isSet(object.deletedOn)
        ? SettingsEntryAsDate.fromJSON(object.deletedOn)
        : undefined,
      hasDifferentParentRights: isSet(object.hasDifferentParentRights)
        ? SettingsEntryAsBool.fromJSON(object.hasDifferentParentRights)
        : undefined,
      itemType: isSet(object.itemType) ? itemTypeFromJSON(object.itemType) : 0,
      targetType: isSet(object.targetType)
        ? itemTypeFromJSON(object.targetType)
        : undefined,
    };
  },

  toJSON(message: ShortcutProperties): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined &&
      (obj.name = message.name
        ? SettingsEntryAsString.toJSON(message.name)
        : undefined);
    message.author !== undefined &&
      (obj.author = message.author
        ? SettingsEntryAsString.toJSON(message.author)
        : undefined);
    message.title !== undefined &&
      (obj.title = message.title
        ? SettingsEntryAsString.toJSON(message.title)
        : undefined);
    message.sequence !== undefined &&
      (obj.sequence = message.sequence
        ? SettingsEntryAsString.toJSON(message.sequence)
        : undefined);
    message.index !== undefined &&
      (obj.index = message.index
        ? SettingsEntryAsString.toJSON(message.index)
        : undefined);
    message.noIndex !== undefined &&
      (obj.noIndex = message.noIndex
        ? SettingsEntryAsBool.toJSON(message.noIndex)
        : undefined);
    message.path !== undefined &&
      (obj.path = message.path
        ? SettingsEntryAsString.toJSON(message.path)
        : undefined);
    message.tags !== undefined &&
      (obj.tags = message.tags
        ? SettingsEntryAsString.toJSON(message.tags)
        : undefined);
    message.creationDate !== undefined &&
      (obj.creationDate = message.creationDate
        ? SettingsEntryAsDate.toJSON(message.creationDate)
        : undefined);
    message.modificationDate !== undefined &&
      (obj.modificationDate = message.modificationDate
        ? SettingsEntryAsDate.toJSON(message.modificationDate)
        : undefined);
    message.description !== undefined &&
      (obj.description = message.description
        ? SettingsEntryAsString.toJSON(message.description)
        : undefined);
    message.targetTitle !== undefined &&
      (obj.targetTitle = message.targetTitle
        ? SettingsEntryAsString.toJSON(message.targetTitle)
        : undefined);
    message.targetPath !== undefined &&
      (obj.targetPath = message.targetPath
        ? SettingsEntryAsString.toJSON(message.targetPath)
        : undefined);
    message.deletedOn !== undefined &&
      (obj.deletedOn = message.deletedOn
        ? SettingsEntryAsDate.toJSON(message.deletedOn)
        : undefined);
    message.hasDifferentParentRights !== undefined &&
      (obj.hasDifferentParentRights = message.hasDifferentParentRights
        ? SettingsEntryAsBool.toJSON(message.hasDifferentParentRights)
        : undefined);
    message.itemType !== undefined &&
      (obj.itemType = itemTypeToJSON(message.itemType));
    message.targetType !== undefined &&
      (obj.targetType =
        message.targetType !== undefined
          ? itemTypeToJSON(message.targetType)
          : undefined);
    return obj;
  },
};

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
